import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ListingComp, ListingCompTable } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const DEFAULT_HEADERS = ['Order ID', 'Order date', 'Package', 'Status', 'Payment method', 'Total'];

const DEFAULT_ADMIN_HEADER = ['Account ID', 'Order ID', 'Order date', 'Package', 'Status', 'Payment method', 'Total'];

const filterOptions = {
  sort: false,
  search: true,
  dateRange: true,
  type: false,
  status: true,
  clear: true,
};

const extraOptions = {
  status: {
    all: { value: '', name: 'All' },
    draft: { value: 'draft', name: 'Draft' },
    pending_payment: { value: 'pending_payment', name: 'Pending Payment' },
    paid: { value: 'paid', name: 'Paid' },
    completed: { value: 'completed', name: 'Completed' },
    cancelled: { value: 'cancelled', name: 'Cancelled' },
  },
};

let mounted = false;
const OrderListing = (props) => {
  const { children, className, staticContext, limit = 10, accountId, routeType = 'account', showPaginator = true, ...rest } = props;
  const classes = useStyles();
  const [runLoadOrders] = useAsyncTask('loadOrders');
  const [listing, setListing] = useState([]);
  const [filter, setFilter] = useState({ limit, offset: 0 });
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const [loading, setLoading] = useState();

  // const router = useRouter();

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const loadOrders = (newFilter = filter) => {
    runLoadOrders(async () => {
      const loader = routeType === 'account' ? API.Users.list_orders : API.Admin.list_orders;
      if (accountId) newFilter.account = accountId;
      const { models, meta } = await loader(newFilter);
      if (mounted) {
        sortListingData(models);
        setFilter({ ...newFilter, count: meta.count });
        setLoading(false);
      }
    });
  };

  const getOrderLink = (id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/order/${id}/detail`;
      default:
        return `/order/${id}/detail`;
    }
  };

  const sortListingData = (models) => {
    let allData = [];
    if (routeType === 'admin' && !accountId && accountId !== 0) {
      models.forEach((ord) => {
        allData.push([
          { label: ord.account?.name, type: 'labelwithlink', bottomLabel: ord.account?.reference, link: `/admin/accounts/${ord.account?.id}/detail` },
          { label: ord.reference, type: 'link', link: getOrderLink(ord.id) },
          { date: ord.created_at, type: 'date' },
          { label: ord.order_items?.map((item) => item.name).join(', ') },
          { label: ord.status, statusType: 'Order', type: 'status' },
          { label: ord.payment_method },
          { type: 'currency', amount: ord.total, currency: ord.currency },
        ]);
      });
    } else {
      models.forEach((ord) => {
        allData.push([
          { label: ord.reference, type: 'link', link: getOrderLink(ord.id) },
          { date: ord.created_at, type: 'date' },
          { label: ord.order_items?.map((item) => item.name).join(', ') },
          { label: ord.status, statusType: 'Order', type: 'status' },
          { label: ord.payment_method },
          { type: 'currency', amount: ord.total, currency: ord.currency },
        ]);
      });
    }
    setListing(allData);
  };

  return (
    <Box justifyItems="center" {...rest} className={clsx(classes.root, className)}>
      <ListingComp
        filter={filter}
        setFilter={setFilter}
        showPaginator={showPaginator}
        loading={loadTask.loadOrders || loading}
        reloadFunc={loadOrders}
        filterOptions={filterOptions}
        extraOptions={extraOptions}
        showFilter={true}
        setLoading={setLoading}
        dateKey="date"
        searchByText={'Order ID'}
        // sortKey="create_at" dateKey="create_date"
      >
        {listing.length > 0 && !loadTask.loadOrders && <ListingCompTable headers={routeType === 'admin' && !accountId && accountId !== 0 ? DEFAULT_ADMIN_HEADER : DEFAULT_HEADERS} data={listing} />}
      </ListingComp>
    </Box>
  );
};

export default OrderListing;
