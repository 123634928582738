import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Title } from 'app/components';
import { ProductDetailComp } from './components';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    padding: spacing(3, 3, 8),
    [breakpoints.down('xs')]: {
      padding: spacing(1, 1, 3),
    },
  },
}));

function ProductDetail() {
  const classes = useStyles();

  return (
    <Box justifyItems="center" className={classes.root}>
      <Title prefix={'Product detail'} />
      <ProductDetailComp />
    </Box>
  );
}

export default ProductDetail;
