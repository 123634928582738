import { Box, Dialog, InputBase, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, TraxxButton, TraxxInput } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 360,
  },
  fieldMargin: {
    marginBottom: theme.spacing(2),
  },
}));

const UpdateCredit = (props) => {
  const { credit, walletId, handleClose, open, onReload, className, ...rest } = props;
  const classes = useStyles();

  const [inputs, setInputs] = useState({
    amount: parseInt(parseInt(credit.amount) / 100),
    expiry_date: credit.expiry_date,
    status: credit.status,
  });
  const [runUpdateCredit] = useAsyncTask('updateCredit');
  const loadTasks = useSelector(({ layout }) => layout.loadingTasks);
  const { enqueueSnackbar } = useSnackbar();

  const checkForNoChanges = () => {
    if (parseInt(credit.amount) === inputs.amount * 100) {
      return false;
    }
    if (!moment(credit.expiry_date).diff(inputs.expiry_date, 'day')) {
      return false;
    }
    if (credit.status !== InputBase.status) return false;
    return true;
  };

  const onSubmit = () => {
    if (checkForNoChanges()) {
      enqueueSnackbar('No changes to input');
      return;
    }
    runUpdateCredit(async () => {
      await API.Admin.update_credit(walletId, credit.id, { ...inputs, amount: inputs.amount * 100 });
      if (typeof onReload === 'function') onReload();
      if (typeof handleClose === 'function') handleClose();
    });
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <Box p={4} {...rest} className={clsx(classes.root, className)}>
        <TraxxInput
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          placeholder="Amount"
          type="number"
          className={classes.fieldMargin}
          value={inputs.amount}
          onChange={({ target }) => setInputs({ ...inputs, amount: target.value })}
        />

        <DatePicker
          disablePast={true}
          disableFuture={false}
          placeholder="DD-MMM-YYYY"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.fieldMargin}
          label="Date of Expiry"
          onChange={(value) => setInputs({ ...inputs, expiry_date: value })}
          value={inputs.expiry_date}
          type={'date'}
          name={'expiry_date'}
        />

        <Select variant="outlined" value={inputs.status} fullWidth onChange={({ target }) => setInputs({ ...inputs, status: target.value })}>
          <MenuItem value="valid">Valid</MenuItem>
          <MenuItem value="invalid">Invalid</MenuItem>
        </Select>

        <Box mt={4}>
          <TraxxButton fullWidth loading={loadTasks.updateCredit} onSubmit={onSubmit}>
            Update credit
          </TraxxButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UpdateCredit;
