import { Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { ListingComp, ListingCompTable } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask, useMoneyFormatter } from 'app/utils';
import { useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { UpdateCredit, InvalidateCredit } from './components';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const headers = ['Credit amount', 'Expiration date', 'Remark', 'Status'];

const admin_headers = ['Credit amount', 'Expiration date', 'Remark', 'Status', 'Update credit'];

const CreditListingComp = (props) => {
  const { walletId, routeType, children, className, ...rest } = props;
  const classes = useStyles();

  const [runCreditList] = useAsyncTask('loadCredits');
  const [listing, setListing] = useState();
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const loadTasks = useSelector(({ layout }) => layout.loadingTasks);
  const [showUpdate, setShowUpdate] = useState(false);
  const [selectedCredit, setCredit] = useState();
  const [invalideCredit, setInvalideCredit] = useState();
  const [currentWalletId, setCurrentWalletId] = useState(walletId);
  const formatter = useMoneyFormatter({ currency: 'TXC', showCurrency: true, decPlace: 0 });

  const loadCredits = (newFilter = filter) => {
    runCreditList(async () => {
      let wallet_id = walletId;
      if (!wallet_id) {
        const { wallet } = await API.Wallet.get_main_wallet();
        wallet_id = wallet.id;
      }
      const loader = routeType === 'account' ? API.Wallet.list_credit : API.Admin.list_credit;
      const { models, meta } = await loader(wallet_id);
      setCurrentWalletId(wallet_id);
      sortListingData(models);
      setFilter({ ...newFilter, count: meta.count });
    });
  };

  const getUpdateContent = (model) => {
    return (
      <Box display="flex">
        <IconButton
          onClick={() => {
            setShowUpdate(true);
            setCredit(model);
          }}>
          <EditIcon />
        </IconButton>
        {model.status === 'valid' && (
          <IconButton
            onClick={() => {
              setInvalideCredit(model);
            }}>
            <RemoveCircleIcon color="error" />
          </IconButton>
        )}
      </Box>
    );
  };

  const handleInvalideClose = () => {
    setInvalideCredit(null);
  };

  const handleClose = () => {
    setShowUpdate(false);
  };

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      if (routeType === 'admin') {
        allData.push([{ label: formatter(model.amount) }, { date: model.expiry_date, type: 'date' }, { label: model.remark }, { label: model.status, type: 'status', statusType: 'Credit' }, { type: 'custom', value: getUpdateContent(model) }]);
      } else {
        allData.push([{ label: formatter(model.amount) }, { date: model.expiry_date, type: 'date' }, { label: model.remark }, { label: model.status, type: 'status', statusType: 'Credit' }]);
      }
    });
    setListing(allData);
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <ListingComp showFilter={false} reloadFunc={loadCredits} filter={filter} setFilter={setFilter} loading={loadTasks.loadCredits}>
        {!!listing?.length && <ListingCompTable headers={routeType === 'admin' ? admin_headers : headers} data={listing} />}
      </ListingComp>
      {selectedCredit && showUpdate && <UpdateCredit onReload={loadCredits} credit={selectedCredit} walletId={currentWalletId} handleClose={handleClose} open={showUpdate} />}
      {invalideCredit && <InvalidateCredit handleClose={handleInvalideClose} walletId={currentWalletId} open={invalideCredit} credit={invalideCredit} onReload={loadCredits} />}
    </Box>
  );
};

export default CreditListingComp;
