import { Table, TableBody, LinearProgress, Checkbox, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useToastCatcher } from 'app/utils';
import { API } from 'app/services';

let mounted = false;
const EditPrivilege = (props) => {
  const { user, onUpdate } = props;
  const [privileges, setPrivileges] = useState(null);
  const loading = useSelector((state) => state.layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const [toaster, errorCatcher] = useToastCatcher({ errorParser: null, taskname: 'editPrivilege' });

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    if (access && access.token) listPrivileges();
    // eslint-disable-next-line
  }, [access]);

  const listPrivileges = () => {
    errorCatcher(async () => {
      const { models } = await API.Users.list_privilege();
      if (mounted) setPrivileges(models);
    });
  };

  const filterPrivileges = (privilege) => {
    return user.roles.indexOf(privilege.name) > -1 && privilege.manageable;
  };

  const handleChecked = (privilegeId) => {
    return () => {
      let hadPrivileges = privileges.filter(filterPrivileges).map((priv) => priv.id);

      if (hadPrivileges.indexOf(privilegeId) > -1) {
        hadPrivileges.splice(hadPrivileges.indexOf(privilegeId), 1);
      } else {
        hadPrivileges.push(privilegeId);
      }

      hadPrivileges = hadPrivileges.sort();

      errorCatcher(async () => {
        await API.Users.set_privilege(user.id, { privileges: hadPrivileges });
        if (typeof onUpdate === 'function') onUpdate();
        toaster('Privilege Updated!', { variant: 'success' });
      });
    };
  };

  return (
    <>
      {loading['editPrivilege'] && <LinearProgress size={30} />}
      {privileges && user && (
        <Table>
          <TableBody>
            {privileges
              .filter((pri) => pri.manageable)
              .map((priv, index) => (
                <TableRow key={index}>
                  <TableCell align="left">
                    {' '}
                    <Checkbox disabled={!!loading['editPrivilege']} checked={user.roles.indexOf(priv.name) > -1} onChange={handleChecked(priv.id)} name="rememberMe" />
                  </TableCell>
                  <TableCell align="left" fontWeight="bold">
                    {priv.label}
                  </TableCell>
                  <TableCell align="right">{priv.description}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default EditPrivilege;
