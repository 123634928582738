import { Box, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton, TraxxInput } from 'app/components';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 663,
    justifyContent: 'space-between',
    padding: 40,
    width: 410,
  },
  image: {
    height: 200,
    width: 200,
  },
}));

function RequestView(props) {
  const classes = useStyles();

  const { onRequest, emailError, setEmail, email } = props;

  const loading = useSelector((state) => state.layout.loadingTasks);
  const handleChange = ({ target }) => setEmail(target.value);

  return (
    <Card className={classes.card}>
      <form onSubmit={onRequest} className={classes.form} autoComplete="false">
        <Box alignSelf="flex-start">
          <TraxxButton component={RouterLink} startIcon={<img src="/images/arrow_back.svg" alt="back to log in" />} to="/access/login" variant="text">
            Back to log in
          </TraxxButton>
        </Box>

        <img className={classes.image} src="/images/forgot_password.svg" alt="forgot password" />

        <Typography variant="h5">Forgot your password&#63;</Typography>

        <Typography align="center" variant="body2">
          Enter the email address associated with your account for us to send you a password reset link&#46;
        </Typography>

        <TraxxInput id="ForgetPasswordRequest-email" autoFocus autoComplete="email" error={emailError} label="Email address" onChange={handleChange} placeholder="example@email.com" value={email} />

        <TraxxButton loading={loading['forgetpassword']} fullWidth form onSubmit={onRequest}>
          Send password reset link
        </TraxxButton>
      </form>
    </Card>
  );
}

export default RequestView;
