import { Box, Checkbox, Chip, Dialog, FormControlLabel, FormGroup, FormLabel, Grid, LinearProgress, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import { Alert } from '@material-ui/lab';
import { Crumbs, DataRow, DateDisplay, DowJonesBrand, ListingComp, MonoText, RefinitivBrand, Title, TraxxButton } from 'app/components';
import { API } from 'app/services';
import { snakeToTitle, useAsyncTask, useRouter } from 'app/utils';
import CryptoJS from 'crypto-js';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import DoubleScrollbar from 'react-double-scrollbar';
import { useSelector } from 'react-redux';
import { Link as BrowserLink } from 'react-router-dom';
import { DownloadPreview } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  table: {
    marginTop: 14,
    borderRadius: 5,
  },
  head: {
    fontWeight: 'bold',
    maxWidth: 200,
  },
  subtitle: {
    opacity: '70%',
  },
  linkBox: {
    minHeight: 600,
    maxHeight: 800,
    width: 700,
    padding: 10,
  },
  linksHeader: {
    textAlign: 'center',
    padding: 10,
  },
  linkPointer: {
    cursor: 'pointer',
  },
  sendLinks: {
    maxWidth: 180,
  },
  linkEmailIcon: {
    float: 'right',
    fontSize: 'xx-large',
    cursor: 'pointer',
    marginRight: 20,
  },
  linkCalculate: {
    margin: 'auto',
  },
  linkConfirmationBox: {
    maxHeight: 500,
    width: 625,
    padding: 10,
  },
  linkConfirmationSubHeader: {
    textAlign: 'center',
  },
  paddingBottom: {
    paddingBottom: 10,
  },
  reportLinks: {
    marginBottom: 10,
  },
  articleRetrievalSubmitButton: {
    float: 'right',
  },
  clickReportLink: {
    cursor: 'pointer',
  },
  clickArticleLink: {
    cursor: 'text',
  },
}));

let mounted = false;
function MatchDetail({ routeType = 'account' }) {
  const classes = useStyles();
  const router = useRouter();
  const { match } = router;
  const { params } = match;
  const screening_id = params.screening_id;
  const match_id = params.match_id;
  const { enqueueSnackbar } = useSnackbar();
  const loading = useSelector((state) => state.layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const wallet = useSelector((state) => state.profile.wallet);
  const [errorCatcher] = useAsyncTask('getScreening');
  const [runMatchListing] = useAsyncTask('matchListing');
  const [runUnresolve] = useAsyncTask('unresolve');
  const [runSendLinks] = useAsyncTask('sendLinks');
  const [runRetrieveReport] = useAsyncTask('retrieveReport');
  const [matching, setMatching] = useState(null);
  const [matchProfile, setMatchProfile] = useState(null);
  const [printPreview, setPrintPreview] = useState(false);
  const [matchList, setMatchListing] = useState();
  const [openedLinks, setOpenedLinks] = useState(false);
  const [linksList, setLinksList] = useState([]);
  const [numOfLinks, setNumOfLinks] = useState(0);
  const [creditCost, setCreditCost] = useState(0);
  const [openLinksConfirmation, setOpenLinksConfirmation] = useState(false);
  const [articleRetrievalConfirmation, setArticleRetrievalConfirmation] = useState(false);
  const [report, setReport] = useState({});

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) getMatchDetail();
    // eslint-disable-next-line
  }, [access]);

  const getMatchDetail = () => {
    if (!screening_id || !match_id) return enqueueSnackbar('invalid id', { variant: 'error' });
    errorCatcher(async () => {
      const loader = routeType === 'account' ? API.Screening.get_match_detail : API.Admin.get_match;
      const { model } = await loader(screening_id, match_id);
      if (mounted) {
        setMatching(model);
        if (model.profile_retrieved_at && routeType === 'account') loadMatchProfiles();
        if (model.profile_retrieved_at && model.screen_profile) setMatchProfile(model.screen_profile);
        getMatchListing();
      }
    });
  };

  const getMatchListing = (filter = { limit: 1000 }) => {
    runMatchListing(async () => {
      const loader = routeType === 'account' ? API.Screening.get_matching_list : API.Admin.list_match;
      const { models } = await loader(screening_id, filter);
      if (mounted) {
        setMatchListing(models);
      }
    });
  };

  const loadMatchProfiles = () => {
    if (!screening_id || !match_id) return enqueueSnackbar('invalid id', { variant: 'error' });
    errorCatcher(async () => {
      const { profile } = await API.Screening.get_match_profile(screening_id, match_id);
      setMatchProfile(profile);
    });
  };

  const retrieveMatchProfile = () => {
    errorCatcher(async () => {
      await API.Screening.retrieve_match(screening_id, match_id);
      enqueueSnackbar('Profile retrieved', { variant: 'success' });
      getMatchDetail();
    });
  };

  const getScreenDetailPath = (id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/screenings/${id}/detail`;
      default:
        return `/screenings/${id}/detail`;
    }
  };

  const getCaseDetailPath = (id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/screenings/cases/${id}/detail`;
      default:
        return `/screenings/cases/${id}/detail`;
    }
  };

  const getEncryptWorldId = (worldID) => {
    worldID = worldID.match(/\d+/g);
    return CryptoJS.AES.encrypt(worldID.toString(), 'TRAXX').toString(); // Decrypt at https://www.browserling.com/tools/aes-decrypt
  };

  const unResolve = () => {
    runUnresolve(async () => {
      await API.Users.unresolve_matches(screening_id, {
        resolve_ids: [match_id],
      });
      enqueueSnackbar('Match unresolved', { variant: 'success' });
      getMatchDetail();
    });
  };

  const sendLinksMenu = () => {
    setOpenedLinks(true);
  };

  const sendLinksMenuClose = () => {
    setOpenedLinks(false);
    resetLinksList();
  };

  const resetLinksList = () => {
    setLinksList([]);
    setNumOfLinks(0);
    setCreditCost(0);
  };

  const sendConfirmLinksMenuOpen = () => {
    if (linksList.length <= 0) {
      enqueueSnackbar('No links selected', { variant: 'error' });
    } else {
      setOpenedLinks(false);
      setOpenLinksConfirmation(true);
    }
  };

  const sendConfirmLinksMenuClose = () => {
    setOpenLinksConfirmation(false);
    resetLinksList();
  };

  const articleRetrievalConfirmationClose = () => {
    setArticleRetrievalConfirmation(false);
  };

  const submitArticleRetrievalConfirmation = async () => {
    if (creditCost * 100 > wallet.balance) {
      enqueueSnackbar('Insufficient wallet balance', { variant: 'error' });
    } else {
      runRetrieveReport(async () => {
        setArticleRetrievalConfirmation(false);
        enqueueSnackbar('Your request has been sent to the admin. Your account has been debited of 10 credits', {
          variant: 'success',
        });
      });
    }
  };

  const onLinkChange = (event) => {
    const name = event.target.name;
    const index = linksList.indexOf(name);
    if (index > -1) {
      linksList.splice(index, 1);
    } else {
      linksList.push(name);
    }
    setLinksList(linksList);
    setNumOfLinks(linksList.length);
    setCreditCost(linksList.length * 10);
  };

  const submitLinks = () => {
    if (creditCost * 100 > wallet.balance) {
      enqueueSnackbar('Insufficient wallet balance', { variant: 'error' });
    } else {
      runSendLinks(async () => {
        await API.Users.send_links(screening_id, match_id, {
          links: linksList,
          world_check_id: matching.pvd_ref1?.match(/\d+/g).toString(),
          profile_id: matching.pvd_ref?.toString(),
        });
        sendConfirmLinksMenuClose();
        enqueueSnackbar(`Invalid links request sent to Admin. Your account has been debited of ${creditCost} credits`, {
          variant: 'success',
        });
      });
    }
  };

  const cleanComment = (comment_details) => {
    let comment = '';
    if (comment_details?.si_comment) {
      comment = comment_details.si_comment;
    } else if (comment_details?.list_comment) {
      comment = comment_details.list_comment;
    }
    const comments = comment.split('\n');
    const conciseComments = comments.slice(3, comments.length);
    const listComments = conciseComments.map((c) => (
      <>
        {c}
        <br />
      </>
    ));

    return <>{listComments}</>;
  };

  const cleanRelationships = (relationships) => {
    const listRelationships = relationships
      ? relationships.map((c) => (
          <>
            {c.name_detail.first_name} {c.name_detail.middle_name} {c.name_detail.surname} ({c.icon_hints}) ({c.connection_type}),{' '}
          </>
        ))
      : null;
    return <>{listRelationships}</>;
  };

  const cleanReports = (reports) => {
    let listReports = [];
    listReports = reports
      ? reports.map((r) =>
          r.accession_number ? (
            <>
              <FormLabel
                className={classes.clickReportLink}
                // onClick={() => {
                //   setReport(r);
                //   setArticleRetrievalConfirmation(true);
                // }}
              >
                Report - {r.title}
              </FormLabel>
              <br />
              <br />
            </>
          ) : r.external_link ? (
            <>
              <FormLabel className={classes.clickArticleLink}>Article - {r.external_link}</FormLabel>
              <br />
              <br />
            </>
          ) : (
            <>
              <FormLabel>Article - {r.title}</FormLabel>
              <br />
              <br />
            </>
          )
        )
      : null;
    return <>{listReports}</>;
  };

  return (
    <Box className={classes.root}>
      {!printPreview && (
        <>
          <Crumbs
            current="Detail"
            key="crumbs"
            links={[
              {
                text: 'Screening',
                href: routeType === 'admin' ? '/admin/screenings' : '/screenings',
                key: 'screening',
              },
              {
                text: params.screening_id,
                href: routeType === 'admin' ? `/admin/screenings/${params.screening_id}/detail` : `/screenings/${params.screening_id}/detail`,
                kegy: 'screeningId',
              },
            ]}
          />
          <Title
            prefix={'Match detail'}
            floatButton={
              matching?.profile_retrieved_at && {
                onClick: () => {
                  setPrintPreview(true);
                },
                text: `Preview print document`,
              }
            }>
            {matching && matching.screen.screen_provider_id === 1 ? <RefinitivBrand /> : <DowJonesBrand />}
          </Title>
          <Grid spacing={2} container>
            <Grid item md={6} xs={12}>
              <TableContainer component={Paper} className={classes.table}>
                {loading.getScreening && <LinearProgress size={30} />}
                {!loading.getScreening && matching && (
                  <Table>
                    <TableBody>
                      <DataRow name="Name" value={matching.name} />
                      <DataRow name="Match rating" value={snakeToTitle(matching.match_rating)} />
                      {matching.screen.screen_provider_id === 2 && <DataRow name="Match Score" value={<MonoText>{matching.score}</MonoText>} />}
                      <DataRow name="Match type" value={matching.match_type} />
                      {(matching.screen.type === 'simple' || matching.screen.type === 'passport') && <DataRow name="Gender" value={snakeToTitle(matching.gender || '')} />}
                      <DataRow name="Created date" value={<DateDisplay date={matching.created_at} />} />
                      <DataRow name="Match ID" value={<MonoText>{matching.reference}</MonoText>} />
                      {matching.screen.screen_provider_id === 1 && <DataRow name="World-Check ID" value={<MonoText>{getEncryptWorldId(matching.pvd_ref1)}</MonoText>} />}
                      <DataRow
                        name="Screening ID"
                        value={
                          <Link underline="always" component={BrowserLink} to={getScreenDetailPath(matching.screen?.id)}>
                            <MonoText>{matching.screen?.reference}</MonoText>
                          </Link>
                        }
                      />
                      {matching.screen && matching.screen.case && (
                        <DataRow
                          name="Case ID"
                          value={
                            <Link underline="always" component={BrowserLink} to={getCaseDetailPath(matching.screen?.case?.id)}>
                              <MonoText>{matching.screen?.case?.reference}</MonoText>
                            </Link>
                          }
                        />
                      )}
                      <DataRow name="Resolved" value={<DateDisplay date={matching.resolved_at} />} />
                      {matching.resolved_reason && matching.resolved_at && <DataRow name="Resolved reason" value={<DateDisplay date={matching.resolved_reason} />} />}
                      {matching.resolved_at && routeType !== 'admin' && (
                        <DataRow
                          name=""
                          value={
                            <TraxxButton loading={loading.unresolve} onSubmit={() => unResolve()}>
                              <Typography>Unresolve</Typography>
                            </TraxxButton>
                          }
                        />
                      )}
                      {matching.profile_retrieved_at && <DataRow name="Profile retrieved date" value={<DateDisplay date={matching.profile_retrieved_at} />} />}
                      {!matching.profile_retrieved_at && routeType !== 'admin' && (
                        <DataRow
                          name={<Chip label="Profile not retrieved" />}
                          value={
                            <TraxxButton onSubmit={() => retrieveMatchProfile()}>
                              <Typography>Retrieve</Typography>
                            </TraxxButton>
                          }
                        />
                      )}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </Grid>
            {matchProfile && (
              <Grid item md={6} xs={12}>
                {loading.getScreening && <LinearProgress size={30} />}
                {!loading.getScreening && matching && (
                  <>
                    {matching.screen.screen_provider_id === 1 && (
                      <>
                        {matchProfile.details &&
                          matchProfile.details.map((detail) => (
                            <TableContainer component={Paper} className={classes.table}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell colSpan={2}>
                                      <Typography className={classes.head} variant="body1">
                                        {detail.title}
                                      </Typography>
                                      <Typography className={classes.subtitle} color="textSecondary " variant="body2">
                                        {detail.detailType}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell colSpan={2} align="left">
                                      {detail.text}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          ))}
                        <TableContainer component={Paper} className={classes.table}>
                          <DoubleScrollbar>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className={classes.head}>External links</TableCell>
                                  <TableCell className={classes.sendLinks}>
                                    {matchProfile.weblinks && (
                                      <Alert severity="info">
                                        Links are captured at time of publication. They may become invalid overtime. <br />
                                        If any of the link(s) below is/are not accessible, Please click the email icon for instructions to request cached copy retrieval.
                                        <EmailIcon className={classes.linkEmailIcon} onClick={sendLinksMenu} />
                                      </Alert>
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {matchProfile.weblinks &&
                                  matchProfile.weblinks.map((weblink) => (
                                    <TableRow>
                                      <TableCell colSpan={3} align="left">
                                        {weblink.caption || 'Article'} -{' '}
                                        <a target="_blank" rel="noopener noreferrer" href={weblink.uri}>
                                          {weblink.uri}
                                        </a>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </DoubleScrollbar>
                        </TableContainer>
                      </>
                    )}
                    {matching.screen.screen_provider_id === 2 && (
                      <>
                        <TableContainer component={Paper} className={classes.table}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell colSpan={2}>
                                  <Typography className={classes.head} variant="body1">
                                    Profile Notes
                                  </Typography>
                                  <Typography className={classes.subtitle} color="textSecondary " variant="body2">
                                    Profile
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={2} align="left">
                                  {cleanComment(matchProfile.details.watchlist.comment_details)}
                                  {matchProfile.details.watchlist.role_details?.primary.category_type}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <TableContainer component={Paper} className={classes.table}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell colSpan={2}>
                                  <Typography className={classes.head} variant="body1">
                                    Identification
                                  </Typography>
                                  <Typography className={classes.subtitle} color="textSecondary " variant="body2">
                                    Relationships
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={2} align="left">
                                  {cleanRelationships(matchProfile.names?.connection_details)}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <TableContainer component={Paper} className={classes.table}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell colSpan={2}>
                                  <Typography className={classes.head} variant="body1">
                                    Articles and Reports
                                  </Typography>
                                  <Typography className={classes.subtitle} color="textSecondary " variant="body2">
                                    <Alert severity="info">
                                      Articles and Reports are captured at time of publication. <br />
                                      Articles are links to websites or title of external articles you may search for. <br />
                                      Reports are publications captured and stored by Dow Jones. Please click the email icon for instructions to request report retrieval.
                                      {matchProfile.weblinks && <EmailIcon className={classes.linkEmailIcon} onClick={sendLinksMenu} />}
                                    </Alert>
                                    {!matchProfile.weblinks && <p>Sorry, no articles exist for this profile</p>}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={2} align="left">
                                  {cleanReports(matchProfile.weblinks)}
                                  {articleRetrievalConfirmation && (
                                    <Dialog maxWidth={'md'} open={articleRetrievalConfirmation} onClose={articleRetrievalConfirmationClose}>
                                      <Box className={classes.linkConfirmationBox}>
                                        <Typography variant="h5" className={classes.linksHeader}>
                                          Request for Report Retrieval
                                        </Typography>
                                        <hr />
                                        <Typography variant="body2">
                                          Each report retrieval will cost <b>10</b> credits. <br />
                                          Please confirm that you would like to request to retrieve article, {report.title}, by clicking the Confirm button. <br />
                                          <br />
                                          A request would be automatically send to the support team for retrieval. <br />
                                          They will respond in under 3 working days to your request. <br />
                                          If the request does not exist, 10 credits will be credited back to you. <br />
                                          <TraxxButton className={classes.articleRetrievalSubmitButton} onSubmit={submitArticleRetrievalConfirmation}>
                                            Confirm
                                          </TraxxButton>
                                        </Typography>
                                      </Box>
                                    </Dialog>
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )}
                  </>
                )}
              </Grid>
            )}
            {openedLinks && (
              <Dialog maxWidth={'md'} open={openedLinks} onClose={sendLinksMenuClose}>
                <Box className={classes.linkBox}>
                  <Typography variant="h5" className={classes.linksHeader}>
                    Cached Article/Report Retrieval Request
                  </Typography>
                  <hr />
                  <Typography variant="body2">
                    &emsp; Select the link(s)* below that you wish to retrieve a cached copy (subject to availability). <br />
                    &emsp; Each retrieval costs 10 Credits and will be debited upon submission of request. <br />
                    &emsp; In the event any of the retrieval request(s) is/are unsuccessful, credits will be returned accordingly. <br />
                    &emsp; You will get a retrieval status update email within 3 business days.
                  </Typography>
                  <Typography variant="caption">&emsp; *Please ensure that the links are invalid before sending this request</Typography>
                  <hr />
                  <Grid container>
                    <Grid item xs={10} className={classes.linkCalculate}>
                      <Typography variant="h6">
                        {' '}
                        &emsp; Links selected: {numOfLinks} &emsp; Credit cost: {creditCost}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <TraxxButton onSubmit={sendConfirmLinksMenuOpen}>Submit</TraxxButton>
                    </Grid>
                  </Grid>
                  <hr />
                  {matchProfile.weblinks
                    ? matchProfile.weblinks.map((weblink, index) => (
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={2} align="left">
                                <FormGroup>
                                  <FormControlLabel key={index} control={<Checkbox name={weblink.title || weblink.uri || weblink.external_link} onChange={onLinkChange} />} label={weblink.title || weblink.uri || weblink.external_link} />
                                </FormGroup>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      ))
                    : 'No Cached Article Reports Available'}
                  <hr />
                  <Grid container className={classes.paddingBottom}>
                    <Grid item xs={10} className={classes.linkCalculate}>
                      <Typography variant="h6">
                        {' '}
                        &emsp; Links selected: {numOfLinks} &emsp; Credit cost: {creditCost}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <TraxxButton onSubmit={sendConfirmLinksMenuOpen}>Submit</TraxxButton>
                    </Grid>
                  </Grid>
                </Box>
              </Dialog>
            )}
            {openLinksConfirmation && (
              <Dialog maxWidth={'md'} open={openLinksConfirmation} onClose={sendConfirmLinksMenuClose}>
                <Box className={classes.linkConfirmationBox}>
                  <Typography variant="h6" className={classes.linksHeader}>
                    Cached Article Retrieval Request Confirmation
                  </Typography>
                  <hr />
                  <Typography variant="body2" className={classes.linkConfirmationSubHeader}>
                    <b>
                      {'\u2B24'} You have requested {numOfLinks} link(s) ({creditCost} credits). Please click to confirm.
                    </b>
                  </Typography>
                  <hr />
                  {linksList.map((weblink, index) => (
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell key={index} colSpan={2} align="left">
                            {index + 1}) {weblink}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ))}
                  <hr />
                  <Grid container>
                    <Grid item xs={10} className={classes.linkCalculate}>
                      <Typography variant="h6">
                        {' '}
                        &emsp; Links selected: {numOfLinks} &emsp; Credit cost: {creditCost}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <TraxxButton onSubmit={submitLinks}>Confirm</TraxxButton>
                    </Grid>
                  </Grid>
                </Box>
              </Dialog>
            )}
            {matching && !!matching.profile_retrieved_at && matchProfile && matchProfile.details.length < 1 && !loading['getScreening'] && (
              <Grid item md={6} xs={12}>
                <ListingComp emptyMessage="No profiles found" showFilter={false} loaded={true} loading={false} showPaginator={false} filter={{ count: 0, limit: 10, offset: 0 }} />
              </Grid>
            )}
            {matching && !matching.profile_retrieved_at && !loading['getScreening'] && (
              <Grid item md={6} xs={12}>
                <ListingComp emptyMessage="Profile not retrieved" showFilter={false} loaded={true} loading={false} showPaginator={false} filter={{ count: 0, limit: 10, offset: 0 }} />
              </Grid>
            )}
          </Grid>
        </>
      )}
      {printPreview && <DownloadPreview matchList={matchList} cancelPrint={setPrintPreview} detail={matching} profile={matchProfile} />}
    </Box>
  );
}

export default MatchDetail;
