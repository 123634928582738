import { Card, CircularProgress, Grid, Typography, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useAsyncTask, useMoneyFormatter } from 'app/utils';
import { API } from 'app/services';
import { useSelector } from 'react-redux';
import { DatePicker } from 'app/components';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  card: {
    alignContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    height: 102,
    justifyContent: 'space-evenly',
    padding: theme.spacing(2),
  },
  category: { marginLeft: 10 },
  datepicker: {
    padding: theme.spacing(1.34),
  },
  firstForm: {
    marginTop: theme.spacing(1),
  },
}));

let mounted = false;
const AllBalance = ({ walletId, Title }) => {
  const classes = useStyles();
  const formatter = useMoneyFormatter({ currency: 'TXC', decPlaces: 0, showCurrency: true });
  const [runLoadAllBalance] = useAsyncTask('loadAllBalance');
  const [statistic, setStatistic] = useState();
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const [dateRange, setDateRange] = useState({
    start_date: moment().startOf('month'),
    end_date: moment(),
  });

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    let { start_date, end_date } = dateRange;
    if (start_date && end_date && access && access.token) loadBalance({ date: `${moment(start_date).unix()},${moment(end_date).unix()}` });
    // eslint-disable-next-line
  }, [dateRange]);

  useEffect(() => {
    if (access && access.token) loadBalance();
    // eslint-disable-next-line
  }, [access]);

  const loadBalance = (filter = { date: `${moment(dateRange.start_date).unix()},${moment(dateRange.end_date).unix()}` }) => {
    runLoadAllBalance(async () => {
      const loader = walletId ? API.Wallet.wallet_statistic : API.Admin.wallet_statistic;
      const result = await loader(filter, walletId);

      if (mounted) {
        setStatistic(result);
      }
    });
  };

  const { total_balance, total_purchases, total_spends } = statistic || {};
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item md={12} sm={12}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            {Title && <Title />}
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl fullWidth>
              <DatePicker
                variant="outlined"
                disableFuture={true}
                inputProps={classes.datepicker}
                placeholder="Start date"
                type="date"
                name="start_date"
                label="Start"
                value={dateRange.start_date}
                onChange={(e) => {
                  setDateRange({ ...dateRange, start_date: e });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl fullWidth>
              <DatePicker
                variant="outlined"
                disableFuture={true}
                inputProps={classes.datepicker}
                placeholder="Start date"
                type="date"
                name="start_date"
                label="End"
                value={dateRange.end_date}
                onChange={(e) => {
                  setDateRange({ ...dateRange, end_date: e });
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} xs={12}>
        <Card className={clsx(classes.card)}>
          <Grid container item alignItems="center" justify="flex-start">
            <AccountBalanceWalletIcon />
            <Typography className={classes.category} color="secondary" variant="body2">
              Total Balance
            </Typography>
          </Grid>
          {!loadTask.loadAllBalance && total_balance && <Typography variant="h5">{`${formatter(total_balance)}`}</Typography>}
          {(loadTask.loadAllBalance || !total_balance) && <CircularProgress />}
        </Card>
      </Grid>
      <Grid item md={4} xs={12}>
        <Card className={clsx(classes.card)}>
          <Grid container item alignItems="center" justify="flex-start">
            <Typography color="secondary" variant="body2">
              Total spending
            </Typography>
          </Grid>
          {!loadTask.loadAllBalance && <Typography variant="h5">{`${formatter(total_spends)}`}</Typography>}
          {loadTask.loadAllBalance && <CircularProgress />}
        </Card>
      </Grid>
      <Grid item md={4} xs={12}>
        <Card className={clsx(classes.card)}>
          <Grid container item alignItems="center" justify="flex-start">
            <Typography color="secondary" variant="body2">
              Total credits purchased
            </Typography>
          </Grid>
          {!loadTask.loadAllBalance && <Typography variant="h5">{`${formatter(total_purchases)}`}</Typography>}
          {loadTask.loadAllBalance && <CircularProgress />}
        </Card>
      </Grid>
    </Grid>
  );
};

export default AllBalance;
