import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { API } from "app/services";
import { useToastCatcher } from "app/utils";
import { Typography } from "@material-ui/core";

const VerifyEmail = () => {

  const location = useLocation();
  const [toaster, errorCatcher] = useToastCatcher({ errorParser: null, taskname: 'login' });
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    verify();
  }, [])

  const verify = async () => {
    errorCatcher(async () => {
      const token = location.search.slice(7);
      const result = await API.Auth.verify_email({token});
      if (result === "success") setVerified(true);
    }, 'verify email');
  }

  return (verified ? <Redirect to="/access/login?verified=success" /> : <Typography>Verifying email...</Typography> )
}

export default VerifyEmail;