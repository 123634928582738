import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { PersonListComp } from './components';
import { Title } from 'app/components';
import { useRouter } from 'app/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
}));

function PersonList() {
  const classes = useStyles();
  const router = useRouter();

  return (
    <Box className={classes.root}>
      <Title
        prefix="Persons"
        floatButton={{
          onClick: () => {
            router.history.push('/admin/person/create');
          },
          text: 'Create new person',
        }}
      />
      <PersonListComp />
    </Box>
  );
}

export default PersonList;
