import { Card, Grid, InputAdornment, Menu, MenuItem, Typography, useMediaQuery, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton, TraxxInput } from 'app/components';
import VisibilityAdornment from 'app/components/TraxxInput/components';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useAsyncTask } from 'app/utils';
import { API } from 'app/services';

const useStyles = makeStyles(({ breakpoints }) => ({
  adornment: {
    marginTop: 4,

    [breakpoints.up('md')]: {
      marginTop: 2,
    },
  },
  card: {
    marginTop: 20,

    [breakpoints.up('md')]: {
      marginLeft: 59,
      marginTop: 0,
    },
  },
  container: {
    height: 326,
    width: 330,

    [breakpoints.up('md')]: {
      height: 598,
      width: 410,
    },
  },
  error: { marginTop: 4 },
  form: {
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 575,
    justifyContent: 'space-between',
    padding: 35,
    width: 350,

    [breakpoints.up('md')]: {
      height: 598,
      padding: 40,
      width: 410,
    },
  },
  image: {
    height: 250,
    width: 250,

    [breakpoints.up('md')]: {
      height: 411,
      width: 411,
    },
  },
  pointer: { cursor: 'pointer' },
}));

function SignUpView(props) {
  const classes = useStyles();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const inviteToken = queryParams.get('invite');
  const [invitation, setInvitation] = useState();
  const [runLoadInvite, loadingInvite, errorInvite] = useAsyncTask('loadInvite');

  const { inputs, setInputs, errors, onSignup } = props;

  const matches = useMediaQuery(({ breakpoints }) => breakpoints.up('md'));

  const [anchorElement, setAnchorElement] = useState(null);
  const [visibility, setVisibility] = useState(false);
  const loading = useSelector((state) => state.layout.loadingTasks);

  useEffect(() => {
    if (!inviteToken) return;
    runLoadInvite(async () => {
      const result = await API.Auth.load_invite(inviteToken);
      setInvitation(result.model);
      setInputs((values) => ({
        ...values,
        inviteToken,
      }));
    });

    // eslint-disable-next-line
  }, [inviteToken]);

  const handleChange =
    (type) =>
    ({ target }) =>
      setInputs((values) => ({
        ...values,
        [type]: target.value,
      }));

  const handleDropDown = ({ currentTarget }) => setAnchorElement((value) => (value ? null : currentTarget));

  const handleSelect = ({ target }) => {
    setInputs((values) => ({
      ...values,
      countryCode: target.value,
    }));
    setAnchorElement(null);
  };

  const handleVisibility = () => setVisibility((value) => !value);

  const inputProps = [
    {
      id: 'SignUp-name',
      autoComplete: 'name',
      error: errors.name,
      label: 'Name',
      onChange: handleChange('name'),
      placeholder: 'Full name',
      value: inputs.name,
    },
    {
      id: 'SignUp-email',
      autoComplete: 'email',
      error: errors.email,
      label: 'Email address',
      onChange: handleChange('email'),
      placeholder: 'example@email.com',
      value: inputs.email,
    },
    {
      id: 'SignUp-password',
      autoComplete: 'password',
      error: errors.password,
      InputProps: {
        endAdornment: <VisibilityAdornment onClick={handleVisibility} />,
      },
      label: 'Password',
      onChange: handleChange('password'),
      placeholder: 'Minimum 8 characters',
      type: visibility ? 'text' : 'password',
      value: inputs.password,
    },
    {
      id: 'SignUp-phoneNumber',
      autoComplete: 'ph-number',
      error: errors.phoneNumber,
      InputProps: {
        endAdornment: (
          <InputAdornment className={clsx(classes.adornment, classes.pointer)} onClick={handleDropDown} position="start">
            <img src="/images/arrow_drop_down.svg" alt="arrow-drop-down-icon" />
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment className={classes.adornment} position="start">
            &#43;{inputs.countryCode}
          </InputAdornment>
        ),
      },
      label: 'Phone number',
      onChange: handleChange('phoneNumber'),
      placeholder: '1234578',
      value: inputs.phoneNumber,
      onKeyPress: (ev) => {
        if (ev.key === 'Enter') onSignup(ev);
      },
    },
  ];

  if (!invitation) {
    inputProps.unshift({
      id: 'SignUp-company',
      autoComplete: 'company',
      error: errors.company,
      label: 'Company Name',
      onChange: handleChange('company'),
      placeholder: 'ABC PTE LTD',
      value: inputs.company,
    });
  }

  return (
    <>
      <Grid className={classes.container} container alignContent={matches ? 'space-around' : 'space-between'} justify="center">
        <Typography variant={matches ? 'h4' : 'h5'}>Sign up for a free account&#46;</Typography>

        <img className={classes.image} src="/images/compliance.svg" alt="compliance_logo" />
      </Grid>

      <Card className={classes.card}>
        <form className={classes.form} autoComplete="false">
          <Typography variant="h5">Create your free account</Typography>

          <LinearProgress hidden={!loadingInvite} />

          {!!invitation && (
            <Typography variant="body1">
              You are invited to join {invitation.account || 'an account'} by {invitation.inviter || 'someone'}.
            </Typography>
          )}

          {!!errorInvite && (
            <Typography variant="body1" color="error">
              {errorInvite.message}
            </Typography>
          )}

          {inputProps.map((props) => (
            <TraxxInput key={props.id} {...props} />
          ))}

          <Menu anchorEl={anchorElement} keepMounted open={!!anchorElement} onClose={handleDropDown}>
            <MenuItem onClick={handleSelect} value="+65">
              &#43;65
            </MenuItem>
            <MenuItem onClick={handleSelect} value="+60">
              &#43;60
            </MenuItem>
            <MenuItem onClick={handleSelect} value="+86">
              &#43;86
            </MenuItem>
          </Menu>

          <TraxxButton loading={loading['signup']} onSubmit={onSignup} form fullWidth>
            Sign up for TRAXX&#46;ID
          </TraxxButton>
        </form>
      </Card>
    </>
  );
}

export default SignUpView;
