import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask, useMoneyFormatter } from 'app/utils';
import clsx from 'clsx';
// import { useSelector } from "react-redux";
import moment from 'moment';
import React, { useState } from 'react';
import XLSX from 'xlsx';
import { WalletTxList } from '../components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
}));

const WalletTransactions = (props) => {
  const { children, className, staticContext, ...rest } = props;
  const classes = useStyles();
  // const access = useSelector(({ profile }) => profile.access);
  const [runLoadWalletTx] = useAsyncTask('walletTxExport');
  const formatter = useMoneyFormatter({ currency: 'TXC', showCurrency: true });
  const [filter, setFilter] = useState();

  const onExport = () => {
    runLoadWalletTx(async () => {
      const { models } = await API.Admin.list_wallet_tx({ ...filter, nolimit: 'true' });
      const data = sort_export_array(models);
      const workbook = {
        SheetNames: ['Credit usage'],
        Sheets: {
          'Credit usage': XLSX.utils.aoa_to_sheet(data),
        },
      };
      return XLSX.writeFile(workbook, `Wallet_Transactions_${moment().format('YYYY-MM-DD-HH:mm:ss')}.xlsx`);
    });
  };

  const getSourceData = (model) => {
    // console.log(model.type);
    switch (model.type) {
      case 'pay_screen':
      case 'pay_report':
        return `${model.owner_screen?.screen_request?.params?.name}(${model.owner_screen.reference})`;
      case 'pay_verification':
        return `${model.owner_verification?.reference}`;
      case 'pay_cached_article_retrieval':
        return `${model.owner_cached_article_retrieval?.reference}`;
      default:
        return '';
    }
  };

  const sort_export_array = (models) => {
    let export_arr = [['Account', 'Wallet', 'Transaction ID', 'Transaction time', 'Type', 'Comment', 'Source', 'Amount', 'Running amount']];
    models.forEach((tx, index) => {
      export_arr.push([
        tx.wallet?.entity?.account.name + ` (${tx.wallet?.entity?.account.reference})`,
        tx.wallet?.reference,
        tx.reference,
        moment(tx.timestamp).format('DD-MMM-YYYY HH:mm:ss'),
        tx.type,
        tx.comments || '',
        getSourceData(tx),
        formatter(tx.amount),
        formatter(tx.post_balance),
      ]);
    });

    return export_arr;
  };

  return (
    <Box justifyItems="center" {...rest} className={clsx(classes.root, className)}>
      <Title
        prefix={'Wallet transactions'}
        floatButton={
          !!filter && {
            onClick: onExport,
            text: 'Export',
          }
        }
      />
      <WalletTxList setExportFilter={setFilter} routeType="admin" />
    </Box>
  );
};

export default WalletTransactions;
