import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useRouter } from 'app/utils';
import { AccountDetailComp, AccountEntityListComp, AddPerson } from './components';
import { TabComp, Title, Crumbs } from 'app/components';
import EventLogListComp from 'app/views/EventLog/EventLogList/components/EventLogListComp';
import { useSelector } from 'react-redux';
import OrderListing from 'app/views/Wallet/OrderHistory/components/OrderListing';

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  root: {
    padding: spacing(3, 3, 8),
    [breakpoints.down('xs')]: {
      padding: spacing(1, 1, 3),
    },
  },
  container: {
    marginTop: spacing(2),
  },
}));

function Detail() {
  const classes = useStyles();
  const router = useRouter();
  const { match } = router;
  const { params } = match;
  const [personsEntity, setPersonsEntity] = useState([]);
  const [onLoad, setOnLoad] = useState();
  const loading = useSelector((state) => state.layout.loadingTasks);
  const [account, setAccount] = useState();

  const onLoadPersons = (models) => {
    let perArr = [];
    models.forEach((person) => {
      perArr.push(person.entity.id);
    });
    setPersonsEntity(perArr);
  };

  return (
    <Box justifyItems="center" className={classes.root}>
      <Crumbs current="Detail" links={[{ text: 'Accounts', href: '/admin/accounts' }]} />
      <Title prefix={'Account detail'} />
      <Grid className={classes.container} container spacing={2}>
        <Grid item md={4} xs={12}>
          <AccountDetailComp setOnLoad={setOnLoad} onAccount={(acc) => setAccount(acc)} />
        </Grid>
        <Grid item lg={8} xl={8} md={8} xs={12}>
          {!loading.loadAccountDetail && (
            <TabComp
              tabTitle="Account tabs"
              tabHeaders={['Persons', 'Event logs', 'Add person', 'Order history']}
              tabContent={[
                <AccountEntityListComp account_owner_id={account?.owner_id} onLoadPersons={onLoadPersons} account_id={params.account_id} />,
                <EventLogListComp routeType="admin" account={params.account_id} />,
                <AddPerson onLoad={onLoad} account={params.account_id} personsEntity={personsEntity} />,
                <OrderListing accountId={params.account_id} routeType="admin" />,
              ]}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Detail;
