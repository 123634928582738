import { Box, Grid, LinearProgress, Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataRow, DateDisplay, MonoText, StatusLabel, TabComp, Title, Crumbs } from 'app/components';
import { StatusMaps } from 'app/constants';
import { API } from 'app/services';
import { useRouter, useToastCatcher } from 'app/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AccountComp, DocumentList, PersonComp, ScreeningList, CaseGroupList } from './components';

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  root: {
    padding: spacing(3, 3, 8),
    [breakpoints.down('md')]: {
      margin: 0,
    },
    [breakpoints.down('xs')]: {
      padding: spacing(1, 1, 3),
    },
  },
  card: {
    marginTop: 10,
    width: '100%',
  },
  table: {},
  head: {
    fontWeight: 'bold',
  },
  button: {
    marginTop: 10,
    width: '100%',
  },
  spaceTop: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  buttonMargin: {
    marginRight: spacing(2),
  },
}));

let mounted = false;
function CaseDetail({ routeType = 'account' }) {
  const classes = useStyles();
  const [currentCase, setCurrentCase] = useState(null);
  const loading = useSelector((state) => state.layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const [toaster, errorCatcher] = useToastCatcher({ errorParser: null, taskname: 'getCase' });
  const router = useRouter();
  const { match } = router;
  const { params } = match;
  const [tabHeader, setTabHeader] = useState('Screening');

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) getCase();
    // eslint-disable-next-line
  }, [access]);

  const getCase = () => {
    if (!params.case_id) return toaster({ message: 'invalid id' });
    errorCatcher(async () => {
      const loader = routeType === 'account' ? API.Screening.get_case : API.Admin.get_case;
      const { model } = await loader(params.case_id);

      if (mounted) setCurrentCase(model);
    });
  };

  return (
    <Box className={classes.root}>
      <Crumbs current="Detail" links={[{ text: 'Cases', href: routeType === 'admin' ? '/admin/screenings/cases' : '/screenings/cases' }]} />
      {tabHeader === 'Screening' ? (
        <Title
          prefix={'Case detail'}
          floatButton={
            routeType === 'account' &&
            currentCase && {
              onClick: () => {
                router.history.push(`/screenings/create?case=${currentCase.reference}`);
              },
              text: `New screen for ${currentCase.reference}`,
              className: classes.buttonMargin,
            }
          }
        />
      ) : (
        <Title
          prefix={'Case detail'}
          floatButton={
            routeType === 'account' &&
            currentCase && {
              onClick: () => {
                router.history.push(`/document-verify?case=${currentCase.reference}`);
              },
              text: `New documents for ${currentCase.reference}`,
            }
          }
        />
      )}
      <Grid container spacing={2}>
        <Grid container spacing={2} item md={4} xs={12}>
          <Grid item xs={12}>
            <TableContainer component={Paper} className={classes.table}>
              {loading['getCase'] && <LinearProgress size={30} />}
              {!loading['getCase'] && currentCase && (
                <Table>
                  <TableBody>
                    <DataRow typoProps={{ className: classes.cell }} name="Name" value={currentCase.label} />
                    <DataRow name="Case ID" value={<MonoText>{currentCase.reference}</MonoText>} />
                    <DataRow name="Status" value={<StatusLabel variantMap={StatusMaps.Case}>{currentCase.status}</StatusLabel>} />
                    <DataRow name="Created date" value={<DateDisplay date={currentCase.created_at} />} />
                    <DataRow name="No. of screens" value={currentCase.screens?.length || '0'} />
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Grid>

          {!loading['getCase'] && currentCase && routeType === 'admin' && (
            <Grid item xl={12} lg={12} md={12} xs={12}>
              <AccountComp account={currentCase.account} />
            </Grid>
          )}

          {!loading['getCase'] && currentCase && routeType === 'admin' && (
            <Grid item xl={12} lg={12} md={12} xs={12}>
              <PersonComp person={currentCase.person} />
            </Grid>
          )}
        </Grid>
        <Grid container item md={8} xl={8} lg={8} xs={12}>
          <Grid item lg={12} xs={12}>
            {currentCase && (
              <TabComp
                getTabHeader={setTabHeader}
                tabTitles="KYC tabs"
                tabHeaders={routeType === 'account' ? ['Screening', 'Documents', 'Case group'] : ['Screening', 'Documents']}
                tabContent={[<ScreeningList case_id={currentCase.id} routeType={routeType} />, <DocumentList case_id={currentCase.id} routeType={routeType} />, routeType === 'account' ? <CaseGroupList case_id={currentCase.id} /> : null]}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CaseDetail;
