import makeStyles from '@material-ui/core/styles/makeStyles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  pointer: { cursor: 'pointer' },
  error: {
    height: 24,
  },
  root: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
  },
}));

/**
 * Keyboard date picker via selection or text input
 *
 * Props:
 ** placeholder: place holder to explain purpose of date picker
 */
function DatePicker({ id, error, label, onChange, required, placeholder, inputProps, value, type = 'text', className = '', name, disabled, variant = 'standard', disableFuture = true, disablePast = false, ...props }) {
  const classes = useStyles();

  const handleDateChange = (value) => {
    onChange(value);
  };

  return (
    <KeyboardDatePicker
      required={required}
      disabled={disabled}
      disablePast={disablePast}
      disableFuture={disableFuture}
      className={clsx(classes.root, className)}
      inputVariant={variant}
      inputProps={{ className: inputProps }}
      format="DD-MMM-YYYY"
      margin="normal"
      placeholder={placeholder}
      error={!!error}
      helperText={error}
      id={name}
      autoOk
      value={value}
      label={label}
      onChange={handleDateChange}
      KeyboardButtonProps={{ 'aria-label': 'change date' }}
      InputLabelProps={{ shrink: true }}
      refuse={/[^a-zA-Z0-9-]+/gi}
      rifmFormatter={(value) => value.replace(/[^a-zA-Z0-9-]+/gi, '')}
    />
  );
}

export default DatePicker;

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  error: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
};

DatePicker.defaultProps = {
  error: undefined,
  id: '',
  label: '',
};
