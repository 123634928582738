import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { EditDialog, ListingComp, Title } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GroupCard } from './components';

const useStyles = makeStyles((theme) => ({
  root: {},
  cardContainer: {
    padding: theme.spacing(2),
  },
}));

const filterOptions = {
  sort: false,
  search: false,
  dateRange: false,
  type: false,
  status: false,
  clear: false,
};

const extraOptions = {};

const setCreateContent = (label, data_key, initial_value, extras = {}) => {
  return {
    label,
    data_key,
    extras,
    initial_value,
  };
};

const createArr = [[setCreateContent('Label', 'label')], [setCreateContent('Description', 'description')]];

let mounted = false;
const GroupListingComp = (props) => {
  const { limit = 10, children, className, ...rest } = props;
  const classes = useStyles();
  const [runGroupListing] = useAsyncTask('loadGroups');
  // const [groups, setGroups] = useState([]);
  const [listing, setListing] = useState([]);
  const [filter, setFilter] = useState({ limit, offset: 0 });
  const { workspace } = useSelector((state) => state.profile);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [runCreateGroup] = useAsyncTask('createGroup');
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const onCreate = (inputs) => {
    runCreateGroup(async () => {
      await API.Group.create_group({ ...inputs, account_id: workspace.account_id });
      if (mounted) {
        handleClose();
        loadGroupListing(filter);
      }
    });
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const loadGroupListing = (newFilter = filter) => {
    runGroupListing(async () => {
      const { models, meta } = await API.Group.list_group();
      if (mounted) {
        setListing(sortListing(models));
        setFilter({ ...newFilter, count: meta.count });
        // sortListingData(models);
      }
    });
  };

  const sortListing = (models) => {
    const newModels = models.map((mod) => ({
      ...mod,
      active: mod.cases.filter((cas) => cas.status === 'active').length,
      newCase: mod.cases.filter((cas) => cas.status === 'new').length,
      archived: mod.cases.filter((cas) => cas.status === 'archived').length,
    }));
    return newModels;
  };

  // const sortListingData = (models) => {
  //   let allData = [];
  //   models.forEach(model => {
  //     allData.push([
  //       { label: model.label },
  //       { label: `${model.reference}`, type: "link", link: `/group/${model.id}/detail` },
  //       { label: model.description },
  //     ])
  //   });

  //   setGroups(allData)
  // }

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Title
        prefix="Groups"
        className={classes.title}
        floatButton={{
          onClick: () => {
            setDialogOpen(true);
          },
          text: 'Create new group',
          icon: <AddIcon />,
        }}
      />
      {/* <GroupCard /> */}
      <ListingComp filter={filter} filterOptions={filterOptions} extraOptions={extraOptions} reloadFunc={loadGroupListing} setFilter={setFilter} loading={loadTask.loadGroups}>
        {/* {groups.length && <ListingCompTable headers={headers} data={groups} />} */}

        {listing && (
          <Grid className={classes.cardContainer} container spacing={2}>
            {listing.map((grp) => (
              <Grid item md={4} sm={6} xs={12} spacing={2}>
                <GroupCard grp={grp} />
              </Grid>
            ))}
          </Grid>
        )}
      </ListingComp>

      <EditDialog loading={loadTask.createGroup} onSubmit={onCreate} editData={createArr} onClose={handleClose} open={dialogOpen} editHeader="Create group" buttonText="Create" />
    </Box>
  );
};

export default GroupListingComp;
