import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HelpIcon from '@material-ui/icons/Help';
import { snakeToTitle } from 'app/utils';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    border: '1px solid #000000q',
  },
  table: {
    borderRadius: 0,
  },
  row: {
    backgroundColor: theme.palette.action.hover,
  },
  head: {
    backgroundColor: '#520000',
    color: theme.palette.common.white,
  },
  cellContent: {
    wordBreak: 'break-word',
  },
  smallText: {
    // fontSize: "0.475rem",
    fontSize: 10,
  },
}));

const DEFAULT_HEADER = ['Name', 'Match rating'];

// eslint-disable-next-line
let mounted = false;
function PreviewMatchList(props) {
  const { matchList, header = 'Details' } = props;
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState(DEFAULT_HEADER);
  const classes = useStyles();

  useEffect(() => {
    mounted = true;
    sortListingData(matchList);
    return () => (mounted = false);
    // eslint-disable-next-line
  }, []);

  const getVariant = (res) => {
    switch (res) {
      case 'NOT_MATCHED':
        return <CancelIcon color="error" />;
      case 'MATCHED':
        return <CheckCircleIcon style={{ color: '#2cc990' }} />;
      default:
        return <HelpIcon style={{ color: '#f2c94e' }} />;
    }
  };

  const matchHeader = (header) => {
    switch (header) {
      case 'Date Of Birth':
        return 'D.O.B';
      case 'Country Location':
        return 'Country';
      case 'Place Of Birth':
        return 'P.O.B';
      default:
        return header;
    }
  };

  const sortListingData = (models) => {
    let allData = [];
    let newHeader = headers;
    models.forEach((model) => {
      const matchAttributes = JSON.parse(model.match_attributes);
      let arr = [
        {
          type: 'custom',
          value: (
            <>
              <Typography className={classes.smallText} fontSize={5}>
                {model.name}
              </Typography>
              <Typography className={classes.smallText} color="secondary" fontWeight="bold">
                {model.reference}
              </Typography>
            </>
          ),
        },
        {
          type: 'custom',
          value: (
            <>
              <Typography className={classes.smallText}>{snakeToTitle(model.match_rating)}</Typography>
              {model.match_type && (
                <Typography className={classes.smallText} color="secondary">
                  ({model.match_type})
                </Typography>
              )}
            </>
          ),
        },
      ];
      if (matchAttributes && matchAttributes.length > 0) {
        matchAttributes.forEach((attribute) => {
          if (!newHeader.includes(matchHeader(snakeToTitle(attribute.field)))) newHeader.push(matchHeader(snakeToTitle(attribute.field)));
          arr.push({
            type: 'custom',
            value: getVariant(attribute.result),
          });
        });
      }
      allData.push(arr);
    });
    setHeaders(newHeader);
    setData(allData);
  };

  const renderCell = (content, index) => {
    return (
      <TableCell key={index} className={classes.cellContent}>
        {content.value}
      </TableCell>
    );
  };

  return (
    <TableContainer className={classes.root}>
      <Table size="small" aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={headers.length} className={classes.head}>
              {header}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!matchList.length && (
            <TableRow>
              {headers.map((hdr, index) => (
                <TableCell className={classes.cellContent} key={index}>
                  <Typography className={classes.smallText}>{hdr}</Typography>
                </TableCell>
              ))}
            </TableRow>
          )}
          {data.map((row, index) => (
            <TableRow key={index}>{row.map(renderCell)}</TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PreviewMatchList;
