import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const BerbixBrand = (props) => {
  const { children, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Typography {...rest} className={clsx(classes.root, className)}>
      Powered by Berbix
    </Typography>
  );
};

export default BerbixBrand;
