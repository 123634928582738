import { Card, Table, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataRow, DateDisplay } from 'app/components';
import { resultParser } from 'app/utils';
import clsx from 'clsx';
import React from 'react';
import TitleField from '../TitleField';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
  table: {
    padding: 0,
  },
}));

const BiographicTable = (props) => {
  const { bio = {}, className } = props;
  const classes = useStyles();

  return (
    <Card className={clsx(classes.card, className)}>
      <TitleField>Biographic</TitleField>
      <Table className={classes.table}>
        <TableBody>
          <DataRow name="Full name" value={bio.FullName} />
          <DataRow name="Age" value={bio.Age} />
          <DataRow name="Gender" value={resultParser.GenderParser(bio.Gender)} />
          <DataRow name="Birthdate" value={<DateDisplay date={bio.BirthDate} dateFormat={'DD-MMM-YYYY'} />} />
          <DataRow name="Expiration Date" value={<DateDisplay date={bio.ExpirationDate} />} />
          {/* <DataRow name="Photo" value={<img src={bio.Photo} />} /> */}
        </TableBody>
      </Table>
    </Card>
  );
};

export default BiographicTable;
