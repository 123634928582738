import { Dialog, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import ScreenDetail from 'app/views/Screening/ScreeningDetail';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 3, 8),
  },
  container: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      minWidth: 500,
    },
  },
  button: {
    marginTop: theme.spacing(3),
    float: 'right',
  },
}));

const InfoDialog = (props) => {
  const { screen, onClose, open } = props;

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} className={classes.root} aria-labelledby="simple-dialog-title" fullScreen open={open}>
      <IconButton className={classes.button} onClick={handleClose}>
        <CancelIcon />
      </IconButton>
      <ScreenDetail newPage="_blank" screenId={screen.id} />
    </Dialog>
  );
};

export default InfoDialog;
