import { LinearProgress, Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataRow, DateDisplay } from 'app/components';
import { snakeToTitle } from 'app/utils';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',
  },
}));

const InputParameters = (props) => {
  const { reload, routeType, screening, loading, children, className, ...rest } = props;
  const classes = useStyles();
  if (!screening) return <LinearProgress size={30} />;

  return (
    <TableContainer component={Paper} className={clsx(classes.root, className)} {...rest}>
      {loading && <LinearProgress size={30} />}
      {!loading && screening && (
        <Table>
          <TableBody>
            <DataRow name="Input parameters" />
            {screening.screen_request.params.name ? (
              <DataRow name="Name" value={screening.screen_request?.params.name} />
            ) : (
              <>
                <DataRow name="First Name" value={screening.screen_request?.params.first_name} />
                <DataRow name="Middle Name" value={screening.screen_request?.params.middle_name} />
                <DataRow name="Last Name" value={screening.screen_request?.params.last_name} />
              </>
            )}
            {screening.type === 'organisation' && (
              <>
                <DataRow name="Registered country" value={screening.screen_request?.params.registered_country} />
              </>
            )}
            {screening.type === 'vessel' && (
              <>
                <DataRow name="IMO number" value={screening.screen_request?.params.imo_number} />
              </>
            )}
            {(screening.type === 'simple' || screening.type === 'passport') && (
              <>
                <DataRow name="Nationality" value={screening.screen_request?.params.nationality} />
                {screening.screen_request?.params.place_of_birth ? (
                  <DataRow name="Place of birth" value={screening.screen_request?.params.place_of_birth} />
                ) : (
                  <DataRow name="Country of Birth" value={screening.screen_request?.params.country_of_birth} />
                )}
                <DataRow name="Date of birth" value={screening.screen_request?.params.date_of_birth ? <DateDisplay date={screening.screen_request?.params.date_of_birth} dateFormat={'DD-MMM-YYYY'} /> : '-'} />
                <DataRow name="Gender" value={snakeToTitle(screening.screen_request?.params.gender || '')} />
              </>
            )}
            {screening.type === 'passport' && (
              <>
                <DataRow name="ID" value={screening.screen_request?.params.id_number} />
                <DataRow name="Issuing state" value={screening.screen_request?.params.issuing_state} />
                <DataRow name="Date of expiry" value={<DateDisplay date={screening.screen_request?.params.date_of_expiry} dateFormat={'DD-MMM-YYYY'} />} />
              </>
            )}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default InputParameters;
