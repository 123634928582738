import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { MenuAccounts, MenuActions, MenuHeader } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: theme.spacing(6),
    right: theme.spacing(2),
    opacity: 0,
    transition: 'opacity .1s ease-in-out',
    pointerEvents: 'none',
    zIndex: 210,
  },
  backdrop: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 210,
  },
  open: {
    opacity: 1,
    pointerEvents: 'unset',
  },
  container: {
    border: `1px solid ${theme.palette.divider}`,
    position: 'relative',
    minWidth: 250,
    zIndex: 211,
  },
}));

/**
 * Combination of MenuHeader, MenuAccounts and MenuActions
 *
 * Props:
 ** className: name of css class element
 */
const ProfileMenu = (props) => {
  const { className, onClose, open, ...rest } = props;
  const classes = useStyles();

  return (
    <Box {...rest} className={clsx(classes.root, { [classes.open]: open }, className)}>
      <Box onClick={onClose} className={classes.backdrop} />
      <Paper className={classes.container} elevation={6}>
        <MenuHeader />
        <MenuAccounts onChangeAccount={onClose} />
        <MenuActions />
      </Paper>
    </Box>
  );
};

export default ProfileMenu;
