import { Box, LinearProgress, makeStyles, Paper } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FilterComp from '../FilterComp';
import Listing from '../Listing';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  filters: {},
  container: {
    marginTop: theme.spacing(1),
  },
}));

let mounted = false;

/**
 * Data listing with filter component
 *
 * Props:
 ** className: name of css class element
 ** emptyMessage: message if no records found
 ** children: data to be shown
 */
const ListingComp = (props) => {
  const classes = useStyles();
  const {
    showPaginator = true,
    title,
    loading,
    filter,
    setFilter,
    filterOptions = {},
    extraOptions = {},
    showFilter,
    reloadFunc,
    setLoading,
    reloadflag = true,
    setReloadflag,
    statusOpt,
    ExtraAction,
    dateKey,
    sortKey,
    searchByText,
    emptyMessage,
    className,
    children,
  } = props;

  const access = useSelector((state) => state.profile.access);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token && reloadflag) {
      reloadFunc();
      if (typeof setReloadflag === 'function') setReloadflag();
    }
    // eslint-disable-next-line
  }, [access, reloadflag]);

  const onFilter = (type) => {
    return (e) => {
      if (!mounted) return;

      const newFilter = { ...filter, [type]: e.target?.value };

      if (!e.target?.value && type !== 'dateRange') delete newFilter[type];
      if (type === 'dateRange') {
        if (!e.startDate || !e.endDate) {
          return;
        } else {
          delete newFilter[type];
          const start = e.startDate.startOf('D').unix();
          const end = e.endDate.endOf('D').unix();
          newFilter[dateKey] = start + ',' + end;
        }
      }
      if (type === 'sort') {
        newFilter.sort = `${sortKey}:${e.target.value}`;
      }
      if (type === 'clear') {
        delete newFilter['search'];
        delete newFilter['date'];
        delete newFilter['sort'];
        delete newFilter['type'];
        delete newFilter['status'];
      } else {
        setFilter({ ...newFilter });
      }
      if (type === 'search') {
        setLoading(true);
        if (timer) clearTimeout(timer);
        setTimer(
          setTimeout(() => {
            if (!mounted) return;
            reloadFunc(newFilter);
          }, 1000)
        );
      } else {
        reloadFunc(newFilter);
      }
    };
  };

  const onPageChange = (newFilter) => {
    reloadFunc(newFilter);
  };

  return (
    <Box className={clsx(classes.root, className)}>
      {showFilter && <FilterComp onFilter={onFilter} filterOptions={filterOptions} extraOptions={extraOptions} sortKey={sortKey} filter={filter} className={classes.filters} statusOpt={statusOpt} searchByText={searchByText} />}
      <Paper className={classes.container}>
        {loading && <LinearProgress size={30} />}
        {!loading && (
          <Listing filter={filter} loading={loading} showPaginator={showPaginator} onPageChange={onPageChange} title={title} ExtraAction={ExtraAction} emptyMessage={emptyMessage}>
            {children}
          </Listing>
        )}
      </Paper>
    </Box>
  );
};

// TODO use default props for all components
ListingComp.defaultProps = {
  title: null,
  showPaginator: true,
  showFilter: true,
  reloadFunc: () => {},
  taskname: 'listing',
  enableRange: true,
  enableSort: true,
  enableStatus: true,
  enableType: true,
  enableSearch: true,
  setLoading: () => {},
  dateKey: 'open_date',
  sortKey: 'open_at',
};

export default ListingComp;
