import { Box, Dialog, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton, TraxxInput } from 'app/components';
import PlaceHolderScreen from 'app/views/PlaceholderScreen';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  container: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      minWidth: 500,
    },
  },
  button: {
    marginTop: theme.spacing(3),
    float: 'right',
  },
}));

/**
 * Dialog box to edit details
 *
 * Props:
 ** editHeader: header text
 ** editData: data(s) to be open to editing
 */
const EditDialog = (props) => {
  const { onClose, open, editHeader = 'Edit Details', editData, loading, onSubmit, underConstruction, buttonText = 'Update' } = props;

  const classes = useStyles();
  const [inputs, setInputs] = useState({});

  const handleClose = () => {
    onClose();
  };

  const getContentType = (data) => {
    if (Object.keys(data.extras).length < 1)
      return (
        <TraxxInput
          label={data.label}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={({ target }) => setInputs({ ...inputs, [data.data_key]: target.value })}
        />
      );
    if (data.extras.type === 'select') {
      return (
        <FormControl fullWidth>
          <InputLabel>{data.label}</InputLabel>
          <Select
            fullWidth
            onChange={({ target }) => {
              setInputs({ ...inputs, [data.data_key]: target.value });
            }}>
            {data.extras.options.map((opts) => (
              <MenuItem value={opts.value}>{opts.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    if (data.extras.type === 'custom') return data.extras.content;
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      {!underConstruction && (
        <Box className={classes.root}>
          <Typography>{editHeader}</Typography>
          {editData.map((rowData, index) => (
            <Grid container className={classes.container} spacing={2} key={index}>
              {rowData.map((cellData, index) => (
                <Grid xs={12} sm={12} md={12 / rowData.length || 1} item key={index}>
                  {getContentType(cellData)}
                </Grid>
              ))}
            </Grid>
          ))}

          <TraxxButton
            loading={loading}
            onSubmit={() => {
              onSubmit(inputs);
            }}
            className={classes.button}>
            {buttonText}
          </TraxxButton>
        </Box>
      )}
      {underConstruction && <PlaceHolderScreen className={classes.root} />}
    </Dialog>
  );
};

export default EditDialog;
