import { Card, Table, TableBody, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataRow, StatusLabel } from 'app/components';
import clsx from 'clsx';
import React from 'react';
import TitleField from '../TitleField';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
  matched: {
    color: 'green',
  },
  unmatched: {
    color: 'red',
  },
  sameline: {
    display: 'flex',
  },
}));

const FaceMatchTable = (props) => {
  const { facematch = {}, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.card, className)}>
      <TitleField>Face Match</TitleField>
      <Table>
        <TableBody>
          <DataRow name="Score" value={facematch.Score} />
          <DataRow name="Result" value={<Typography className={classes.sameline}>{facematch.IsMatch ? <StatusLabel variant="success">Match</StatusLabel> : <StatusLabel variant="error">Does not match</StatusLabel>}</Typography>} />
        </TableBody>
      </Table>
    </Card>
  );
};

export default FaceMatchTable;
