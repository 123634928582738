import { Checkbox, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as BrowserLink, useRouteMatch } from 'react-router-dom';
import { DateDisplay } from 'app/components';

const useStyles = makeStyles(({ palette }) => ({
  body: { fontSize: 14 },
  card: { marginTop: 20 },
  checkbox: { color: palette.grey.primary },
  dot: {
    color: '#00b4cc',
    fontSize: 14,
  },
  green: {
    backgroundColor: '#c9efe1',
    color: palette.status.green,
  },
  head: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  red: {
    backgroundColor: '#f8d0d0',
    color: palette.status.red,
  },
  resultId: {
    color: palette.red.primary,
    textDecoration: 'underline',
  },
  status: {
    fontSize: 11,
    fontWeight: 'bold',
    padding: '0 6px',
  },
  tableCell: { padding: 10 },
  yellow: {
    backgroundColor: '#fff2cb',
    color: palette.status.yellow,
  },
}));

const getStatusClass = (status) => {
  const mapping = {
    COMPLETED: 'green',
    FAILED: 'red',
    PENDING: 'yellow',
  };

  return mapping[status];
};

const getTimeDifference = (date, status) => {
  if (status === 'COMPLETED' || status === 'FAILED') {
    return '-';
  }

  const completeDate = moment(date);
  let difference = completeDate.diff(moment());

  if (difference <= 0) {
    return '-';
  }

  const minute = 60000;
  const hour = 3600000;
  const day = 86400000;

  const days = Math.floor(difference / day);
  const remainingHrs = difference % day;

  const hours = Math.floor(remainingHrs / hour);
  const remainingMins = remainingHrs % minute;

  const minutes = Math.floor(remainingMins / minute);

  return `${days}d ${hours}h ${minutes}min`;
};

const TraxxTable = ({ className, data, full }) => {
  const classes = useStyles();

  /**
   * @todo: delete
   */
  const match = useRouteMatch();

  return (
    <TableContainer className={className}>
      <Table>
        <TableHead>
          <TableRow>
            {!full && <TableCell className={classes.tableCell} />}
            {full && (
              <TableCell className={classes.tableCell}>
                <Checkbox className={classes.checkbox} />
              </TableCell>
            )}

            <TableCell className={classes.tableCell}>
              <Typography className={classes.head} color="secondary">
                Result ID
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography className={classes.head} color="secondary">
                Date
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography className={classes.head} color="secondary">
                Screening Type
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography className={classes.head} color="secondary">
                Case No.
              </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography className={classes.head} color="secondary">
                Name
              </Typography>
            </TableCell>

            {full && (
              <>
                <TableCell className={classes.tableCell}>
                  <Typography className={classes.head} color="secondary">
                    Status
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Typography className={classes.head} color="secondary">
                    Time Left
                  </Typography>
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map(({ id, date, type, caseNumber, name, status, completeDate }) => (
            <TableRow key={id}>
              {!full && <TableCell className={classes.tableCell}>{status === 'COMPLETED' && <FiberManualRecordIcon className={classes.dot} fontSize="small" />}</TableCell>}
              {full && (
                <TableCell className={classes.tableCell}>
                  <Checkbox className={classes.checkbox} />
                </TableCell>
              )}

              <TableCell className={classes.tableCell}>
                <Typography className={classes.resultId} variant="body2">
                  <Link component={BrowserLink} to={match}>
                    {id}
                  </Link>
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <DateDisplay date={date} />
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography color="textSecondary" variant="body2">
                  {type}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography color="textSecondary" variant="body2">
                  {caseNumber}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography color="textSecondary" variant="body2">
                  {name}
                </Typography>
              </TableCell>

              {full && (
                <>
                  <TableCell className={classes.tableCell}>
                    <Typography className={clsx(classes.status, classes[getStatusClass(status)])} component="span">
                      {status}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography color="textSecondary" variant="body2">
                      {completeDate && getTimeDifference(completeDate, status)}
                    </Typography>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TraxxTable;

TraxxTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      caseNumber: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.string,
      completeDate: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string,
  full: PropTypes.bool,
};

TraxxTable.defaultProps = {
  className: '',
  full: false,
};
