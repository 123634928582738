import { Box, FormControl, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton } from 'app/components';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link as BrowserLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    // [theme.breakpoints.down("sm")]: {
    //   flexDirection: "column",
    // }
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  button: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      width: '100%',
    },
  },
  dropdown: {
    marginRight: 6,
  },
  dropdownContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  inviteBox: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      flexDirection: 'column',
      width: '100%',
    },
  },
}));

const dateRangeOption = {
  none: { value: '', name: 'All time' },
  0: { value: 0, name: 'Today' },
  1: { value: 1, name: 'Yesterday' },
  7: { value: 7, name: 'Last 7 Days' },
  30: { value: 30, name: 'Last 30 Days' },
};

const DateRange = ({ className, onChange, onInvite, allowKYC }) => {
  const classes = useStyles();

  const [dateRange, setDateRange] = useState('Date range');

  const handleDates = ({ target }) => {
    if (typeof onChange === 'function') {
      if (target.value || target.value === 0) {
        const start = moment()
          .add(parseInt(target.value) * -1, 'd')
          .startOf('day')
          .unix();
        const end = moment().endOf('day').unix();
        onChange(start + ',' + end);
      } else onChange('none');
    }
    setDateRange(target.value);
  };

  return (
    <Grid className={clsx(classes.root, className)} container alignItems="center" justify="space-between">
      <Grid item md={4} xs={12} sm={12}>
        <Typography variant="h5">{moment().format('DD MMM YYYY')} Summary</Typography>
      </Grid>

      <Grid container justify="flex-end" item md={8} xs={12} sm={12}>
        <Box flexDirection="row" display="flex-end" className={classes.inviteBox}>
          {allowKYC && 
          (
            <span>
              <TraxxButton  className={classes.button} onSubmit={() => onInvite()}>
                Send screening invitation
              </TraxxButton>
              <TraxxButton className={classes.button} component={BrowserLink} to="/kyc/new">
                Start new KYC
              </TraxxButton>
            </span>
          )}
          
          <FormControl className={classes.dropdownContainer}>
            <Select
              displayEmpty
              renderValue={(v) => (
                <Box display="flex" alignItems="center" className={classes.dropdown}>
                  {(dateRangeOption[dateRange + ''] && dateRangeOption[dateRange + ''].name) || 'All time'}
                </Box>
              )}
              variant="outlined"
              margin="dense"
              onChange={handleDates}
              value={dateRange}
              placeholder="Date Range">
              {Object.values(dateRangeOption).map((range, index) => (
                <MenuItem key={index} value={range.value}>
                  {range.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DateRange;

DateRange.propTypes = { className: PropTypes.string };

DateRange.defaultProps = { className: '' };
