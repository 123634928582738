import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, Container } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { TraxxButton } from "app/components";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  button: { marginTop: '15px' },
  dialogBox: {
    padding: '20px 30px',
    [theme.breakpoints.down('500')]: {
      padding: '5px'
    }
  },
  text: { 
    margin: '30px',
    textAlign: 'center' 
  },
}))

const UpdateDialog = (props) => {
  const { expired_at, name, getDate, open, setOpen, onUpdate, loading } = props;
  const classes = useStyles();

  const [prevExpiry, setPrevExpiry] = useState('');
  const [newExpiry, setNewExpiry] = useState('');

  useEffect(() => {
    const prev = moment(expired_at, moment.ISO_8601);
    setPrevExpiry(getDate(prev));
    setNewExpiry(getDate(prev.add(1, 'y').subtract(1, 'd')));
  }, []);

  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Container className={classes.dialogBox}>
        <DialogTitle>
          Ongoing Screening Renewal
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to renew ongoing screening for { name ? name : "this person" }. <br />
            You will be charged <strong>4 credits</strong> for ongoing screening with a validity of <strong>1 year</strong> from previous expiry date.
          </DialogContentText>
          <DialogContentText className={classes.text}>
            Previous date of expiry: {prevExpiry} <br />
            New date of expiry: {newExpiry}
          </DialogContentText>
          <DialogContentText>
            An email will be sent a month before month of expiry as a reminder to renew your purchase.
          </DialogContentText>

          <TraxxButton className={classes.button} loading={loading} fullWidth onSubmit={onUpdate}>Update</TraxxButton>
        </DialogContent>
      </Container>
    </Dialog>
  )
}

export default UpdateDialog;