import { combineReducers } from 'redux';

import profile from './profile/reducer';
import layout from './layout/reducer';
import access from './access/reducer';

export default combineReducers({
  profile,
  layout,
  access,
});
