import { Card, Table, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataRow, MonoText, StatusLabel } from 'app/components';
import clsx from 'clsx';
import React from 'react';
import TitleField from '../TitleField';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
  matched: {
    color: 'green',
  },
  unmatched: {
    color: 'red',
  },
  sameline: {
    display: 'flex',
  },
}));

const LivenessTable = (props) => {
  const { liveness = {}, className, ...rest } = props;
  const classes = useStyles();
  const { LivenessResult, Error } = liveness;

  return (
    <Card {...rest} className={clsx(classes.card, className)}>
      <TitleField>Liveness</TitleField>
      <Table>
        <TableBody>
          <DataRow name="Score" value={<MonoText>{LivenessResult?.Score}</MonoText>} />
          <DataRow name="Liveness Assessment" value={<StatusLabel variant={LivenessResult?.LivenessAssessment === 'Live' ? 'success' : 'warning'}>{LivenessResult?.LivenessAssessment}</StatusLabel>} />
          {typeof Error === 'string' && <DataRow name="Error" value={Error} />}
        </TableBody>
      </Table>
    </Card>
  );
};

export default LivenessTable;
