import { Box, Checkbox, Dialog, Grid, Typography, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import DescriptionIcon from '@material-ui/icons/Description';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import MobileOffIcon from '@material-ui/icons/MobileOff';
import { TraxxButton, TraxxInput, SearchSelect } from 'app/components';
import { GlobalRegex } from 'app/constants';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    width: 500,
    [theme.breakpoints.down('xs')]: {
      width: 300,
    },
  },
  email: {
    marginTop: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
  },
  baseContainer: {
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  smallFont: {
    fontSize: 10,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}));

const InviteDialog = (props) => {
  const { onClose, open } = props;
  const classes = useStyles();
  const [required, setRequired] = useState({
    selfie: true,
    document: true,
    live_document: true,
    force_selfie: true,
    berbix_type: true,
  });
  const [runLoadCase] = useAsyncTask('loadCase');
  const [caseOptions, setCaseOptions] = useState([]);
  const [caseValue, setCaseValue] = useState('');
  const [timer, setTimer] = useState(null);

  const [errors, setErrors] = useState({});

  const [runSendInvitation, sendInviteLoading] = useAsyncTask('sendInvitation');
  const loadTask = useSelector((state) => state.layout.loadingTasks);
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
  });
  const [loading, setLoading] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const access = useSelector((state) => state.profile.access);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (access && access.token) {
      loadCases();
    }
    // eslint-disable-next-line
  }, [access]);

  const loadCases = (search) => {
    runLoadCase(async () => {
      let filter = { limit: 1000, offset: 0 };
      if (search) filter.search = search;
      const result = await API.Screening.list_case(filter);
      const caseOpts = result.models.map((mod) => ({
        name: `${mod.reference}${mod.label ? ` - ${mod.label}` : ''}${mod.status ? ` (${mod.status})` : ''}`,
        value: mod.id,
        ref: mod.reference,
      }));
      if (search) {
        caseOpts.forEach((cas) => {
          if (cas.ref === search) {
            // setInputs({ ...inputs, case_id: cas.value })
            setCaseValue(cas.name);
          }
        });
      }
      setCaseOptions(caseOpts);
      setLoading(false);
    });
  };

  const onCaseInputs = (event, value, reason) => {
    setCaseValue(value);
    if (timer) clearTimeout(timer);
    setLoading(true);
    setTimer(
      setTimeout(() => {
        loadCases(value);
      }, 1000)
    );
  };

  const onCaseSelect = (event, value, reason) => {
    if (value && caseOptions) {
      let foundCase = caseOptions.find((c) => c.name === value);
      setInputs({ ...inputs, case_id: foundCase.value });
    } else {
      setInputs({ ...inputs, case_id: null });
      setCaseValue('');
    }
  };

  const sendInvitation = () => {
    if (loadTask.sendInvitation) return;

    if (inputs.email === '' || inputs.name === '') {
      setErrors({});
      return enqueueSnackbar('Please fill in the recipient details', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }

    let err = {};
    if (!inputs.email) {
      err.email = 'Email field cannot be empty';
      setErrors(err);
    }

    if (!inputs.name) {
      err.name = 'Name field cannot be empty';
      setErrors(err);
    }

    if (!GlobalRegex.EmailValidation.test(inputs.email)) {
      err.email = 'Please enter a valid email';
      setErrors(err);
    }

    const { selfie, force_selfie, document, live_document, hide_document, berbix_type } = required;

    let submitChecks = {
      liveness_enabled: true,
      facematch_enabled: true,
      doc_required: 'optional',
      doc_force_live: 'optional',
      selfie_required: 'optional',
      selfie_force_live: 'optional',
      berbix_type: true,
      target_email: inputs.email,
      target_name: inputs.name,
    };
    if (inputs.case_id) submitChecks.case_id = inputs.case_id;

    if (document) {
      submitChecks.doc_required = 'required';
    } else {
      if (hide_document) submitChecks.doc_required = 'hidden';
      else submitChecks.doc_required = 'optional';
    }

    if (live_document) {
      submitChecks.doc_force_live = 'force_live';
    } else {
      if (hide_document) submitChecks.doc_force_live = 'no_live';
      else submitChecks.doc_force_live = 'optional';
    }

    if (selfie) {
      submitChecks.selfie_required = 'required';
      submitChecks.selfie_force_live = 'force_live';
    } else {
      if (force_selfie) {
        submitChecks.selfie_required = 'hidden';
        submitChecks.selfie_force_live = 'no_live';
      } else {
        submitChecks.selfie_required = 'optional';
        submitChecks.selfie_force_live = 'optional';
      }
    }

    berbix_type ? (submitChecks.berbix_type = 'passport_only') : (submitChecks.berbix_type = 'any_document');

    if (hide_document && force_selfie) {
      setErrors({});
      return enqueueSnackbar('Need to upload either selfie or document or both', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }

    setErrors({});

    runSendInvitation(async () => {
      // await API.Users.send_verification_invites(submitChecks);
      await API.Users.send_sumsub_verification_invites(submitChecks);
      enqueueSnackbar('Invitation sent', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      onClose();
    });
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <Box className={classes.root}>
        <Typography variant="h5">Send screening invitation</Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <TraxxInput InputLabelProps={{ shrink: true }} label="Recipient email" placeholder="john.smith@example.com" onChange={({ target }) => setInputs({ ...inputs, email: target.value })} className={classes.email} error={errors.email} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TraxxInput InputLabelProps={{ shrink: true }} label="Recipient name (email addressee)" placeholder="John Smith" onChange={({ target }) => setInputs({ ...inputs, name: target.value })} className={classes.email} error={errors.name} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth className={classes.email}>
              <SearchSelect label={'Select case'} inputValue={caseValue} options={caseOptions} loading={loading || loadTask.loadCase} onSelect={onCaseSelect} onInput={onCaseInputs} />
            </FormControl>
          </Grid>
        </Grid>

        <Typography className={classes.subtitle} variant="body1">
          Verification options
        </Typography>
        <Box className={classes.container}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item className={classes.itemContainer}>
              <DescriptionIcon className={classes.icon} />
              <Typography>ID/Passport</Typography>
            </Grid>
            <Grid item>
              <Checkbox checked={!!required.document} disabled onClick={() => setRequired({ ...required, document: !required.document })} />
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="space-between">
            <Grid item className={classes.itemContainer}>
              <EmojiEmotionsIcon className={classes.icon} />
              <Typography>Selfie</Typography>
            </Grid>
            <Grid item>
              <Checkbox checked={required.selfie} disabled onChange={() => setRequired({ ...required, selfie: !required.selfie })} />
            </Grid>
          </Grid>
        </Box>

        <Typography className={classes.subtitle} variant="body1">
          Additional requirements
        </Typography>

        <Box className={classes.container}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item className={classes.itemContainer}>
              <CameraAltIcon className={classes.icon} />
              <Typography>Force live capture (disable file upload)</Typography>
            </Grid>
            <Grid item>
              <Checkbox
                checked={!!required.live_document}
                disabled 
                // disabled={required.hide_document}
                onClick={() =>
                  setRequired({
                    ...required,
                    live_document: !required.live_document,
                    document: !required.live_document && !required.document ? true : required.document,
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="space-between">
            <Grid item className={classes.itemContainer}>
              <MobileOffIcon className={classes.icon} />
              <Typography>Selfie mandatory</Typography>
            </Grid>
            <Grid item>
              <Checkbox 
                checked={required.force_selfie && required.selfie} 
                // disabled={!required.selfie} 
                disabled 
                onChange={() => setRequired({ ...required, force_selfie: !required.force_selfie })} />
            </Grid>
          </Grid>
          {/* <Grid container alignItems="center" justify="space-between">
            <Grid item className={classes.itemContainer}>
              <AssignmentIndIcon className={classes.icon} />
              <Typography>Passport Capture Only</Typography>
            </Grid>
            <Grid item>
              <Checkbox checked={required.berbix_type} onChange={() => setRequired({ ...required, berbix_type: !required.berbix_type })} />
            </Grid>
          </Grid> */}
        </Box>

        <Box mt={2}>
          <Typography align="right">Estimated cost: 6 TXC</Typography>
        </Box>

        <Box className={classes.baseContainer}>
          <Box className={classes.buttonContainer}>
            <TraxxButton color="default" onSubmit={() => onClose()}>
              Cancel
            </TraxxButton>
            {/* sends invitation */}
            <TraxxButton loading={sendInviteLoading} onSubmit={() => sendInvitation()}>
              Send
            </TraxxButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default InviteDialog;
