import { Box, Card, FormControl, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton, TraxxInput } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  centered: {
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  info: {
    width: '100%',
  },
  head: {
    fontWeight: 'bold',
    fontSize: '.765rem',
  },
}));

const ActionField = (props) => {
  const { caseGroupID, onExport, batchData, setGroupUpdated, displayData, setCaseGroupID, groupUpdated, children, className, ...rest } = props;
  const classes = useStyles();
  const [runLoadCase] = useAsyncTask('loadCaseGroup');
  const [runUpdateCaseGroup] = useAsyncTask('updateCaseGroup');
  const [caseGroup, setCaseGroup] = useState();
  const [opts, setOpts] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [caseName, setCaseName] = useState();
  const [timer, setTimer] = useState(null);
  const [exportFileName, setExportFileName] = useState(moment().format('YYYY-MM-DD') + ' ' + batchData.name);

  useEffect(() => {
    loadCaseGroup(caseGroupID);
    // eslint-disable-next-line
  }, []);

  const loadCaseGroup = (search) => {
    runLoadCase(async () => {
      let filter = { limit: 1000, offset: 0 };
      if (search) filter.reference = search;
      const { models } = await API.Group.list_group(filter);
      const caseOpts = models.map((mod) => ({
        name: `${mod.label}${mod.reference ? ` (${mod.reference})` : ''}`,
        value: mod.id,
        ref: mod.reference,
      }));
      if (search) {
        caseOpts.forEach((cas) => {
          if (cas.ref === search) {
            setCaseName(cas.name);
            setCaseGroup(cas);
            setCaseGroupID(cas.ref);
          }
        });
      }
      setOpts(caseOpts);
    });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={4}>
        {/* <Grid container item md={6} sm={12} xs={12}>
          <Grid md={10} sm={12} xs={12} item>
            <FormControl disabled={groupUpdated} fullWidth>
              <SearchSelect label={'Case group'} inputValue={caseName} options={opts} loading={loadTask.loadCaseGroup} onInput={onCaseInputs} disabled={groupUpdated} onSelect={onCaseSelect} />
            </FormControl>
          </Grid>
          <Grid item md={2} sm={12} xs={12}>
            <Box className={classes.centered} display="flex">
              <Box flexGrow={1} />
              <TraxxButton disabled={groupUpdated} onSubmit={() => updateCaseGroup()}>
                Update
              </TraxxButton>
            </Box>
          </Grid>
        </Grid> */}
        <Grid container item md={6} sm={12} xs={12}>
          <Grid md={10} sm={12} xs={12} item>
            <FormControl fullWidth>
              <TraxxInput
                label="Export file name"
                onChange={({ target }) => setExportFileName(target.value)}
                placeholder={`${moment().format('DD-MMM-YYYY')} Template`}
                className={classes.head}
                value={exportFileName}
                InputProps={{
                  classes: { underline: classes.underline },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2} sm={12} xs={12}>
            <Box className={classes.centered} display="flex">
              <Box flexGrow={1} />
              <TraxxButton onSubmit={() => onExport(exportFileName)}>Export</TraxxButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ActionField;
