import { Box, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SumsubWebSdk from '@sumsub/websdk-react';
import { TraxxButton } from 'app/components';

import { API } from 'app/services';

import { makeStyles } from '@material-ui/core/styles';

import { useAsyncTask } from 'app/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  card: {
    padding: theme.spacing(4),
    width: '100%',
  },
  input: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: 25,
  },
  buttonContainer: {
    width: '100%',
  },
  clearButton: {
    float: 'right',
    marginBottom: 25,
  },
  app: {
    margin: 'auto',
  },
}));

const SelectDocument = (props) => {
  const { children, newCase, caseId, caseName, className, type, onBack, onNext, staticContext, onDocument, ...rest } = props;
  const classes = useStyles();

  const [accessToken, setAccessToken] = useState(null);

  const applicantEmail = '';
  const applicantPhone = '';

  const [loaded, setLoaded] = useState(false);
  const [runLoadRequestDetail] = useAsyncTask('loadRequest');

  const requestAccessToken = async (token) => {
    runLoadRequestDetail(async () => {
      const data = {
        case_id: caseId,
        case_name: caseName,
      };

      const result = await API.Users.verify_submit(data);

      if (onDocument) {
        onDocument({
          model: {
            case_id: result.caseId,
          },
        });
      }
      setAccessToken(result.token);
      setLoaded(true);
    });
    // if (!loaded) onBack();
  };

  useEffect(() => {
    // eslint-disable-next-line
    requestAccessToken();
  }, []);

  return (
    <Box className={classes.app}>
      <Box display="flex">
        {onBack && <Button onClick={onBack}>Previous</Button>}
        <Box flexGrow={1} />
        <TraxxButton onSubmit={onNext}>Next</TraxxButton>
      </Box>
      {loaded && (
        <SumsubWebSdk
          accessToken={accessToken}
          updateAccessToken={() => requestAccessToken()}
          expirationHandler={() => Promise.resolve(accessToken)}
          config={{
            lang: 'ru-RU',
            email: applicantEmail,
            phone: applicantPhone,
            i18n: {
              document: {
                subTitles: {
                  IDENTITY: 'Upload a document that proves your identity',
                },
              },
            },
            onMessage: (type, payload) => {
              console.log('WebSDK onMessage', type, payload);
            },
            uiConf: {
              customCssStr:
                ':root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 150px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}',
            },
            onError: (error) => {
              console.error('WebSDK onError', error);
            },
          }}
          options={{ addViewportTag: false, adaptIframeHeight: true }}
          onMessage={(type, payload) => {
            console.log('onMessage', type, payload);
          }}
          onError={(data) => console.log('onError', data)}
        />
      )}
    </Box>
  );
};

export default SelectDocument;
