import { Box, Button, Chip, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import Alert from '@material-ui/lab/Alert';
import { DataRow, DateDisplay, EditDialog, StatusLabel, TraxxButton, WalletCard } from 'app/components';
import { StatusMaps } from 'app/constants';
import { API } from 'app/services';
import { snakeToTitle, useAsyncTask, useRouter } from 'app/utils';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  table: {
    borderRadius: 5,
  },
  head: {
    fontWeight: 'bold',
  },
  walletCard: {
    marginTop: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  warning: {
    marginBottom: theme.spacing(1),
  },
  select: {
    padding: theme.spacing(1),
  },
  padRight: {
    marginLeft: theme.spacing(1),
  },
}));

let mounted = false;
function AccountDetail(props) {
  const { setOnLoad, onAccount } = props;
  const classes = useStyles();
  const [account, setAccount] = useState(null);
  const loading = useSelector((state) => state.layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const [wallet, setWallet] = useState();
  const [runLoadAccountDetail] = useAsyncTask('loadAccountDetail');
  const [runLoadWallet] = useAsyncTask('loadWallet');
  const [runSetActive] = useAsyncTask('setActive');
  const [runUpdateAccount] = useAsyncTask('updateDetail');
  const router = useRouter();
  const { match } = router;
  const { params } = match;
  const { enqueueSnackbar } = useSnackbar();
  const [editData, setEditData] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showConfirmActive, setShowConfirmActive] = useState(false);
  const [showConfirmSuspend, setShowConfirmSuspend] = useState(false);
  const [showConfirmNew, setShowConfirmNew] = useState(false);
  const [showConfirmReactivate, setShowConfirmReactivate] = useState(false);
  // const [showChangeStatus, setChangeStatus] = useState(false);
  const [runUpdateStatus] = useAsyncTask('updateStatus');

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const singleDisplay = (type) => {
    setShowConfirmActive(type === 'active');
    setShowConfirmSuspend(type === 'suspend');
    setShowConfirmNew(type === 'new');
    setShowConfirmReactivate(type === 'reactivate');
  };

  useEffect(() => {
    if (access && access.token) getAccount();
    setOnLoad(() => getAccount);
    // eslint-disable-next-line
  }, [access]);

  const getAccount = () => {
    if (!params.account_id) return enqueueSnackbar('invalid id', { variant: 'error' });
    runLoadAccountDetail(async () => {
      const { model } = await API.Admin.get_account(params.account_id);
      if (mounted) {
        setAccount(model);
        onAccount(model);
        configureEdit(model);
        loadWallet(model.id);
      }
    });
  };

  const onSubmit = (inputs) => {
    runUpdateAccount(async () => {
      await API.Admin.update_account(params.account_id, inputs);
      if (mounted) {
        getAccount();
        handleClose();
      }
    });
  };

  const setToActive = () => {
    runSetActive(async () => {
      await API.Admin.set_active_account(params.account_id);
      enqueueSnackbar('Account activated', { variant: 'success' });
      singleDisplay();
      if (mounted) getAccount();
    });
  };

  const setEditContent = (label, data_key, initial_value, extras = {}) => {
    return {
      label,
      data_key,
      extras,
      initial_value,
    };
  };

  const configureEdit = (model) => {
    let editArr = [[setEditContent('Name', 'name', model.name)]];
    setEditData(editArr);
  };

  const loadWallet = (account_id) => {
    runLoadWallet(async () => {
      const { wallet } = await API.Admin.get_account_wallet(account_id);
      if (mounted) {
        if (!wallet) enqueueSnackbar('No wallet found', { variant: 'error' });
        else setWallet(wallet);
      }
    });
  };

  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const suspendAccount = () => {
    runUpdateStatus(async () => {
      // setChangeStatus(false)
      singleDisplay();
      await API.Admin.update_account_status(account.id, { status: 'Suspended' });
      getAccount();
    });
  };

  const unactivate = () => {
    runUpdateStatus(async () => {
      // setChangeStatus(false)
      await API.Admin.update_account_status(account.id, { status: 'New' });
      singleDisplay();
      getAccount();
    });
  };

  const reactivate = () => {
    runUpdateStatus(async () => {
      // setChangeStatus(false)
      await API.Admin.update_account_status(account.id, { status: 'Active' });
      singleDisplay();
      getAccount();
    });
  };

  return (
    <Box>
      <TableContainer className={classes.table} component={Paper}>
        {loading.loadAccountDetail && <LinearProgress size={30} />}
        {!loading.loadAccountDetail && !loading.setActive && account && (
          <Table>
            <TableBody>
              <DataRow name={'Name'} value={account.name} />
              <DataRow name={'Account ID'} value={account.reference} />
              <DataRow name={'Type'} value={snakeToTitle(account.entity.owner_type)} />
              <DataRow
                name={'Status'}
                value={
                  // showChangeStatus ? <div>
                  //   <Select value={snakeToTitle(account.status)} fullWidth onChange={onStatusChange}>
                  //     <MenuItem value="New">New</MenuItem>
                  //     <MenuItem value="Active">Active</MenuItem>
                  //   </Select>
                  // </div> :
                  <StatusLabel variantMap={StatusMaps.Account}>{account.status}</StatusLabel>
                }
              />
              <DataRow name={'Created date'} value={<DateDisplay date={account.created_at} />} />
              {/* {account && account.status !== "new" && account.status !== "suspended" && (
                <DataRow
                  name="Change Status"
                  value={
                    <Select
                      variant="outlined"
                      value={snakeToTitle(account.status)} fullWidth
                      onChange={onStatusChange} inputProps={{
                        className: classes.select
                      }}
                    >
                      <MenuItem value="New">New</MenuItem>
                      <MenuItem value="Active">Active</MenuItem>
                    </Select>
                  }
                />
              )} */}
              <DataRow
                name=""
                value={
                  <Box display="flex">
                    {account && account.status !== 'suspended' && <Chip icon={<RemoveCircleIcon />} onClick={() => singleDisplay('suspend')} label="Suspend" />}
                    {account && account.status === 'active' && <Chip className={classes.padRight} icon={<SettingsBackupRestoreIcon />} onClick={() => singleDisplay('new')} label="Set to new" />}
                    {account && account.status === 'suspended' && <Chip className={classes.padRight} icon={<SettingsBackupRestoreIcon />} onClick={() => singleDisplay('reactivate')} label="Re-activate" />}
                    {account && account.status === 'new' && !showConfirmActive && <Chip className={classes.padRight} icon={<EditIcon />} onClick={() => singleDisplay('active')} label="Activate" />}
                  </Box>
                }
              />
              {showConfirmActive && (
                <TableRow>
                  <TableCell colSpan={2}>
                    <Alert className={classes.warning} severity="info">
                      Account will be activated
                    </Alert>
                    <Box display="flex">
                      <Button disabled={loading.setActive} onClick={() => singleDisplay(false)}>
                        Cancel
                      </Button>
                      <Box flexGrow={1} />
                      <TraxxButton loading={loading.setActive} onSubmit={() => setToActive()}>
                        Confirm
                      </TraxxButton>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {showConfirmSuspend && (
                <TableRow>
                  <TableCell colSpan={2}>
                    <Alert className={classes.warning} severity="info">
                      Account will be suspended
                    </Alert>
                    <Box display="flex">
                      <Button disabled={loading.setActive} onClick={() => singleDisplay(false)}>
                        Cancel
                      </Button>
                      <Box flexGrow={1} />
                      <TraxxButton loading={loading.setActive} onSubmit={() => suspendAccount()}>
                        Confirm
                      </TraxxButton>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {showConfirmNew && (
                <TableRow>
                  <TableCell colSpan={2}>
                    <Alert className={classes.warning} severity="info">
                      Account status will be reverted to new
                    </Alert>
                    <Box display="flex">
                      <Button disabled={loading.setActive} onClick={() => singleDisplay(false)}>
                        Cancel
                      </Button>
                      <Box flexGrow={1} />
                      <TraxxButton loading={loading.setActive} onSubmit={() => unactivate()}>
                        Confirm
                      </TraxxButton>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {showConfirmReactivate && (
                <TableRow>
                  <TableCell colSpan={2}>
                    <Alert className={classes.warning} severity="info">
                      Account will be reactivated
                    </Alert>
                    <Box display="flex">
                      <Button disabled={loading.setActive} onClick={() => singleDisplay(false)}>
                        Cancel
                      </Button>
                      <Box flexGrow={1} />
                      <TraxxButton loading={loading.setActive} onSubmit={() => reactivate()}>
                        Confirm
                      </TraxxButton>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              <DataRow name="" value={<Chip icon={<EditIcon />} onClick={() => handleOpen()} label="Edit" />} />
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {wallet && (
        <WalletCard
          onClick={() => {
            router.history.push(`/admin/wallets/${wallet.id}/detail`);
          }}
          wallet={wallet}
          className={classes.walletCard}
        />
      )}
      {account && editData && <EditDialog loading={loading.loadAccountDetail} onSubmit={onSubmit} editData={editData} onClose={handleClose} open={dialogOpen} editHeader="Edit account" />}
    </Box>
  );
}

export default AccountDetail;
