const decimal = require('decimal.js');

const currencies = {
  SGD: {
    name: 'Singapore Dollars',
    compression: 2,
    symbol: 'SGD',
    country_code: 'sg',
  },
  USD: {
    name: 'U.S. Dollars',
    compression: 2,
    symbol: 'USD',
    country_code: 'us',
  },
  TXC: {
    name: 'TRAXX.ID Credits',
    compression: 2,
    symbol: 'credits',
  },
};

export const active = () => 'SGD';
export const map = () => JSON.parse(JSON.stringify(currencies));
export const list = () => Object.values(currencies);
export const symbols = () => Object.values(currencies).map((currency) => currency.symbol);
export const decompress = (value, currency) => {
  if (!value || !currency) return value;
  return parseInt(decimal(value).times(decimal(10).pow(currencies[currency].compression).toFixed(0)));
};

export default {
  currencies,
  active,
  map,
  list,
  symbols,
  decompress,
};
