import { Box, Container, Grid, Paper, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WorkIcon from '@material-ui/icons/Work';
import { Title } from 'app/components';
import { SelectDocument } from 'app/views/KYC/NewDocumentScreening/components';
import { ScreenParams } from 'app/views/KYC/NewScreening/components';
import ScreeningDetail from 'app/views/Screening/ScreeningDetail';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { CaseAssignment, CompleteScreen } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 1, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
    '@media print': {
      padding: 'none',
    },
  },
  stepper: {
    backgroundColor: 'transparent',
    '& .MuiStepConnector-vertical': {
      padding: 0,
    },
    '@media print': {
      display: 'none',
    },
  },
  completeScreen: {
    maxWidth: 800,
    margin: '0 auto',
    textAlign: 'center',
  },
  container: {
    width: '100%',
    alignItems: 'center',
    alignContent: 'center',
  },
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
  noPrintPadding: {
    padding: theme.spacing(3),
    '@media print': {
      padding: 'none',
      boxShadow: 'none',
    },
  },
}));

const steps = [
  {
    label: 'Case assignment',
    icon: WorkIcon,
  },
  // {
  //   label: 'Document type',
  //   icon: WorkIcon,
  // },
  {
    label: 'Document upload',
    footer: '(6 credits)',
    icon: WorkIcon,
  },
  // {
  //   label: 'Verification result',
  //   icon: WorkIcon,
  // },
  {
    label: 'Screening',
    footer: '(4 credits)',
    icon: WorkIcon,
  },
  {
    label: 'Screening result',
    icon: WorkIcon,
  },
  {
    label: 'Complete',
    icon: WorkIcon,
  },
];

const DEFAULT_INPUTS = {
  documentType: 'id',
  caseName: null,
  newCase: true,
  caseId: null,
  params: {},
};

const FullKYC = (props) => {
  const { children, className, ...rest } = props;
  const classes = useStyles();
  // eslint
  const [activeStep, setActiveStep] = useState(0);
  const [inputs, setInputs] = useState({
    ...DEFAULT_INPUTS,
    params: { ...DEFAULT_INPUTS.params },
  });
  const [documentResult, setDocumentResult] = useState();
  const [screenResult, setScreenResult] = useState();
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Title className={classes.noPrint} prefix="Create screening" />

      <Container maxWidth="lg">
        <Paper>
          <Grid container>
            <Grid item xs={12} md={2}>
              <Stepper activeStep={activeStep} className={classes.stepper} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepLabel>
                      <Typography>{step.label}</Typography>
                      {step.footer && <Typography variant="body2">{step.footer}</Typography>}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item xs={12} md={10}>
              <Box className={classes.noPrintPadding}>
                {activeStep === 0 && <CaseAssignment caseName={inputs.caseName} onNameInput={(caseName) => setInputs({ ...inputs, caseName })} onNext={handleNext} />}
                {/* {activeStep === 1 && <SelectType type={inputs.documentType} onSelect={(documentType) => setInputs({ ...inputs, documentType })} onBack={handleBack} onNext={handleNext} />} */}
                {activeStep === 1 && <SelectDocument type={inputs.documentType} newCase={inputs.newCase} caseName={inputs.caseName} onDocument={(document) => setDocumentResult(document)} onBack={handleBack} onNext={handleNext} />}
                {/* {activeStep === 2 && <DocumetDetail documentResult={documentResult} onBack={handleBack} onNext={handleNext} />} */}
                {activeStep === 2 && (
                  <ScreenParams
                    documentResult={documentResult}
                    caseId={documentResult.model?.case_id}
                    // documentType={inputs.documentType}
                    type={'person'}
                    onResult={(result) => {
                      setScreenResult(result);
                    }}
                    onBack={handleBack}
                    onNext={handleNext}
                    full={true}
                  />
                )}
                {activeStep === 3 && <ScreeningDetail routeType={'account'} screenId={screenResult.screens[0].id} onNext={handleNext} />}
              </Box>
              <div className={classes.completeScreen}>{activeStep === 4 && <CompleteScreen caseId={documentResult?.model?.case_id} />}</div>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default FullKYC;
