import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import React from 'react';

const useStyle = makeStyles((theme) => ({
  root: {
    margin: 0,
  },
  mono: {
    fontFamily: 'Roboto Mono',
  },
  textField: {
    marginTop: theme.spacing(1),
  },
}));
const default_opt = [];

/**
 * Auto complete text field used for search
 *
 * Props:
 ** label: placeholder
 ** className: name of css class element
 */
const SearchSelect = (props) => {
  const { value, onSelect, onInput, inputValue = '', label, required, disabled, options = default_opt, loading, helperText, className, ...rest } = props;
 
  const classes = useStyle();
  return (
    <Autocomplete
      className={clsx(classes.root, className)}
      disabled={disabled}
      value={value}
      required={required}
      inputValue={inputValue}
      onChange={onSelect}
      loading={!!loading}
      onInputChange={onInput}
      options={options.map((opt) => opt.name)}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.textField}
          label={label || 'Search'}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          helperText={helperText}
          // InputProps={{ ...params.InputProps, type: 'search' }}
        />
      )}
      {...rest}
    />
  );
};

SearchSelect.propTypes = {};

SearchSelect.defaultProps = {};

export default SearchSelect;
