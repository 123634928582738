import { ListingComp, ListingCompTable } from 'app/components';
import { API } from 'app/services';
import { useToastCatcher, msgParser } from 'app/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const headers = ['Event creator', 'Owner', 'Description', 'Event time', 'IP address'];

let mounted = false;
function EventLogListComp(props) {
  const { routeType = 'account', actor, account } = props;
  const [eventLogs, setEventLogs] = useState([]);
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  // TODO
  // eslint-disable-next-line
  const [toaster, errorCatcher] = useToastCatcher({ errorParser: null, taskname: 'accountList' });

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const listEventLog = (newFilter = filter) => {
    setLoading(true);
    errorCatcher(async () => {
      let loader;
      if (routeType === 'admin') loader = API.Admin.get_event_logs;
      else loader = API.Users.get_event_logs;

      if (actor) newFilter.actor = actor;
      if (account) newFilter.account = account;

      const { models, meta } = await loader(newFilter);
      if (mounted) {
        setFilter({ ...newFilter, count: meta.count });
        sortListingData(models);
        setLoading(false);
        setLoaded(true);
      }
    });
  };

  const getActorLink = (model) => {
    if (routeType === 'admin') return `/admin/persons/${model.actor?.person.id}/detail`;
    return `/app/users/${model.account_entity.id}/detail`;
    // TODO account event log uses account entity id
    // else return `/app/users/${model.id}/detail`
  };

  const getOwnerData = (owner, type) => {
    if (!owner) return {};
    if (routeType === 'admin') {
      if (type === 'account') {
        return { label: owner.name, type: 'labelwithlink', link: `/admin/accounts/${owner.id}/detail`, bottomLabel: owner.reference };
      }
      if (type === 'person') {
        return { label: owner.firstname, type: 'labelwithlink', link: `/admin/persons/${owner.id}/detail`, bottomLabel: owner.reference };
      }
      if (type === 'screen') {
        return { label: `${owner.type} screen`, type: 'labelwithlink', link: `/admin/screenings/${owner.id}/detail`, bottomLabel: owner.reference };
      }
      if (type === 'case') {
        return { label: 'case screen', type: 'labelwithlink', link: `/admin/screenings/cases/${owner.id}/detail`, bottomLabel: owner.reference };
      }
      // if(type === "verification") {
      //   return { label: "case screen", type: "labelwithlink", link: `/admin/screenings/cases/${owner.id}/detail`, bottomLabel: owner.reference };
      // }
    }

    if (routeType === 'account') {
      if (type === 'verification') {
        return { label: owner.name || 'Document', type: 'labelwithlink', link: `/documents/${owner.id}/detail`, bottomLabel: owner.reference };
      }
      if (type === 'screen') {
        return { label: owner.name || 'Screen', type: 'labelwithlink', link: `/screenings/${owner.id}/detail`, bottomLabel: owner.reference };
      }
      if (type === 'case') {
        return { label: owner.name || 'Case', type: 'labelwithlink', link: `/screenings/cases/${owner.id}/detail`, bottomLabel: owner.reference };
      }
      if (type === 'person') {
        return { label: owner.name || 'Person', type: 'labelwithlink', link: `/app/users/${owner.account_entity?.id}/detail`, bottomLabel: owner.reference };
      }
    }

    return {};
  };

  const sortListingData = (models) => {
    let allData = [];

    if (routeType === 'admin') {
      models.forEach((model) => {
        let currArr = [];
        if (account) currArr.push({ label: model.actor?.person?.firstname, type: 'labelwithlink', link: getActorLink(model), bottomLabel: model.actor?.person.reference });
        else if (actor) currArr.push({ label: model.account?.name, bottomLabel: model.account?.reference, type: 'labelwithlink', link: `/admin/accounts/${model.account?.id}/detail` });
        else {
          currArr.push({ label: model.actor?.person?.firstname, type: 'labelwithlink', link: getActorLink(model), bottomLabel: model.actor?.person.reference });
          currArr.push({ label: model.account?.name, bottomLabel: model.account?.reference, type: 'labelwithlink', link: `/admin/accounts/${model.account?.id}/detail` });
        }
        currArr.push(getOwnerData(model.owner, model.owner_type));
        currArr.push({ label: msgParser(model.description) });
        currArr.push({ date: model.timestamp, type: 'date' });
        currArr.push({ label: model.ip_address, type: 'mono' });
        allData.push([...currArr]);
      });
    } else {
      models.forEach((model) => {
        if (model.account_entity && model.owner) {
          model.owner.account_entity = model.account_entity;
        }
        allData.push([
          { label: model.actor?.person?.firstname, type: 'labelwithlink', link: getActorLink(model), bottomLabel: model.actor?.person.reference },
          getOwnerData(model.owner, model.owner_type),
          { label: msgParser(model.description) },
          { date: model.timestamp, type: 'date' },
          { label: model.ip_address, type: 'mono' },
        ]);
      });
    }

    setEventLogs(allData);
  };

  const getHeader = () => {
    if (routeType === 'account') return headers;
    if (actor) {
      return ['Account', 'Owner', 'Description', 'Event time', 'IP address'];
    }
    if (account) {
      return ['Event creator', 'Owner', 'Description', 'Event time', 'IP address'];
    }
    return ['Event creator', 'Account', 'Owner', 'Description', 'Event time', 'IP address'];
  };

  return (
    <ListingComp showFilter={false} loading={loading || loadTask['accountList']} reloadFunc={listEventLog} loaded={loaded} filter={filter} setLoading={setLoading}>
      <ListingCompTable headers={getHeader()} data={eventLogs} />
    </ListingComp>
  );
}

export default EventLogListComp;
