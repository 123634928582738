import { get_api_path } from './api';
import { sessioned_client } from './auth';
import { store } from 'app/store';

export async function getProfile() {
  const url = get_api_path('self/profile');
  const { token } = store.getState().profile.access;
  const response = await sessioned_client({ token }).get(url);
  const { model } = response.data.result;
  return { model };
}

export async function accountList() {
  const url = get_api_path('self/account/list');
  const { token } = store.getState().profile.access;
  const response = await sessioned_client({ token }).get(url);
  const { models, meta } = response.data.result;
  return { models, meta };
}
export async function joinAccount(inviteToken) {
  const url = get_api_path('self/account/join');
  const { token } = store.getState().profile.access;
  const response = await sessioned_client({ token }).post(url, { token: inviteToken });
  return response.data.result;
}
export async function authorise(account_id) {
  const url = get_api_path('self/authorise', null, { account_id });
  const { token } = store.getState().profile.access;
  const response = await sessioned_client({ token }).post(url);
  return response.data.result;
}
