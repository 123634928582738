import React, { useState, useEffect } from 'react';
import { Box, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { TraxxButton } from 'app/components';
import TitleField from '../TitleField';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: '100%',
  },
  imageContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    flexDirection: 'row',
  },
  image: {
    padding: theme.spacing(1),
    objectFit: 'contain',
    width: 200,
  },
  loadImageBtn: {
    float: 'right',
    height: 25,
    fontSize: 12,
  },
  downloadBtn: {
    marginRight: 5,
    [theme.breakpoints.down('476')]: {
      marginRight: 0,
      marginTop: 3
    }
  }
}));

const BerbixImageField = (props) => {
  const { images = [], className, updateVerify, downloadImages, imageError, setImageError, routeType, ...rest } = props;
  const classes = useStyles();

  const [downloadPrivilege, setDownloadPrivilege] = useState(false);
  const workspace = useSelector((state) => state.profile.workspace);

  useEffect(() => {
    if (workspace.account.entity.privileges.find(privilege => privilege.name === 'berbix.download_image')) setDownloadPrivilege(true);
  }, [workspace])

  return (
    <Card {...rest} className={clsx(classes.card, className)}>
      
      <TitleField>
        Uploaded Document
        
        <TraxxButton onSubmit={updateVerify} className={classes.loadImageBtn}>
          Reload Images
        </TraxxButton>
        { (!imageError && images && (routeType === "admin" || downloadPrivilege)) &&
          <TraxxButton onSubmit={downloadImages} className={clsx(classes.loadImageBtn, classes.downloadBtn)}>
            Download as .zip
          </TraxxButton>
        }
      </TitleField>

      <Box classes={classes.imageContainer}>
        {images.front?.full_image && <img alt="front" src={images.front?.full_image} className={classes.image} onError={() => setImageError(true)} />}
        {images.back?.full_image && (<img alt="back" src={images.back?.full_image} className={classes.image} onError={() => setImageError(true)} />)}
        {images.selfie?.full_image && (<img alt="selfie" src={images.selfie?.full_image} className={classes.image} onError={() => setImageError(true)} />)}
        {images.liveness?.full_image && (<img alt="liveness" src={images.liveness?.full_image} className={classes.image} onError={() => setImageError(true)} />)}
      </Box>
      
    </Card>
  );
};

export default BerbixImageField;
