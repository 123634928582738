import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateDisplay, DowJonesBrand, MonoText, RefinitivBrand, StatusLabel, Title } from 'app/components';
import { StatusMaps } from 'app/constants';
import clsx from 'clsx';
import moment from 'moment';
import React, { useState } from 'react';
import { PreviewMatchList, TableDetailPreview, TablePreview } from './components';
const useStyles = makeStyles((theme) => ({
  root: {
    '@media print': {
      boxShadow: 'none',
    },
    '-webkit-print-color-adjust': 'exact !important',
    'color-adjust': 'exact !important',
  },
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
  printContent: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
    '@media print': {
      margin: 'none',
    },
    component: 'none',
  },
  logo: {
    height: 82,
  },
  frame: {
    width: '80%',
    'margin-left': 'auto',
    'margin-right': 'auto',
  },
  marginTop: {
    marginTop: theme.spacing(2),
    '@media print': {
      margin: 'none',
    },
  },
  table: {
    border: '2px solid #f5f5f5',
  },
  tableHeader: {
    backgroundColor: 'red',
  },
  button: {
    float: 'right',
    height: 30,
    marginLeft: theme.spacing(2),
    '@media print': {
      margin: 'none',
      height: 0,
      display: 'none',
    },
  },
}));

const getTableData = (key, value, extra) => {
  return { key, value, extra };
};

const getScreen = ({ screen }) => {
  return [
    getTableData('Date', screen.submit_at, { wrapper: () => <DateDisplay date={screen.submit_at} dateFormat={'DD-MMM-YYYY HH:mm'} /> }),
    getTableData('Screen ID', screen.reference, { wrapper: MonoText }),
    getTableData('Type', screen.type),
    getTableData('Status', screen.status, { wrapper: ({ children }) => <StatusLabel variantMap={StatusMaps.Screen}>{children}</StatusLabel> }),
    getTableData('Match Count', screen.results_count),
  ];
};

const getCase = ({ case: caseRef }) => {
  return [
    getTableData('Name', caseRef.label),
    getTableData('Case ID', caseRef.reference, { wrapper: MonoText }),
    getTableData('Status', caseRef.status, { wrapper: ({ children }) => <StatusLabel variantMap={StatusMaps.Case}>{children}</StatusLabel> }),
  ];
};

const getMatch = (match) => {
  if (match.screen_provider_id === 1) {
    return [
      getTableData('Match ID', match.reference, { wrapper: MonoText }),
      getTableData('Name', match.name),
      getTableData('Match Rating', match.match_rating),
      getTableData('Match Type', match.match_type),
      getTableData('Gender', match.gender),
      getTableData('Profile Retrieve Date', match.profile_retrieved_at, { wrapper: () => <DateDisplay date={match.profile_retrieved_at} /> }),
    ];
  } else {
    return [
      getTableData('Match ID', match.reference, { wrapper: MonoText }),
      getTableData('Name', match.name),
      getTableData('Match Rating', match.match_rating),
      getTableData('Match Type', match.match_type),
      getTableData('Match Score', match.score),
      getTableData('Gender', match.gender),
      getTableData('Profile Retrieve Date', match.profile_retrieved_at, { wrapper: () => <DateDisplay date={match.profile_retrieved_at} /> }),
    ];
  }
};

const cleanComment = (comment) => {
  const comments = comment?.split('\n');
  const conciseComments = comments?.slice(3, comments.length);
  const listComments = conciseComments?.map((c) => (
    <>
      {c}
      <br />
    </>
  ));

  return <>{listComments}</>;
};

const cleanRelationships = (relationships) => {
  const listRelationships = relationships?.map((c) => (
    <>
      {c.name_detail.first_name} {c.name_detail.middle_name} {c.name_detail.surname} ({c.icon_hints}) ({c.connection_type}),{' '}
    </>
  ));
  return <>{listRelationships}</>;
};

const cleanReports = (reports) => {
  const listReports = reports.map((r) => (
    <>
      {r.title || r.external_link} <br />
    </>
  ));
  return <>{listReports}</>;
};

const getMatchDetail = ({ screen }, { details, names }) => {
  let arr = [];
  if (screen.screen_provider_id === 1) {
    details.forEach((detail) => {
      arr.push(getTableData(detail.title, detail.text));
    });
  } else {
    console.log(details.watchlist.comment_details.si_comment);
    console.log(details.watchlist.comment_details.list_comment);
    console.log(details.watchlist);

    const profile = cleanComment(details.watchlist.comment_details.si_comment || details.watchlist.comment_details.list_comment);
    arr.push(getTableData('Profile', profile));
    const relationships = cleanRelationships(names?.connection_details);
    arr.push(getTableData('Identification', relationships));
    const reports = cleanReports(details.watchlist.sources);
    arr.push(getTableData('Articles and Reports', reports));
  }
  return arr;
};

const DownloadPreview = (props) => {
  const { matchList, detail, profile, cancelPrint, className, ...rest } = props;
  const [screenData] = useState(getScreen(detail));
  const [caseData] = useState(getCase(detail.screen));
  const [matchData] = useState(getMatch(detail));
  const [matchDetailData] = useState(getMatchDetail(detail, profile));
  const classes = useStyles();

  const getButtons = () => {
    let arr = [
      { space: 3, onClick: () => cancelPrint(), text: 'Cancel' },
      { space: 4, onClick: () => window.print(), text: 'Print' },
      { space: 5, holder: true },
    ];
    return arr;
  };

  return (
    <Box className={clsx(classes.root, className)}>
      <Title prefix="Print preview" className={classes.noPrint} floatButtons={getButtons()} />
      <Card className={clsx(classes.root, classes.frame, className)}>
        <CardContent className={classes.printContent}>
          <Grid container justify="space-between" {...rest}>
            <Grid item>
              <img alt="Brand" className={classes.logo} src="/images/traxx_logo.svg" />
              {detail.screen.screen_provider_id === 1 ? <RefinitivBrand /> : detail.screen.screen_provider_id === 1 ? <DowJonesBrand /> : "Powered by ComplyAdvantage"}
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <Grid item>
              <MonoText>
                <Typography variant="h4" fontWeight="bold">
                  Screening Report
                </Typography>
              </MonoText>
            </Grid>
          </Grid>
          <Grid className={classes.marginTop} container justify="space-between">
            <Grid item>
              <MonoText>{`Report generated on ${moment().format('DD MMM YYYY, HH:mm')}`}</MonoText>
            </Grid>
          </Grid>
          <Grid className={classes.marginTop} container spacing={2} justify="space-between">
            <Grid item xs={6} sm={6} lg={6}>
              <TablePreview header="Match profile" data={matchData} />
            </Grid>
            <Grid item xs={6} sm={6} lg={6}>
              <TablePreview header="Case details" data={caseData} />
            </Grid>
          </Grid>
          <Grid className={classes.marginTop} container spacing={2} justify="space-between">
            <Grid item xs={12} sm={12} lg={12}>
              <TablePreview header="Screen details" data={screenData} />
            </Grid>
          </Grid>
          <Grid className={classes.marginTop} container spacing={2} justify="space-between">
            <Grid item xs={12} sm={12} lg={12}>
              <TableDetailPreview header="Match detail" data={matchDetailData} />
            </Grid>
          </Grid>
          {matchList && (
            <Grid className={classes.marginTop} container spacing={2} justify="space-between">
              <Grid item xs={12} sm={12} lg={12}>
                <PreviewMatchList header="All matches" matchList={matchList} />
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default DownloadPreview;
