import { Grid } from '@material-ui/core';
import { API } from 'app/services';
import { useToastCatcher } from 'app/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RequestView, SuccessView } from './components';

const init_inputs = {
  confirmPassword: '',
  newPassword: '',
};

const init_errors = {
  confirmPassword: '',
  newPassword: '',
  message: '',
};

const init_visibilities = {
  confirmPassword: '',
  newPassword: '',
};

let mounted = false;

function ResetPasswordRequest() {
  const [pwToken, setPwToken] = useState(null);
  const [inputs, setInputs] = useState(init_inputs);
  const [user, setUser] = useState(null);
  const [errors, setErrors] = useState(init_errors);
  const [visibility, setVisibility] = useState(init_visibilities);
  const [success, setSuccess] = useState(false);
  const errorParser = (error) => {
    if (!error) return;
    let message = 'Error Occured!';
    if (error && error.message === 'request expired') message = 'Request has expired!';
    if (error && error.message === 'already reset') message = 'Password has already been resetted.';
    if (error && error.message === 'invalid token') message = 'Invalid Token.';
    setErrors({ ...errors, message });
    return message;
  };
  const [toaster, errorCatcher] = useToastCatcher({ errorParser, taskname: 'resetPasswordRequest' });
  const loading = useSelector((state) => state.layout.loadingTasks);

  const handleChange =
    (type) =>
    ({ target }) =>
      setInputs((values) => ({ ...values, [type]: target.value }));

  const handleVisibility = (type) => () =>
    setVisibility((values) => ({
      ...values,
      [type]: !values[type],
    }));

  useEffect(() => {
    mounted = true;
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('pw_token');
    if (!token) {
      toaster('Token not available!');
      setErrors({ ...errors, message: 'Token not available!' });
    } else {
      getTokenRequester(token);
      setPwToken(token);
    }
    mounted = true;
    return () => (mounted = false);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!pwToken) return;
    getTokenRequester();
    // eslint-disable-next-line
  }, [pwToken]);

  const getTokenRequester = (token) => {
    errorCatcher(async () => {
      const response = await API.Auth.password_query({ token });
      if (mounted && response.email) {
        setUser(response);
      }
    }, 'requestpwtoken');
  };

  const validateInput = () => {
    const { newPassword, confirmPassword } = inputs;
    const error = {};
    if (!newPassword || newPassword.length < 8) error.newPassword = 'Password minimum 8 characters';
    if (newPassword !== confirmPassword) error.confirmPassword = 'password does not match.';
    return error;
  };

  const resetPassword = (event) => {
    let error = validateInput();
    setErrors({ ...init_errors, ...error });
    if (Object.keys(error).length > 0) return;

    errorCatcher(async () => {
      const { newPassword } = inputs;
      await API.Auth.password_reset({
        password: newPassword,
        token: pwToken,
      });
      if (mounted) {
        toaster('Password reset successful!', { variant: 'success' });
        setSuccess(true);
      }
    }, 'resetpassword');
  };
  const should_load = loading['requestpwtoken'] || loading['resetpassword'];

  return (
    <Grid container item alignItems="center" justify="center" xs>
      {success && <SuccessView />}
      {!success && <RequestView loading={should_load} user={user} resetPassword={resetPassword} inputs={inputs} errors={errors} handleChange={handleChange} visibility={visibility} handleVisibility={handleVisibility} />}
    </Grid>
  );
}

export default ResetPasswordRequest;
