import { AppBar, Box, Button, CircularProgress, Grid, Hidden, IconButton, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/MenuOutlined';
import { GlobalShowKeys } from 'app/constants';
import { actions } from 'app/store';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileMenu } from './components';

const useStyles = makeStyles((theme) => ({
  badge: { backgroundColor: '#780000' },
  grow: { flexGrow: 1 },
  logo: {
    height: 38,
    width: 128,
  },
  root: {
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.divider}`,
    zIndex: 200,
  },
  section: { display: 'flex' },
  user: {
    padding: theme.spacing(0, 1),
  },
  helloText: {
    marginLeft: theme.spacing(1),
    textTransform: 'none',
  },
  loading: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  menuIcon: {
    marginRight: theme.spacing(2),
  },
}));

/**
 * Top navigation bar
 *
 * Props:
 ** className: name of css class element
 */
const NavBar = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const self = useSelector(({ profile }) => profile.self);
  const loading = useSelector((state) => state.layout.loadingTasks.loadProfile);

  const onOpenMenu = () => {
    dispatch(actions.Layout.updateGlobalShow(GlobalShowKeys.MobileSideNav));
  };

  return (
    <>
      <Box {...rest} className={clsx(classes.root, className)}>
        <AppBar color="transparent" elevation={0} position="static">
          <Toolbar>
            <Hidden mdUp>
              <IconButton onClick={onOpenMenu} className={clsx(classes.menuIcon)}>
                <MenuIcon />
              </IconButton>
            </Hidden>

            <img className={classes.logo} src="/images/traxx_logo.svg" alt="TRAXX.ID logo" />

            <Hidden smDown>
              <Grid container item xs alignItems="center" justify="flex-end">
                {/* <IconButton color="secondary">
                <SearchIcon />
              </IconButton> */}

                {/* <IconButton color="secondary">
                <Badge
                  badgeContent={1}
                  classes={{ badge: classes.badge }}
                  overlap="circle"
                  variant="dot"
                >
                  <NotificationsNoneOutlinedIcon />
                </Badge>
              </IconButton> */}

                {!loading ? (
                  <Button onClick={() => setShowProfileMenu(!showProfileMenu)}>
                    <img className={classes.user} src="/images/user.svg" alt="user" />
                    <Typography className={classes.helloText}>
                      <strong>Hello, {self?.person?.firstname}</strong>
                    </Typography>
                  </Button>
                ) : (
                  <CircularProgress className={classes.loading} size={14} />
                )}
              </Grid>
            </Hidden>
          </Toolbar>
        </AppBar>
      </Box>
      <ProfileMenu onClose={() => setShowProfileMenu(false)} open={showProfileMenu} />
    </>
  );
};

export default NavBar;
