import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React from 'react';
import TitleField from '../TitleField';
import { resultParser } from 'app/utils';
import { StatusLabel } from 'app/components';

const useStyles = makeStyles((theme) => ({
  card: {
    // padding: theme.spacing(1),
    width: '100%',
  },
  title: {
    paddingLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
  detail: {
    flexDirection: 'column',
  },
  detailBox: {
    marginBottom: theme.spacing(2),
  },
  summaryHeader: {
    fontWeight: 'bold',
  },
  result: {
    marginRight: theme.spacing(2),
  },
  semibold: {
    fontWeight: '600',
  },
}));

const AlertsTable = (props) => {
  const { alerts = [], className } = props;
  const classes = useStyles();

  const getLabelStyle = (result) => {
    switch (result) {
      case 1:
        return 'success';
      case 2:
        return 'error';
      default:
        return 'warning';
    }
  };

  return (
    <Box className={clsx(className, classes.card)}>
      <TitleField>Alerts</TitleField>
      {alerts.map((alert) => (
        <>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <StatusLabel variant={getLabelStyle(alert.Result)} className={classes.result}>
                {resultParser.AlertResultParser(alert.Result)}
              </StatusLabel>
              <Typography className={classes.summaryHeader} color="primary" variant="body1">
                {alert.Name}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.detail}>
              <div className={classes.detailBox}>
                <Typography variant="body1" className={classes.semibold}>
                  Description
                </Typography>
                <Typography variant="body2">{alert.Description}</Typography>
              </div>
              <div className={classes.detailBox}>
                <Typography variant="body1" className={classes.semibold}>
                  Information
                </Typography>
                <Typography variant="body2">{alert.Information}</Typography>
              </div>
              <div className={classes.detailBox}>
                <Typography variant="body1" className={classes.semibold}>
                  Actions
                </Typography>
                <Typography variant="body2">{alert.Actions}</Typography>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </>
      ))}
    </Box>
  );
};

export default AlertsTable;
