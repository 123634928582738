import { Box, Link, Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataRow, MonoText, StatusLabel } from 'app/components';
import { StatusMaps } from 'app/constants';
import clsx from 'clsx';
import React from 'react';
import { Link as BrowserLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    display: 'inline-flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  underline: {
    textDecoration: 'underline',
  },
}));

const AccountComp = (props) => {
  const { account, children, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      {/* <Typography className={classes.title}>Account</Typography> */}
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <DataRow type="subHeader" name="ACCOUNT DETAIL" />
            <DataRow name="Account" value={account.name} />
            <DataRow name="Account ID" wrapperProps={{ underline: 'always', to: `/admin/accounts/${account.id}/detail`, component: BrowserLink }} wrapper={Link} value={<MonoText className={classes.underline}>{account.reference}</MonoText>} />
            <DataRow name="Status" value={<StatusLabel variantMap={StatusMaps.Account}>{account.status}</StatusLabel>} />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AccountComp;
