import { Grid, IconButton, makeStyles, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { TraxxButton } from 'app/components';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const useStyles = makeStyles((theme) => ({
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5,
  },
  dropZone: {
    alignItems: 'center',
    border: '2px dashed #d8d8d8',
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: 384,
    justifyContent: 'space-between',
    outline: 'none',
    padding: theme.spacing(6, 4.5),
    width: '100%',

    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer',
    },
  },
  dropzoneText: { color: '#686462' },
  icon: {
    fontSize: 20,

    [theme.breakpoints.up('sm')]: { fontSize: 24 },
  },
  fileImage: {
    height: 'auto',
    fontSize: 10,
    maxWidth: 20,
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [theme.breakpoints.up('sm')]: {
      height: 'auto',
      fontSize: 16,
      maxWidth: 24,
    },
  },
  fileProperties: {
    fontSize: 10,
    paddingRight: theme.spacing(1),
    width: '25%',

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.caption.fontSize,
    },
  },
  image: {
    height: 72,
    marginBottom: theme.spacing(1),
    width: 72,
  },
  selectedHeader: { fontWeight: 800 },
  selectedInstruction: { marginBottom: theme.spacing(2.5) },
  selectedSection: { width: '100%' },
  selectedSectionText: {
    border: '2px dashed #d8d8d8',
    borderRadius: 2,
    padding: theme.spacing(1),
  },
  selectedWrapper: {
    border: '1px solid #f5f5f5',
    borderRadius: 6,
    boxShadow: '0 2px 4px rgba(108, 133, 154, 0.111781)',
    height: 384,
    padding: theme.spacing(2.5),

    [theme.breakpoints.down('md')]: {
      height: ({ maxNumberOfFiles }) => (maxNumberOfFiles ? 'auto' : 384),
    },
  },
}));

function FilesDropzone({ buttonText, className, fileFormatText, fileNumberLimit, fileRegex, files, fileSizeLimit, header1, header2, imgSrc, imgAlt, instruction, selectedInstruction, setFiles, ...rest }) {
  const classes = useStyles({
    maxNumberOfFiles: files.length === fileNumberLimit,
  });

  const theme = useTheme();
  const matchSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const [filesSize, setFilesSize] = useState(0);
  const [filesSrc, setFilesSrc] = useState([]);

  const handleDelete = (index) => () => {
    setFiles((prevFiles) => {
      return index === 0 ? prevFiles.slice(1) : prevFiles.slice(0, index);
    });
    setFilesSrc((prevFiles) => {
      return index === 0 ? prevFiles.slice(1) : prevFiles.slice(0, index);
    });
  };

  const handleDrop = (acceptedFiles) => {
    if (files.length >= fileNumberLimit) {
      return;
    }

    const filteredFiles = acceptedFiles.filter((file, index) => {
      if (!fileRegex.test(file.path) || files.length + index + 1 > fileNumberLimit || filesSize + file.size > fileSizeLimit) {
        return false;
      }

      setFilesSize((prevSize) => prevSize + file.size);

      const reader = new FileReader();
      reader.onload = (event) => {
        setFilesSrc((prevFiles) => [...prevFiles].concat(event.target.result));
      };
      reader.readAsDataURL(file);

      return true;
    });

    setFiles((prevFiles) => [...prevFiles].concat(filteredFiles));
    // eslint-disable-next-line
  };

  const renderFileImage = (fileName, index) => {
    if (/(pdf)$/i.test(fileName)) {
      return <PictureAsPdfIcon className={classes.icon} />;
    }

    return <img className={classes.fileImage} src={filesSrc[index]} alt={fileName} />;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  });

  if (files.length) {
    return (
      <div className={className} {...rest}>
        <Grid className={classes.selectedWrapper} container alignContent="space-between">
          <div className={classes.selectedSection}>
            <Typography className={classes.selectedHeader}>{fileNumberLimit > 1 ? 'Selected files' : 'Selected file'}</Typography>

            {files.map((file, index) => {
              const fileSize = bytesToSize(file.size);

              return (
                <Grid key={index} container alignItems="center" justify="space-between">
                  <Tooltip title={file.name}>{renderFileImage(file.name, index)}</Tooltip>

                  <Tooltip title={file.name}>
                    <Typography className={classes.fileProperties} noWrap>
                      <strong>{file.name}</strong>&nbsp;
                    </Typography>
                  </Tooltip>

                  <Tooltip title={fileSize}>
                    <Typography className={classes.fileProperties} noWrap>
                      {fileSize}
                    </Typography>
                  </Tooltip>

                  <IconButton edge="end" onClick={handleDelete(index)}>
                    <DeleteIcon className={classes.icon} />
                  </IconButton>
                </Grid>
              );
            })}
          </div>

          {files.length < fileNumberLimit && (
            <div className={clsx(classes.selectedSection, classes.selectedSectionText, isDragActive && classes.dragActive)} {...getRootProps()}>
              <input {...getInputProps()} />

              <Typography className={classes.selectedInstruction} align="center" variant="body2">
                {selectedInstruction}
              </Typography>
              <Typography align="center" component="p" variant="caption">
                {fileFormatText}
              </Typography>
            </div>
          )}
        </Grid>
      </div>
    );
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div className={clsx(classes.dropZone, isDragActive && classes.dragActive)} {...getRootProps()}>
        <input {...getInputProps()} />

        <Grid container direction="column" alignItems="center">
          <img className={classes.image} src={imgSrc} alt={imgAlt} />

          <Typography className={clsx(classes.header, classes.dropzoneText)} align="center">
            <strong>{header1}</strong>
            <br />
            {header2}
          </Typography>
        </Grid>

        <TraxxButton>{buttonText}</TraxxButton>

        <Typography className={classes.dropzoneText} align="center" variant={matchSmUp ? 'body2' : 'caption'}>
          <strong>{instruction}</strong>
        </Typography>

        <Typography className={classes.dropzoneText} align="center" variant={matchSmUp ? 'body2' : 'caption'}>
          {fileFormatText}
        </Typography>
      </div>
    </div>
  );
}

FilesDropzone.propTypes = {
  className: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  fileFormatText: PropTypes.string.isRequired,
  fileNumberLimit: PropTypes.number.isRequired,
  fileRegex: PropTypes.any.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
    })
  ).isRequired,
  fileSizeLimit: PropTypes.number.isRequired,
  header1: PropTypes.string.isRequired,
  header2: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  instruction: PropTypes.string.isRequired,
  selectedInstruction: PropTypes.string.isRequired,
  setFiles: PropTypes.func.isRequired,
};

FilesDropzone.defaultProps = {
  className: '',
};

export default FilesDropzone;
