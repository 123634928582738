export const LocalStorageKeys = {
  AccessToken: 'traxxid:access-token',
};

export const StatusColors = {
  success: '#2CC990',
  warning: '#F2C94E',
  error: '#F24E4E',
  disabled: '#c2c2c2',
};

export const Paths = {
  Dashboard: {
    Overview: '/dashboard',
    nothing: '/nothing',
  },
  Auth: {
    Login: '/access/login',
    Expired: '/access/login',
  },
};

export const StatusMaps = {
  Account: {
    new: 'warning',
    active: 'success',
    suspended: 'disabled',
  },
  Screen: {
    complete: 'success',
    open: 'warning',
    screening: 'warning',
  },
  Case: {
    new: 'warning',
    active: 'success',
    archived: 'warning',
  },
  Wallet: {
    active: 'success',
    inactive: 'disabled',
  },
  Credit: {
    valid: 'success',
    invalid: 'warning',
  },
  Invite: {
    accepted: 'success',
    pending: 'warning',
    cancelled: 'error',
  },
  User: {
    active: 'success',
    disabled: 'error',
  },
  Verification: {
    open: 'warning',
    verifying: 'warning',
    disabled: 'error',
    completed: 'success',
    submitted: 'warning',
  },
  Order: {
    draft: 'warning',
    pending_payment: 'warning',
    paid: 'success',
    completed: 'success',
    cancelled: 'error',
  },
  Payment: {
    pending_payment: 'warning',
    gateway_processing: 'warning',
    failed: 'error',
    completed: 'success',
  },
  Batch: {
    unprocessed: 'warning',
    processed: 'success',
    processing: 'warning',
    failed: 'error',
  },
  API: {
    active: 'success',
    disabled: 'error',
  },
  CachedArticleRetrieval: {
    pending: 'warning',
    completed: 'success',
    expired: 'error',
  },
};

export const GlobalShowKeys = {
  MobileSideNav: 'mobile_side_nav',
};

export const GlobalRegex = {
  EmailValidation:
    // eslint-disable-next-line
    /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i,
};
