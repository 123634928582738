import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton } from 'app/components';
import { useRouter } from 'app/utils';
import React from 'react';

const useStyles = makeStyles(() => ({
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 500,
    justifyContent: 'space-between',
    padding: 40,
    width: 410,
  },
  image: {
    height: 200,
    width: 200,
  },
  link: { alignSelf: 'flex-start' },
}));

const SuccessView = () => {
  const classes = useStyles();
  const router = useRouter();
  return (
    <Card className={classes.card}>
      <form className={classes.form} autoComplete="false">
        <img className={classes.image} src="/images/reset_ps_success.svg" alt="sent mail" />

        <Typography variant="h5">Password reset successful</Typography>

        <Typography align="center" variant="body2">
          You can now proceed to login with your new password.
        </Typography>

        <TraxxButton fullWidth form onSubmit={() => router.history.push('/access/login')}>
          Login
        </TraxxButton>
      </form>
    </Card>
  );
};

export default SuccessView;
