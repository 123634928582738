import { Grid, ListItem, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InitialsAvatar from 'app/components/InitialsAvatar';
import React from 'react';

const useStyles = makeStyles(({ palette, typography }) => ({
  avatar: {
    backgroundColor: ({ color }) => color,
    color: palette.common.white,
    height: 32,
    fontSize: typography.caption.fontSize,
    fontWeight: 800,
    width: 32,
  },
  icon: {
    color: palette.grey.primary,
    marginLeft: 22,
  },
  text: { fontWeight: 'bold' },
  textContainer: {
    paddingLeft: 10,
  },
}));

const ListItemDropdown = ({ children, handleOpen, index, open, results, text }) => {
  const { palette } = useTheme();
  const avatarColors = [palette.red.primary, '#dad2c3', palette.secondary.main, '#b84a40', palette.primary.main];
  const classes = useStyles({ color: avatarColors[index] });

  return (
    <>
      <ListItem button onClick={handleOpen}>
        <InitialsAvatar index={index}>{text}</InitialsAvatar>

        <Grid className={classes.textContainer} container justify="space-between">
          <Typography color="secondary" className={classes.text} variant="caption">
            {text}
          </Typography>

          <Typography color="secondary" className={classes.text} variant="caption">
            {results} results
          </Typography>
        </Grid>

        {open ? <ExpandLessIcon className={classes.icon} /> : <ExpandMoreIcon className={classes.icon} />}
      </ListItem>

      {children}
    </>
  );
};

export default ListItemDropdown;
