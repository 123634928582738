import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import reducers from './reducers';
import * as actions from './actions';

// const logger = createLogger();

// const middlewares = [thunk, logger];
const middlewares = [thunk];

export { actions };
export const store = createStore(reducers, composeWithDevTools(applyMiddleware(...middlewares)));

export default store;
