import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: '1.5rem',
    marginBottom: '0.5rem',
  },
  bolded: {
    fontWeight: 'bold',
  },
}));

const TraxxIDTerms = () => {
  const classes = useStyles();

  return (
    <Container>
      <Typography variant="h4">TRAXX Terms and Conditions</Typography>

      <Typography variant="h5" className={classes.header}>
        Agreed Terms
      </Typography>
      <Typography variant="h5" className={classes.header}>
        1. <b>Definitions</b>
      </Typography>
      <Typography>The definitions and rules of interpretation in this clause apply in this Agreement.</Typography>
      <br></br>
      <Typography>
        1. Authorised Users: those employees, agents and independent contractors of the Client who are authorised by the Client to use the Services and the Documentation, as further described in 2.2(d). Business Day: a day other than a Saturday,
        Sunday, or public holiday in Singapore when banks in Singapore are open for business.
      </Typography>
      <br></br>
      <Typography>2. Business Day: a day other than a Saturday, Sunday, or public holiday in Singapore when banks in Singapore are open for business.</Typography>
      <br></br>
      <Typography>
        3. Change of Control: the expression change of control shall be construed as a change in the beneficial ownership of more than 50% of the issued share capital of a company or the legal power to direct or cause the direction of the general
        management of the company, and controls, controlled and the expression change of control shall be construed accordingly.
      </Typography>
      <br></br>
      <Typography>4. Confidential Information: information that is proprietary or confidential and is either clearly labelled as such or identified as Confidential Information in clause 8.5.</Typography>
      <br></br>
      <Typography>5. Controller, processor, data subject, personal data, personal data breach, processing, and appropriate technical and organisational measures: as defined in the Data Protection Legislation.</Typography>
      <br></br>
      <Typography>6. Client Data: the data and personal data inputted by the Client, Authorised Users, or TRAXX on the Client's behalf for the purpose of using the Services or facilitating the Client's use of the Services.</Typography>
      <br></br>
      <Typography>7. Data Protection Legislation: legislation relating to an individual's right to privacy with respect to the processing of Personally Identifiable Information which is applicable to a party from time to time.</Typography>
      <br></br>
      <Typography>8. Digital ID: digital identification, allowing remote identification through digital channels.</Typography>
      <br></br>
      <Typography>9. Documentation: the document made available to the Client by TRAXX which sets out a description of the Services and the user instructions for the Services.</Typography>
      <br></br>
      <Typography>10. e-KYC: electronic know-your-client, a remote, paperless process that minimizes cost and traditional methods necessary in KYC processes.</Typography>
      <br></br>
      <Typography>
        11. Heightened Cybersecurity Requirements: any laws, regulations, codes, guidance (from regulatory and advisory bodies. Whether mandatory or not), international and national standards, industry schemes and sanctions, which are applicable to
        either the Client or an Authorised User relating to security of network and information systems and security breach and incident reporting requirements, which may include the Cybersecurity Act 2018, all as amended or updated from time to
        time.
      </Typography>
      <br></br>
      <Typography>12. Normal Business Hours: [9.00 am to 6.00 pm] local Singapore time, each Business Day.</Typography>
      <br></br>
      <Typography>13. Personally Identifiable Information: personal data processed as part of the Services or in connection with this Agreement.</Typography>
      <br></br>
      <Typography>
        14. Services: the services provided by TRAXX to the Client under this Agreement via the TRAXX.ID website, or other website notified to the Client by TRAXX from time to time, as more particularly described in the Documentation.
      </Typography>
      <br></br>
      <Typography>15. Software: the online software applications provided by TRAXX as part of the Services.</Typography>
      <br></br>
      <Typography>16. Support Services Policy: TRAXX's policy for providing support in relation to the Services as made available or such other website address as may be notified to the Client from time to time.</Typography>
      <br></br>
      <Typography>
        17. Virus: any thing or device (including any software, code, file or programme) which may: prevent, impair or otherwise adversely affect the operation of any computer software, hardware or network, any telecommunications service, equipment
        or network or any other service or device; prevent, impair or otherwise adversely affect access to or the operation of any programme or data, including the reliability of any programme or data (whether by re-arranging, altering or erasing the
        programme or data in whole or part or otherwise); or adversely affect the user experience, including worms, trojan horses, viruses and other similar things or devices.
      </Typography>
      <br></br>
      <Typography>
        18. Vulnerability: a weakness in the computational logic (for example, code) found in software and hardware components that when exploited, results in a negative impact to the confidentiality, integrity, or availability, and the term
        Vulnerabilities shall be construed accordingly.
      </Typography>
      <br></br>
      <br></br>
      <Typography>1.2 Clause, schedule, and paragraph headings shall not affect the interpretation of this Agreement.</Typography>
      <br></br>
      <Typography>1.3 A person includes an individual, corporate, or unincorporated body (whether having separate legal personality) [and that person's legal and personal representatives, successors or permitted assigns].</Typography>
      <br></br>
      <Typography>1.4 A reference to a company shall include any company, corporation, or other body corporate, wherever and however incorporated or established.</Typography>
      <br></br>
      <Typography>1.5 Unless the context otherwise requires, words in the singular shall include the plural and, in the plural, shall include the singular.</Typography>
      <br></br>
      <Typography>1.6 Unless the context otherwise requires, a reference to one gender shall include a reference to the other genders. </Typography>
      <br></br>
      <Typography>1.7 A reference to a statute or statutory provision is a reference to it as it is in force as at the date of this Agreement.</Typography>
      <br></br>
      <Typography>1.8 A reference to a statute or statutory provision shall include all subordinate legislation made as at the date of this Agreement under that statute or statutory provision.</Typography>
      <br></br>
      <Typography>1.9 A reference to writing or written includes faxes but not e-mail.</Typography>
      <br></br>
      <Typography>1.10 References to clauses and schedules are to the clauses and schedules of this Agreement; references to paragraphs are to paragraphs of the relevant schedule to this Agreement.</Typography>

      <Typography variant="h5" className={classes.header}>
        <b>2. User subscriptions</b>
      </Typography>
      <Typography>
        2.1 Subject to the Client purchasing the User Subscriptions in accordance with the restrictions set out in this 2 and the other terms and conditions of this Agreement, TRAXX hereby grants to the Client a non-exclusive, non-transferable right,
        without the right to grant sublicences, to permit the Authorised Users to use the Services and the Documentation during the Subscription Term solely for the Client's internal business operations.
      </Typography>
      <br></br>
      <Typography>
        2.2 In relation to the Authorised Users, the Client undertakes that:
        <List>
          <ListItem>(a) the maximum number of Authorised Users that it authorises to access and use the Services and the Documentation shall not exceed the number of User Subscriptions it has purchased from time to time;</ListItem>
          <ListItem>
            (b) it will not allow or suffer any User Subscription to be used by more than one individual Authorised User unless it has been reassigned in its entirety to another individual Authorised User, in which case the prior Authorised User
            shall no longer have any right to access or use the Services and/or Documentation;
          </ListItem>
          <ListItem>(c) each Authorised User shall keep a secure password for his use of the Services and Documentation, that such password shall be changed regularly, and that each Authorised User shall keep his password confidential;</ListItem>
          <ListItem>(d) it shall maintain a written, up to date list of current Authorised Users and provide such list to TRAXX within [5] Business Days of TRAXX's written request at any time or times;</ListItem>
          <ListItem>
            (e) it shall permit TRAXX or TRAXX's designated auditor to audit the Services to establish the name and password of each Authorised User and the Client's data processing facilities to audit compliance with this Agreement. Each such audit
            may be conducted no more than once per quarter, at TRAXX's expense, and this right shall be exercised with reasonable prior notice, in such a manner as not to substantially interfere with the Client's normal conduct of business;
          </ListItem>
          <ListItem>
            (f) if any of the audits referred to in 2.2(e) reveal that any password has been provided to any individual who is not an Authorised User, then without prejudice to TRAXX's other rights, the Client shall promptly disable such passwords
            and TRAXX shall not issue any new passwords to any such individual; and
          </ListItem>
          <ListItem>
            (g) if any of the audits referred to in 2.2(e) reveal that the Client has underpaid Subscription Fees to TRAXX, then without prejudice to TRAXX's other rights, the Client shall pay to TRAXX an amount equal to such underpayment as
            calculated in accordance with the prices set out within [10] Business Days of the date of the relevant audit.
          </ListItem>
        </List>
      </Typography>
      <br></br>
      <Typography>
        2.3 The Client shall not access, store, distribute or transmit any Viruses, or any material during the course of its use of the Services that:
        <List>
          <ListItem>(a) is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive;</ListItem>
          <ListItem>(b) facilitates fraudulent or illegal activity;</ListItem>
          <ListItem>
            (c) is otherwise illegal or causes damage or injury to any person or property; and TRAXX reserves the right, without liability or prejudice to its other rights to the Client, to disable the Client's access to any material that breaches
            the provisions of this clause.
          </ListItem>
        </List>
      </Typography>
      <br></br>
      <Typography>
        2.4 The Client shall not:
        <List>
          <ListItem>(a) except as may be allowed by any applicable law which is incapable of exclusion by agreement between the parties and except to the extent expressly permitted under this Agreement:</ListItem>
          <ListItem>
            (i)attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Software and/or Documentation (as applicable) in any form or media or by
            any means; or
          </ListItem>
          <ListItem>(ii) attempt to de-compile, reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Software; or</ListItem>
          <ListItem>(b) access all or any part of the Services and Documentation in order to build a product or service which competes with the Services and/or the Documentation; or</ListItem>
          <ListItem>(c) use the Services and/or Documentation to provide services to third parties; or</ListItem>
          <ListItem>
            (d) subject to 18.1, license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Services and/or Documentation available to any third party except the Authorised
            Users, or
          </ListItem>
          <ListItem>(e) attempt to obtain, or assist third parties in obtaining, access to the Services and/or Documentation, other than as provided under this 2; or</ListItem>
          <ListItem>(f) introduce or permit the introduction of, any Virus or Vulnerability into TRAXX's network and information systems; or</ListItem>
          <ListItem>(g) use the Service in furtherance of any fraudulent or illegal purpose.</ListItem>
        </List>
      </Typography>
      <br></br>
      <Typography>2.5 The Client shall use all reasonable endeavours to prevent any unauthorised access to, or use of, the Services and/or the Documentation and, in the event of any such unauthorised access or use, promptly notify TRAXX.</Typography>
      <br></br>
      <Typography>2.6 The rights provided under this 2 are granted to the Client only and shall not be considered granted to any subsidiary or holding company of the Client.</Typography>
      <br></br>
      <Typography variant="h5" className={classes.header}>
        3. Services
      </Typography>
      <br></br>
      <Typography>3.1 TRAXX shall provide the Services and make available the Documentation to the Client on and subject to the terms of this Agreement.</Typography>
      <br></br>
      <Typography>
        3.2 TRAXX shall use commercially reasonable endeavours to make the Services available 24 hours a day, seven days a week, except for:
        <List>
          <ListItem>(a) planned maintenance carried out during the maintenance window of [10.00 pm to 2.00 am Singapore time]; and</ListItem>
          <ListItem>(b) unscheduled maintenance performed outside Normal Business Hours, provided that TRAXX has used reasonable endeavours to give the Client at least [6] Normal Business Hours' notice in advance.</ListItem>
        </List>
      </Typography>
      <br></br>
      <Typography>
        3.3 TRAXX will, as part of the Services, provide the Client with TRAXX's standard customer support services during Normal Business Hours in accordance with TRAXX's Support Services Policy in effect at the time that the Services are provided.
        TRAXX may amend the Support Services Policy in its sole and absolute discretion from time to time. The Client may purchase enhanced support services separately at TRAXX's then current rates.
      </Typography>
      <br></br>
      <Typography variant="h5" className={classes.header}>
        4. <b>Client Data</b>
      </Typography>
      <br></br>
      <Typography>
        4.1 The Client shall own all right, title, and interest in and to all of the Client Data that is not Personally Identifiable Information and shall have sole responsibility for the legality, reliability, integrity, accuracy and quality of all
        such Client Data.
      </Typography>
      <br></br>
      <Typography>4.2 TRAXX shall, in providing the Services, comply with its Privacy Policy, relating to the privacy and security of the Client Data, as such document may be amended from time to time by TRAXX in its sole discretion.</Typography>
      <br></br>
      <Typography>
        4.3 Both parties will comply with all applicable requirements of the Data Protection Legislation. This 4 is in addition to, and does not relieve, remove, or replace, a party's obligations or rights under the Data Protection Legislation.
      </Typography>
      <br></br>
      <Typography>
        4.4 The parties acknowledge that:
        <List>
          <ListItem>
            (a) where TRAXX processes any Personally Identifiable Information on the Client's behalf when performing its obligations under this Agreement, the Client is the controller and TRAXX is the processor for the purposes of the Data Protection
            Legislation.
          </ListItem>
          <ListItem>
            (b) the Personally Identifiable Information may be transferred or stored outside Singapore or the country where the Client and the Authorised Users are located in order to carry out the Services and TRAXX's other obligations under this
            Agreement.
          </ListItem>
          <ListItem>(c) The Personally Identifiable Information may be transferred to and processed by third party service providers.</ListItem>
        </List>
      </Typography>
      <Typography>
        4.5 Without prejudice to the generality of 4.3, the Client will ensure that it has all necessary appropriate consents and notices in place to enable lawful transfer of the Personally Identifiable Information to TRAXX for the duration and
        purposes of this Agreement so that TRAXX may lawfully use, process and transfer the Personally Identifiable Information in accordance with this Agreement on the Client's behalf.
      </Typography>
      <Typography>
        4.6 Without prejudice to the generality of 4.3, TRAXX shall, in relation to Personally Identifiable Information processed in connection with the performance by TRAXX of its obligations under this Agreement:{' '}
        <List>
          <ListItem>
            (a) process that Personally Identifiable Information only on the instructions of the Client while providing the Services. Where TRAXX is obliged under any applicable law or regulation to process or disclose Personally Identifiable
            Information in any other manner other than in the course of performing the Services, TRAXX shall promptly notify the Client of this before performing the processing required by the applicable laws or regulations unless those applicable
            laws or regulations prohibit TRAXX from so notifying the Client;
          </ListItem>
          <ListItem>(b) not transfer any personal data outside of Singapore unless the following conditions are fulfilled:</ListItem>
          <ListItem>(i) the Client or TRAXX has provided appropriate safeguards in relation to the transfer;</ListItem>
          <ListItem>(ii) the data subject has enforceable rights and effective legal remedies;</ListItem>
          <ListItem>(iii) TRAXX complies with its obligations under the Data Protection Legislation by providing an adequate level of protection to any Personally Identifiable Information that is transferred; and</ListItem>
          <ListItem>(iv) TRAXX complies with reasonable instructions notified to it in advance by the Client with respect to the processing of the Personally Identifiable Information;</ListItem>
          <ListItem>
            (c) assist the Client, at the Client's cost, in responding to any request from a data subject and in ensuring compliance with its obligations under the Data Protection Legislation with respect to security, breach notifications, impact
            assessments and consultations with supervisory authorities or regulators;
          </ListItem>
          <ListItem>(d) notify the Client without undue delay on becoming aware of a Personally Identifiable Information breach;</ListItem>
          <ListItem>
            (e) at the written direction of the Client, delete or return Personally Identifiable Information and copies thereof to the Client on termination of the Agreement unless required by Applicable Law to store the Personally Identifiable
            Information (and for these purposes the term "delete" shall mean to put such data beyond use); and
          </ListItem>
          <ListItem>
            (f) maintain complete and accurate records and information to demonstrate its compliance with this 4 and immediately inform the Client if, in the opinion of TRAXX, an instruction infringes the Data Protection Legislation.
          </ListItem>
        </List>
      </Typography>
      <Typography>
        4.7 Each party shall ensure that it has in place appropriate technical and organisational measures, reviewed and approved by the other party, to protect against unauthorised or unlawful processing of Personally Identifiable Information and
        against accidental loss or destruction of, or damage to, Personally Identifiable Information, appropriate to the harm that might result from the unauthorised or unlawful processing or accidental loss, destruction or damage and the nature of
        the data to be protected, having regard to the state of technological development and the cost of implementing any measures (those measures may include, where appropriate, pseudonymising and encrypting Personally Identifiable Information,
        ensuring confidentiality, integrity, availability and resilience of its systems and services, ensuring that availability of and access to Personally Identifiable Information can be restored in a timely manner after an incident, and regularly
        assessing and evaluating the effectiveness of the technical and organisational measures adopted by it).
      </Typography>
      <Typography>
        4.8 The Client consents to TRAXX appointing as third-party processors of Personally Identifiable Information under this Agreement. TRAXX confirms that it has entered or (as the case may be) will enter with the third-party processors into a
        written agreement substantially on that third party's standard terms of business and in either case which TRAXX confirms reflect and will continue to reflect the requirements of the Data Protection Legislation. Client further acknowledges
        that it may be necessary for them to execute a separate agreement with such third-party processors to access the Services, in which case the management of Client Data shall be governed directly under such agreement.
      </Typography>

      <Typography variant="h5" className={classes.header}>
        <b>5. TRAXX'S obligations</b>
      </Typography>
      <Typography>5.1 TRAXX undertakes that the Services will be performed substantially in accordance with the Documentation and with reasonable skill and care.</Typography>
      <Typography>
        5.2 The undertaking at 5.1 shall not apply to the extent of any non-conformance which is caused by use of the Services contrary to TRAXX's instructions, or modification or alteration of the Services by any party other than TRAXX or TRAXX's
        duly authorised contractors or agents. If the Services do not conform with the foregoing undertaking, TRAXX will, at its expense, use all reasonable commercial endeavours to correct any such non-conformance promptly, or provide the Client
        with an alternative means of accomplishing the desired performance. Such correction or substitution constitutes the Client's sole and exclusive remedy for any breach of the undertaking set out in 5.1.{' '}
      </Typography>
      <Typography>
        5.3 TRAXX:
        <List>
          <ListItem>(a) does not warrant that:</ListItem>
          <ListItem>(i) the Client's use of the Services will be uninterrupted or error-free; or</ListItem>
          <ListItem>(ii) that the Services, Documentation and/or the information obtained by the Client through the Services will meet the Client's requirements;</ListItem>
          <ListItem>(iii) the Software or the Services will be free from Vulnerabilities; or</ListItem>
          <ListItem>(iv) the Software, Documentation or Services will comply with any Heightened Cybersecurity Requirements.</ListItem>
          <ListItem>
            (b) is not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of data over communications networks and facilities, including the internet, and the Client acknowledges that the Services
            and Documentation may be subject to limitations, delays and other problems inherent in the use of such communications facilities.
          </ListItem>
        </List>
      </Typography>
      <Typography>5.4 TRAXX warrants that it has and will maintain all necessary licences, consents, and permissions necessary for the performance of its obligations under this Agreement.</Typography>

      <Typography variant="h5" className={classes.header}>
        6. Client's obligations
      </Typography>
      <Typography>
        6.1 The Client shall:{' '}
        <List>
          <ListItem>(a) provide TRAXX with:</ListItem>
          <ListItem>(i) all necessary co-operation in relation to this Agreement; and</ListItem>
          <ListItem>(ii) all necessary authorisations to access such information as may be required by TRAXX;</ListItem>
          <ListItem>in order to provide the Services, including but not limited to Client Data, security access information and configuration services;</ListItem>
          <ListItem>(b) without affecting its other obligations under this Agreement, comply with all applicable laws and regulations with respect to its activities under this Agreement;</ListItem>
          <ListItem>
            (c) carry out all other Client responsibilities set out in this Agreement in a proper and accurate manner. In the event of any delays, inaccuracies or errors in the Client's provision of Client Data as agreed by the parties, TRAXX may not
            be able to perform the Services in an efficient and accurate manner;
          </ListItem>
          <ListItem>(d) ensure that the Authorised Users use the Services and the Documentation in accordance with the terms and conditions of this Agreement and shall be responsible for any Authorised User's breach of this Agreement;</ListItem>
          <ListItem>(e) ensure that its network, software and systems comply with the relevant specifications provided by TRAXX from time to time; and</ListItem>
          <ListItem>
            (f) be, to the extent permitted by law and except as otherwise expressly provided in this Agreement, solely responsible for procuring, maintaining and securing its network connections and telecommunications links from its systems to
            TRAXX's data centres, and all problems, conditions, delays, delivery failures and all other loss or damage arising from or relating to the Client's network connections or telecommunications links or caused by the internet.
          </ListItem>
        </List>
      </Typography>

      <Typography>
        6.2 The Client further represents and warrants that:
        <List>
          <ListItem>
            (a) It will not obtain, retain, use or provide access to the Services to an Authorised Users in a manner that may breach any applicable export control or economic sanctions laws and regulations for any jurisdiction including the United
            States of America and the European Union and its members states
          </ListItem>
          <ListItem>
            (b) Neither it nor any Authorised Users to which the Client provides access to the Services is or is affiliated with a specially designated or sanctioned entity under any of those laws and that, in any transaction relating to TRAXX, it
            will not involve sanctioned parties, including without limitation through the use of bank accounts at banks that are sanctioned parties.
          </ListItem>
        </List>
        Failure to comply with this undertaking shall be deemed a material breach of this Agreement.
      </Typography>

      <Typography variant="h5" className={classes.header}>
        7. Proprietary rights
      </Typography>
      <Typography>
        7.1 The Client acknowledges and agrees that TRAXX and/or its licensors own all intellectual property rights in the Services and the Documentation. Except as expressly stated herein, this Agreement does not grant the Client any rights to,
        under or in, any patents, copyright, database right, trade secrets, trade names, trade marks (whether registered or unregistered), or any other rights or licences in respect of the Services or the Documentation.
      </Typography>
      <Typography>7.2 TRAXX confirms that it has all the rights in relation to the Services and the Documentation that are necessary to grant all the rights it purports to grant under, and in accordance with, the terms of this Agreement.</Typography>
      <Typography variant="h5" className={classes.header}>
        8. Confidentiality [and compliance with policies]
      </Typography>
      <Typography>
        8.1 Each party may be given access to Confidential Information from the other party in order to perform its obligations under this Agreement. A party's Confidential Information shall not be deemed to include information that:{' '}
        <List>
          <ListItem>(a) is or becomes publicly known other than through any act or omission of the receiving party;</ListItem>
          <ListItem>(b) was in the other party's lawful possession before the disclosure;</ListItem>
          <ListItem>(c) is lawfully disclosed to the receiving party by a third party without restriction on disclosure; or</ListItem>
          <ListItem>(d) is independently developed by the receiving party, which independent development can be shown by written evidence.</ListItem>
        </List>
      </Typography>
      <Typography>
        8.2 Subject to 8.4, each party shall hold the other's Confidential Information in confidence and not make the other's Confidential Information available to any third party, or use the other's Confidential Information for any purpose other
        than the implementation of this Agreement.
      </Typography>
      <Typography>
        8.3 Each party shall take all reasonable steps to ensure that the other's Confidential Information to which it has access is not disclosed or distributed by its employees or agents in violation of the terms of this Agreement.
      </Typography>
      <Typography>
        8.4 A party may disclose Confidential Information to the extent such Confidential Information is required to be disclosed by law, by any governmental or other regulatory authority or by a court or other authority of competent jurisdiction,
        provided that, to the extent it is legally permitted to do so, it gives the other party as much notice of such disclosure as possible and, where notice of disclosure is not prohibited and is given in accordance with this 8.4, it takes into
        account the reasonable requests of the other party in relation to the content of such disclosure.
      </Typography>
      <Typography>8.5 TRAXX acknowledges that the Client Data is the Confidential Information of the Client.</Typography>
      <Typography>
        8.6 No party shall make, or permit any person to make, any public announcement concerning this Agreement without the prior written consent of the other parties (such consent not to be unreasonably withheld or delayed), except as required by
        law, any governmental or regulatory authority (including, without limitation, any relevant securities exchange), any court or other authority of competent jurisdiction.
      </Typography>
      <Typography>8.7 The above provisions of this 8 shall survive termination of this Agreement, however arising.</Typography>
      <Typography>
        8.6 No party shall make, or permit any person to make, any public announcement concerning this Agreement without the prior written consent of the other parties (such consent not to be unreasonably withheld or delayed), except as required by
        law, any governmental or regulatory authority (including, without limitation, any relevant securities exchange), any court or other authority of competent jurisdiction.
      </Typography>
      <Typography variant="h5" className={classes.header}>
        9. Indemnity{' '}
      </Typography>
      <Typography>
        9.1 The Client shall defend, indemnify, and hold harmless TRAXX against claims, actions, proceedings, losses, damages, expenses and costs (including without limitation court costs and reasonable legal fees) arising out of or in connection
        with the Client's use of the Services and/or Documentation, provided that:
        <List>
          <ListItem>(a) the Client is given prompt notice of any such claim;</ListItem>
          <ListItem>(b) TRAXX provides reasonable co-operation to the Client in the defence and settlement of such claim, at the Client's expense; and</ListItem>
          <ListItem>(c) the Client is given sole authority to defend or settle the claim.</ListItem>
        </List>
      </Typography>
      <Typography>
        9.2 TRAXX shall defend the Client, its officers, directors and employees against any claim that the Client's use of the Services or Documentation in accordance with this Agreement infringes any patent effective as of the Effective Date,
        copyright, trade mark, database right or right of use, and shall indemnify the Client for any amounts awarded against the Client in judgment or settlement of such claims, provided that:
        <List>
          <ListItem>(a) TRAXX is given prompt notice of any such claim;</ListItem>
          <ListItem>(b) the Client provides reasonable co-operation to TRAXX in the defence and settlement of such claim, at TRAXX's expense; and</ListItem>
          <ListItem>(c) TRAXX is given sole authority to defend or settle the claim.</ListItem>
        </List>
      </Typography>
      <Typography>
        9.3 In the defence or settlement of any claim, TRAXX may procure the right for the Client to continue using the Services, replace or modify the Services so that they become non-infringing or, if such remedies are not reasonably available,
        terminate this Agreement on [2] Business Days' notice to the Client without any additional liability or obligation to pay liquidated damages or other additional costs to the Client.
      </Typography>
      <Typography>
        9.4 In no event shall TRAXX, its employees, agents and sub-contractors be liable to the Client to the extent that the alleged infringement is based on:
        <List>
          <ListItem>(a) a modification of the Services or Documentation by anyone other than TRAXX; or</ListItem>
          <ListItem>(b) the Client's use of the Services or Documentation in a manner contrary to the instructions given to the Client by TRAXX; or</ListItem>
          <ListItem>(c) the Client's use of the Services or Documentation after notice of the alleged or actual infringement from TRAXX or any appropriate authority.</ListItem>
        </List>
      </Typography>
      <Typography>
        9.5 The foregoing and 10.3(b) state the Client's sole and exclusive rights and remedies, and TRAXX's (including TRAXX's employees', agents' and sub-contractors') entire obligations and liability, for infringement of any patent, copyright,
        trade mark, database right or right of use.
      </Typography>
      <Typography variant="h5" className={classes.header}>
        10. Limitation of liability
      </Typography>
      <Typography>
        10.1 Except as expressly and specifically provided in this Agreement:
        <List>
          <ListItem>
            (a) the Client assumes sole responsibility for conclusions drawn from the results obtained from the use of the Services and the Documentation by the Client. TRAXX shall have no liability for any inaccuracies caused by errors or omissions
            in any information, instructions or documents provided to TRAXX by the Client in connection with the Services, or any actions taken by TRAXX at the Client's direction;
          </ListItem>
          <ListItem>(b)all warranties, representations, conditions, and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by applicable law, excluded from this Agreement; and</ListItem>
          <ListItem>(c) the Services and the Documentation are provided to the Client on an "as is" basis.</ListItem>
        </List>
      </Typography>
      <Typography>10.2 Nothing in this Agreement excludes the liability of TRAXX for fraud or fraudulent misrepresentation.</Typography>
      <Typography>
        10.3 Subject to 10.1 and 10.2:{' '}
        <List>
          <ListItem>
            (a) TRAXX shall not be liable whether in tort (including for negligence or breach of statutory duty), contract, misrepresentation, restitution or otherwise for any loss of profits, loss of business, depletion of goodwill and/or similar
            losses or loss or corruption of data or information, or pure economic loss, or for any special, indirect or consequential loss, costs, damages, charges or expenses however arising under this Agreement; and
          </ListItem>
          <ListItem>
            (b) TRAXX's total aggregate liability in contract (including in respect of the indemnity at 9.2), tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise, arising in connection with the
            performance or contemplated performance of this Agreement shall be limited to the total Subscription Fees paid for the User Subscriptions during the 12 months immediately preceding the date on which the claim arose.
          </ListItem>
        </List>
      </Typography>
      <Typography variant="h5" className={classes.header}>
        11. Force majeure
      </Typography>
      <Typography>
        TRAXX shall have no liability to the Client under this Agreement if it is prevented from or delayed in performing its obligations under this Agreement, or from carrying on its business, by acts, events, omissions or accidents beyond its
        reasonable control, including, without limitation, strikes, lock-outs or other industrial disputes (whether involving the workforce of TRAXX or any other party), failure of a utility service or transport or telecommunications network, act of
        God, war, riot, civil commotion, malicious damage, compliance with any law or governmental order, rule, regulation or direction, accident, breakdown of plant or machinery, fire, flood, storm, pandemic, epidemic or default of third party
        service providers, provided that the Client is notified of such an event and its expected duration.
      </Typography>
      <Typography variant="h5" className={classes.header}>
        12. Variation
      </Typography>
      <Typography>No variation of this Agreement shall be effective unless it is in writing and signed by the parties (or their authorised representatives).</Typography>
      <Typography variant="h5" className={classes.header}>
        13. Waiver
      </Typography>
      <Typography>
        No failure or delay by a party to exercise any right or remedy provided under this Agreement or by law shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other
        right or remedy. No single or partial exercise of such right or remedy shall prevent or restrict the further exercise of that or any other right or remedy.
      </Typography>
      <Typography variant="h5" className={classes.header}>
        14. Rights and remedies
      </Typography>
      <Typography>Except as expressly provided in this Agreement, the rights and remedies provided under this Agreement are in addition to, and not exclusive of, any rights or remedies provided by law.</Typography>
      <Typography variant="h5" className={classes.header}>
        15. Severance
      </Typography>
      <Typography>
        15.1 If any provision or part-provision of this Agreement is or becomes invalid, illegal or unenforceable, it shall be deemed deleted, but that shall not affect the validity and enforceability of the rest of this Agreement.
      </Typography>
      <Typography>
        15.2 If any provision or part-provision of this Agreement is deemed deleted under 15.1 the parties shall negotiate in good faith to agree a replacement provision that, to the greatest extent possible, achieves the intended commercial result
        of the original provision.
      </Typography>
      <Typography variant="h5" className={classes.header}>
        16. Entire agreement
      </Typography>
      <Typography>
        16.1 This Agreement constitutes the entire agreement between the parties and supersedes and extinguishes all previous agreements, promises, assurances, warranties, representations, and understandings between them, whether written or oral,
        relating to its subject matter.
      </Typography>
      <Typography>
        16.2 Each party acknowledges that in entering into this Agreement it does not rely on, and shall have no remedies in respect of, any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out
        in this Agreement.
      </Typography>
      <Typography>16.3 Each party agrees that it shall have no claim for innocent or negligent misrepresentation or negligent misstatement based on any statement in this Agreement.</Typography>
      <Typography>16.4 Nothing in this clause shall limit or exclude any liability for fraud.</Typography>
      <Typography variant="h5" className={classes.header}>
        17. Assignment
      </Typography>
      <Typography>17.1 The Client shall not, without the prior written consent of TRAXX, assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under this Agreement.</Typography>
      <Typography>17.2 TRAXX may at any time assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under this Agreement.</Typography>
      <Typography variant="h5" className={classes.header}>
        18. No partnership or agency
      </Typography>
      <Typography>
        Nothing in this Agreement is intended to or shall operate to create a partnership between the parties, or authorise either party to act as agent for the other, and neither party shall have the authority to act in the name or on behalf of or
        otherwise to bind the other in any way (including, but not limited to, the making of any representation or warranty, the assumption of any obligation or liability and the exercise of any right or power).
      </Typography>
      <Typography variant="h5" className={classes.header}>
        19. Third party rights
      </Typography>
      <Typography>
        This Agreement does not confer any rights on any person or party (other than the parties to this Agreement and, where applicable, their successors and permitted assigns) pursuant to the Contracts (Rights of Third Parties) Act, Cap. 53B.
      </Typography>
      <Typography variant="h5" className={classes.header}>
        20. Notices
      </Typography>
      <Typography>
        20.1 Any notice required to be given under this Agreement shall be in writing and shall be delivered by hand or sent by pre-paid post or recorded delivery post to the other party at its address set out in this Agreement, or such other address
        as may have been notified by that party for such purposes, or sent by email to the other party's email address as set out in this Agreement.
      </Typography>
      <Typography>
        20.2 A notice delivered by hand shall be deemed to have been received when delivered (or if delivery is not in business hours, at 9 am on the first business day following delivery). A correctly addressed notice sent by pre-paid post or
        certificate of posting shall be deemed to have been received at the time at which it would have been delivered in the normal course of post. A notice sent by email shall be deemed to have been received at the time of transmission (as shown by
        the timed printout obtained by the sender).
      </Typography>
      <Typography variant="h5" className={classes.header}>
        21. Governing law and Jurisdiction
      </Typography>
      <Typography>
        21.1 This Agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of the
        Republic of Singapore.
      </Typography>
      <Typography>
        21.2 If any dispute in connection with this Agreement is not resolved to the satisfaction of each complaining party within 20 Business Days after the delivery by such complaining party of a written notice to the noncomplaining party in such
        dispute, the complaining party shall first refer the matter to mediation under the Law Society of Singapore Mediation Scheme, and if Parties fail to resolve the dispute in this manner after 90 days, may then refer the dispute to confidential,
        binding English language arbitration to be held in Singapore in accordance with the rules of the Singapore International Arbitration Centre then in effect, before a sole arbitrator who will be appointed in accordance with such rules
      </Typography>
    </Container>
  );
};

export default TraxxIDTerms;
