import { makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import TypoCell from '../TypoCell';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  head: {
    fontWeight: 'bold',
  },
  cell: {
    alignItems: 'flex-end',
    wordWrap: 'break-word',
    float: 'right',
    maxWidth: 260,
    [breakpoints.down('sm')]: {
      maxWidth: 240,
    },
    [breakpoints.down('xs')]: {
      maxWidth: 160,
    },
  },
  subHeader: {
    fontWeight: 'bold',
    // backgroundColor: palette.action.hover,
    borderBottom: '0.5px solid #c2c2c2',
  },
  text: {
    fontWeight: '900',
    color: palette.text.secondary,
    fontSize: 14,
  },
}));

/**
 * Upgraded TableRow for consistent formatting
 *
 * Props:
 ** type: type of row
 ** titleProps: props for title table cell
 ** dataProps: cell props for typo cell
 ** typoProps: typo props for typo cell
 ** value: value for Typo cell
 ** wrapper: wrapper for typo cell
 ** wrapperProps: wrapper props for typo cell
 ** name: text in Table Cell
 */
const DataRow = (props) => {
  const { type, titleProps = {}, dataProps = {}, typoProps = {}, value, wrapper, wrapperProps = {}, name } = props;
  const classes = useStyles();

  let { className: titleClassName, align: titleAlign = 'left', ...otherTitleProps } = titleProps;
  let { className: dataClassName, ...otherDataProps } = dataProps;
  let { className: typoClassName, ...otherTypoProps } = typoProps;

  return (
    <TableRow>
      {type === 'subHeader' && (
        <TableCell disabled className={classes.subHeader} align="left" colSpan={2}>
          <Typography className={classes.text}>{name}</Typography>
        </TableCell>
      )}
      {!type && (
        <>
          <TableCell align={titleAlign} className={clsx(classes.head, titleClassName)} {...otherTitleProps}>
            {name}
          </TableCell>
          <TypoCell wrapper={wrapper} wrapperProps={wrapperProps} cellProps={{ align: 'right', ...otherDataProps }} typoProps={{ className: clsx(typoClassName, classes.cell), ...otherTypoProps }} value={value} />
        </>
      )}
    </TableRow>
  );
};

export default DataRow;

DataRow.propTypes = {
  titleProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  dataProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  typoProps: PropTypes.object,
  wrapperProps: PropTypes.object,
  value: PropTypes.node,
};
