import { get_api_path } from './api';
import { sessioned_client } from './auth';
import { store } from 'app/store';

export async function list_wallets(filter = {}) {
  const url = get_api_path('wallet/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_wallet(wallet_id) {
  const url = get_api_path('wallet/detail', null, { wallet_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function list_wallet_tx(filter = {}, wallet_id) {
  const url = get_api_path('wallet/tx/list', filter, { wallet_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_wallettx_detail(wallet_tx_id, wallet_id) {
  const url = get_api_path('wallet/tx/detail', null, { wallet_tx_id, wallet_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_main_wallet() {
  const url = get_api_path('wallet/main');
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function wallet_statistic(filter = {}, wallet_id) {
  const url = get_api_path('wallet/statistic', filter, { wallet_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function list_credit(wallet_id) {
  const url = get_api_path('wallet/credit/list', null, { wallet_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function credit_detail(wallet_id, credit_id) {
  const url = get_api_path('wallet/credit/detail', null, { wallet_id, credit_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}
