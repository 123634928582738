import { Box, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DashboardGuard, NavBar } from 'app/components';
import { Privilege } from 'app/services';
import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { MainNav } from './components';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 64,
    marginLeft: 287,
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: 56,
      marginLeft: 0,
    },
    '@media print': {
      boxShadow: 'none',
      margin: 0,
    },
  },
  views: {
    flex: 1,
  },
  paddingLogo: {
    padding: theme.spacing(8, 0),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(16, 0),
    },
    '& img': {
      opacity: 0.2,
      width: 200,
      [theme.breakpoints.up('sm')]: {
        width: 128,
      },
      [theme.breakpoints.up('md')]: {
        width: 256,
      },
    },
    '@media print': {
      display: 'none',
    },
  },
  noPrint: {
    '@media print': {
      display: 'none',
      margin: 0,
      padding: 0,
    },
  },
}));

const DashboardLayout = (props) => {
  const classes = useStyles();
  const { route } = props;
  const [dashboardRoutes, setDashboardRoutes] = useState(route.routes);
  const self = useSelector((state) => state.profile.self);
  const workspace = useSelector(({ profile }) => profile.workspace);

  useEffect(() => {
    if (self?.roles) {
      const roles = [].concat(self?.roles || [], workspace?.roles || []);
      if (roles.includes('account.owner')) {
        if (workspace.account.owner_id !== self.person.id) {
          roles.splice(roles.indexOf('account.owner'), 1);
        }
      }
      let privileges = Privilege.validatePrivilege(roles);
      let routes = [];
      const checkPrivileges = Privilege.usePrivilegeChecker(privileges);
      route.routes.forEach((route) => {
        if (route.access) {
          if (!checkPrivileges(route.access, route.roles)) {
          } else {
            routes.push(route);
          }
        } else {
          routes.push(route);
        }
      });
      setDashboardRoutes(routes);
    }
    // eslint-disable-next-line
  }, [self, self?.roles, workspace]);

  return (
    <>
      <NavBar className={classes.noPrint} />
      <MainNav className={classes.noPrint} />
      <DashboardGuard>
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>{renderRoutes(dashboardRoutes)}</Suspense>

          <Box className={classes.paddingLogo}>
            <img src="/images/traxx_logo.svg" alt="TRAXX.ID logo" />
          </Box>
        </main>
      </DashboardGuard>
    </>
  );
};

export default DashboardLayout;
