import { Box, Button, Card, CircularProgress, Dialog, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Alert } from '@material-ui/lab';
import { Listing, StatusLabel, Title, TraxxButton, ViewAllButtonRow } from 'app/components';
import { StatusMaps } from 'app/constants';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import XLSX from 'xlsx';
import { ActionField, CountryCode, FilesDropzone, InfoDialog, QueueButler } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  filesDropzone: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  upload: {
    marginBottom: theme.spacing(1),
  },
  checkbox: { color: theme.palette.grey.primary },
  dot: {
    color: '#00b4cc',
    fontSize: 14,
  },
  head: {
    fontWeight: 'bold',
    fontSize: '.765rem',
  },
  links: {
    fontFamily: ['Roboto Mono', 'monospace'],
    textDecoration: 'underline',
  },
  tableCell: {
    '@media print': {
      wordWrap: 'break-word',
    },
  },
  name: {
    maxWidth: 450,
    [theme.breakpoints.down('md')]: {
      maxWidth: 300,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 240,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 180,
    },
    wordBreak: 'break-word',
  },
  listing: {
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',
  },
  clickable: {
    cursor: 'pointer',
  },
  smallFont: {
    fontSize: '.675rem',
  },
  card: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  record: {
    marginTop: theme.spacing(0.5),
  },
}));

function usePrompt(message, when) {
  const history = useHistory();
  const unblock = useRef(null);

  useEffect(() => {
    if (when) {
      unblock.current = history.block(message);
    } else {
      unblock.current = null;
    }
    return () => {
      if (unblock.current) {
        unblock.current();
      }
    };
  }, [when, history, message]);
}

function useCallbackPrompt(when) {
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      if (!confirmedNavigation) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation]
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
    // eslint-disable-next-line
  }, [confirmedNavigation, lastLocation]);

  usePrompt(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
}

const NavigationPrompt = ({ isBlocking, isSaving, onSave }) => {
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isBlocking);
  const classes = useStyles();

  return (
    <Dialog open={showPrompt} onClose={cancelNavigation}>
      <Box display="inline" className={classes.root}>
        <Alert severity="warning">Navigating away from current page will end current session. Please ensure all changes are saved.</Alert>
        <Box mt={2} display="flex">
          <Button onClick={cancelNavigation}>cancel</Button>
          <Box flexGrow={1} />
          <TraxxButton onSubmit={confirmNavigation}>Confirm</TraxxButton>
        </Box>
      </Box>
    </Dialog>
  );
};

const SHEET_ARR = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

const BatchKYC = (props) => {
  const { children, className, when, staticContext, ...rest } = props;
  const classes = useStyles();
  const [batchFiles, setBatchFiles] = useState([]);
  const [batchData, setBatchData] = useState();
  const [displayData, setDisplayData] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [runScreening] = useAsyncTask('processScreen');
  const [runBatch] = useAsyncTask('processBatch');
  const [currentProcessing, setCurrentProcessing] = useState();
  const [bulkProcessing, setBulkProcessing] = useState(false);
  const [caseGroupID, setCaseGroupID] = useState();
  const [groupUpdated, setGroupUpdated] = useState(false);
  const [selectedScreen, setSelectedScreen] = useState(null);
  const [openCountryCode, setOpenCountryCode] = useState(false);

  const [isBlocking, setIsBlocking] = useState(true);

  useEffect(() => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = 'Refreshing page will end current session. Please ensure all changes are saved.';
    });
    // window.onbeforeunload = function () { return "Refreshing page will end current session. Please ensure all changes are saved." }
    return () => setIsBlocking(false);
  }, []);

  useEffect(() => {
    if (batchData) {
      onLoadFile();
    }
    // eslint-disable-next-line
  }, [batchData]);

  const onLoadFile = (sheet = 0) => {
    const loadedSheet = batchData.Sheets[batchData.SheetNames[sheet]];
    let tempDisplayData = {};

    Object.keys(loadedSheet).forEach((cellNum) => {
      if (cellNum.includes('!')) return;
      let lineNumbers = cellNum.match(/\d+/g).map(Number);
      if (lineNumbers.length > 1) return enqueueSnackbar('error parsing cell ' + cellNum, { variant: 'error' });
      let line = lineNumbers[0];
      let cellAp = cellNum.replace(line, '');
      if (!tempDisplayData[line]) tempDisplayData[line] = {};
      tempDisplayData[line][cellAp] = loadedSheet[cellNum];
      tempDisplayData[line].status = 'unprocessed';
    });
    Object.values(tempDisplayData).forEach((dt) => {
      if (dt.K && (dt.K.v === 'Processed' || dt.K.v === 'processed')) {
        dt.status = 'processed';
      }
    });

    Object.keys(tempDisplayData).forEach((val) => {
      if (tempDisplayData[val].A.v.toLowerCase() === 'case group') {
        if (tempDisplayData[val].B && tempDisplayData[val].B.v) setCaseGroupID(tempDisplayData[val].B.v.split(/[()]/)[1]);
        delete tempDisplayData[val];
      }
    });

    setDisplayData(tempDisplayData);
  };

  const parseCell = (cell, num) => {
    switch (num) {
      case 'E':
      case 'J':
        return moment(cell.v).format('YYYY-MM-DD');
      default:
        return cell.v;
    }
  };

  const bulkProcess = () => {
    setBulkProcessing(true);
    let tempData = displayData;
    let processed_arr = [];
    Object.keys(displayData).forEach((num) => {
      if (num === '1') return;
      let currentData = tempData[num];
      if (currentData.status === 'unprocessed') {
        currentData.status = 'processing';
        processed_arr.push({
          first_name: parseCell(currentData.A, 'A') || '-',
          last_name: parseCell(currentData.B, 'B') || '-',
          middle_name: parseCell(currentData.C, 'C') || '-',
          country_of_birth: parseCell(currentData.D, 'D') || '-',
          date_of_birth: parseCell(currentData.E, 'E') || '-',
          gender: parseCell(currentData.F, 'F') || '',
          nationality: parseCell(currentData.G, 'G') || '-',
          issuing_state: parseCell(currentData.H, 'H') || '-',
          id_number: parseCell(currentData.I, 'I') || '',
          date_of_expiry: parseCell(currentData.J, 'J') || '-',
          case_id: parseCell(currentData.K, 'K') || '-',
        });
      }
    });

    runBatch(async () => {
      const result = await API.Users.batch_process({ params: processed_arr });
      let counts = 0;

      Object.keys(displayData).forEach((num) => {
        if (num === '1') return;
        let currentData = tempData[num];
        if (currentData.status === 'processing') {
          currentData.status = 'processing';
          currentData.data = result[counts++];
        }
      });

      setBulkProcessing(false);
    });
  };

  const individualProcess = async (screenData, num) => {
    let data = {
      first_name: parseCell(screenData.A, 'A'),
      middle_name: parseCell(screenData.B, 'B'),
      last_name: parseCell(screenData.C, 'C'),
      country_of_birth: parseCell(screenData.D, 'D'),
      date_of_birth: parseCell(screenData.E, 'E'),
      gender: parseCell(screenData.F, 'F'),
      nationality: parseCell(screenData.G, 'G'),
      issuing_state: parseCell(screenData.H, 'H'),
      id_number: parseCell(screenData.I, 'I'),
      date_of_expiry: parseCell(screenData.J, 'J'),
      // case_id: parseCell(screenData.K, 'K'),
    };
    setCurrentProcessing(num);
    setDisplayData((prev) => {
      return {
        ...prev,
        [num]: {
          ...prev[num],
          status: 'processing',
        },
      };
    });
    console.log(displayData);
    await runScreening(async () => {
      try {
        const result = await API.Screening.create_passport_screen({ params: data });
        setCurrentProcessing(null);
        setDisplayData((prev) => {
          return {
            ...prev,
            [num]: {
              ...prev[num],
              status: 'processed',
              data: result,
            },
          };
        });
        setGroupUpdated(false);
      } catch (e) {
        let error = e.response?.data?.error;

        setCurrentProcessing(null);
        let error_msg = error?.message || e.message;
        let errors = [];
        if (error?.errors) {
          Object.keys(error.errors).forEach((err) => {
            errors.push(err.replace('params.', ''));
          });
        }
        setDisplayData((prev) => {
          return {
            ...prev,
            [num]: {
              ...prev[num],
              status: 'failed',
              errors,
              error_msg,
            },
          };
        });
      }
    });
  };

  const exportFile = (filename = 'Processed') => {
    const exp_arr = [];
    Object.values(displayData).forEach((row, index) => {
      let rowData = [];
      SHEET_ARR.forEach((cellAl) => {
        rowData.push(row[cellAl]);
      });
      if (!index) rowData.push(row.K);
      else rowData.push(row.status);
      exp_arr.push(rowData);
    });
    if (caseGroupID) {
      exp_arr.push(['Case group', caseGroupID]);
    }
    const worksheet = XLSX.utils.aoa_to_sheet(exp_arr);
    const workbook = {
      SheetNames: ['sheet1'],
      Sheets: {
        sheet1: worksheet,
      },
    };
    return XLSX.writeFile(workbook, `${filename}.csv`);
  };

  const downloadSampleData = () => {
    const header_row = ['First Name', 'Last Name', 'Middle Name', 'Country of Birth', 'Date Of Birth', 'Gender', 'Nationality', 'Issuing State', 'ID Number', 'Date of Expiry', 'Case ID', 'Case Name'];
    const sample_one = ['John', 'Doe', 'M', 'US', '1975/02/07', 'male', 'US', 'US', 'S12312321Z', '2024/12/07', '123', 'Doe Case'];
    const sample_two = ['Jane', 'Doe', 'L', 'US', '1977/03/12', 'female', 'SG', 'SG', 'S12312321Z', '2024/11/21', '124', 'Doe Case'];

    const worksheet = XLSX.utils.aoa_to_sheet([header_row, sample_one, sample_two]);
    const workbook = {
      SheetNames: ['sheet1'],
      Sheets: {
        sheet1: worksheet,
      },
    };
    return XLSX.writeFile(workbook, `sample.csv`);
  };

  const handleClose = () => {
    setSelectedScreen(null);
  };

  const handleOpen = (screen) => {
    setSelectedScreen(screen);
  };

  const handleCountryClose = () => {
    setOpenCountryCode(null);
  };

  const handleCountryOpen = () => {
    setOpenCountryCode(true);
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <QueueButler dataSet={displayData} setData={setDisplayData} />
      <Title prefix="Batch processing" />

      <Alert severity="info">
        Batch processing allows multiple screening.{' '}
        <u className={classes.clickable} onClick={() => downloadSampleData()}>
          Sample data
        </u>{' '}
        | <u onClick={handleCountryOpen}>ISO country code</u>
      </Alert>
      <FilesDropzone
        buttonText="Choose files"
        className={clsx(classes.filesDropzone, classes.upload)}
        fileFormatText="CSV format, below 10MB in size"
        fileNumberLimit={1}
        fileRegex={/(csv|xlsx)$/i}
        files={batchFiles}
        fileSizeLimit={10000000}
        header1="Batch file"
        header2="Drag your file here or"
        instruction="Select the batch file for screening"
        imgAlt="Batch files"
        imgSrc="images/multiple.svg"
        selectedInstruction="Drag more files here or click to browse"
        setFiles={setBatchFiles}
        setBatchData={setBatchData}
      />

      {displayData && <ActionField caseGroupID={caseGroupID} onExport={exportFile} batchData={batchData} groupUpdated={groupUpdated} setGroupUpdated={setGroupUpdated} displayData={displayData} setCaseGroupID={setCaseGroupID} />}

      {displayData && (
        <Grid container spacing={1}>
          <Grid item md={4} xs={12}>
            <Card className={classes.card}>
              <Box mr={4} display="flex">
                <StatusLabel variantMap={StatusMaps.Batch}>processed</StatusLabel>
                <Box flexGrow="1" />
                <Typography className={classes.record} color="secondary">
                  {Object.values(displayData).filter((dat, index) => index && dat.status === 'processed').length}
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item md={4} xs={12}>
            <Card className={classes.card}>
              <Box mr={4} display="flex">
                <StatusLabel variantMap={StatusMaps.Batch}>unprocessed</StatusLabel>
                <Box flexGrow="1" />
                <Typography className={classes.record} color="secondary">
                  {Object.values(displayData).filter((dat, index) => index && dat.status === 'unprocessed').length}
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item md={4} xs={12}>
            <Card className={classes.card}>
              <Box mr={4} display="flex">
                <StatusLabel variantMap={StatusMaps.Batch}>failed</StatusLabel>
                <Box flexGrow="1" />
                <Typography className={classes.record} color="secondary">
                  {Object.values(displayData).filter((dat, index) => index && (dat.status === 'failed' || dat.status === 'error')).length}
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}

      {displayData && <Alert severity="warning">4 credits will be charged per record.</Alert>}

      {displayData && (
        <Listing filter={{ count: Object.keys(displayData).length, limit: 10000000 }} className={classes.listing} showPaginator={false} ExtraAction={() => <ViewAllButtonRow disabled={bulkProcessing} text="BULK PROCESS" onClick={bulkProcess} />}>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <Typography className={classes.head} color="secondary">
                      Row
                    </Typography>
                  </TableCell>
                  {SHEET_ARR.map(
                    (cell) =>
                      !!displayData[1][cell] && (
                        <TableCell key={cell} className={classes.tableCell}>
                          <Typography className={classes.head} color="secondary">
                            {displayData[1][cell].v}
                          </Typography>
                        </TableCell>
                      )
                  )}
                  <TableCell className={classes.tableCell}>
                    <Typography className={classes.head} color="secondary">
                      Status
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography className={classes.head} color="secondary">
                      Errors
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography className={classes.head} color="secondary">
                      Action
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(displayData).map((num) => {
                  if (num === '1') return false;
                  return (
                    <TableRow selected={displayData[num].status === 'processed' && !displayData[num].data}>
                      <TableCell className={classes.smallFont}>
                        <Typography className={classes.smallFont} color="secondary">
                          {num}
                        </Typography>
                      </TableCell>
                      {SHEET_ARR.map(
                        (cell) =>
                          displayData[num][cell] && (
                            <TableCell key={cell} className={classes.smallFont}>
                              <Typography className={classes.smallFont} color="secondary">
                                {parseCell(displayData[num][cell], cell)}
                              </Typography>
                            </TableCell>
                          )
                      )}
                      <TableCell className={classes.smallFont}>
                        <StatusLabel variantMap={StatusMaps.Batch}>{displayData[num].status}</StatusLabel>
                      </TableCell>

                      <TableCell className={classes.smallFont}>
                        <Typography className={clsx(classes.head, classes.smallFont)} color="secondary">
                          {displayData[num].errors?.length ? displayData[num].errors.join(',') : displayData[num].error_msg}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.smallFont}>
                        <Typography className={classes.name} color="secondary">
                          {displayData[num].status === 'unprocessed' && num !== currentProcessing && (
                            <div>
                              <IconButton onClick={() => individualProcess(displayData[num], num)}>
                                <RotateRightIcon color="primary" />
                              </IconButton>
                            </div>
                          )}
                          {displayData[num].status === 'processed' && displayData[num].data && (
                            <IconButton onClick={() => handleOpen(displayData[num].data)}>
                              <VisibilityIcon />
                            </IconButton>
                          )}
                          {displayData[num].status === 'processed' && !displayData[num].data && (
                            <Typography className={classes.smallFont} color="secondary">
                              Past record
                            </Typography>
                          )}
                          {currentProcessing === num && <CircularProgress />}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Listing>
      )}
      {selectedScreen && <InfoDialog open={selectedScreen} screen={selectedScreen} onClose={handleClose} />}
      <NavigationPrompt isBlocking={isBlocking} />
      <CountryCode open={openCountryCode} onClose={handleCountryClose} />
    </Box>
  );
};

export default BatchKYC;
