import { Card, Table, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataRow, MonoText } from 'app/components';
import clsx from 'clsx';
import React from 'react';
import TitleField from '../TitleField';
import { StatusLabel } from 'app/components';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: '100%',
  },
}));

const DocumentResult = (props) => {
  const { result = {}, className, ...rest } = props;
  const classes = useStyles();
  const { type, status, reference } = result;

  return (
    <Card {...rest} className={clsx(classes.card, className)}>
      <TitleField>Verification Info</TitleField>
      <Table>
        <TableBody>
          <DataRow name="Status" value={<StatusLabel variant={status === 'complete' ? 'success' : 'warning'}>{status}</StatusLabel>} />
          <DataRow name="Verification Type" value={<MonoText>{type}</MonoText>} />
          <DataRow name="Verification ID" value={<MonoText>{reference}</MonoText>} />
        </TableBody>
      </Table>
    </Card>
  );
};

export default DocumentResult;
