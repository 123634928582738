import { Box, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useState } from 'react';

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

/**
 * Table component
 *
 * Props:
 ** tabTitle: tab title
 ** tabHeaders: array of tab header
 ** tabContent: array of tab content
 */
function TabComp(props) {
  const { getTabHeader, tabTitle = 'Tabs', tabHeaders = [], tabContent = [] } = props;
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (ev, newTabValue) => {
    setTabValue(newTabValue);
    if (typeof getTabHeader === 'function') {
      getTabHeader(tabHeaders[newTabValue]);
    }
  };

  return (
    <Box component={Paper}>
      <Tabs variant="scrollable" scrollButtons="auto" value={tabValue} onChange={handleTabChange} aria-label={tabTitle}>
        {tabHeaders.map((header, index) => (
          <Tab key={`${header}${index}`} label={header} {...tabProps(index)} />
        ))}
      </Tabs>
      {tabContent.map((content, index) => (
        <TabPanel key={`tab${index}`} value={tabValue} index={index}>
          {content}
        </TabPanel>
      ))}
    </Box>
  );
}

export default TabComp;
