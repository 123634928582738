import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    border: '1px solid #000000q',
  },
  table: {
    borderRadius: 0,
  },
  row: {
    backgroundColor: theme.palette.action.hover,
  },
  head: {
    backgroundColor: '#520000',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    '@media print': {
      backgroundColor: '#520000',
      color: theme.palette.common.white,
      fontWeight: 'bold',
    },
  },
}));

const capitalizeFirstChar = (word) => {
  return word === null ? '-' : typeof word === 'string' ? word.charAt(0).toUpperCase() + word.slice(1) : word;
};

const TablePreview = (props) => {
  const { header = 'Details', data } = props;
  const classes = useStyles();

  const displayValue = (row) => {
    if (row.extra) {
      let Wrapper = row.extra.wrapper;
      return <Wrapper>{row.value}</Wrapper>;
    }
    return capitalizeFirstChar(row.value);
  };

  const text_parser = (text) => {
    switch (text) {
      case 'Id number':
        return 'ID number';
      default:
        return text;
    }
  };

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table} size="small" aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.head} colSpan={2}>
              {header}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow className={!(index % 2) && classes.row} key={index}>
              <TableCell scope="row">{text_parser(row.key)}</TableCell>
              <TableCell align="right">{displayValue(row)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TablePreview;
