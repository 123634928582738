import { Card, Table, TableBody, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataRow, DateDisplay, MonoText } from 'app/components';
import { resultParser } from 'app/utils';
import clsx from 'clsx';
import React from 'react';
import TitleField from '../TitleField';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
  subTitle: {
    fontWeight: 'bold',
  },
  table: {
    padding: 0,
  },
  small: {
    fontSize: 12,
    color: theme.palette.grey.primary,
  },
}));

const DataFieldTable = (props) => {
  const { datafield, className } = props;
  const classes = useStyles();

  const Wrapper = ({ type, children }) => {
    if (type === 'datetime' && children.match(/Date/)) {
      return <DateDisplay date={children} />;
    } else if (type === 'uri') {
      return <img alt="uploaded" src={children} />;
    } else {
      return <>{children}</>;
    }
  };

  return (
    <Card className={clsx(classes.card, className)}>
      <TitleField>Data Fields</TitleField>
      {datafield
        .filter((fie) => fie.Type !== 'uri')
        .map((field) => (
          <Table className={classes.table}>
            <TableBody>
              <DataRow
                name={
                  <>
                    <Typography className={classes.subTitle} color="secondary" variant="body1">
                      {field.Name}
                    </Typography>
                    <Typography color="secondary">{field.Description}</Typography>
                    <MonoText className={classes.small}>Data Source: {resultParser.DataSourceParser(field.DataSource)}</MonoText>
                  </>
                }
                value={<Wrapper type={field.Type}>{field.Value}</Wrapper>}
              />
            </TableBody>
          </Table>
        ))}
    </Card>
  );
};

export default DataFieldTable;