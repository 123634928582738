import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

/**
 * Acuant Branding
 *
 * Props:
 ** className: name of css class element
 */
const AcuantBrand = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <Typography {...rest} className={clsx(classes.root, className)}>
      Powered by Acuant
    </Typography>
  );
};

export default AcuantBrand;
