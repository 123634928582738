import { InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  adornment: {
    cursor: 'pointer',
    marginTop: 2,
  },
}));

/**
 * Input adornment for visibility
 */
function VisibilityAdornment({ onClick }) {
  const classes = useStyles();
  return (
    <InputAdornment className={classes.adornment} onClick={onClick} position="start">
      <img src="/images/visibility.svg" alt="visibility-icon" />
    </InputAdornment>
  );
}

export default VisibilityAdornment;

VisibilityAdornment.propTypes = { onClick: PropTypes.func.isRequired };
