import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SearchSelect, TraxxInput } from 'app/components';
import React, { useState } from 'react';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  input: {
    width: '100%',
    marginBottom: spacing(3),
  },
}));

const OrganisationScreening = (props) => {
  const classes = useStyles();
  const { countries, errors, inputs, setInputs } = props;
  const [orgCountry, onOrgCountry] = useState('');

  const handleChange = (type) => {
    return ({ target }) => setInputs({ ...inputs, [type]: target.value });
  };
  const handleSelect = (type) => {
    return (event, value) => {
      let selected;
      countries.forEach((country) => {
        if (country.name === value) selected = country;
      });
      if (selected) {
        setInputs({
          ...inputs,
          [type]: selected.code,
        });
      } else {
        setInputs({
          ...inputs,
          [type]: '',
        });
      }
    };
  };

  const getFilteredOptions = (inputKey) => {
    const maps = countries
      // eslint-disable-next-line array-callback-return
      .map((country) => {
        if (!inputKey) return country;
        if (country.code === inputKey) return country;
      })
      .filter((count) => !!count);
    return maps;
  };

  const onOrgCountryInput = (event, value) => {
    onOrgCountry(value);
  };

  return (
    <>
      <TraxxInput
        required={true}
        className={classes.input}
        label="Organisation"
        fullWidth
        error={errors.name}
        InputProps={{
          classes: {
            underline: classes.underline,
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleChange('name')}
        placeholder="Organisation Name"
        value={inputs.name}
      />
      <FormControl fullWidth className={classes.input}>
        <SearchSelect label="Registered Country" options={getFilteredOptions(inputs.registered_country)} onSelect={handleSelect('registered_country')} inputValue={orgCountry} onInput={onOrgCountryInput} />
      </FormControl>
    </>
  );
};

export default OrganisationScreening;
