import { Box, Collapse, Drawer, Hidden, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Toolbar, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountBox from '@material-ui/icons/AccountBox';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import CodeIcon from '@material-ui/icons/Code';
import CommentIcon from '@material-ui/icons/Comment';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ScreenIcon from '@material-ui/icons/FindInPage';
import GroupIcon from '@material-ui/icons/GroupWork';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LinkIcon from '@material-ui/icons/Link';
import ListIcon from '@material-ui/icons/List';
import ListAltIcon from '@material-ui/icons/ListAlt';
import InviteIcon from '@material-ui/icons/Mail';
import PeopleIcon from '@material-ui/icons/People';
import CasesIcon from '@material-ui/icons/PermContactCalendar';
import Person from '@material-ui/icons/Person';
import ReceiptIcon from '@material-ui/icons/Receipt';
import VerificationRequestIcon from '@material-ui/icons/Send';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import KYCIcon from '@material-ui/icons/VerifiedUser';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import WorkIcon from '@material-ui/icons/Work';
import { TraxxButton } from 'app/components';
import { ProfileMenu } from 'app/components/NavBar/components';
import { GlobalShowKeys } from 'app/constants';
import { Privilege } from 'app/services';
import { actions } from 'app/store';
import { useRouter } from 'app/utils';
import clsx from 'clsx';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

const navGroups = [
  {
    title: 'Administration',
    access: 'traxx',
    items: [
      {
        icon: <AccountBox />,
        text: 'Accounts',
        nav: '/admin/accounts',
        access: 'account_manager',
      },
      {
        icon: <AccountBalanceIcon />,
        text: 'Finance',
        expand: true,
        access: 'account_manager',
        items: [
          {
            icon: <AccountBalanceWalletIcon />,
            text: 'Wallets',
            nav: '/admin/wallets',
          },
          {
            icon: <ListIcon />,
            text: 'Wallet transactions',
            nav: '/admin/wallet-transactions',
          },
        ],
      },
      {
        icon: <Person />,
        text: 'Persons',
        nav: '/admin/persons',
        access: 'user_manager',
      },
      {
        icon: <CommentIcon />,
        text: 'Products',
        access: 'account_manager',
        expand: true,
        items: [
          {
            icon: <ScreenIcon />,
            text: 'Products',
            nav: '/admin/products',
          },
          {
            icon: <InsertDriveFileIcon />,
            text: 'Create product',
            nav: '/admin/product/create',
          },
        ],
      },
      {
        icon: <WorkIcon />,
        text: 'All cases',
        nav: '/admin/screenings/cases',
        access: 'user_manager',
      },
      {
        icon: <KYCIcon />,
        text: 'All KYC',
        access: 'user_manager',
        expand: true,
        items: [
          {
            icon: <ScreenIcon />,
            text: 'All screenings',
            nav: '/admin/screenings',
          },
          {
            icon: <InsertDriveFileIcon />,
            text: 'All document verifications',
            nav: '/admin/documents',
          },
          {
            icon: <VerificationRequestIcon />,
            text: 'All verification requests',
            nav: '/admin/verifications',
          },
          {
            icon: <LinkIcon />,
            text: 'All cached article retrievals',
            nav: '/admin/cached_article_retrieval',
          },
        ],
      },
      {
        icon: <ReceiptIcon />,
        text: 'All billing',
        expand: true,
        access: 'account_manager',
        items: [
          {
            icon: <ListIcon />,
            text: 'All order history',
            nav: '/admin/order-history',
          },
          {
            icon: <ListAltIcon />,
            text: 'All payments',
            nav: '/admin/payments',
          },
          {
            icon: <ListIcon />,
            text: 'All credit usage',
            nav: '/admin/credit-usage',
          },
        ],
      },
      {
        icon: <CommentIcon />,
        text: 'All event logs',
        access: 'user_manager|account_manager',
        nav: '/admin/logs',
      },
      {
        icon: <SettingsApplicationsIcon />,
        text: 'Manage',
        access: 'user_manager|account_manager',
        nav: '/admin/manage',
      },
    ],
  },
  {
    title: 'Account management',
    access: 'account',
    items: [
      {
        icon: <DashboardIcon />,
        text: 'Dashboard',
        nav: '/dashboard',
        access: 'all',
      },
      {
        icon: <CasesIcon />,
        text: 'Cases',
        nav: '/screenings/cases',
        access: 'owner|compliance_manager|compliance_user',
      },
      {
        icon: <GroupIcon />,
        text: 'Case groups',
        nav: '/groups',
        access: 'owner|compliance_manager|compliance_user',
      },
      {
        icon: <KYCIcon />,
        text: 'KYC by type',
        expand: true,
        access: 'owner|compliance_manager|compliance_user',
        items: [
          {
            icon: <ScreenIcon />,
            text: 'Screenings',
            nav: '/screenings',
          },
          {
            icon: <InsertDriveFileIcon />,
            text: 'Document verifications',
            nav: '/documents',
          },
          {
            icon: <VerificationRequestIcon />,
            text: 'Verification requests',
            nav: '/screenings/verifications',
          },
          {
            icon: <LinkIcon />,
            text: 'Cached Article Retrievals',
            nav: '/cached_article_retrieval',
          },
        ],
      },
      {
        icon: <ReceiptIcon />,
        text: 'Billing',
        expand: true,
        access: 'owner|billing_manager',
        items: [
          {
            icon: <CreditCardIcon />,
            text: 'Credit purchase',
            nav: '/credit-purchase',
          },
          {
            icon: <ListIcon />,
            text: 'Order history',
            nav: '/order-history',
          },
          {
            icon: <ListAltIcon />,
            text: 'Credit usage',
            nav: '/credit-usage',
          },
          {
            icon: <ListIcon />,
            text: 'Credits',
            nav: '/credits',
            // }, {
            //   icon: <ListAltIcon />,
            //   text: "Payments",
            //   nav: "/app/payments",
          },
        ],
      },
      {
        icon: <CommentIcon />,
        text: 'Event logs',
        nav: '/app/logs',
        access: 'owner|compliance_manager|billing_manager',
      },
      {
        icon: <PeopleIcon />,
        text: 'Team',
        expand: true,
        access: 'owner|user_manager',
        items: [
          {
            icon: <PeopleIcon />,
            text: 'Users',
            nav: '/app/users',
          },
          {
            icon: <InviteIcon />,
            text: 'Invite',
            nav: '/app/users/invites',
          },
        ],
      },
      {
        icon: <CodeIcon />,
        text: 'Developers',
        expand: true,
        access: 'owner|developer',
        items: [
          {
            icon: <VpnKeyIcon />,
            text: 'Keys',
            nav: '/app/create-key',
          },
          {
            icon: <CodeIcon />,
            text: 'API reference',
            nav: '/app/developer',
          },
        ],
      },
    ],
  },
  {
    title: 'Audit',
    access: 'external',
    items: [
      {
        icon: <DashboardIcon />,
        text: 'Dashboard',
        nav: '/dashboard',
        access: 'all',
      },
      {
        icon: <KYCIcon />,
        text: 'KYC by type',
        expand: true,
        access: 'auditor',
        items: [
          {
            icon: <ScreenIcon />,
            text: 'Screenings',
            nav: '/screenings',
          },
          {
            icon: <InsertDriveFileIcon />,
            text: 'Document verifications',
            nav: '/documents',
          },
          {
            icon: <VerificationRequestIcon />,
            text: 'Verification requests',
            nav: '/screenings/verifications',
          },
          {
            icon: <LinkIcon />,
            text: 'Cached Article Retrievals',
            nav: '/cached_article_retrieval',
          },
        ],
      },
    ]
    }
];

const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.red.primary,
  },
  createCTAContainer: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  expandIcon: {
    color: theme.palette.grey.primary,
  },
  listItemText: {
    fontWeight: 'bold',
  },
  nestedListItem: {
    paddingLeft: theme.spacing(4),
  },
  root: {
    backgroundColor: '#fafafa',
    zIndex: 100,
    width: 287,
  },
  drawerPaper: {
    width: 287,
    [theme.breakpoints.up('md')]: {
      borderRight: 'none',
    },
  },
  footer: {
    marginTop: 'auto',
    fontSize: 10,
    opacity: 0.7,
    textAlign: 'center',
  },
  accountMenuContainer: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  helloText: {
    marginLeft: theme.spacing(1),
    textTransform: 'none',
  },
  accButton: {
    justifyContent: 'flex-start',
  },
  user: {
    padding: 3,
  },
}));

const createOpenState = (groups) => {
  const state = {};
  groups.forEach(({ items }) => {
    items.forEach(({ expand, text }) => {
      if (expand) {
        state[text] = false;
      }
    });
  });
  return state;
};

const MainNav = ({ className, ...props }) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const desktopView = useMediaQuery('(min-width:960px)');
  const dispatch = useDispatch();
  const router = useRouter();
  const self = useSelector(({ profile }) => profile.self);
  const workspace = useSelector(({ profile }) => profile.workspace);
  const showDrawer = useSelector(({ layout }) => layout.globalShow[GlobalShowKeys.MobileSideNav]);
  // TODO: use privilege?
  // eslint-disable-next-line
  const [privilege, setPrivilege] = useState(null);
  const loading = useSelector((state) => state.layout.loadingTasks.loadProfile);
  const [showDisplay, setShowDisplay] = useState(() => (x) => {
    return false;
  });
  const [open, setOpen] = useState(createOpenState(navGroups));
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  useEffect(() => {
    // TODO: why no use roles but self.roles
    const roles = [].concat(self?.roles || [], workspace?.roles || []);
    if (roles.includes('account.owner')) {
      if (workspace.account.owner_id !== self.person.id) {
        roles.splice(roles.indexOf('account.owner'), 1);
      }
    }
    const privilegeObject = Privilege.validatePrivilege(roles);
    setPrivilege(privilegeObject);
    setShowDisplay(() => Privilege.usePrivilegeChecker(privilegeObject));
    // eslint-disable-next-line
  }, [self?.roles, workspace]);

  const onCloseDrawer = () => {
    dispatch(actions.Layout.updateGlobalShow(GlobalShowKeys.MobileSideNav, false));
  };

  const onClick = (nav, text, expand) => {
    if (expand) {
      setOpen((values) => ({
        ...values,
        [text]: !values[text],
      }));
    }

    if (nav) {
      onCloseDrawer();
      router.history.push(nav);
    }
  };

  const validateWorkspace = (access) => {
    if (access === 'account') return workspace && workspace.id;
    return true;
  };

  return (
    <Drawer {...props} variant={desktopView ? 'permanent' : 'temporary'} onClose={onCloseDrawer} open={showDrawer} className={clsx(classes.root, className)} PaperProps={{ className: classes.drawerPaper, elevation: 1 }}>
      <Hidden smDown>
        <Toolbar />
      </Hidden>

      <Box className={classes.createCTAContainer}>
        <TraxxButton form fullWidth onSubmit={() => router.history.push('/kyc/new')} startIcon={<AddIcon />} variant="outlined">
          Start new KYC
        </TraxxButton>
      </Box>

      <Hidden mdUp>
        <Box className={classes.accountMenuContainer}>
          <TraxxButton loading={loading} startIcon={<AccountCircleIcon />} onSubmit={() => setShowProfileMenu(!showProfileMenu)} form textalign="left" fullWidth className={classes.accButton} variant="outlined">
            <strong>Hello, {self?.person?.firstname}</strong>
          </TraxxButton>
        </Box>
      </Hidden>
      <ProfileMenu onClose={() => setShowProfileMenu(false)} open={showProfileMenu} />

      <List component="nav">
        {navGroups.map(
          (group, index) =>
            validateWorkspace(group.access) &&
            showDisplay(group.access) && (
              <Box key={index}>
                <ListSubheader disableSticky>{group.title}</ListSubheader>

                {group.items.map(
                  ({ icon, text, expand = false, nav, items, access }) =>
                    showDisplay(group.access, access) && (
                      <Fragment key={text}>
                        <ListItem
                          onClick={() => {
                            onClick(nav, text, expand);
                          }}
                          button
                          className={clsx({ [classes.active]: nav === match.url })}>
                          <ListItemIcon
                            className={clsx(classes.listItemIcon, {
                              [classes.active]: nav === match.url,
                            })}>
                            {icon}
                          </ListItemIcon>

                          <ListItemText primary={text} primaryTypographyProps={{ className: classes.listItemText }} />

                          {expand && !open[text] && <ExpandMoreIcon className={classes.expandIcon} />}
                          {expand && open[text] && <ExpandLessIcon className={classes.expandIcon} />}
                        </ListItem>

                        {items && (
                          <Collapse in={open[text]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              {items.map((item) => (
                                <ListItem
                                  key={item.nav}
                                  button
                                  className={clsx(classes.nestedListItem, {
                                    [classes.active]: item.nav === match.url,
                                  })}
                                  onClick={() => onClick(item.nav)}>
                                  <ListItemIcon
                                    className={clsx(classes.listItemIcon, {
                                      [classes.active]: item.nav === match.url,
                                    })}>
                                    {item.icon}
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={item.text}
                                    primaryTypographyProps={{
                                      className: classes.listItemText,
                                      variant: 'body2',
                                    }}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </Collapse>
                        )}
                      </Fragment>
                    )
                )}
              </Box>
            )
        )}
      </List>

      <Box className={classes.footer}>
        <p>
          V {process.env.REACT_APP_APP_VERSION}
          <br />
          TRAXX IT Services Pte. Ltd.
        </p>
      </Box>
    </Drawer>
  );
};

export default MainNav;
