import { Box, Grid, LinearProgress, Paper, TableContainer, Table, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { DataRow, DateDisplay, Title, MonoText, Crumbs } from 'app/components';
import { API } from 'app/services';
import { useSelector } from 'react-redux';
import { Link as BrowserLink } from 'react-router-dom';
import { useAsyncTask, useRouter, useMoneyFormatter, snakeToTitle } from 'app/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
}));
let mounted = false;
const TransactionDetail = (props) => {
  const { children, className, routeType, ...rest } = props;
  const classes = useStyles();

  const loading = useSelector((state) => state.layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const [runLoadTransaction] = useAsyncTask('loadTransaction');
  const [walletTx, setWalletTx] = useState();
  const formatter = useMoneyFormatter({ currency: 'TXC', decPlaces: 0 });

  const router = useRouter();
  const { match } = router;
  const { params } = match;

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) loadTransaction();
    // eslint-disable-next-line
  }, [access]);

  const loadTransaction = () => {
    runLoadTransaction(async () => {
      const loader = routeType === 'admin' ? API.Admin.get_wallettx_detail : API.Wallet.get_wallettx_detail;
      const { model } = await loader(params.transaction_id, params.wallet_id);
      if (mounted) setWalletTx(model);
    });
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Crumbs current="Detail" links={[{ text: 'Transactions', href: routeType === 'admin' ? '/admin/wallet-transactions' : '/credit-usage' }]} />
      <Title prefix="Transaction Detail" />
      <Grid container spacing={2}>
        <Grid item md={6} xs={12} sm={12}>
          <TableContainer component={Paper}>
            {loading.loadTransaction && <LinearProgress size={30} />}
            {!loading.loadTransaction && walletTx && (
              <Table>
                <DataRow name="Reference" value={walletTx.reference} />
                <DataRow name="Type" value={snakeToTitle(walletTx.type, true)} />
                <DataRow name="Amount" value={formatter(walletTx.amount)} />
                <DataRow name="Post balance" value={formatter(walletTx.post_balance)} />
                <DataRow name="Timestamp" value={<DateDisplay date={walletTx.timestamp} />} />
                {walletTx.wallet && routeType === 'admin' && (
                  <DataRow
                    name="Wallet"
                    value={
                      <Link underline="always" component={BrowserLink} to={`/admin/wallets/${walletTx.wallet.id}/detail`}>
                        <MonoText>{walletTx.wallet?.reference}</MonoText>
                      </Link>
                    }
                  />
                )}
              </Table>
            )}
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransactionDetail;
