import React, { useEffect, useState } from 'react';
import { Dialog, Container, DialogTitle, DialogContent, Typography, Grid, Checkbox } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TraxxButton } from 'app/components';
import { useAsyncTask } from 'app/utils';
import { API } from 'app/services';
import { useSelector } from 'react-redux';
import { useToastCatcher } from 'app/utils';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  dialogBox: {
    padding: '20px',
    [theme.breakpoints.down('500')]: {
      padding: '5px',
    },
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  div: {
    padding: '0px',
    margin: '30px 0',
  },
  bottom: {
    margin: '0 0 10px',
  },
  balanceRight: {
    textAlign: 'right',
  },
  button: {
    marginTop: '10px',
  },
  expiry: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const renewalCharge = 4;

let mounted = false;
const RenewDialog = ({ open, onClose }) => {
  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const classes = useStyles();
  const access = useSelector((state) => state.profile.access);
  const wallet = useSelector((state) => state.profile.wallet);
  const [runLoadScreenings] = useAsyncTask('getScreeningsForRenewal');
  const [toaster, errorCatcher] = useToastCatcher({ errorParser: null, taskname: 'renewOngoingScreening' });
  const { enqueueSnackbar } = useSnackbar();

  const [screenings, setScreenings] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [balance, setBalance] = useState(wallet.balance ? parseInt(wallet.balance.toString().slice(0, -2)) : 0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (access && access.token) getScreenings();
    // eslint-disable-next-line
  }, [access]);

  const getScreenings = () => {
    runLoadScreenings(async () => {
      // const loader = routeType === 'account' ? API.Screening.get_renewal_screen : API.Admin.get_renewal_screen;
      const loader = API.Screening.get_renewal_screen;
      const data = await loader();
      if (mounted) {
        setScreenings(data);
        if (balance >= renewalCharge * data.length) {
          setIsCheck(data.map((item) => item.screen_id.toString()));
          setBalance(balance - renewalCharge * data.length);
          setIsCheckAll(true);
        }
      }
    });
  };

  const handleCheckAll = () => {
    if (!isCheckAll && balance < renewalCharge * screenings.length) {
      displayError();
      return;
    }
    setIsCheckAll(!isCheckAll);
    if (isCheckAll) {
      setIsCheck([]); //remove all
      setBalance(balance + renewalCharge * screenings.length);
    } else {
      setIsCheck(screenings.map((item) => item.screen_id.toString())); //add all
      setBalance(balance - renewalCharge * screenings.length);
    }
  };

  const displayError = () => {
    enqueueSnackbar('insufficient balance:TXC', {
      variant: 'error',
      autoHideDuration: 3000,
    });
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id)); //remove
      setBalance(balance + renewalCharge);
      setIsCheckAll(false);
    } else {
      if (balance < renewalCharge) {
        displayError();
        return;
      }
      setIsCheck([...isCheck, id]); //add
      setBalance(balance - renewalCharge);
      if (isCheck.length === screenings.length - 1) setIsCheckAll(true);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    errorCatcher(async () => {
      await API.Screening.renew_ongoing_screening(isCheck);
      setLoading(false);
      toaster('Ongoing screening renewal is successful!', { variant: 'success' });
      if (isCheckAll) onClose();
      else getScreenings();
    }, 'renewOngoingScreening');
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Container className={classes.dialogBox}>
        <DialogTitle>Ongoing Screening Renewal</DialogTitle>
        {screenings.length === 0 ? (
          <DialogContent>
            <Typography>You have no ongoing screening due for renewal at the moment.</Typography>
          </DialogContent>
        ) : (
          <DialogContent>
            <Typography>
              Ongoing screenings for the screenings below are due for renewal. Check the boxes to renew the ongoing screenings. Note that <strong>4</strong> credits will be deducted from your account's wallet for each renewal.
            </Typography>
            <div className={classes.div}>
              <Grid container>
                <Grid item xs={2}>
                  <Checkbox onChange={handleCheckAll} checked={isCheckAll} />
                </Grid>
                <Grid item xs={7} className={classes.tableHeader}>
                  <Typography>
                    <strong>Name</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.expiry}>
                  <Typography>
                    <strong>Expiry Date</strong>
                  </Typography>
                </Grid>
              </Grid>
              {screenings.map((screening) => {
                const { screen_id, entity, reference, expiry_date } = screening;
                return (
                  <Grid container>
                    <Grid item xs={2}>
                      <Checkbox id={screen_id} onChange={handleClick} checked={isCheck.includes(screen_id.toString())} />
                    </Grid>
                    <Grid item xs={7} className={classes.tableHeader}>
                      <Typography>
                        {entity} ({reference})
                      </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.expiry}>
                      <Typography>{expiry_date}</Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </div>
            <Grid className={classes.bottom} container>
              <Grid item xs={6}>
                <Typography>
                  Total: <br />
                  <strong>{isCheck.length * renewalCharge}</strong> credits ({isCheck.length} selected)
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.balanceRight}>
                  Available Balance: <br />
                  <strong>{balance}</strong> credits
                </Typography>
              </Grid>
            </Grid>

            <TraxxButton onSubmit={onSubmit} className={classes.button} fullWidth disabled={isCheck.length === 0} loading={loading}>
              Renew Screenings
            </TraxxButton>
          </DialogContent>
        )}
      </Container>
    </Dialog>
  );
};

export default RenewDialog;
