import { Box, Checkbox, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FormControl, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CaseGrouper, ListingComp, ListingCompTable, SearchSelect, Title, TraxxButton } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask, useRouter } from 'app/utils';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: 10,
    width: '100%',
  },
  filters: { marginTop: 8 },
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  tableTabs: { marginTop: 17 },
  expansionBorder: {},
}));

let mounted = false;

const headers = ['Name', 'Case ID', 'Created date', 'No. of screenings', 'Status'];

let newHeaders = headers;

const admin_headers = ['Name', 'Case ID', 'Account ID', 'Person ID', 'Created date', 'No. of screenings', 'Status'];

const filterOptions = {
  sort: false,
  search: true,
  dateRange: true,
  type: false,
  status: true,
  clear: true,
};

const extraOptions = {
  status: {
    all: { value: '', name: 'All' },
    active: { value: 'active', name: 'Active' },
    new: { value: 'new', name: 'New' },
  },
};

const CaseList = ({ routeType = 'account' }) => {
  const classes = useStyles();
  // TODO
  // eslint-disable-next-line
  const [errorCatcher] = useAsyncTask('loadCases');
  const [runLoadGroupDetail] = useAsyncTask('loadGroup');
  const [runLoadGroups] = useAsyncTask('loadGroups');
  const [runSubmitGroup] = useAsyncTask('submitGroup');
  const [caseModel, setCaseModel] = useState();
  const [cases, setCases] = useState([]);
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [showGrouper, setShowGrouper] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState();
  const [groupOpt, setGroupOpt] = useState([]);
  const [groupInput, setGroupInput] = useState('');
  const [newAddition, setNewAddition] = useState([]);
  const [additionCaseDetail, setAdditionCaseDetail] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const caseRef = new URLSearchParams(router.location.search).get('caseRef');
  const defaultExpanded = !!caseRef;

  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const loadCases = (newFilter = filter) => {
    setLoading(true);
    errorCatcher(async () => {
      const loader = routeType === 'account' ? API.Screening.list_case : API.Admin.list_case;
      const { models, meta } = await loader(newFilter);
      if (mounted) {
        setCaseModel(models);
        sortListingData(models);
        setFilter({ ...newFilter, count: meta.count });
        setLoading(false);
        setLoaded(true);
        if (!selectedGroup && caseRef) loadGroups(caseRef, models);
      }
    });
  };

  const loadGroups = (caseRef, casMod) => {
    runLoadGroups(async () => {
      const { models } = await API.Group.list_group({ limit: 1000000 });
      if (mounted) {
        const opts = models.map((model) => ({ ...model, name: `${model.label} (${model.reference})`, value: model.id }));
        if (caseRef) {
          opts.forEach((opt) => {
            if (opt.reference === caseRef) {
              loadGroupDetail(opt.id, opt.name, casMod);
              setGroupInput(opt.name);
            }
          });
        }
        setGroupOpt(opts);
      }
    });
  };

  const onSelectGroup = (ev, value) => {
    groupOpt.forEach((grp) => {
      if (grp.name === value) {
        loadGroupDetail(grp.id, value);
      }
    });
  };

  const loadGroupDetail = (group_id, value, casMod = caseModel) => {
    runLoadGroupDetail(async () => {
      const { model } = await API.Group.get_group(group_id);
      if (mounted) {
        model.name = value;
        model.value = model.id;
        setSelectedGroup(model);
        setNewAddition(model.cases.map((mod) => mod.id));
        newHeaders = [''].concat(headers);
        setAdditionCaseDetail(Object.assign([], model.cases));
        sortListingData(
          casMod,
          model.cases.map((mod) => mod.id),
          model.cases.map((mod) => mod.id),
          Object.assign([], model.cases)
        );
      }
    });
  };

  const getDetailPath = (id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/screenings/cases/${id}/detail`;
      default:
        return `/screenings/cases/${id}/detail`;
    }
  };

  const toggleCase = (id, groupCases, newGroupCase, models, caseObj) => {
    return () => {
      if (newGroupCase.includes(id)) {
        newGroupCase.splice(newGroupCase.indexOf(id), 1);
        models.forEach((mod) => {
          if (mod.id === id) {
            caseObj.forEach((cas) => {
              if (cas.id === id) {
                caseObj.splice(caseObj.indexOf(cas), 1);
              }
            });
          }
        });
      } else {
        newGroupCase.push(id);
        models.forEach((mod) => {
          if (mod.id === id) {
            caseObj.push(mod);
          }
        });
      }

      setAdditionCaseDetail(caseObj);
      setNewAddition(newGroupCase);
      sortListingData(models, groupCases, newGroupCase, caseObj);
    };
  };

  const sortListingData = (models, groupCases = selectedGroup?.cases.map((mod) => mod.id), newGroupCase = newAddition, caseObj = additionCaseDetail) => {
    let allData = [];
    if (routeType === 'admin') {
      models.forEach((model) => {
        allData.push([
          { label: model.label, tooltip: true },
          { label: model.reference, type: 'link', link: getDetailPath(model.id) },
          { label: model.account?.reference, type: 'link', link: `/admin/accounts/${model.account?.id}/detail` },
          { label: model.person?.reference, type: 'link', link: `/admin/persons/${model.person?.id}/detail` },
          { date: model.created_at, type: 'date' },
          { label: model.screens.length || '0', monoText: true },
          { label: model.status, type: 'status', statusType: 'Case' },
        ]);
      });
    } else {
      models.forEach((model) => {
        let tempArr = [
          { label: model.label, tooltip: true },
          { label: model.reference, type: 'link', link: getDetailPath(model.id) },
          { date: model.created_at, type: 'date' },
          { label: model.screens.length || '0', monoText: true },
          { label: model.status, type: 'status', statusType: 'Case' },
        ];
        if (groupCases) {
          tempArr = [
            {
              value: <Checkbox checked={newGroupCase?.includes(model.id)} onClick={toggleCase(model.id, groupCases, newGroupCase, models, caseObj)} />,
              type: 'custom',
              selected: newGroupCase?.indexOf(model.id) > -1,
            },
          ].concat(tempArr);
        }

        allData.push(tempArr);
      });
    }
    setCases(allData);
  };
  const handleClose = () => {
    setShowGrouper(false);
  };

  const onRemoveCase = (caseRemove) => {
    let newCases = newAddition;
    newCases.splice(newCases.indexOf(caseRemove.id), 1);
    additionCaseDetail.forEach((cas) => {
      if (cas.id === caseRemove.id) {
        additionCaseDetail.splice(additionCaseDetail.indexOf(cas), 1);
      }
    });
    setNewAddition(newCases);
    setAdditionCaseDetail(additionCaseDetail);
    sortListingData(
      caseModel,
      selectedGroup.cases.map((mod) => mod.id),
      newCases,
      additionCaseDetail
    );
  };

  const onSubmitResult = () => {
    runSubmitGroup(async () => {
      await API.Group.create_case_group(selectedGroup.id, { cases: newAddition, replace: true });
      enqueueSnackbar('Group updated', { variant: 'success' });
      handleClose();
    });
  };

  return (
    <Box className={classes.root}>
      <Title prefix="Cases" />
      <ListingComp
        filter={filter}
        setFilter={setFilter}
        filterOptions={filterOptions}
        extraOptions={extraOptions}
        loaded={loaded}
        searchByText={'Name or Case ID'}
        reloadFunc={loadCases}
        dateKey="date"
        loading={loading || loadTask['loadCases']}
        setLoading={setLoading}>
        {routeType === 'account' && (
          <ExpansionPanel
            className={classes.expansionBorder}
            defaultExpanded={defaultExpanded}
            expanded={selectedGroup}
            onChange={(ev, ct) => {
              if (ct && !selectedGroup) loadGroups();
            }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">Update groupings</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container width="100%" display="flex" spacing={2}>
                <Grid item md={8} sm={12} xs={12}>
                  <FormControl fullWidth style={{ margin: 0, padding: 0 }}>
                    <SearchSelect label="Select groups to edit" options={groupOpt} value={selectedGroup?.name} onSelect={onSelectGroup} inputValue={groupInput} onInput={(ev, value) => setGroupInput(value)} />
                  </FormControl>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Box m={1} display="flex">
                    <Box flexGrow={1} />
                    {selectedGroup && <TraxxButton onSubmit={() => setShowGrouper(true)}>Update</TraxxButton>}
                  </Box>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
        <ListingCompTable headers={routeType === 'admin' ? admin_headers : newHeaders} data={cases} />
        <CaseGrouper
          loading={loadTask.submitGroup}
          onSubmit={onSubmitResult}
          selectedGroup={selectedGroup}
          onRemoveCase={onRemoveCase}
          originalCases={selectedGroup?.cases}
          newCases={newAddition}
          caseDetail={additionCaseDetail}
          open={showGrouper}
          handleClose={handleClose}
        />
      </ListingComp>
    </Box>
  );
};

export default CaseList;
