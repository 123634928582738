import { Card, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FolderIcon from '@material-ui/icons/Folder';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InitialsAvatar from 'app/components/InitialsAvatar';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  avatar: { marginRight: 10 },
  caption: { fontSize: 10 },
  container: {
    height: 116,
    padding: 20,
    width: 360,
  },
  icon: { fontSize: 18 },
  name: { fontWeight: 800 },
  results: { marginRight: 20 },
  root: {},
}));

const ProjectCard = (props) => {
  const classes = useStyles();

  const name = 'NGNRS Software Solutions';
  const date = '2020/05/20';
  const timeDifference = '1 hours ago';
  const results = 20;
  const cases = 7;

  return (
    <Card>
      <Grid className={classes.container} alignItems="space-between" container>
        <Grid container alignItems="center" justify="space-between">
          <Grid container alignItems="center" item xs>
            <InitialsAvatar className={classes.avatar} index={0}>
              {name}
            </InitialsAvatar>

            <div>
              <Typography className={classes.name} color="secondary">
                {name}
              </Typography>
              <Typography className={classes.caption} color="secondary">
                <strong>Created on</strong>&nbsp;{date}&nbsp;&nbsp;
                <strong>Edited</strong>&nbsp;{timeDifference}
              </Typography>
            </div>
          </Grid>

          <MoreVertIcon />
        </Grid>

        <Grid container alignContent="center">
          <AssignmentTurnedInIcon className={classes.icon} />
          &nbsp;
          <Typography className={classes.results} color="secondary" variant="caption">
            {results} results
          </Typography>
          <FolderIcon className={classes.icon} />
          &nbsp;
          <Typography color="secondary" variant="caption">
            {cases} cases
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProjectCard;
