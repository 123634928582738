import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: ['Open Sans', 'monospace'],
    borderRadius: 4,
    fontSize: 12,
    fontWeight: 'bold',
    padding: theme.spacing(0.5, 2),
    textTransform: 'uppercase',
  },
  success: {
    backgroundColor: '#C9EFE1',
    color: '#2CC990',
  },
  error: {
    backgroundColor: '#f8d0d0',
    color: '#F24E4E',
  },
  warning: {
    backgroundColor: '#fff2cb',
    color: '#F2C94E',
  },
  disabled: {
    backgroundColor: '#c2c2c2',
  },
  review: {
    backgroundColor: "#f09a75",
    color: "#de5114",
  },
}));

/**
 * Status label
 *
 * Props:
 ** className: name of css class element
 */
const StatusLabel = (props) => {
  const { children, className, variantMap, tooltip, ...rest } = props;
  const classes = useStyles();

  let status = typeof children === 'string' ? children : props.status;
  let variant = props.variant;
  if (!variant && typeof variantMap === 'object' && typeof status === 'string') variant = variantMap[status];

  if (!status) return null;

  return (
    (tooltip) ? 
    (<Tooltip title={tooltip}>
      <span {...rest} className={clsx(classes.root, variant && classes[variant], className)}>
          {status}
      </span>
    </Tooltip>) 
      : 
    (<span {...rest} className={clsx(classes.root, variant && classes[variant], className)}>
      {status}
    </span>)
    
  );
};

StatusLabel.propTypes = {
  variantMap: PropTypes.object.isRequired,
};

export default StatusLabel;
