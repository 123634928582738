import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  img: {
    width: '100%',
  },
}));

const ImageHolder = (props) => {
  const { title, imgSrc } = props;
  const classes = useStyles();

  return <img alt={title} className={classes.img} src={imgSrc} />;
};

export default ImageHolder;
