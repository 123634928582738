import { Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
// import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { ListingComp, ListingCompTable, StatusLabel } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

const headers = ['Name', 'Account ID', 'Status', 'Add account'];

let mounted = false;
function AddAccount(props) {
  const { onCreate, userAcc, entityId } = props;
  const classes = useStyles();

  // TODO
  // eslint-disable-next-line
  const [errorCatcher] = useAsyncTask('loadEntity');
  const [runAddEntity] = useAsyncTask('addEntity');
  // const [runRemoveEntity] = useAsyncTask("removeEntity");
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [listing, setListing] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const getAccounts = (newFilter = filter) => {
    errorCatcher(async () => {
      const { models, meta } = await API.Admin.list_accounts(newFilter);
      if (mounted) {
        setFilter({ ...newFilter, count: meta.count });
        sortListingData(models);
        setLoading(false);
        setLoaded(true);
      }
    });
  };

  const addToAccount = (account_id) => {
    runAddEntity(async () => {
      await API.Admin.add_person_to_account({ account_id, entity_id: entityId });
      if (mounted) {
        enqueueSnackbar('Account added', { variant: 'success' });
        onCreate();
      }
    });
  };

  // const removeFromAccount = () => {
  //   runRemoveEntity(async () => {
  //     await API.Admin.remove_person_from_account(entityId);
  //   })
  // }

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const getCustomContent = (model) => {
    if (userAcc.includes(model.id)) {
      return (
        <>
          <StatusLabel variant="success">Added</StatusLabel>
          {/* <IconButton onClick={() => { removeFromAccount(model.id) }}>
            <RemoveCircleIcon color="error" />
          </IconButton> */}
        </>
      );
    } else {
      return (
        <>
          <IconButton
            onClick={() => {
              addToAccount(model.id);
            }}>
            <AddCircleIcon color="primary" />
          </IconButton>
        </>
      );
    }
  };

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      allData.push([{ label: model.name }, { label: model.reference, type: 'link', link: `/admin/accounts/${model.id}/detail` }, { label: model.status, statusType: 'Account', type: 'status' }, { type: 'custom', value: getCustomContent(model) }]);
    });

    setListing(allData);
  };

  return (
    <Box className={classes.root}>
      <ListingComp filter={filter} setFilter={setFilter} setLoading={setLoading} loading={loading} showFilter={false} reloadFunc={getAccounts} loaded={loaded}>
        <ListingCompTable headers={headers} data={listing} />
      </ListingComp>
    </Box>
  );
}

export default AddAccount;
