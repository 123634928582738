import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  pointer: { cursor: 'pointer' },
  error: {
    height: 24,
    marginTop: 4,
  },
  root: {
    width: '100%',
  },
}));

/**
 * Unique text field for TRAXX.ID
 *
 * Props:
 ** label: label
 ** placeholder: place holder
 ** className: name of css class element
 */
function TraxxInput({ id, error, label, onChange, placeholder, value, type = 'text', className, required, autoComplete, ...props }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <TextField id={id} error={!!error} fullWidth label={label} onChange={onChange} placeholder={placeholder} value={value} type={type} required={required} helperText={error} autoComplete={autoComplete} {...props} />
    </div>
  );
}

export default TraxxInput;

TraxxInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  id: PropTypes.string,
  label: PropTypes.string,
};

TraxxInput.defaultProps = {
  error: undefined,
  id: '',
  label: '',
};
