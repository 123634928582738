import { Box, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from 'app/components';
import clsx from 'clsx';
import React from 'react';
import { ReactComponent as BatchSVG } from './assets/batch.svg';
import { ReactComponent as SearchSVG } from './assets/search.svg';
import { ReactComponent as VerificationSVG } from './assets/verification.svg';
import { ReactComponent as WholeFlowSVG } from './assets/wholeflow.svg';
import { KYCOption, MainKYCOption } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
    width: '100%',
  },
  container: {
    maxWidth: 800,
    margin: '0 auto',
  },
  alertBox: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    textAlign: 'center',
    maxWidth: 950,
  },
}));

const NewKYC = (props) => {
  const { children, className, ...rest } = props;
  let dowjonesDisabled = false,
    berbixDisabled = false;
  // if(moment().isAfter("2021-09-25 08:00") && moment().isBefore("2021-09-27 08:00")) {
  //   refinitivDisabled = true;
  // }
  const classes = useStyles();

  return (
    <Box {...rest} display="block" className={clsx(classes.root, className)}>
      <Title prefix="New KYC" />
      <Box className={classes.alertBox}>
        {/* <Alert severity="warning" >
          <b>
            Refinitiv will be undergoing maintenance between 00:00 GMT on 25-Sep-2021 and 23:59 GMT on 26-Sep-2021.<br/>
            During this time, Screening service will not be available.
          </b>
        </Alert> */}
      </Box>
      <Grid className={classes.container} container spacing={4}>
        <Grid item xs={12} md={12}>
          <Hidden mdUp>
            <KYCOption
              header="Full package"
              description="Document verification followed by person screening."
              subheader="Powered by ComplyAdvantage and Compliance & Sumsub"
              startButtonText="Full package"
              ArtComponent={WholeFlowSVG}
              href="/full-screening"
              disabled={dowjonesDisabled || berbixDisabled}
            />
          </Hidden>
          <Hidden smDown>
            <MainKYCOption
              header="Full package"
              description="Document verification followed by person screening."
              subheader="Powered by ComplyAdvantage & Sumsub"
              startButtonText="Full package"
              ArtComponent={WholeFlowSVG}
              href="/full-screening"
              disabled={dowjonesDisabled || berbixDisabled}
            />
          </Hidden>
        </Grid>
        <Grid item xs={12} md={6}>
          <KYCOption
            header="Screening"
            description="Carry out a background check on a person with their name and personal particulars."
            subheader="Powered by ComplyAdvantage"
            startButtonText="New screening"
            ArtComponent={SearchSVG}
            href="/screenings/create"
            disabled={dowjonesDisabled}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <KYCOption
            header="Document verification"
            description="Verify identity document with face match capability and liveness detection."
            subheader="Powered by Sumsub"
            startButtonText="New verification"
            ArtComponent={VerificationSVG}
            href="/document-verify"
            disabled={berbixDisabled}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Hidden mdUp>
            <KYCOption
              header="Batch processing"
              description="Document verification/person screening for multiple records."
              subheader="Powered by ComplyAdvantage"
              startButtonText="Batch processing"
              ArtComponent={BatchSVG}
              href="/batch-processing"
              disabled={dowjonesDisabled || berbixDisabled}
            />
          </Hidden>
          <Hidden smDown>
            <MainKYCOption
              header="Batch processing"
              description="Document simple screening for multiple records."
              subheader="Powered by ComplyAdvantage"
              startButtonText="Batch processing"
              ArtComponent={BatchSVG}
              href="/batch-processing"
              disabled={dowjonesDisabled || berbixDisabled}
            />
          </Hidden>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewKYC;
