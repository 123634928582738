import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ListingComp, ListingCompTable } from 'app/components';
import { API } from 'app/services';
import { useToastCatcher } from 'app/utils';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

const headers = ['Name', 'Account ID', 'Created date', 'Status'];

let mounted = false;
function AccountListComp(props) {
  const { entityId, onLoad } = props;
  const classes = useStyles();

  // TODO
  // eslint-disable-next-line
  const [toaster, errorCatcher] = useToastCatcher({ taskname: 'loadEntity' });
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [listing, setListing] = useState([]);

  const getPersonAccounts = (newFilter = filter) => {
    errorCatcher(async () => {
      const { models, meta } = await API.Admin.list_user_accounts(entityId, newFilter);
      if (mounted) {
        setFilter({ ...newFilter, count: meta.count });
        if (typeof onLoad === 'function') onLoad(models);
        sortListingData(models);
        setLoading(false);
        setLoaded(true);
      }
    });
  };

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      allData.push([
        { label: model.name || model.account?.name },
        { label: model.reference, type: 'link', link: `/admin/accounts/${model.account_id}/detail` },
        { date: model.created_at, type: 'date' },
        { label: model.account?.status, statusType: 'Account', type: 'status' },
      ]);
    });

    setListing(allData);
  };

  return (
    <Box className={classes.root}>
      <ListingComp filter={filter} setFilter={setFilter} setLoading={setLoading} loading={loading} showFilter={false} reloadFunc={getPersonAccounts} loaded={loaded}>
        <ListingCompTable headers={headers} data={listing} />
      </ListingComp>
    </Box>
  );
}

export default AccountListComp;
