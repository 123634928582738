import { Link, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { DateDisplay, MonoText, StatusLabel } from 'app/components';
import { StatusMaps } from 'app/constants';
import { useMoneyFormatter } from 'app/utils';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import DoubleScrollbar from 'react-double-scrollbar';
import { Link as BrowserLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  body: { fontSize: 14 },
  card: { marginTop: 20 },
  checkbox: { color: theme.palette.grey.primary },
  dot: {
    color: '#00b4cc',
    fontSize: 14,
  },
  head: {
    fontSize: 13,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  links: {
    fontFamily: ['Roboto Mono', 'monospace'],
    textDecoration: 'underline',
  },
  tableCell: {
    '@media print': {
      wordWrap: 'break-word',
    },
  },
  reducedFont: {
    fontSize: 10,
  },
  name: {
    maxWidth: 450,
    [theme.breakpoints.down('md')]: {
      maxWidth: 300,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 240,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 180,
    },
    // wordBreak: "break-word",
  },
  nameRow: {
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'row',
  },
  icon: {
    marginLeft: theme.spacing(2),
    padding: 4,
    opacity: 0.8,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tooltext: {
    padding: 0,
    margin: 0,
  },
  sortable: {
    '&:hover': {
      cursor: 'pointer',
      // opacity: 0.5,
      backgroundColor: '#CCCCCC',
    },
  },
  sortIcon: {
    bottom: 0,
  },
  description: {
    wordBreak: 'break-word',
  },
  overflow: {
    'overflow-x': 'auto',
  },
}));

/**
 * Table data listing
 *
 * Props:
 ** className: name of css class element
 ** header: column names of represented data
 ** data: data to be shown
 */
const ListingCompTable = ({ handleSort, sortDetail, className, headers, data = [] }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const renderCell = (content, index) => {
    switch (content.type) {
      case 'link':
        return (
          <TableCell key={index} className={classes.tableCell}>
            <Typography className={classes.links} variant="body2">
              <Link target={content.newPage} component={BrowserLink} to={content.link}>
                {content.label}
              </Link>
            </Typography>
          </TableCell>
        );
      case 'status':
        return (
          <TableCell key={index} className={classes.tableCell}>
            <StatusLabel variantMap={StatusMaps[content.statusType]}>{content.label}</StatusLabel>
          </TableCell>
        );
      case 'date':
        return (
          <TableCell key={index} className={classes.tableCell}>
            <DateDisplay dateFormat={content.dateFormat || 'DD-MMM-YYYY HH:mm'} date={content.date} />
          </TableCell>
        );
      case 'currency':
        const CurrencyWrapper = ({ currency }) => {
          const formatter = useMoneyFormatter({ currency: currency ? currency : 'TXC', decPlaces: 2, showCurrency: true });
          const noDecimal = useMoneyFormatter({ currency: currency ? currency : 'TXC', decPlaces: 0, showCurrency: true });

          return (
            <TableCell key={index} className={classes.tableCell}>
              <MonoText>{content.amount ? (content.decimal === 0 ? noDecimal(content.amount) : formatter(content.amount)) : '-'}</MonoText>
            </TableCell>
          );
        };

        return <CurrencyWrapper key={index} currency={content.currency} />;

      case 'mono':
        return (
          <TableCell key={index} className={classes.tableCell}>
            <MonoText>{content.label}</MonoText>
          </TableCell>
        );
      case 'labelwithlink':
        return (
          <TableCell key={index} className={classes.tableCell}>
            <Typography className={classes.name} variant="body2">
              {content.matchedTerm}
            </Typography>
            <Typography className={classes.name} variant="body2">
              {content.label}
            </Typography>
            <Typography className={classes.links} variant="body2">
              <Link target={content.newPage} component={BrowserLink} to={content.link}>
                {content.bottomLabel}
              </Link>
            </Typography>
          </TableCell>
        );
      case 'description':
        return (
          <TableCell key={index} className={classes.tableCell}>
            <Typography className={clsx(classes.name, classes.description)}>{content.label}</Typography>
          </TableCell>
        );
      case 'custom':
        return (
          <TableCell key={index} className={classes.tableCell}>
            {content.value}
          </TableCell>
        );
      case 'check':
        return (
          <TableCell key={index} className={classes.tableCell}>
            <Checkbox checked={content.label} disabled />
          </TableCell>
        );
      default:
        const Wrapper = ({ children, ...rest }) => {
          if (content.tooltip) {
            return (
              <Tooltip title={content.label} className={classes.tooltext} {...rest}>
                <p>{children}</p>
              </Tooltip>
            );
          } else return <>{children}</>;
        };
        const Textwraper = ({ children, ...rest }) => {
          if (content.monoText) {
            return (
              <MonoText className={classes.name} {...rest}>
                {children}
              </MonoText>
            );
          } else {
            return (
              <div className={classes.name} {...rest}>
                {children}
              </div>
            );
          }
        };

        return (
          <TableCell key={index} className={classes.tableCell}>
            <Wrapper className={classes.nameRow} key={index}>
              <Textwraper className={classes.name} variant="body2">
                {content.label}
              </Textwraper>
              {content.label && content.copy && <FileCopyIcon className={classes.icon} fontSize="small" onClick={copyText(content.label)} />}
            </Wrapper>
          </TableCell>
        );
    }
  };

  const copyText = (text) => {
    return () => {
      const el = document.createElement('textarea');
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      enqueueSnackbar('copied to clipboard');
    };
  };

  const sort = (header) => {
    if (typeof handleSort === 'function') {
      if (sortDetail.header && sortDetail.header === header) {
        handleSort({
          header,
          order: sortDetail.order && sortDetail.order === 'asc' ? 'desc' : 'asc',
        });
      } else {
        handleSort({
          header,
          order: 'asc',
        });
      }
    }
  };

  const showSort = (header) => {
    if (sortDetail && sortDetail.header === header) {
      return sortDetail.order === 'asc' ? <KeyboardArrowUpIcon className={classes.sortIcon} /> : <KeyboardArrowDownIcon className={classes.sortIcon} />;
    }
  };

  if (data.length < 1) return <></>;
  return (
    <Paper className={clsx(classes.overflow, className)}>
      <DoubleScrollbar>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell onClick={() => sort(header)} key={index} className={clsx(classes.tableCell, handleSort && classes.sortable)}>
                  <Typography className={classes.head} color="secondary">
                    {header}
                    {showSort(header)}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow selected={row[0].selected} key={index}>
                {row.map(renderCell)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DoubleScrollbar>
    </Paper>
  );
};

export default ListingCompTable;

ListingCompTable.propTypes = {
  // data: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     id: PropTypes.number.isRequired,
  //     status: PropTypes.string,
  //   })
  // ).isRequired,
  className: PropTypes.string,
  full: PropTypes.bool,
};

ListingCompTable.defaultProps = {
  className: '',
  full: false,
};
