import { Box, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ListingComp, ListingCompTable, StatusLabel } from 'app/components';
import { API } from 'app/services';
import { snakeToTitle, useAsyncTask } from 'app/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

const headers = ['Person', 'Person ID', 'Status', 'Created date', 'Roles', 'Remove'];

let mounted = false;
function AccountEntityListComp(props) {
  const { onLoadPersons, account_id, account_owner_id } = props;
  const classes = useStyles();
  const [runLoadPersons] = useAsyncTask('loadPersons');
  const [runRemovePerson] = useAsyncTask('removePerson');
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const loadTasks = useSelector(({ layout }) => layout.loadingTasks);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [listing, setListing] = useState([]);

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const getAccountEntities = (newFilter = filter) => {
    runLoadPersons(async () => {
      const { models, meta } = await API.Admin.list_account_users(account_id, newFilter);
      if (mounted) {
        sortListingData(models);
        onLoadPersons(models);
        setFilter({ ...newFilter, count: meta.count });
        setLoaded(true);
      }
    });
  };

  const removeFromAccount = (account_entity_id) => {
    return () => {
      runRemovePerson(async () => {
        await API.Admin.remove_person_from_account(account_entity_id);
        if (mounted) getAccountEntities();
      });
    };
  };

  const getRoles = (model) => {
    const { entity, roles } = model;
    if (entity.owner_id === account_owner_id) {
      return <Typography>Owner</Typography>;
    } else {
      return (
        <>
          {roles.map((role, index) => (
            <>
              <Typography>
                {snakeToTitle(role.replace('account.', ''))}
                {index < roles.length - 1 && ', '}
              </Typography>
            </>
          ))}
        </>
      );
    }
  };

  const getCustomContent = (model) => {
    const { entity } = model;
    if (entity.owner_id === account_owner_id) {
      return <StatusLabel variant="error">N.A.</StatusLabel>;
    } else
      return (
        <IconButton onClick={removeFromAccount(model.id)}>
          <RemoveCircleIcon color="error" />
        </IconButton>
      );
  };

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      allData.push([
        { label: model.entity[model.entity?.owner_type]?.firstname || model.entity[model.entity?.owner_type].label || '' },
        { label: model.entity[model.entity?.owner_type]?.reference, type: 'link', link: `/admin/persons/${model.entity[model.entity?.owner_type].id}/detail` },
        { label: model.status, type: 'status', statusType: 'Wallet' },
        { date: model.created_at, type: 'date' },
        { type: 'custom', value: getRoles(model) },
        {
          type: 'custom',
          value: getCustomContent(model),
        },
      ]);
    });

    setListing(allData);
  };

  return (
    <Box className={classes.root}>
      <ListingComp filter={filter} setFilter={setFilter} setLoading={setLoading} loading={loading || loadTasks.loadPersons} showFilter={false} reloadFunc={getAccountEntities} loaded={loaded}>
        {!!listing.length && <ListingCompTable headers={headers} data={listing} />}
      </ListingComp>
    </Box>
  );
}

export default AccountEntityListComp;
