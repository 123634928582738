import { Card, Table, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataRow } from 'app/components';
import clsx from 'clsx';
import React from 'react';
import TitleField from '../TitleField';
import { resultParser } from 'app/utils';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
  table: {
    padding: 0,
  },
}));

const ClassificationTable = (props) => {
  const { classification, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Card className={clsx(classes.card, className)} {...rest}>
      <TitleField>Document Classification</TitleField>
      <Table className={classes.table}>
        <TableBody>
          <DataRow name="Name" value={classification.Type.Name} />
          <DataRow name="Issuer" value={classification.Type.IssuerName} />
          <DataRow name="Mode" value={resultParser.ClassificationModeParser(classification.Mode)} />
        </TableBody>
      </Table>
    </Card>
  );
};

export default ClassificationTable;
