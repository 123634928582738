import { TYPES } from './action';

const { UPDATE_SESSION, INIT, UPDATE_ACCESS, UPDATE_LAST_ROUTE } = TYPES;

const initialState = {
  self: null,
  access: null,
  loading: false,
  workspace: null,
  init: false,
  lastRoute: null,
  wallet: null,
  expired: false,
};

export default (state = initialState, action) => {
  const { self, accounts, access, workspace, lastRoute, wallet, init, expired } = action;
  switch (action.type) {
    case INIT:
      return {
        ...state,
        init,
      };
    case UPDATE_SESSION:
      return {
        ...state,
        self,
        accounts,
        access,
        workspace,
        lastRoute,
        wallet,
        expired,
        init: true,
      };
    case UPDATE_ACCESS:
      return {
        ...state,
        access,
        workspace,
      };
    case UPDATE_LAST_ROUTE:
      return {
        ...state,
        lastRoute,
      };
    default:
      return state;
  }
};
