import { Collapse, Grid, List, ListItem, ListItemIcon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useRouter } from 'app/utils';

const useStyles = makeStyles(({ palette }) => ({
  dot: { fontSize: 14 },
  green: { color: palette.status.green },
  icon: {
    fontSize: 14,
    minWidth: 0,
  },
  list: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#EEEEEE',
    },
  },
  listItem: {
    paddingLeft: 56,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  red: { color: palette.status.red },
  textContainer: {
    paddingLeft: 10,
    paddingRight: 56,
  },
  text: { fontSize: 11 },
  yellow: { color: palette.status.yellow },
}));

const Breakdown = ({ active, archived, open, newCase, groupId }) => {
  const classes = useStyles();
  const router = useRouter();

  const mapping = {
    Active: 'green',
    New: 'yellow',
    Archived: 'grey',
  };

  const listItems = [
    { status: 'Active', results: active },
    { status: 'New', results: newCase },
    { status: 'Archived', results: archived },
  ];

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List className={classes.list} component="div" disablePadding onClick={() => router.history.push(`/group/${groupId}/detail`)}>
        {listItems.map(({ status, results }) => (
          <ListItem key={status} className={classes.listItem}>
            <ListItemIcon className={classes.icon}>
              <FiberManualRecordIcon className={clsx(classes.dot, classes[mapping[status]])} />
            </ListItemIcon>

            <Grid className={classes.textContainer} container justify="space-between">
              <Typography className={classes.text}>{status}</Typography>
              <Typography className={classes.text}>{results} results</Typography>
            </Grid>
          </ListItem>
        ))}
      </List>
    </Collapse>
  );
};

export default Breakdown;

Breakdown.propTypes = {
  open: PropTypes.bool,
  completed: PropTypes.number,
  failed: PropTypes.number,
  pending: PropTypes.number,
};

Breakdown.defaultProps = {
  open: false,
  completed: 0,
  failed: 0,
  pending: 0,
};
