import { CardContent, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyle = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '48px 40px 22px',
    width: '100%',
  },
  message: {
    fontWeight: 800,
  },
  image: {
    width: 300,
  },
}));

/**
 * Empty state to show when no records found
 *
 * Props:
 ** active: boolean switch
 ** emoji: present emoji (off by default)
 ** message: description
 */
const EmptyState = (props) => {
  const { active, emoji, message, ...rest } = props;
  const classes = useStyle();
  if (!active) return null;
  return (
    <CardContent className={classes.root} {...rest}>
      {/* <Typography align="center" variant="h2" className={classes.not_found_emoji}>{emoji}</Typography> */}
      <img className={classes.image} src={'/images/empty_state.svg'} alt="no result" />
      <Typography align="center" className={classes.message}>
        {message}
      </Typography>
    </CardContent>
  );
};

EmptyState.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.any,
};

EmptyState.defaultProps = {
  emoji: '¯\\_(ツ)_/¯',
  message: 'No records found in your query',
};

export default EmptyState;
