import moment from 'moment-timezone';

export default (timestamp, dateFormat) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return dateFormat === 'DD-MMM-YYYY'
    ? moment(timestamp).format(dateFormat || 'DD-MMM-YYYY')
    : moment(timestamp)
        .subtract(8, 'hours')
        .utc(true)
        .tz(timezone)
        .format(dateFormat || 'DD-MMM-YYYY HH:mm:ss');
};
