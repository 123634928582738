import { LinearProgress } from '@material-ui/core';
import { Privilege } from 'app/services';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

let mounted = false;

/**
 * Privilege guard to ensure role is authorised
 *
 * Props:
 ** children: data to be wrapped be guard
 */
const PrivilegeGuard = (props) => {
  const { userRoles = [], children, excludeSuperAdmin } = props;
  const self = useSelector(({ profile }) => profile.self);
  const workspace = useSelector(({ profile }) => profile.workspace);
  const [loading, setLoading] = useState(true);
  const [privilege, setPrivilege] = useState();

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  });

  useEffect(() => {
    if (!mounted) return;
    const privilegeObject = Privilege.validatePrivilege([].concat(self?.roles || [], workspace?.roles || []));
    const checker = Privilege.usePrivilegeChecker(privilegeObject, { excludeSuperAdmin });
    
    let havePrivilege = false;
    userRoles.forEach((role) => {
      if (havePrivilege) return;
      havePrivilege = checker(role);
    });

    setPrivilege(havePrivilege);
    setLoading(false);
    // eslint-disable-next-line
  }, [self, workspace]);

  return (
    <>
      {loading && <LinearProgress />}
      {!loading && privilege && <>{children}</>}
    </>
  );
};

export default PrivilegeGuard;
