import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ListingComp, ListingCompTable } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    marginTop: theme.spacing(1),
  },
}));

const headers = ['Cached Article Retrieval ID', 'Screen Match ID', 'No. of Links', 'Created date', 'Status'];

const filterOptions = {
  sort: true,
  search: true,
  dateRange: true,
  type: false,
  status: true,
  clear: true,
};

const extraOptions = {
  status: {
    all: { value: '', name: 'All' },
    completed: { value: 'completed', name: 'Completed' },
    pending: { value: 'pending', name: 'Pending' },
    expired: { value: 'expired', name: 'Expired' },
  },
};

let mounted = false;
const CachedArticleRetrievalListComp = (props) => {
  const { status, showPaginator = true, showFilter = true, limit = 10, ExtraAction, children, className, routeType = 'account', ...rest } = props;
  const classes = useStyles();
  const [errorCatcher] = useAsyncTask('loadCachedArticleRetrieval');
  const [cachedArticleRetrievals, setCachedArticleRetrievals] = useState([]);
  const [filter, setFilter] = useState({ limit, offset: 0 });
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const loadCachedArticleRetrievalList = (newFilter = filter) => {
    setLoading(true);
    if (!newFilter.status && status) newFilter['status'] = status;
    errorCatcher(async () => {
      const loader = routeType === 'account' ? API.Screening.list_cached_article_retrieval : API.Admin.list_cached_article_retrieval;
      const { models, meta } = await loader(newFilter);
      if (mounted) {
        sortCachedArticleRetrievalData(models);
        setFilter({ ...newFilter, count: meta.count });
        setLoading(false);
        setLoaded(true);
      }
    });
  };

  const getCachedArticleRetrievalDetailPath = (id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/cached_article_retrieval/${id}/detail`;
      default:
        return `/cached_article_retrieval/${id}/detail`;
    }
  };

  const getScreenResultDetailPath = (screening_id, match_id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/screenings/${screening_id}/match/${match_id}/detail`;
      default:
        return `/screenings/${screening_id}/match/${match_id}/detail`;
    }
  };

  const sortCachedArticleRetrievalData = (models) => {
    let allData = [];
    models.forEach((model) => {
      allData.push([
        { label: model.reference, type: 'link', link: getCachedArticleRetrievalDetailPath(model.id) },
        {
          label: model.screen_profile.screen_result.reference,
          type: 'labelwithlink',
          link: getScreenResultDetailPath(model.screen_profile.screen_result.screen.id, model.screen_profile.screen_result.id),
          bottomLabel: model.screen_profile.screen_result.name,
        },
        { label: JSON.parse(model.links).length, type: 'description' },
        { date: model.created_at, type: 'date' },
        { label: model.status, statusType: 'CachedArticleRetrieval', type: 'status' },
      ]);
    });
    setCachedArticleRetrievals(allData);
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <ListingComp
        filter={filter}
        setFilter={setFilter}
        showFilter={showFilter}
        ExtraAction={ExtraAction}
        loaded={loaded}
        showPaginator={showPaginator}
        loading={loading || loadTask['loadScreening']}
        filterOptions={filterOptions}
        extraOptions={extraOptions}
        reloadFunc={loadCachedArticleRetrievalList}
        setLoading={setLoading}
        searchByText={'Cached Article Retrieval ID'}
        dateKey="date">
        {cachedArticleRetrievals.length > 0 && <ListingCompTable headers={headers} data={cachedArticleRetrievals} />}
      </ListingComp>
    </Box>
  );
};

export default CachedArticleRetrievalListComp;
