import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KYCOption } from 'app/views/KYC/NewKYC/components';
import clsx from 'clsx';
import React from 'react';
import { ReactComponent as SuccessSVG } from './assets/success.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
    alignContent: 'center',
  },

  card: {
    boxShadow: 'none',
  },
}));

const CompleteScreen = (props) => {
  const { caseId, children, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <KYCOption header="Completed" description="You have completed the full screening process" startButtonText="Go to case" ArtComponent={SuccessSVG} href={`/screenings/cases/${caseId}/detail`} cardStyle={classes.card} />
    </Box>
  );
};

export default CompleteScreen;
