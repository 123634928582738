import { Box, Card, CardContent, Container, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton } from 'app/components';
import { API } from 'app/services';
import { actions } from 'app/store';
import { useAsyncTask, useRouter } from 'app/utils';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
}));

const JoinAccount = (props) => {
  const { children, className, staticContext, ...rest } = props;
  const classes = useStyles();
  const router = useRouter();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const inviteToken = queryParams.get('invite');
  const [invitation, setInvitation] = useState();
  const [runLoadInvite, loadingInvite, errorInvite] = useAsyncTask('loadInvite');
  const [runJoinAccount, loadingJoin, errorJoin] = useAsyncTask('joinAccount');
  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    if (!inviteToken) return;
    runLoadInvite(async () => {
      const result = await API.Auth.load_invite(inviteToken);
      setInvitation(result.model);
    });

    // eslint-disable-next-line
  }, [inviteToken]);

  const onSubmit = () => {
    runJoinAccount(async () => {
      const result = await API.Self.joinAccount(inviteToken);
      const { token, expires_at, expires_in, workspace, wallet } = result;
      dispatch(
        actions.Profile.updateSession({
          access: { expires_at, expires_in, token },
          workspace,
          wallet: wallet,
          self: profile.self,
          accounts: [...profile.accounts, workspace.account],
        })
      );
      enqueueSnackbar(`Active account is now ${workspace.account.reference}`);
      router.history.push(`/app`);
    });
  };

  return (
    <Container maxWidth="sm" {...rest} className={clsx(classes.root, className)}>
      <Card>
        <CardContent>
          <Typography variant="h5">Account invitation</Typography>
          <LinearProgress hidden={!loadingInvite} />

          {!!invitation && (
            <Typography variant="body1">
              You are invited to join {invitation.account || 'an account'} by {invitation.inviter || 'someone'}.
            </Typography>
          )}

          {!!(errorInvite || errorJoin) && (
            <Typography variant="body1" color="error">
              {(errorInvite || errorJoin).message}
            </Typography>
          )}

          <Box padding={2} />

          <TraxxButton fullWidth disabled={!invitation} loading={loadingJoin} onSubmit={onSubmit}>
            Join {invitation?.account || 'account'}
          </TraxxButton>
        </CardContent>
      </Card>
    </Container>
  );
};

export default JoinAccount;
