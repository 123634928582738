import { Card, Table, TableBody, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataRow, DateDisplay } from 'app/components';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import TitleField from '../TitleField';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
  subTitle: {
    fontWeight: 'bold',
  },
  table: {
    padding: 0,
  },
  small: {
    fontSize: 12,
    color: theme.palette.grey.primary,
  },
}));

const SumsubFieldTable = (props) => {
  const { datafield, className } = props;

  console.log('datafield: ', datafield);

  const [data, setData] = useState();

  const classes = useStyles();

  const Wrapper = ({ type, children }) => {
    if (type === 'datetime' && children.match(/Date/)) {
      return <DateDisplay date={children} />;
    } else if (type === 'uri') {
      return <img alt="uploaded" src={children} />;
    } else {
      return <>{children}</>;
    }
  };

  useEffect(() => {
    if (datafield) {
      const fields = [
        { title: 'First Name', value: datafield.firstName || '' },
        { title: 'First Name (EN)', value: datafield.firstNameEn || '' },
        { title: 'Last Name', value: datafield.lastName || '' },
        { title: 'Last Name (EN)', value: datafield.lastNameEn || '' },
        { title: 'Middle Name', value: datafield.middleName || '' },
        { title: 'Middle Name (EN)', value: datafield.middleNameEn || '' },
        { title: 'Legal Name', value: datafield.legalName || '' },
        { title: 'Gender', value: datafield.gender || '' },
        { title: 'Date of Birth', value: datafield.dob || '' },
        { title: 'Place of Birth', value: datafield.placeOfBirth || '' },
        { title: 'Country', value: datafield.country || '' },
        { title: 'Nationality', value: datafield.nationality || '' },
        { title: 'Address', type: 'array', value: datafield.addresses || '' },
        // { title: 'idDocs', type: 'array', value: datafield.idDocs || '' },
        { title: 'Tax Identification Number', value: datafield.tin || '' },
      ];
      setData(fields);
    }
  }, [datafield]);

  return (
    <Card className={clsx(classes.card, className)}>
      <TitleField>Data Fields</TitleField>
      {
        <Table className={classes.table}>
          <TableBody>
            {data &&
              data.map(
                (field, id) =>
                  field.value && (
                    <DataRow
                      key={id}
                      name={
                        <>
                          <Typography className={classes.subTitle} color="secondary" variant="body1">
                            {field.title}
                          </Typography>

                          {field.type === 'array' ? (
                            field.value.map((item, index) => {
                              return (
                                <>
                                  <br />
                                  <Typography>{`${field.title} ${index + 1}`}</Typography>
                                  {Object.keys(item).map((key) => (
                                    <Typography color="secondary" key={index}>
                                      {item[key]}
                                    </Typography>
                                  ))}
                                </>
                              );
                            })
                          ) : (
                            <Typography color="secondary">{field.value}</Typography>
                          )}
                        </>
                      }
                    />
                  )
              )}
          </TableBody>
        </Table>
      }
    </Card>
  );
};

export default SumsubFieldTable;
