import { TableCell } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Table cell
 *
 * Props:
 ** cellProps: props for table cell
 ** typoProps: typo props for typo cell
 ** wrapperProps: wrapper props for typo cell
 ** value: value for Typo cell
 ** wrapper: wrapper for typo cell
 */
const TypoCell = (props) => {
  const { cellProps = {}, typoProps = {}, wrapperProps = {}, value, wrapper = ({ children }) => <>{children}</> } = props;
  const Wrapper = wrapper;

  return (
    <TableCell {...cellProps}>
      <Wrapper {...wrapperProps}>
        <div variant="body2" {...{ ...typoProps, color: typoProps.color }}>
          {value}
        </div>
      </Wrapper>
    </TableCell>
  );
};

export default TypoCell;

TypoCell.propTypes = {
  cellProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  typoProps: PropTypes.object,
  value: PropTypes.node,
  wrapperProps: PropTypes.object,
};
