import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UploadModalCard from 'app/components/UploadModal/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import BerbixVerify from 'berbix-react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => ({
  box: {
    // minWidth: 375,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
}));

const DocumentUploadV2 = () => {
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [loaded, setLoaded] = useState(false);
  const [clientToken, setClientToken] = useState(null);
  const [complete, setComplete] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [verificationRequestToken, setVerificationRequestToken] = useState('');
  const [runLoadRequestDetail] = useAsyncTask('loadRequest');
  const [runBerbixCallback] = useAsyncTask('berbixCallback');

  useEffect(() => {
    const token = queryParams.get('token');
    setVerificationRequestToken(token);
    loadRequest(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadRequest = async (token = '') => {
    // token from email
    if (token) {
      runLoadRequestDetail(async () => {
        // sends token to transaction
        const result = await API.Auth.berbix_transaction({ token });
        console.log('result', result);
        if (result.status === 'disabled') {
          setInvalidToken(true);
        } else if (result.status === 'submitted' || result.status === 'completed') {
          setComplete(true);
        } else {
          setClientToken(result.clientToken);
          if (result.transactionId) {
            setInvalidToken(false);
          }
        }
        // setInvalidToken(false);
        setLoaded(true);
      });
    }
  };

  const berbixCallback = async () => {
    runBerbixCallback(async () => {
      await API.Auth.berbix_callback({ token: verificationRequestToken });
    });
  };

  return (
    <Box className={classes.box}>
      {loaded && (<>
        {!complete && invalidToken && <UploadModalCard alt="Unauthorised" content="Verification token is invalid." header="Unauthorised" src="/images/unsuccessful.svg" />}
        {!complete && !invalidToken && (
          <BerbixVerify
            clientToken={clientToken}
            onComplete={() => {
              // the user has completed the verification flow
              berbixCallback();
              setComplete(true);
            }}
          />
        )}
        {complete && <UploadModalCard alt="Success" content="Your documents are being reviewed and you should be notified via email." header="Thank you!" src="/images/success.svg" />}
      </>)}
    </Box>
  );
};

export default DocumentUploadV2;
