import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { TraxxButton, TraxxInput } from 'app/components';
import VisibilityAdornment from 'app/components/TraxxInput/components';
import { LocalStorageKeys } from 'app/constants';
import { API } from 'app/services';
import { actions } from 'app/store';
import { useToastCatcher } from 'app/utils';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import LoginDialog from 'app/components/LoginDialog';

const useStyles = makeStyles(({ palette }) => ({
  cardContent: { height: '100%' },
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 688,
    justifyContent: 'space-between',
    padding: '48px 40px 22px',
    width: 410,
  },
  image: {
    height: 200,
    width: 200,
  },
  sumsub: {
    marginTop: "1rem"
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

const init_input = {
  password: '',
  email: '',
};

const init_errors = {
  password: '',
  email: '',
};

let mounted = false;
function Login() {
  const classes = useStyles();

  const [inputs, setInputs] = useState(init_input);
  const [errors, setErrors] = useState(init_errors);
  const [visibility, setVisibility] = useState(false);
  const loading = useSelector((state) => state.layout.loadingTasks);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // TODO
  // eslint-disable-next-line
  const [toaster, errorCatcher] = useToastCatcher({ errorParser: null, taskname: 'login' });
  const profile = useSelector((state) => state.profile);
  const expired = profile && profile.expired;
  const lastRoute = profile && profile.lastRoute;
  const [verified, setVerified] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState('');

  if (window.location.href.endsWith('verified=success') && verified === false) {
    setVerified(true);
    enqueueSnackbar(`Email successfully verified!`, {
      variant: 'success',
      autoHideDuration: 3000,
    });
  }

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const handleChange =
    (type) =>
    ({ target }) =>
      setInputs((values) => ({ ...values, [type]: target.value }));

  const handleClick = () => setVisibility((value) => !value);

  const inputProps = [
    {
      id: 'Email',
      autoComplete: 'email',
      error: errors.email,
      label: 'Email',
      onChange: handleChange('email'),
      placeholder: 'traxx@example.com',
      value: inputs.email,
      autoFocus: true,
      onKeyPress: (ev) => {
        if (ev.key === 'Enter') onSubmit(ev);
      },
    },
    {
      id: 'Login-password',
      autoComplete: 'password',
      error: errors.password,
      InputProps: {
        endAdornment: <VisibilityAdornment onClick={handleClick} />,
      },
      label: 'Password',
      onChange: handleChange('password'),
      placeholder: 'Minimum 8 characters',
      type: visibility ? 'text' : 'password',
      value: inputs.password,
      onKeyPress: (ev) => {
        if (ev.key === 'Enter') onSubmit(ev);
      },
    },
  ];

  const validateInput = () => {
    const { password, email } = inputs;
    const error = {};
    if (!email) error.email = 'Please enter your email.';
    if (!password || password.length < 8) error.password = 'Password minimum 8 characters';

    return error;
  };

  const onSubmit = (event) => {
    event.preventDefault();

    let error = validateInput();
    setErrors({ ...init_errors, ...error });
    if (Object.keys(error).length > 0) return;

    const { password, email } = inputs;
    const userExists = localStorage.getItem(`traxxid:${email}`)

    errorCatcher(async () => {
      const data = await API.Auth.login({
        username: email,
        password,
        send_code: userExists === null ? true : false
      });

      const { wallet, self, access, accounts, workspace } = data;

      if (mounted) {
        if (userExists) {
          // Login directly
          dispatch(actions.Profile.updateSession({ wallet, self, access, accounts, lastRoute, workspace }));
          localStorage.setItem(LocalStorageKeys.AccessToken, JSON.stringify(access));
          enqueueSnackbar(`Welcome, ${self.person.firstname}.`, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        } else {
          // New user - login verification code
          setOpenDialog(true);
          setData({...data, ...inputs});
        }
      }
    }, 'login');
  };

  return (
    <Grid container item alignItems="center" justify="center" xs>
      <Box className={classes.box}>
      <Card>
        <form className={classes.form} autoComplete="false">
          <img className={classes.image} src={!expired ? '/images/hello.svg' : '/images/session_expired.svg'} alt="hello" />

          <Typography variant="h5">{!expired ? 'Welcome back!' : 'Your session has expired'} </Typography>
          {expired && (
            <Typography align="center" variant="body2">
              Please log in again to resume your session
            </Typography>
          )}

          {inputProps.map((props) => (
            <TraxxInput key={props.id} {...props} />
          ))}

          <TraxxButton loading={loading['login']} form fullWidth onSubmit={onSubmit}>
            {!expired ? 'Login' : 'Log back in'}
          </TraxxButton>

          {!expired && (
            <Link color="secondary" component={RouterLink} to="/access/forget-password/request" variant="body1">
              Forgot password?
            </Link>
          )}
        </form>
      </Card>

      <Link className={classes.sumsub} component={RouterLink} to="/public/terms-conditions" variant="body3">
        Terms And Conditions
      </Link>
      <Link className={classes.sumsub} component={RouterLink} to="/public/privacy-policy" variant="body2">
        Privacy Policy
      </Link>

      { openDialog && 
        <LoginDialog 
          open={openDialog} 
          setOpen={setOpenDialog} 
          data={data} 
          setData={setData} 
          lastRoute={lastRoute}
        /> 
      }
      </Box>
    </Grid>
  );
}

export default Login;
