import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
  },
  image: {
    marginRight: theme.spacing(3),
  },
  title: {
    fontFamily: 'Muli',
    fontSize: 16,
    lineHeight: 1.4,
    fontWeight: 'bold',
  },
  subtitle: {
    fontFamily: 'Open Sans',
    fontSize: 12,
    lineHeight: 1.4,
  },
}));

/**
 * Presents user and role
 *
 * Props:
 ** className: name of css class element
 */
const MenuHeader = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const self = useSelector(({ profile }) => profile.self);

  const getRole = () => {
    // TODO: to prioritise most important first
    return self?.roles.length > 0 ? self.roles[0] : 'User';
  };

  return (
    <>
      <Box {...rest} className={clsx(classes.root, className)}>
        <img className={classes.image} src="/images/user.svg" alt="user" />
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Typography color="primary" className={classes.title}>
            {self?.person?.firstname}
          </Typography>
          <Typography color="secondary" className={classes.subtitle}>
            {getRole()}
          </Typography>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default MenuHeader;
