import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { GroupDetailComp } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
}));
const GroupDetail = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <GroupDetailComp />
    </Box>
  );
};

export default GroupDetail;
