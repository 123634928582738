import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(({ palette }) => ({
  button: {
    height: 50,
    width: 102,
  },
  buttonText: {
    color: palette.red.primary,
    fontSize: 10,
    fontWeight: 'bold',
  },
  disabled: {
    color: 'grey',
  },
}));

/**
 * View all button
 *
 * Props:
 ** disabled: boolean switch to disable
 ** text: description
 ** onClick: function to perform on click
 */
const ViewAllButtonRow = ({ disabled = false, text = 'VIEW ALL', onClick }) => {
  const classes = useStyles();

  return (
    <Grid container item justify="flex-end" xs={12}>
      <Button disabled={disabled} onClick={onClick} className={clsx(classes.button, disabled && classes.disabled)} endIcon={<ChevronRightIcon />}>
        <Typography className={clsx(classes.buttonText, disabled && classes.disabled)}>{text}</Typography>
      </Button>
    </Grid>
  );
};

export default ViewAllButtonRow;
