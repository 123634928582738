import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ListingComp, ListingCompTable } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask, useRouter } from 'app/utils';
import ViewAllButtonRow from 'app/views/Screening/components/ViewAllButtonRow';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const headers = ['Account', 'Wallet', 'Description', 'Transaction date', 'Amount', 'Balance'];

const filterOptions = {
  sort: false,
  search: false,
  dateRange: false,
  type: false,
  status: false,
  clear: false,
};

const extraOptions = {};

let mounted = false;
const PastTransactions = (props) => {
  const { limit = 5, children, className, routeType, ...rest } = props;
  const classes = useStyles();

  const router = useRouter();
  const [runLoadTransactions] = useAsyncTask('loadTransactions');
  const [filter, setFilter] = useState({ limit, offset: 0, count: 0 });
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const loadTransactions = (newFilter = filter) => {
    runLoadTransactions(async () => {
      // if (mounted) return;
      const { models, meta } = await API.Admin.list_wallet_tx(newFilter);
      if (mounted) {
        sortListingData(models);
        setFilter({ ...newFilter, count: meta.count });
      }
    });
  };

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      allData.push([
        { label: model.wallet?.entity?.account?.name, type: 'labelwithlink', link: `/admin/accounts/${model.wallet?.entity?.account?.id}/detail`, bottomLabel: model.wallet?.entity?.account?.reference },
        { label: model.wallet?.reference || model.account?.label, type: 'link', link: `/admin/wallets/${model.wallet?.id}/detail` },
        { label: model.comments || model.description },
        { date: model.timestamp, type: 'date' },
        { type: 'currency', amount: model.amount, decimal: 0 },
        { type: 'currency', amount: model.post_balance, decimal: 0 },
      ]);
    });

    setTransactions(allData);
  };

  const extraAction = (props) => {
    return <ViewAllButtonRow onClick={() => router.history.push('/admin/wallet-transactions')} />;
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <ListingComp showFilter={false} filter={filter} setFilter={setFilter} reloadFunc={loadTransactions} showPaginator={false} loading={loadTask.loadTransactions} filterOptions={filterOptions} extraOptions={extraOptions} ExtraAction={extraAction}>
        {!!transactions.length && <ListingCompTable headers={headers} data={transactions} />}
      </ListingComp>
    </Box>
  );
};

export default PastTransactions;
