import { Dialog, Table, TableBody, TableCell, TableContainer, TableRow, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useAsyncTask } from 'app/utils';
import { API } from 'app/services';
import XLSX from 'xlsx';
import { TraxxButton } from 'app/components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
  },
  container: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      minWidth: 500,
    },
  },
  button: {
    marginTop: theme.spacing(3),
    float: 'right',
  },
}));

const CountryCode = (props) => {
  const { onClose, open } = props;
  const [countries, setCountries] = useState();

  useEffect(() => {
    if (open && !countries) getCountries();
    // eslint-disable-next-line
  }, [open]);

  const [runLoadCountries] = useAsyncTask('loadCountries');
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const downloadSampleData = () => {
    const country_arr = countries.map((country) => [country.name, country.ISO]);

    const worksheet = XLSX.utils.aoa_to_sheet(country_arr);
    const workbook = {
      SheetNames: ['sheet1'],
      Sheets: {
        sheet1: worksheet,
      },
    };
    return XLSX.writeFile(workbook, `country.csv`);
  };

  const getCountries = () => {
    runLoadCountries(async () => {
      const { countries } = await API.Screening.get_countries();
      // console.log({ countries });
      // const map = Object.keys(countries)
      //   .map(country => ({ code: country, name: countries[country], value: country }))
      //   .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

      setCountries(Object.keys(countries).map((country) => ({ ISO: country, name: countries[country] })));
    });
  };

  return (
    <Dialog onClose={handleClose} className={classes.root} aria-labelledby="simple-dialog-title" open={open}>
      {countries && (
        <Box p={2} mb={2} display="flex">
          <Box flexGrow={1} />
          <TraxxButton onSubmit={downloadSampleData}>Download</TraxxButton>
        </Box>
      )}
      {countries && (
        <TableContainer>
          <Table>
            <TableBody>
              {countries.map((country) => (
                <TableRow>
                  <TableCell>
                    <Typography>{country.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{country.ISO}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Dialog>
  );
};

export default CountryCode;
