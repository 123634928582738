import { Box, Button, Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { Crumbs, DataRow, DateDisplay, ListingComp, ListingCompTable, MonoText, StatusLabel, TabComp, Title, TraxxButton } from 'app/components';
import { StatusMaps } from 'app/constants';
import { API } from 'app/services';
import { useAsyncTask, useMoneyFormatter, useRouter } from 'app/utils';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  root: {
    padding: spacing(3, 3, 8),
    [breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  button: {
    marginTop: 10,
    float: 'right',
  },
  table: {
    borderRadius: 5,
  },
  green: {
    backgroundColor: '#c9efe1',
  },
  red: {
    backgroundColor: '#f8d0d0',
  },
  yellow: {
    backgroundColor: '#fff2cb',
  },
  status: {
    borderRadius: 4,
    fontSize: 12,
    fontWeight: 'bold',
    padding: '4px 6px',
  },
  header: {
    opacity: 0.8,
    fontWeight: 'bold',
  },
  iframe: {
    // border: "none",
    display: 'flex',
    width: '100%',
    height: 800,
  },
}));

const PAYMENT_HEADER = ['Payment ID', 'Payment date', 'Status'];

const PRODUCT_HEADER = ['Product', 'Product ID', 'Price', 'Credit'];
const API_HOST = process.env.REACT_APP_API_HOST;
let mounted = false;
function OrderDetail({ routeType = 'account' }) {
  const classes = useStyles();
  const [order, setOrder] = useState(null);
  const loading = useSelector((state) => state.layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const [runLoadOrder] = useAsyncTask('loadOrderDetail');
  const [runCancelOrder] = useAsyncTask('cancelOrder');
  const [payments, setPayments] = useState([]);
  const [products, setProducts] = useState([]);
  const [showCancel, setShowCancel] = useState(false);
  const router = useRouter();
  const { match } = router;
  const { params } = match;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) getOrder();
    // eslint-disable-next-line
  }, [access]);

  const getOrder = () => {
    if (!params.order_id) return enqueueSnackbar('invalid id');
    runLoadOrder(async () => {
      const loader = routeType === 'account' ? API.Users.get_order_detail : API.Admin.get_order_detail;
      const { model } = await loader(params.order_id);
      if (mounted) {
        if (model.payments.length) {
          sortPayments(model.payments);
        }
        if (model.order_items.length) {
          sortProducts(model);
        }
        setOrder(model);
      }
    });
  };

  const getPaymentLink = (id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/payment/${id}/detail`;
      default:
        return `/app/payment/${id}/detail`;
    }
  };

  const sortPayments = (payments) => {
    let pay_arr = [];
    payments.forEach((payment) => {
      pay_arr.push([
        { label: payment.reference, type: 'link', link: getPaymentLink(payment.id) },
        { date: payment.updated_at || payment.date, type: 'date' },
        { label: payment.status, statusType: 'Order', type: 'status' },
      ]);
    });
    setPayments(pay_arr);
  };

  const sortProducts = (model) => {
    const { order_items, currency } = model;
    let item_arr = [];
    order_items.forEach((item) => {
      item_arr.push([{ label: item.name }, { label: item.reference, type: 'mono' }, { type: 'currency', amount: item.unit_price, currency: currency }, { type: 'currency', amount: parseInt(item.extras0), currency: 'TXC', decimal: 0 }]);
    });
    setProducts(item_arr);
  };

  const makePayment = () => {
    window.location.replace(`${API_HOST}/public/payment/pay?ref=${order.payments[0].reference}`);
    // setShowIframe(true);
  };

  const CurrencyWraper = ({ currency, amount }) => {
    const formatter = useMoneyFormatter({ currency: currency ? currency : 'TXC', decPlaces: 0, showCurrency: true });

    return <>{formatter(amount)}</>;
  };

  // window.addEventListener('message', function (event) {
  //   // console.log(event.origin, event.data, event.origin === "http://localhost:8181", event.data === "payment success")
  //   if (event.origin === "http://localhost:8181") {
  //     if (event.data === "start watch") {
  //       // setRefreshPage(true)
  //     }
  //   }
  // });

  const checkPayable = () => {
    if (!order || !order.payment || routeType !== 'account') return false;
    switch (order.status) {
      case 'pending_payment':
        break;
      default:
        return false;
    }
    switch (order.payment.status) {
      case 'pending_payment':
      case 'failed':
        return true;
      default:
        return false;
    }
  };

  const checkCancelable = () => {
    if (!order || !order.payment || order.status === 'cancelled') return false;
    let cancelable = false;
    if (order.payment.status === 'gateway_processing') return routeType === 'admin';

    switch (order.status) {
      case 'pending_payment':
        cancelable = true;
        break;
      default:
        if (routeType === 'admin') {
          if (order.status === 'completed' || order.status === 'paid') return false;
          else return true;
        } else return false;
    }

    return cancelable;
  };

  const cancelOrder = () => {
    runCancelOrder(async () => {
      const loader = routeType === 'admin' ? API.Admin.cancel_order : API.Users.order_cancel;
      await loader(params.order_id);
      setShowCancel(false);
      enqueueSnackbar('order cancelled', { variant: 'success' });
      getOrder();
    });
  };

  return (
    <Box className={classes.root}>
      <Crumbs current="Detail" links={[{ text: 'Orders', href: routeType === 'admin' ? '/admin/order-history' : '/order-history' }]} />
      <Title prefix={'Order detail'} />
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <TableContainer component={Paper} className={classes.table}>
            {loading.loadOrderDetail && <LinearProgress size={30} />}
            {!loading.loadOrderDetail && order && (
              <Table>
                <TableBody>
                  <DataRow name="Order ID" value={<MonoText>{order.reference}</MonoText>} />
                  <DataRow name="Order date" value={<DateDisplay date={order.date} />} />
                  <DataRow name="Status" value={<StatusLabel variantMap={StatusMaps.Order}>{order.status}</StatusLabel>} />
                  <DataRow
                    name="Total"
                    value={
                      <MonoText>
                        <CurrencyWraper currency={order.currency} amount={order.total} />
                      </MonoText>
                    }
                  />
                  <DataRow name="Payment method" value={<MonoText>{order.payment_method}</MonoText>} />
                  {checkPayable() && (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <TraxxButton loading={loading.loadOrderDetail || loading.cancelOrder} fullWidth onSubmit={makePayment}>
                          Pay
                        </TraxxButton>
                      </TableCell>
                    </TableRow>
                  )}
                  {checkCancelable() && (
                    <TableRow>
                      <TableCell colSpan={2}>
                        {showCancel && (
                          <>
                            <Alert severity="warning">
                              <Typography variant="body1">Order will be cancelled</Typography>
                            </Alert>
                            <Box mt={1} display="flex">
                              <Button loading={loading.loadOrderDetail || loading.cancelOrder} onClick={() => setShowCancel(false)}>
                                Cancel
                              </Button>
                              <Box flexGrow={1} />
                              <TraxxButton loading={loading.loadOrderDetail || loading.cancelOrder} onSubmit={cancelOrder}>
                                confirm
                              </TraxxButton>
                            </Box>
                          </>
                        )}
                        {!showCancel && (
                          <TraxxButton fullWidth onSubmit={() => setShowCancel(true)}>
                            Cancel order
                          </TraxxButton>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Grid>
        {order && (
          <Grid container item md={8} xs={12}>
            <Grid item xs={12}>
              <TabComp
                tabTitle="Order tabs"
                tabHeaders={['Payments', 'Products']}
                tabContent={[
                  <ListingComp showFilter={false} loading={loading.loadOrderDetail} filter={{ count: payments.length }} showPaginator={false}>
                    <ListingCompTable headers={PAYMENT_HEADER} data={payments} />
                  </ListingComp>,
                  <ListingComp showFilter={false} loading={loading.loadOrderDetail} filter={{ count: products.length }} showPaginator={false}>
                    <ListingCompTable headers={PRODUCT_HEADER} data={products} />
                  </ListingComp>,
                ]}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default OrderDetail;
