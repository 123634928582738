import { Box, FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { TraxxButton } from 'app/components';
import clsx from 'clsx';
import React, { useState } from 'react';
import DatePicker from '../DatePicker';
import { SearchField } from './components';
import { ReactComponent as ComplianceCheckIcon } from './components/ComplianceCheckIcon.svg';

const useStyles = makeStyles((theme) => ({
  dot: { fontSize: 24 },
  field: {
    display: 'flex',
    width: 'auto',
  },
  container: {},
  input: {
    padding: theme.spacing(1.34),
  },
  green: { color: theme.palette.status.green },
  icon: { marginRight: 4 },
  red: { color: theme.palette.status.red },
  yellow: { color: theme.palette.status.yellow },
  select: { backgroundColor: 'white' },
}));

const DEFAULT_SCREEN_TYPE_OPTIONS = {
  all: { value: '', name: 'All' },
  simple: { value: 'simple', name: 'Simple' },
  passport: { value: 'passport', name: 'Passport' },
  organisation: { value: 'organisation', name: 'Organisation' },
  vessel: { value: 'vessel', name: 'Vessel' },
};

const DEFAULT_STATUS_OPTIONS = {
  all: { value: '', name: 'All' },
  complete: { value: 'complete', name: 'Complete' },
  new: { value: 'new', name: 'New' },
  screening: { value: 'screening', name: 'Screening' },
};

const DEFAULT_SORT_OPTIONS = {
  asc: { value: 'asc', name: 'Date asc' },
  desc: { value: 'desc', name: 'Date desc' },
};

// const DEFAULT_DATE_RANGE_OPTIONS = {
//   none: { value: "", name: "All time" },
//   "0": { value: 0, name: "Today" },
//   "1": { value: 1, name: "Yesterday" },
//   "7": { value: 7, name: "Last 7 Days" },
//   "30": { value: 30, name: "Last 30 Days" },
// }

/**
 * Filters used for data listing
 *
 * Props:
 ** filterOptions: options for which filters to be used
 ** extraOptions: options for sorting, type and status
 ** sortKey: unique sorting key
 ** className: name of css class element
 */
const Filters = ({ filterOptions, extraOptions, sortKey, className, onFilter = () => {}, filter = {}, initial_range = '', searchByText = 'Reference ID' }) => {
  const classes = useStyles();
  const { type = '', status = '', sort = 'desc' } = filter;
  const [search, setSearch] = useState();
  // const [accountSearch, setAccountSearch] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (type) => {
    return (value) => {
      let ranges = { startDate, endDate };
      ranges[type] = value;
      onFilter('dateRange')(ranges);
      if (type === 'startDate') setStartDate(value);
      else setEndDate(value);
    };
  };
  const clearFilters = () => {
    setSearch('');
    setStartDate(null);
    setEndDate(null);
    onFilter('clear')('clear');
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    onFilter('search')(e);
  };
  // const handleAccountSearch = (e) => {
  //   setAccountSearch(e.target.value);
  //   onFilter("accountSearch")(e);
  // };
  const [sortOpts] = useState(extraOptions.sort || DEFAULT_SORT_OPTIONS);
  // const [dateRangeOpts] = useState(extraOptions.dateRange || DEFAULT_DATE_RANGE_OPTIONS);
  const [typeOpts] = useState(extraOptions.type || DEFAULT_SCREEN_TYPE_OPTIONS);
  const [statusOpts] = useState(extraOptions.status || DEFAULT_STATUS_OPTIONS);

  const getStatusColor = (status) => {
    switch (status) {
      case 'draft':
      case 'screening':
        return '#F2C94E';
      case 'open':
      case 'new':
        return '#FFF59D';
      case 'gateway_processing':
      case 'verifying':
      case 'pending_payment':
        return '#F2C94E';
      case 'active':
      case 'complete':
      case 'paid':
      case 'completed':
        return '#2CC990';
      case 'cancelled':
      case 'failed':
        return '#780000';
      default:
        return 'grey';
    }
  };
  return (
    <Box className={className} flex={1}>
      <Grid spacing={1} container className={classes.container}>
        {filterOptions.search && (
          <Grid item md={6} xs={12} sm={6}>
            <SearchField
              className={classes.field}
              onChange={(e) => {
                handleSearch(e);
              }} //  onFilter("search")
              placeholder={`Search by ${searchByText}`}
              value={search}
            />
          </Grid>
        )}

        {filterOptions.dateRange && (
          <Grid item md={3} sm={6} xs={12}>
            <FormControl className={clsx(classes.field, classes.select)}>
              <DatePicker variant="outlined" disableFuture={true} inputProps={classes.input} placeholder="Start date" type="date" name="start_date" onChange={handleDateChange('startDate')} value={startDate} />
            </FormControl>
          </Grid>
        )}

        {filterOptions.dateRange && (
          <Grid item md={3} sm={6} xs={12}>
            <FormControl className={clsx(classes.field, classes.select)}>
              <DatePicker variant="outlined" disableFuture={true} inputProps={classes.input} placeholder="End date" type="date" name="end_date" onChange={handleDateChange('endDate')} value={endDate} />
            </FormControl>
          </Grid>
        )}
        {filterOptions.sort && (
          <Grid item md={3} xs={12} sm={3}>
            <FormControl className={clsx(classes.field, classes.select)}>
              <Select
                className={classes.select}
                displayEmpty
                renderValue={(v) => (
                  <Box display="flex" alignItems="center">
                    <ComplianceCheckIcon height="1em" style={{ marginRight: 6 }} />
                    {sortOpts[sort.replace(`${sortKey}:`, '')].name}
                  </Box>
                )}
                variant="outlined"
                margin="dense"
                fullWidth
                onChange={onFilter('sort')}
                value={type}
                placeholder="Sort by">
                {Object.values(sortOpts).map((type, index) => (
                  <MenuItem key={index} value={type.value}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {filterOptions.type && (
          <Grid item md={3} xs={12} sm={3}>
            <FormControl className={clsx(classes.field, classes.select)}>
              <Select
                className={classes.select}
                displayEmpty
                renderValue={(v) => (
                  <Box display="flex" alignItems="center">
                    <ComplianceCheckIcon height="1em" style={{ marginRight: 6 }} />
                    {(typeOpts[type] && typeOpts[type].name) || 'Type'}
                  </Box>
                )}
                variant="outlined"
                margin="dense"
                fullWidth
                onChange={onFilter('type')}
                value={type}
                placeholder="Screen type">
                {Object.values(typeOpts).map((type, index) => (
                  <MenuItem key={index} value={type.value}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {filterOptions.status && (
          <Grid item md={3} xs={12} sm={3}>
            <FormControl className={clsx(classes.field, classes.select)}>
              <Select
                className={classes.select}
                displayEmpty
                renderValue={(v) => (
                  <Box display="flex" alignItems="center">
                    <FiberManualRecordIcon style={{ color: getStatusColor(status), marginRight: 6, height: '0.8em' }} />
                    {(statusOpts[status] && statusOpts[status].name) || 'Status'}
                  </Box>
                )}
                variant="outlined"
                margin="dense"
                fullWidth
                onChange={onFilter('status')}
                value={status}
                placeholder="Status">
                {Object.values(statusOpts).map((status, index) => (
                  <MenuItem key={index} value={status.value}>
                    {status.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* {filterOptions.accountSearch && (
          <Grid item md={3} xs={12} sm={3}>
            <SearchField
              className={classes.field}
              onChange={(e) => { handleAccountSearch(e) }}
              placeholder={`Search by Account Name/ID`}
              value={accountSearch} />
          </Grid>
        )} */}

        {filterOptions.clear && (
          <Grid item md={3} xs={12} sm={3}>
            <FormControl className={clsx(classes.field, classes.select)}>
              <TraxxButton className={classes.button} onSubmit={clearFilters}>
                Clear
              </TraxxButton>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Filters;
