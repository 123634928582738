import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import React from 'react';
import { Link as BrowserLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0.1),
    },
  },
}));

/**
 * Sample view component
 *
 * Props:
 ** current: text
 ** links: links for navigation
 ** className: name of css class element
 */
const SampleView = (props) => {
  const { current, links = [], className } = props;
  const classes = useStyles();

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} className={clsx(classes.root, className)} aria-label="breadcrumb">
      {links.map((nav, index) => (
        <Link component={BrowserLink} to={nav.href} key={index}>
          {nav.text}
        </Link>
      ))}
      <Typography color="textPrimary">{current}</Typography>
    </Breadcrumbs>
  );
};

export default SampleView;
