import { Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { ListingComp, ListingCompTable, StatusLabel } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

const headers = ['Name', 'Person ID', 'Email', 'Add person'];

const filterOptions = {
  sort: false,
  search: true,
  dateRange: false,
  type: false,
  status: false,
  clear: true,
};

const extraOptions = {};

let mounted = false;
function AddAccount(props) {
  const { onLoad, personsEntity, account } = props;
  const classes = useStyles();

  // TODO
  // eslint-disable-next-line
  const [errorCatcher] = useAsyncTask({ taskname: 'loadEntity' });
  const [runAddEntity] = useAsyncTask({ taskname: 'addEntity' });
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [listing, setListing] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const getPersons = (newFilter = filter) => {
    errorCatcher(async () => {
      const { models, meta } = await API.Admin.list_persons(newFilter);
      if (mounted) {
        setFilter({ ...newFilter, count: meta.count });
        sortListingData(models);
        setLoading(false);
        setLoaded(true);
      }
    });
  };

  const addToAccount = (entity_id) => {
    runAddEntity(async () => {
      await API.Admin.add_person_to_account({ account_id: account, entity_id });
      if (mounted) {
        enqueueSnackbar('Account added', { variant: 'success' });
        onLoad();
      }
    });
  };

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const getCustomContent = (model) => {
    if (personsEntity.includes(model.entity.id)) {
      return <StatusLabel variant="success">Added</StatusLabel>;
    } else {
      return (
        <>
          <IconButton
            onClick={() => {
              addToAccount(model.entity.id);
            }}>
            <AddCircleIcon color="primary" />
          </IconButton>
        </>
      );
    }
  };

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      allData.push([{ label: model.firstname }, { label: model.reference, type: 'link', link: `/admin/persons/${model.id}/detail` }, { label: model.primary_email }, { type: 'custom', value: getCustomContent(model) }]);
    });

    setListing(allData);
  };

  return (
    <Box className={classes.root}>
      <ListingComp filter={filter} setFilter={setFilter} setLoading={setLoading} loading={loading} showFilter={true} reloadFunc={getPersons} loaded={loaded} filterOptions={filterOptions} extraOptions={extraOptions}>
        <ListingCompTable headers={headers} data={listing} />
      </ListingComp>
    </Box>
  );
}

export default AddAccount;
