export default {
  underlineHover: {
    '&:hover': {
      cursor: 'pointer',
    },

    '&:visited': {
      // color: "#780000",
    },
  },
};
