import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { DocumentDetailComp } from './components';

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  root: {
    padding: spacing(3, 3, 8),
    [breakpoints.down('xs')]: {
      padding: spacing(1, 1, 3),
    },
    '@media print': {
      paddingTop: 0,
    },
  },
}));

function Detail(props) {
  const classes = useStyles();
  const { routeType = 'account', documentResult, onBack, onNext } = props;

  return (
    <Box justifyItems="center" className={classes.root}>
      <DocumentDetailComp documentResult={documentResult} onNext={onNext} onBack={onBack} routeType={routeType} />
    </Box>
  );
}

export default Detail;
