import { makeStyles } from '@material-ui/core/styles';
import { API } from 'app/services';
import { useAsyncTask, useRouter } from 'app/utils';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MatchDetailCA from './MatchDetailCA';
import MatchDetailDJ from './MatchDetailDJ';

let mounted = false;
function MatchDetail({ routeType = 'account' }) {
  const router = useRouter();
  const { match } = router;
  const { params } = match;
  const screening_id = params.screening_id;
  const match_id = params.match_id;
  const { enqueueSnackbar } = useSnackbar();
  const access = useSelector((state) => state.profile.access);
  const [errorCatcher] = useAsyncTask('getScreening');
  const [runMatchListing] = useAsyncTask('matchListing');
  const [matching, setMatching] = useState(null);
  const [matchProfile, setMatchProfile] = useState(null);
  const [matchRawResult, setMatchRawResult] = useState(null);
  const [matchList, setMatchListing] = useState();

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) getMatchDetail();
    // eslint-disable-next-line
  }, [access]);

  const getMatchDetail = () => {
    if (!screening_id || !match_id) return enqueueSnackbar('invalid id', { variant: 'error' });
    errorCatcher(async () => {
      const loader = routeType === 'account' ? API.Screening.get_match_detail : API.Admin.get_match;
      const { model } = await loader(screening_id, match_id);
      if (mounted) {
        setMatching(model);
        setMatchRawResult(JSON.parse(model.screen_result_raw.content));
        if (model.profile_retrieved_at && routeType === 'account') loadMatchProfiles();
        if (model.profile_retrieved_at && model.screen_profile) {
          setMatchProfile(model.screen_profile);
        }
        getMatchListing();
      }
    });
  };

  const getMatchListing = (filter = { limit: 1000 }) => {
    runMatchListing(async () => {
      const loader = routeType === 'account' ? API.Screening.get_matching_list : API.Admin.list_match;
      const { models } = await loader(screening_id, filter);
      if (mounted) {
        setMatchListing(models);
      }
    });
  };

  const loadMatchProfiles = () => {
    if (!screening_id || !match_id) return enqueueSnackbar('invalid id', { variant: 'error' });
    errorCatcher(async () => {
      const { profile } = await API.Screening.get_match_profile(screening_id, match_id);
      setMatchProfile(profile);
    });
  };

  if (matching?.screen?.screen_provider_id === 3) {
    return <MatchDetailCA />
  } else {
    return <MatchDetailDJ />
  }

  // if (matching?.screen?.screen_provider_id === 1 || matching?.screen?.screen_provider_id === 2) {
  //   return <MatchDetailDJ />;
  // } else {
  //   return <MatchDetailCA />;
  // }
}

export default MatchDetail;
