import { Card, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { useMoneyFormatter } from 'app/utils';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  card: {
    alignContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    height: 102,
    justifyContent: 'space-evenly',
    paddingLeft: 20,
    // marginTop: 20
  },
  category: { marginLeft: 10 },
}));

/**
 * Wallet card
 *
 * Props:
 ** wallet: wallet object
 ** className: name of css class element
 */
const WalletCard = (props) => {
  const { wallet, loading, className, onClick } = props;
  const classes = useStyles();
  const formatter = useMoneyFormatter({ currency: wallet?.currency, decPlaces: 0, showCurrency: true });

  return (
    <Card className={clsx(classes.card, className)} onClick={onClick}>
      <Grid container item alignItems="center" justify="flex-start">
        <AccountBalanceWalletIcon />
        <Typography className={classes.category} color="secondary" variant="body2">
          Credit balance
        </Typography>
      </Grid>
      {!loading && wallet && <Typography variant="h5">{wallet.balance ? `${formatter(wallet.balance)}` : '-'}</Typography>}
      {(loading || !wallet) && <CircularProgress />}
    </Card>
  );
};

export default WalletCard;
