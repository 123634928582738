import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton } from 'app/components';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    marginBottom: spacing(1),
  },
  link: {
    color: palette.common.black,
    fontSize: 10,
    fontWeight: 'bold',
  },
  separator: {
    color: palette.grey.primary,
    fontSize: 16,
    marginTop: 4,
  },
  text: { paddingTop: 4 },
  floatButton: {
    height: 30,
  },
  sideSpace: {
    paddingRight: 5,
  },
  singleButton: {
    [breakpoints.up('sm')]: {
      maxWidth: 340,
    },
  },
  title: {
    [breakpoints.up('sm')]: {
      paddingLeft: spacing(2),
    },
  },
  displayNone: {
    display: 'none',
  },
}));

/**
 * Title of content
 *
 * Props:
 ** className: name of css class element
 ** children: data to be wrapped
 */
function Title({ className, children, floatButton, floatButtons = [], prefix }) {
  const classes = useStyles();

  return (
    <Box display="flex" className={clsx(className, classes.root)} xs={12}>
      <Grid container spacing={1}>
        <Grid item md={floatButton || floatButtons.length > 0 ? 2 : 12} xs={12} sm={12}>
          {prefix && (
            <Typography className={classes.title} variant="h5">
              {prefix}
            </Typography>
          )}
          <div className={classes.title}>{children}</div>
        </Grid>
        <Grid container item md={floatButton || floatButtons.length > 0 ? 10 : false} xs={12} sm={12} className={!floatButton && floatButtons.length === 0 ? classes.displayNone : ''}>
          <Box width="100%" display="flex" mt={children ? 2 : 0} mb={1} flexDirection="row-reverse">
            {floatButton && (
              <TraxxButton
                startIcon={floatButton.icon}
                fullWidth
                onSubmit={() => {
                  floatButton.onClick();
                }}
                className={clsx(classes.floatButton, classes.singleButton, floatButton.className)}>
                {floatButton.text}
              </TraxxButton>
            )}
            {floatButtons &&
              floatButtons.map((butOpt) => (
                <Grid item md={butOpt.space || floatButtons.length} sm={12} xs={12} className={classes.sideSpace}>
                  {!butOpt.holder && (
                    <TraxxButton
                      spacing={1}
                      fullWidth
                      onSubmit={() => {
                        butOpt.onClick();
                      }}
                      className={clsx(classes.floatButton, butOpt.className)}>
                      {butOpt.text}
                    </TraxxButton>
                  )}
                </Grid>
              ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Title;

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Title.defaultProps = {
  className: '',
  children: null,
};
