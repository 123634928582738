import { Box, Card, CardActions, CardContent, CardHeader, CardMedia, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton } from 'app/components';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    padding: theme.spacing(2),
  },
  startButton: {
    flex: 1,
    position: 'absolute',
    bottom: 0,
    width: '92.5%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    boxShadow: 'none',
  },
  cover: {
    maxWidth: 500,
    minHeight: 300,
  },
  action: {
    display: 'flex',
    textAlign: 'center',
    paddingLeft: theme.spacing(2),
  },
}));

const MainKYCOption = (props) => {
  const { children, className, header, description, ArtComponent, startButtonText, subheader, href, disabled = false, ...rest } = props;
  const classes = useStyles();

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Card>
        <Grid container>
          <Grid className={classes.cover} item xs={6}>
            <CardMedia>
              <Link to={href}>
                <ArtComponent />
              </Link>
            </CardMedia>
          </Grid>
          <Grid item xs={6} className={classes.card}>
            <Card className={classes.content}>
              <CardHeader title={header} subheader={subheader} />
              <Divider />
              <CardContent>
                <Typography>{description}</Typography>
              </CardContent>
              <CardActions className={classes.action}>
                <TraxxButton component={Link} className={classes.startButton} to={href} disabled={disabled}>
                  {startButtonText || 'Start'}
                </TraxxButton>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default MainKYCOption;
