export const TYPES = {
  UPDATE: 'UPDATE',
};

export function update(state) {
  return {
    type: TYPES.UPDATE,
    state,
  };
}
