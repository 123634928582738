import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Paths } from 'app/constants';
import { actions } from 'app/store';
import { useRouter } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoginButler from '../LoginButler';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

let mounted = false;

/**
 * DashboardGuard to ensure user has valid privilege to have authorisation to access features
 *
 * Props:
 ** children: child components to be wrapped by AuthGuard
 ** className: name of css class element
 */
const DashboardGuard = (props) => {
  const { children, className, ...rest } = props;
  const router = useRouter();
  const self = useSelector((state) => state.profile.self);
  const init = useSelector((state) => state.profile.init);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  });

  useEffect(() => {
    if (!mounted) return;
    if (init && !self) {
      dispatch(actions.Profile.updateLastRoute(router.location.pathname));
      router.history.push(Paths.Auth.Login);
    }
    // eslint-disable-next-line
  }, [self, init]);

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <LoginButler />
      {children}
    </Box>
  );
};

export default DashboardGuard;
