import { Grid, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UploadModal from 'app/components/UploadModal';
import UploadModalCard from 'app/components/UploadModal/components';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  determinate: {
    backgroundColor: '#f5f5f5',
    borderRadius: 10,
    height: 15,
    marginBottom: theme.spacing(1.5),
    width: '100%',
  },
  determinateBar: {
    backgroundColor: theme.palette.red.primary,
    borderRadius: 10,
  },
  duration: {
    marginBottom: theme.spacing(4),
  },
  successWrapper: {
    height: 340,
    width: 347,

    [theme.breakpoints.down('xs')]: { width: 300 },
  },
  uploading: {
    marginBottom: theme.spacing(4),
  },
  uploadingWrapper: {
    maxWidth: 365,
  },
}));

const UploadDialog = ({ error, onRetry, timeLeft }) => {
  const [periodCount, setPeriodCount] = useState(1);
  const classes = useStyles();

  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    if (error) return;

    const periodCountInterval = setInterval(() => setPeriodCount((count) => (count === 3 ? 1 : (count += 1))), 500);

    return () => clearInterval(periodCountInterval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) return;

    const uploadDuration = timeLeft < 500 ? 1 : timeLeft;
    const progressRate = Math.floor((500 / uploadDuration) * 100);
    const progressInterval = setInterval(
      () =>
        setProgress((value) => {
          const newValue = value + progressRate;

          if (newValue >= 100) {
            clearInterval(progressInterval);
            setStatus('success');
            return 100;
          }

          return newValue;
        }),
      500
    );

    return () => {
      if (progressInterval) clearInterval(progressInterval);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <UploadModal open>
      {error && (
        <UploadModalCard
          alt="Unsuccessful"
          buttonText="Retry upload"
          content="Sorry, something went wrong during the upload. Please try uploading your documents again."
          header="Document upload unsuccessful"
          onButtonClick={onRetry}
          src="/images/unsuccessful.svg"
        />
      )}

      {!error && status === 'loading' && (
        <UploadModalCard className={classes.uploadingWrapper}>
          <Grid className={classes.uploading} container>
            <Grid item xs />
            <Typography align="center" variant="h5">
              Uploading
            </Typography>
            <Grid item xs>
              <Typography variant="h5">{'.'.repeat(periodCount)}</Typography>
            </Grid>
          </Grid>

          <LinearProgress
            classes={{
              bar1Determinate: classes.determinateBar,
              determinate: classes.determinate,
            }}
            variant="determinate"
            value={progress}
          />

          <Grid className={classes.duration} container justify="space-between">
            <Typography color="secondary" variant="caption">
              {moment.duration(timeLeft).humanize(true)}
            </Typography>
            <Typography color="secondary" variant="caption">
              <strong>{`${progress}%`}</strong>
            </Typography>
          </Grid>

          <Typography align="center" color="secondary" variant="body2">
            Please wait while we upload your files and do not navigate away from this page&#46;
          </Typography>
        </UploadModalCard>
      )}

      {!error && status === 'success' && (
        <UploadModalCard
          alt="Success"
          content="Your documents are being reviewed and you should be notified via
            email within 1-3 business days."
          header="Document upload successful"
          src="/images/success.svg"
        />
      )}
    </UploadModal>
  );
};

UploadDialog.propTypes = {
  error: PropTypes.bool.isRequired,
  onRetry: PropTypes.func.isRequired,
  timeLeft: PropTypes.number,
};

UploadDialog.defaultProps = {
  timeLeft: 500,
};

export default UploadDialog;
