import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, SearchSelect, TraxxInput } from 'app/components';
import { useRouter } from 'app/utils';
import React, { Fragment, useState } from 'react';

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  card: {
    padding: spacing(4),
    width: '100%',
  },
  dropzoneActive: {
    border: '2px dashed #d8d8d8',
    width: 320,
  },
  dropzoneGrid: { height: 320 },
  dropzoneInactive: {
    border: '2px solid #f5f5f5',
    width: 300,
  },
  dropzoneLabel: { marginBottom: 30 },
  dropzoneText: { color: palette.grey.primary },
  icon: {
    color: palette.grey.primary,
    fontSize: 78,
    marginBottom: 30,
  },
  label: {
    fontSize: 18,
    fontWeight: 800,
  },
  nameLabel: { marginBottom: 24 },
  inputContainer: { marginBottom: 39 },
  root: {
    padding: '20px 24px',
    width: '100%',
  },
  selectButton: {
    fontSize: typography.body1.fontSize,
    height: 60,
    width: 500,
  },
  selectLabel: { marginBottom: 12 },
  title: { marginBottom: 25 },
  underline: {
    '&:before': { borderBottom: '1px solid #c0c0c0' },
  },
  uploadLabel: { marginBottom: 21 },
  input: {
    marginBottom: spacing(2),
  },
  checkbox: {
    marginBottom: spacing(2),
    justifyContent: 'center',
  },
}));

const NormalScreening = (props) => {
  const classes = useStyles();
  const { full, countries = [], errors, inputs, setInputs } = props;
  const [nationality, setNationality] = useState('');
  const [birthPlace, setBirthPlace] = useState('');
  const [issuingState, setIssuingState] = useState('');
  const router = useRouter();

  const handleChange = (type) => {
    return ({ target }) => setInputs({ ...inputs, [type]: target.value });
  };

  const handleDocNumChange = (type) => {
    return ({ target }) => setInputs({ ...inputs, [type]: target.value.replace(/[^a-zA-Z0-9]/g, '') });
  };

  const handleDateChange = (type) => {
    return (value) => setInputs({ ...inputs, [type]: value });
  };
  const handleSelect = (type) => {
    return (event) => {
      setInputs({
        ...inputs,
        [type]: event.target.value,
      });
    };
  };

  // useEffect(() => {
  //   if (inputs.nationality) {
  //     countries.forEach((country) => {
  //       if (country.code === inputs.nationality) {
  //         setNationality(country.name);
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [inputs.nationality]);

  const onCountrySelect = (type) => {
    return (event, value, reason) => {
      if (!type) return;
      let selected;
      countries.forEach((country) => {
        if (country.name === value) {
          selected = country;
        }
      });
      if (selected) {
        setInputs({
          ...inputs,
          [type]: selected.code,
        });
        switch (type) {
          case 'nationality':
            setNationality(selected.name);
            break;
          case 'country_of_birth':
            setBirthPlace(selected.name);
            break;
          default:
            break;
        }
      } else {
        setInputs({
          ...inputs,
          [type]: '',
        });
        switch (type) {
          case 'nationality':
            setNationality('');
            break;
          case 'country_of_birth':
            setBirthPlace('null');
            break;
          default:
            break;
        }
      }
    };
  };

  const getFilteredOptions = (inputKey) => {
    // eslint-disable-next-line
    const maps = countries
      .map((country) => {
        if (!inputKey) return country;
        if (country.code === inputKey) return country;
        return country;
      })
      .filter((count) => !!count);
    return maps;
  };

  const onNationalityInput = (event, value, reason) => {
    setNationality(value);
  };

  const onIssuingStateInput = (event, value, reason) => {
    setIssuingState(value);
  };

  const onBirthPlace = (event, value, reason) => {
    setBirthPlace(value);
  };

  const getDisableStatus = () => {
    if (inputs.screen_type === 'passport') return false;
    return true;
  };

  const render_passport_name_fields = () => {
    return (
      <Fragment>
        <Grid item xs={12} md={6}>
          <TraxxInput
            required={false}
            className={classes.input}
            label="Middle name"
            id="middle_name"
            error={errors.middle_name}
            InputProps={{}}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange('middle_name')}
            placeholder="Middle name as recorded in document"
            value={inputs.middle_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TraxxInput
            required={true}
            className={classes.input}
            label="Last name"
            id="last_name"
            error={errors.last_name}
            InputProps={{}}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange('last_name')}
            placeholder="Last name as recorded in document"
            value={inputs.last_name}
          />
        </Grid>
      </Fragment>
    );
  };

  const render_passport_fields = () => {
    return (
      <Fragment>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth className={classes.input} disabled={getDisableStatus()}>
            <InputLabel required shrink>
              Type of Identification
            </InputLabel>
            <Select fullWidth required={true} onChange={handleSelect('type')} value={inputs.type} disabled={getDisableStatus()}>
              <MenuItem value="SOCIAL_SECURITY_NUMBER">Social Security Number</MenuItem>
              <MenuItem value="DRIVING_LICENCE">Driving License</MenuItem>
              <MenuItem value="PASSPORT">Passport</MenuItem>
              <MenuItem value="IDENTITY_CARD">Identity Card</MenuItem>
              <MenuItem value="TAX_REGISTRATION">Tax Registration</MenuItem>
              <MenuItem value="ALIEN_IDENTIFICATION">Alien Identification</MenuItem>
              <MenuItem value="EMPLOYER_IDENTIFICATION">Employer Identification</MenuItem>
              <MenuItem value="OTHER">Other</MenuItem>
            </Select>
            {errors.type && <FormHelperText error>{errors.type}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth className={classes.input}>
            <SearchSelect disabled={getDisableStatus()} label="Issuing state" options={getFilteredOptions(inputs.issuing_state)} inputValue={issuingState} onInput={onIssuingStateInput} onSelect={onCountrySelect('issuing_state')} />
            {errors.issuing_state && <FormHelperText error>{errors.issuing_state}</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <TraxxInput
            disabled={getDisableStatus()}
            className={classes.input}
            label="Document number (Only alphanumeric characters)"
            id="id_number"
            error={errors.id_number}
            InputProps={{}}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleDocNumChange('id_number')}
            placeholder="Document number as recorded in document"
            value={inputs.id_number}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <DatePicker
            disabled={getDisableStatus()}
            disableFuture={false || getDisableStatus()}
            className={classes.input}
            placeholder="DD-MMM-YYYY"
            error={errors.date_of_expiry}
            InputProps={{
              classes: {
                underline: classes.underline,
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            label="Date of expiry"
            onChange={handleDateChange('date_of_expiry')}
            value={inputs.date_of_expiry}
            type={'date'}
            name={'date_of_expiry'}
          />
        </Grid>
      </Fragment>
    );
  };

  const togglePassport = () => {
    setInputs({ ...inputs, screen_type: inputs.screen_type === 'passport' ? 'simple' : 'passport' });
  };
  return (
    <>
      {!full && (
        <Typography>
          If you have documents to verify, we recommend{' '}
          <u style={{ cursor: 'pointer' }} onClick={() => router.history.push('/document-verify')}>
            document verification
          </u>{' '}
          first before screening
        </Typography>
      )}
      <FormControlLabel control={<Checkbox checked={inputs.screen_type === 'passport'} onChange={togglePassport} name="rememberMe" />} className={classes.checkbox} label="Input passport/ID document detail" />
      <Grid spacing={2} container>
        <Grid item xs={12} md={6}>
          <TraxxInput
            required={true}
            className={classes.input}
            label="First Name"
            fullWidth
            error={errors.first_name}
            InputProps={{}}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange('first_name')}
            placeholder="First name as recorded in document"
            value={inputs.first_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <DatePicker
              required={false}
              error={errors.date_of_birth}
              placeholder="DD-MMM-YYYY"
              InputProps={{
                classes: {
                  underline: classes.underline,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Date of birth"
              onChange={handleDateChange('date_of_birth')}
              value={inputs.date_of_birth}
              type={'date'}
              name={'date_of_birth'}
            />
          </FormControl>
        </Grid>
        {render_passport_name_fields()}

        <Grid item xs={12} md={6}>
          <FormControl fullWidth className={classes.input}>
            <SearchSelect label="Nationality" options={getFilteredOptions(inputs.nationality)} inputValue={nationality} onInput={onNationalityInput} onSelect={onCountrySelect('nationality')} />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth className={classes.input}>
            <SearchSelect label="Place of birth" options={getFilteredOptions(inputs.country_of_birth)} inputValue={birthPlace} onInput={onBirthPlace} onSelect={onCountrySelect('country_of_birth')} />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth className={classes.input}>
            <InputLabel required shrink>
              Gender
            </InputLabel>
            <Select fullWidth required={true} onChange={handleSelect('gender')} value={inputs.gender} error={errors.gender} label="Gender">
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </Select>
            {errors.gender && <FormHelperText error>{errors.gender}</FormHelperText>}
          </FormControl>
        </Grid>
        {render_passport_fields()}
      </Grid>
    </>
  );
};

export default NormalScreening;
