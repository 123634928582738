export default {
  background: { primary: '#e5e5e5' },
  divider: '#d7d7d7',
  grey: { primary: '#c2c2c2' },
  primary: { main: '#2b2320' },
  red: { primary: '#780000' },
  secondary: { main: '#4d4643' },
  status: {
    green: '#2cc990',
    red: '#f24e4e',
    yellow: '#f2c94e',
  },
  text: {
    primary: '#2b2320',
    secondary: '#4d4643',
  },
};
