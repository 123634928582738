import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SortIcon from '@material-ui/icons/Sort';
import { SearchField, SelectButton } from 'app/layout/Dashboard/components';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  icon: { marginRight: 4 },
  searchField: { marginRight: 10 },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
}));

const FilterHeader = ({ className, onClickSort, onSearch, search }) => {
  const classes = useStyles();

  return (
    <Grid className={className} alignItems="center" container>
      <Typography className={classes.title}>Showing 5 projects</Typography>

      <Grid container item justify="flex-end" xs>
        <SearchField className={classes.searchField} onChange={onSearch} placeholder="Search group names" value={search} />
        <SelectButton onSelect={onClickSort}>
          <SortIcon className={classes.icon} />
          Sort by Date
        </SelectButton>
      </Grid>
    </Grid>
  );
};

export default FilterHeader;

FilterHeader.propTypes = {
  className: PropTypes.string,
  onClickSort: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
};

FilterHeader.defaultProps = { className: '' };
