import { Box, Link, Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataRow, MonoText } from 'app/components';
import clsx from 'clsx';
import React from 'react';
import { Link as BrowserLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    display: 'inline-flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  underline: {
    textDecoration: 'underline',
  },
}));

const PersonComp = (props) => {
  const { person, children, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      {/* <Typography className={classes.title}>Person</Typography> */}
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <DataRow type="subHeader" name="PERSON DETAIL" value={`${person?.firstname}${!!person?.middlename ? ` ${person?.middlename}` : ''}${person?.lastname ? ` ${person?.lastname}` : ''}`} />
            <DataRow name="Person ID" wrapperProps={{ underline: 'always', to: `/admin/persons/${person?.id}/detail`, component: BrowserLink }} wrapper={Link} value={<MonoText className={classes.underline}>{person?.reference}</MonoText>} />
            <DataRow name="Gender" value={person?.gender} />
            <DataRow name="Email" value={person?.primary_email} />
            <DataRow name="Phone" value={person?.primary_phone} />
            {person?.salutation && <DataRow name="Salutation" value={person?.salutation} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PersonComp;
