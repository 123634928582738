import { Card, CardContent, Container, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import { TraxxButton } from 'app/components';
import { useAsyncTask, validate } from 'app/utils';
import clsx from 'clsx';
import React, { useState } from 'react';
import { API } from 'app/services';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 0,
  },
  formGrid: {
    marginTop: theme.spacing(2),
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

const initialFormState = {
  name: {
    error: null,
    dirty: false,
    value: '',
  },
  email: {
    error: null,
    dirty: false,
    value: '',
  },
};

const formSchema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
  },
};

const UsersInvitesCreate = (props) => {
  const { children, className, onCreate, ...rest } = props;
  const [formState, setFormState] = useState(initialFormState);
  const { enqueueSnackbar } = useSnackbar();
  const [runSubmitTask, loading, submitError] = useAsyncTask('submitUsersInvitesCreate');
  const classes = useStyles();

  const validateInputs = (newFormState = formState) => {
    const errors = validate(
      {
        name: newFormState.name.value,
        email: newFormState.email.value,
      },
      formSchema
    );

    return errors;
  };

  const updateErrors = (newFormState = formState, errors = {}) => {
    Object.keys(newFormState).forEach((key) => {
      const fieldErrors = errors[key];
      newFormState[key].error = fieldErrors?.length > 0 ? fieldErrors[0] : null;
    });
    return newFormState;
  };

  const onChange = (key) => {
    return (event) => {
      const { value } = event.target;

      const newFormState = {
        ...formState,
        [key]: {
          ...formState[key],
          value,
        },
      };

      const errors = validateInputs(newFormState);
      updateErrors(newFormState, errors);

      setFormState({ ...newFormState });
    };
  };

  const onBlur = (key) => {
    return () => {
      if (formState[key].dirty) return;

      setFormState({
        ...formState,

        [key]: {
          ...formState[key],
          dirty: true,
        },
      });
    };
  };

  const onSubmit = () => {
    const errors = validateInputs(formState);
    updateErrors(formState, errors);

    Object.keys(formState).forEach((key) => {
      const formItem = formState[key];
      if (formItem.dirty) return;
      formItem.dirty = true;
    });

    if (errors) return setFormState({ ...formState });

    runSubmitTask(async () => {
      const { email, name } = formState;
      const result = await API.Users.create_invite({ name: name.value, email: email.value });

      setFormState({ ...initialFormState });

      enqueueSnackbar('Invitation sent!');

      if (typeof onCreate === 'function') onCreate(result);
    });
  };

  return (
    <Container maxWidth="sm" disableGutters {...rest} className={clsx(classes.root, className)}>
      <Card>
        <CardContent>
          <Typography variant="h6">Invite new team member</Typography>
          <Typography variant="body2">Send invite to your co-workers to collaborate on your TRAXX.ID account.</Typography>
          {submitError && (
            <Typography color="error" variant="body2">
              Submit error: {submitError.message}
            </Typography>
          )}
          <Grid container spacing={2} className={classes.formGrid}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Name"
                placeholder="Jane Doe"
                onChange={onChange('name')}
                onBlur={onBlur('name')}
                helperText={formState.name.dirty && formState.name.error}
                error={!!formState.name.error && formState.name.dirty}
                value={formState.name.value}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                placeholder="jane.doe@example.com"
                onChange={onChange('email')}
                onBlur={onBlur('email')}
                helperText={formState.email.dirty && formState.email.error}
                error={!!formState.email.error && formState.email.dirty}
                value={formState.email.value}
              />
            </Grid>
            <Grid item xs={12} className={classes.btnContainer}>
              <TraxxButton loading={loading} onSubmit={onSubmit} endIcon={<SendIcon />}>
                Send invite
              </TraxxButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default UsersInvitesCreate;
