import { get_api_path } from './api';
import { sessioned_client } from './auth';
import { store } from 'app/store';

export async function get_statistics(filter) {
  const url = get_api_path('stats/summary', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}
