import { Box, Container, Grid, Paper, Step, StepLabel, Stepper, Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WorkIcon from '@material-ui/icons/Work';
import { Title } from 'app/components';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { useRouter } from 'app/utils';
import { SelectCase, SelectDocument } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  stepper: {
    backgroundColor: 'transparent',
    '& .MuiStepConnector-vertical': {
      padding: 0,
    },
  },
}));

const steps = [
  {
    label: 'Case assignment',
    icon: WorkIcon,
  },
  {
    label: 'Documents upload',
    icon: WorkIcon,
  },
];

const DEFAULT_INPUTS = {
  type: 'id',
  caseName: null,
  newCase: true,
  caseId: null,
  params: {},
};

const NewDocumentScreening = (props) => {
  const { children, className, ...rest } = props;
  const classes = useStyles();
  const router = useRouter();
  const caseRef = new URLSearchParams(router.location.search).get('case');
  // eslint
  const [activeStep, setActiveStep] = useState(0);
  const [inputs, setInputs] = useState({
    ...DEFAULT_INPUTS,
    params: { ...DEFAULT_INPUTS.params },
  });
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (caseRef) {
      if (inputs.newCase) setInputs({ ...inputs, newCase: false });
    }
    // eslint-disable-next-line
  }, []);

  const handleNext = () => {
    setShowDialog(true);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleAgree = () => {
    setShowDialog(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Title prefix="Create verification" />

      {
        <Dialog open={showDialog} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'Document Verification Confirmation'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You will be charged <b>6 credits</b> for document verification.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleAgree} autoFocus>
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
      }

      <Container maxWidth="lg">
        <Paper>
          <Grid container>
            <Grid item xs={12} md={3}>
              <Stepper activeStep={activeStep} className={classes.stepper} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepLabel>{step.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box p={3}>
                {/* {activeStep === 0 && (
                  <SelectType
                    type={inputs.type}
                    onSelect={(type) => {
                      setInputs({ ...inputs, type });
                    }}
                    onNext={handleNext}
                  />
                )} */}
                {activeStep === 0 && (
                  <SelectCase
                    newCase={inputs.newCase}
                    caseId={inputs.caseId}
                    onToggleCase={(newCase) => setInputs({ ...inputs, newCase })}
                    caseName={inputs.caseName}
                    onSelect={(caseId) => setInputs({ ...inputs, caseId })}
                    onNameInput={(caseName) => setInputs({ ...inputs, caseName })}
                    onBack={handleBack}
                    onNext={handleNext}
                  />
                )}
                {activeStep === 1 && <SelectDocument type={inputs.type} newCase={inputs.newCase} caseId={inputs.caseId} caseName={inputs.caseName} onBack={handleBack} />}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default NewDocumentScreening;
