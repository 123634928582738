import { Card, Typography, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Breakdown from './Breakdown';
import ListItemDropdown from './ListItemDropDown';
import ViewAllButtonRow from './ViewAllButtonRow';
import { useRouter } from 'app/utils';
import { EmptyState } from 'app/components';

const useStyles = makeStyles((theme) => ({
  card: { marginTop: 20 },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  comingSoon: {
    fontSize: 18,
    fontWeight: 'bold',
    padding: 30,
  },
}));

const createOpenState = (groups) => {
  const state = {};
  // groups.forEach(({ items }) => {
  //   items.forEach(({ expand, text }) => {
  //     if (expand) {
  //       state[text] = false;
  //     }
  //   });
  // });
  return state;
};

const GroupsBreakdown = ({ className, data }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(createOpenState(data));
  const router = useRouter();

  const handleOpen = (type) => () =>
    setOpen((values) => ({
      ...values,
      [type]: !values[type],
    }));

  return (
    <div className={className}>
      <Typography className={classes.title}>Groups breakdown</Typography>

      <Card className={classes.card}>
        <List>
          {data.map(({ label, total, active, newCase, archived, id }, index) => (
            <ListItemDropdown key={label} handleOpen={handleOpen(label)} index={index} open={open[label]} results={total} text={label}>
              <Breakdown active={active} newCase={newCase} open={open[label]} archived={archived} groupId={id} />
            </ListItemDropdown>
          ))}
        </List>
        <EmptyState message={'No groups found'} active={!data.length} />

        <ViewAllButtonRow onClick={() => router.history.push('/groups')} />
      </Card>
    </div>
  );
};

export default GroupsBreakdown;

GroupsBreakdown.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      completed: PropTypes.number.isRequired,
      failed: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      pending: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
};

GroupsBreakdown.defaultProps = {
  className: '',
};
