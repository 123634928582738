import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FolderIcon from '@material-ui/icons/Folder';
import { PrivilegeGuard, WalletCard } from 'app/components';
import { StatusColors } from 'app/constants';
import { API } from 'app/services';
import { useRouter, useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CountCard, DateRange, ViewAllButtonRow } from '../components';
import { ScreeningListComp } from '../ScreeningList/components';
import { InviteDialog, RenewDialog } from './components';
import { GroupsBreakdown } from '../components';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  completedScreens: {
    paddingTop: 34,
  },
  countCards: {
    paddingTop: 27,
  },
  dateRange: { paddingTop: 25 },
  groupsBreakdown: {
    paddingTop: 34,
    width: 433,
  },
  green: { color: theme.palette.status.green },
  red: { color: theme.palette.status.red },
  yellow: { color: theme.palette.status.yellow },
  extraAction: {
    justifyContent: 'flex-end',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    display: 'inline-flex',
    alignItems: 'center',
  },
  card: { marginTop: 20 },
  container: {
    display: 'block',
  },
}));

const countCards = [
  {
    Icon: (props) => <FolderIcon color="secondary" {...props} />,
    category: 'Total cases',
    type: 'case',
    name: 'total_cases',
  },
  {
    Icon: (props) => <AssignmentTurnedInIcon color="secondary" {...props} />,
    category: 'Total results',
    type: 'screening',
    name: 'total_screens',
  },
  {
    Icon: (props) => <FiberManualRecordIcon fontSize="small" style={{ color: '#2CC990' }} {...props} />,
    category: 'Completed',
    type: 'screening',
    status: 'complete',
    name: 'completed_screens',
  },
  {
    Icon: (props) => <FiberManualRecordIcon fontSize="small" style={{ color: '#F2C94E' }} {...props} />,
    category: 'Pending',
    type: 'screening',
    status: 'screening',
    name: 'pending_screens',
  },
];

let mounted = false;

const Dashboard = (props) => {
  const { className, staticContext, ...rest } = props;
  const classes = useStyles();
  const router = useRouter();
  const [filterDates, setFilterDates] = useState(undefined);
  const token = useSelector(({ profile }) => profile.access?.token);
  const self = useSelector((state) => state.profile.workspace);
  const [errorCatcher] = useAsyncTask('stats');
  const [walletCatcher] = useAsyncTask('loadWallet');
  const loading = useSelector(({ layout }) => layout.loadingTasks);
  const [wallet, setWallet] = useState();
  const [result, setResult] = useState({});
  const [groups, setGroups] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [runGroupListing] = useAsyncTask('loadGroups');
  const { enqueueSnackbar } = useSnackbar();
  const [openRenew, setOpenRenew] = useState(false);

  const handleOpen = () => setModalOpen(true);

  const handleClose = () => setModalOpen(false);

  const handleCloseRenew = () => {
    setOpenRenew(false);
    loadWallet();
    router.history.push('/dashboard');
  };

  const extraAction = (props) => {
    return <ViewAllButtonRow onClick={() => router.history.push('/screenings')} />;
  };

  useEffect(() => {
    mounted = true;
    if (window.location.href.endsWith('ongoing-screening-renewal')) setOpenRenew(true);
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (token) {
      loadStats();
      loadWallet();
      loadGroups();
    }
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (filterDates) loadStats();
    // eslint-disable-next-line
  }, [filterDates]);

  const loadStats = () => {
    errorCatcher(async () => {
      let filter = {};
      if (filterDates && filterDates !== 'none') filter.date = filterDates;
      const result = await API.Statistics.get_statistics(filter);
      if (mounted) setResult(result);
    });
  };

  const loadWallet = () => {
    walletCatcher(async () => {
      const { wallet } = await API.Wallet.get_main_wallet();
      if (mounted) {
        setWallet(wallet);
        if (!wallet) enqueueSnackbar('No wallet found');
      }
    });
  };

  const loadGroups = () => {
    runGroupListing(async () => {
      const { models } = await API.Group.list_group();
      if (mounted) {
        setGroups(sortGroup(models));
      }
    });
  };

  const sortGroup = (models) => {
    const newModels = models.map((mod) => ({
      ...mod,
      item: {
        expand: false,
        text: mod.label,
      },
      active: mod.cases.filter((cas) => cas.status === 'active').length,
      newCase: mod.cases.filter((cas) => cas.status === 'new').length,
      archived: mod.cases.filter((cas) => cas.status === 'archived').length,
    }));
    return newModels;
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      {/* <PrivilegeGuard userRoles={['account']} excludeSuperAdmin={true}> */}
      {/* {self && (self.roles.includes('account.owner') || self.roles.includes('account.compliance_manager') || self.roles.includes('account.compliance_user') || self.roles.includes('traxx.superadmin')) && ( */}
      <PrivilegeGuard userRoles={['account', 'traxx', 'external']} excludeSuperAdmin={true}>
        {self && (
          <div>
            <DateRange
              allowKYC={self.roles.includes('account.owner') || self.roles.includes('account.compliance_manager') || self.roles.includes('account.compliance_user') || self.roles.includes('traxx.superadmin')}
              onInvite={handleOpen}
              onChange={setFilterDates}
              className={classes.dateRange}
            />

            <Grid className={classes.countCards} container spacing={2} justify="space-between" alignContent="flex-start">
              {countCards.map((cardProps, index) => (
                <Grid key={index} item xs={12} sm={6} md={3} lg={2}>
                  <CountCard {...cardProps} count={result[cardProps.name] >= 0 ? result[cardProps.name] : null} loading={loading['stats']} />
                </Grid>
              ))}
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <WalletCard wallet={wallet} loading={loading['loadWallet']} />
              </Grid>
              <Hidden mdDown>
                <Grid item lg={2} />
              </Hidden>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={8} sm={12} xs={12}>
                <Box>
                  <div className={classes.completedScreens}>
                    <Typography className={classes.title}>
                      <FiberManualRecordIcon style={{ color: StatusColors.success, marginRight: 4 }} fontSize="small" />
                      Latest completed screens
                    </Typography>
                    <ScreeningListComp full={false} className={classes.card} showPaginator={false} showCheckbox={false} ExtraAction={extraAction} limit={5} showFilter={false} status="complete" />
                  </div>

                  <div className={classes.completedScreens}>
                    <Typography className={classes.title}>
                      <FiberManualRecordIcon style={{ color: StatusColors.warning, marginRight: 4 }} fontSize="small" />
                      Latest pending screens
                    </Typography>
                    <ScreeningListComp full={false} className={classes.card} showPaginator={false} showCheckbox={false} ExtraAction={extraAction} limit={5} showFilter={false} status="screening" />
                  </div>
                </Box>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <div className={classes.completedScreens}>
                  <GroupsBreakdown data={groups} />
                </div>
              </Grid>
            </Grid>

            <InviteDialog onClose={handleClose} open={modalOpen} />
            <RenewDialog onClose={handleCloseRenew} open={openRenew} />
          </div>
        )}
      </PrivilegeGuard>
    </Box>
  );
};

export default Dashboard;
