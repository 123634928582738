import { ListingComp, ListingCompTable } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const statusOpt = {
  all: { value: '', name: 'All' },
  active: { value: 'active', name: 'Active' },
  new: { value: 'new', name: 'New' },
};

const headers = ['Owner', 'Owner ID', 'Wallet ID', 'Created date', 'Status', 'Balance'];

let mounted = false;
const WalletListComp = () => {
  // TODO
  // eslint-disable-next-line
  const [runLoadWallet] = useAsyncTask('loadWallet');
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const [listing, setListing] = useState([]);
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  const loadWallets = (newFilter = filter) => {
    setLoading(true);
    runLoadWallet(async () => {
      const { models, meta } = await API.Admin.list_wallets(newFilter);
      if (mounted) {
        // setListing(models);
        sortListingData(models);
        setFilter({ ...newFilter, count: meta.count });
        setLoading(false);
        setLoaded(true);
      }
    });
  };

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      const { id, entity, status, balance, created_at, reference } = model;
      allData.push([
        {
          label: entity[entity.owner_type]?.name || entity[entity.owner_type]?.label || '',
        },
        {
          label: entity[entity.owner_type]?.reference,
          type: 'link',
          link: `/admin/accounts/${entity[entity.owner_type]?.id}/detail`,
        },
        { label: reference, type: 'link', link: `/admin/wallets/${id}/detail` },
        { date: created_at, type: 'date' },
        { label: status, statusType: 'Wallet', type: 'status' },
        { type: 'currency', amount: balance },
      ]);
    });

    setListing(allData);
  };

  return (
    <ListingComp
      statusOpt={statusOpt}
      showFilter={false}
      enableRange={false}
      enableType={false}
      enableSort={false}
      filter={filter}
      searchByText={'Wallet ID'}
      setFilter={setFilter}
      setLoading={setLoading}
      reloadFunc={loadWallets}
      loaded={loaded}
      loading={loading || loadTask['loadWallet']}>
      {!!listing.length && <ListingCompTable headers={headers} data={listing} />}
    </ListingComp>
  );
};

export default WalletListComp;
