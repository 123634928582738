import { Box, Button, Card, Dialog, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { Alert } from '@material-ui/lab';
import { EditDialog, EmptyState, ListingComp, ListingCompTable, Title, TraxxButton, TraxxInput } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  card: {
    padding: theme.spacing(2),
    maxWidth: 500,
  },
  head: {
    fontSize: 13,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  container: {
    padding: 0,
  },
  info: {
    marginBottom: theme.spacing(1),
  },
  deleteDialog: {
    minWidth: 300,
  },
}));

const SampleView = (props) => {
  const { children, className, staticContext, ...rest } = props;
  const classes = useStyles();

  const access = useSelector((state) => state.profile.access);
  const workspace = useSelector(({ profile }) => profile.workspace);
  const self = useSelector(({ profile }) => profile.self);
  const [runLoadKeys] = useAsyncTask('loadKeys', () => {});
  const [runGenerateKey] = useAsyncTask('generateKey');
  const [runUpdateKey, updateKey] = useAsyncTask('updateKey');
  const [runRemoveKey] = useAsyncTask('removeKey');
  const [keyLabel, setKeyLabel] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [listing, setListing] = useState();
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const { enqueueSnackbar } = useSnackbar();
  const [editData, setEditData] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [keyTarget, setKeyTarget] = useState();
  const [maxKey, setMaxKey] = useState(true);

  useEffect(() => {
    if (access && access.token) {
      loadKeys();
      setUsername(self.person.primary_email);
    }
    // eslint-disable-next-line
  }, [access]);

  const handleClose = () => {
    setDialogOpen(false);
    setEditData(null);
    setKeyTarget(null);
  };

  const deleteHandleClose = () => {
    setDeleteData(null);
    setKeyTarget(null);
  };

  const configEdit = (data) => {
    return () => {
      let editArr = [[{ label: 'API Key Label', data_key: 'label', extras: {} }]];
      setEditData(editArr);
      setDialogOpen(true);
      setKeyTarget(data);
      // console.log("setting", { editArr });
    };
  };

  const configDelete = (data) => {
    return () => {
      setKeyTarget(data);
      setDeleteData(true);
    };
  };

  const loadKeys = () => {
    runLoadKeys(async () => {
      const result = await API.Users.retrieve_keys(workspace.id);
      const keys = result.models;
      const temp = keys.map((res) => {
        return [
          { label: res.label },
          { label: res.key, type: 'mono' },
          { label: res.expire_at.substring(0, 10), type: 'mono' },
          {
            type: 'custom',
            value: (
              <IconButton onClick={configEdit(res)}>
                <EditIcon />
              </IconButton>
            ),
          },
          {
            type: 'custom',
            value: (
              <IconButton onClick={configDelete(res)}>
                <CloseIcon />
              </IconButton>
            ),
          },
        ];
      });
      setListing(temp);
      if (temp.length === 3) setMaxKey(false);
      else setMaxKey(true);
    });
  };

  const generateKey = () => {
    if (!keyLabel) return enqueueSnackbar('Please enter a key label', { variant: 'error' });
    if (!password)
      return enqueueSnackbar('Please enter your password for verification', {
        variant: 'error',
      });
    runGenerateKey(async () => {
      await API.Users.generate_key(workspace.id, username, password, keyLabel);
      enqueueSnackbar('Keys created');
      setKeyLabel(null);
      setPassword(null);
      loadKeys();
    });
  };

  const onSubmit = (inputs) => {
    runUpdateKey(async () => {
      if (!inputs.label) inputs.label = keyTarget.label;
      await API.Users.update_api_key(workspace.id, keyTarget.id, inputs);
      handleClose();
      loadKeys();
    });
  };

  const onRemove = () => {
    runRemoveKey(async () => {
      enqueueSnackbar('Deleted!', { variant: 'success' });
      await API.Users.remove_api_key(workspace.id, keyTarget.id);
      loadKeys();
      setDeleteData(false);
    });
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Title prefix="API Key" />
      {maxKey && (
        <Card className={classes.card}>
          <Alert className={classes.info} severity="info">
            Generate Keys (max of 3 keys)
          </Alert>
          <TraxxInput
            label="Key Label"
            value={keyLabel}
            onChange={({ target }) => {
              setKeyLabel(target.value);
            }}></TraxxInput>
          <TraxxInput
            type="password"
            label="Password for Verification"
            value={password}
            onChange={({ target }) => {
              setPassword(target.value);
            }}></TraxxInput>
          <Box mt={2} display="flex">
            <Box flexGrow={1} />
            <TraxxButton onSubmit={generateKey}>Generate</TraxxButton>
          </Box>
        </Card>
      )}
      <Box mt={2}>
        <EmptyState message="No keys" active={!loadTask.loadKeys && !listing} />
        {!loadTask.loadKeys && listing && (
          <ListingComp filter={{ count: listing.length }} showPaginator={false} showFilter={false}>
            <ListingCompTable headers={['Label', 'Key', 'Expire On']} data={listing} />
          </ListingComp>
        )}
      </Box>
      {editData && <EditDialog loading={updateKey || loadTask.loadKeys} onSubmit={onSubmit} editData={editData} onClose={handleClose} open={editData && dialogOpen} editHeader="Edit API key" />}
      {deleteData && (
        <Dialog open={deleteData} onClose={deleteHandleClose}>
          <Box className={classes.deleteDialog}>
            <Alert severity="warning">
              <Typography variant="body1">Api Key will be deleted!</Typography>
            </Alert>
            <Box mt={1} display="flex">
              <Button onClick={() => setDeleteData(false)}>Cancel</Button>
              <Box flexGrow={2} />
              <TraxxButton onSubmit={onRemove}>Delete</TraxxButton>
            </Box>
          </Box>
        </Dialog>
      )}
    </Box>
  );
};

export default SampleView;
