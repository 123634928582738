import validate from 'validate.js';
import moment from 'moment';

validate.validators.dateCheck = function (value, options, key, attributes) {
  const date = moment(value);
  if (!value || !date.isValid()) return 'is not a valid date';
  if (!date.isBefore(options.before)) return `is not before ${moment().format(options.beforeFormat || 'YYYY-MM-DD')}`;
};

export default validate;
