import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from 'app/components';
import clsx from 'clsx';
import React from 'react';
import { OrderListing } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  card: {
    marginBottom: theme.spacing(1),
    minWidth: 300,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      '&:first-child': {
        marginRight: theme.spacing(2),
      },
    },
  },
  sameRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  addCard: {
    alignContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    height: 102,
    justifyContent: 'space-evenly',
    padding: theme.spacing(2),
    marginTop: 20,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
      // boxShadow: "0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);",
      transform: 'scale(1.1)',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  icon: {
    alignSelf: 'center',
  },
}));

const OrderHistory = (props) => {
  const { routeType = 'account', children, className, staticContext, ...rest } = props;
  const classes = useStyles();

  return (
    <Box justifyItems="center" {...rest} className={clsx(classes.root, className)}>
      <Title prefix="Order history" />
      <OrderListing routeType={routeType} />
    </Box>
  );
};

export default OrderHistory;
