import { Box, Card, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Listing, ListingCompTable, Title } from 'app/components';
import { useAsyncTask, snakeToTitle } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { API } from 'app/services';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  filters: {
    paddingTop: 17,
  },
  card: {
    marginTop: 10,
    width: '100%',
  },
}));

const headers = ['User', 'User ID', 'Email', 'Roles', 'Status'];

const UserList = (props) => {
  const { children, className, staticContext, ...rest } = props;
  const classes = useStyles();
  const account_owner_id = useSelector(({ profile }) => profile.workspace?.entity?.owner_id);
  const token = useSelector(({ profile }) => profile.access?.token);
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const [queryMeta, setQueryMeta] = useState({ count: 0 });
  const [runReloadTask, loading] = useAsyncTask('loadAccountUserListing');
  const [listing, setListing] = useState([]);

  useEffect(() => {
    if (token && account_owner_id) onReload();
    // eslint-disable-next-line
  }, [token, filter, account_owner_id]);

  const onReload = () => {
    runReloadTask(async () => {
      const { models, meta } = await API.Users.list_users(filter);
      setQueryMeta({ count: meta.count });
      sortListingData(models);
    });
  };

  const onPageChange = (newFilter) => {
    if (newFilter.offset !== filter.offset) setFilter({ ...newFilter });
  };

  const getRoles = (model) => {
    const { entity, roles } = model;
    if (account_owner_id && entity.owner_id === account_owner_id) {
      return <Typography>Owner</Typography>;
    } else {
      // roles.splice(roles.indexOf('account.owner'), 1);
      return (
        <>
          {roles.map((role, index) => (
            <>
              <Typography>
                {snakeToTitle(role.split(".")[1])}
                {index < roles.length - 1 && ', '}
              </Typography>
            </>
          ))}
        </>
      );
    }
  };

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      allData.push([
        { label: model.entity?.person?.firstname },
        { label: model.entity?.person?.reference, type: 'link', link: `/app/users/${model.id}/detail` },
        { label: model.entity?.person?.primary_email },
        { type: 'custom', value: getRoles(model) },
        { label: model.status, type: 'status', statusType: 'User' },
      ]);
    });

    setListing(allData);
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Title prefix="Users" />
      <Card className={classes.card}>
        <LinearProgress hidden={!loading} />
        <Listing filter={{ ...filter, ...queryMeta }} loading={loading} onPageChange={onPageChange}>
          {!!listing.length && <ListingCompTable headers={headers} data={listing} />}
        </Listing>
      </Card>
    </Box>
  );
};

export default UserList;
