import { Box, Button, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton } from 'app/components';
import clsx from 'clsx';
import React from 'react';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 360,
  },
  fieldMargin: {
    marginBottom: theme.spacing(2),
  },
}));

const InvalidateCredit = (props) => {
  const { credit, walletId, handleClose, open, loading, onReload, className, ...rest } = props;
  const classes = useStyles();
  const [runInvalidateCredit] = useAsyncTask('invalidateCredit');
  const loadTasks = useSelector(({ layout }) => layout.loadingTasks);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = () => {
    runInvalidateCredit(async () => {
      await API.Admin.invalidate_credit(walletId, credit.id);
      enqueueSnackbar('Credit invalidated');
      if (typeof onReload === 'function') onReload();
      handleClose();
    });
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <Box padding={2}>
        <Alert severity="warning">Credits will be invalidated!</Alert>
        <Box mt={2} display="flex" {...rest} className={clsx(classes.root, className)}>
          <Button disabled={loadTasks.invalidateCredit} onClick={handleClose}>
            Cancel
          </Button>
          <Box flexGrow={1} />
          <TraxxButton loading={loadTasks.invalidateCredit} onSubmit={onSubmit}>
            Invalidate
          </TraxxButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default InvalidateCredit;
