import { LocalStorageKeys, Paths } from 'app/constants';
import { API } from 'app/services';
import store, { actions } from 'app/store';
import { useAsyncTask, useRouter } from 'app/utils';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Process to verify if existing access token is valid to transit to dashboard page
 *
 */
const LoginButler = (props) => {
  const { self, access } = useSelector(({ profile }) => profile);
  const [asyncTaskRunner, loading, error] = useAsyncTask('loadProfile'); // eslint-disable-line
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    let expiryCheck = null;
    if (access && access.expires_at) {
      expiryCheck = setInterval(() => {
        let expired = moment().isAfter(access.expires_at * 1000);
        if (expired) {
          clearInterval(expiryCheck);
          localStorage.removeItem(LocalStorageKeys.AccessToken);
          store.dispatch(
            actions.Profile.updateSession({
              access: null,
              self: null,
              accounts: null,
              workspace: null,
              expired: true,
              lastRoute: router.location.pathname,
            })
          );
          router.history.push(Paths.Auth.Expired);
        }
      }, process.env.REACT_APP_EXPIRY_INTERVAL || 5000);
    }

    return () => expiryCheck && clearInterval(expiryCheck);

    // eslint-disable-next-line
  }, [access, router.location.pathname]);

  useEffect(() => {
    const savedAccessToken = localStorage.getItem(LocalStorageKeys.AccessToken);

    if (!self && savedAccessToken) {
      const access = JSON.parse(savedAccessToken);
      asyncTaskRunner(async () => {
        const result = await API.Auth.retrieve_profile({ token: access.token, access });
        dispatch(
          actions.Profile.updateSession({
            self: result.self,
            wallet: result.wallet,
            access,
            expired: false,
            workspace: result.workspace,
            accounts: result.accounts,
            lastRoute: null,
          })
        );
      });
    }
    if (!savedAccessToken) {
      dispatch(actions.Profile.init(true));
    }

    // eslint-disable-next-line
  }, []);

  return null;
};

export default LoginButler;
