import { Button, CircularProgress, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles(({ palette, typography }) => ({
  button: {
    backgroundColor: palette.common.white,
    border: `1px solid ${palette.divider}`,
    borderRadius: 6,
    color: palette.secondary.main,
    fontWeight: 'normal',
    padding: 7,
    textTransform: 'none',
    width: ({ large }) => (large ? 180 : 150),
  },
  endIcon: {
    color: palette.grey.primary,
    fontSize: 24,
    marginLeft: 0,
  },
  label: { justifyContent: 'space-between' },
  menuItem: { fontSize: typography.body2.fontSize },
}));

/**
 * Button input drop down list selector
 *
 * Props:
 ** children: child component as button
 ** className: name of css class element
 */
const SelectButton = ({ children, className, disabled, large, loading, onSelect, options, ...props }) => {
  const classes = useStyles({ large });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleSelect = (value) => () => {
    onSelect(value);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={clsx(classes.button, className)}
        classes={{
          label: classes.label,
          endIcon: classes.endIcon,
        }}
        disabled={loading || disabled}
        disableElevation
        endIcon={!!anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={handleClick}
        variant="contained"
        {...props}>
        {loading ? <CircularProgress value={15} size={15} /> : children}
      </Button>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        getContentAnchorEl={null}
        onClose={handleClose}
        open={!!anchorEl}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}>
        {options.map((option) => (
          <MenuItem key={option.value} className={classes.menuItem} onClick={handleSelect(option)}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default SelectButton;

SelectButton.propTypes = {
  children: PropTypes.node.isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  loading: PropTypes.bool,
};

SelectButton.defaultProps = {
  className: '',
  disabled: false,
  large: false,
  loading: false,
  options: [],
};
