import MuiButton from './MuiButton';
import MuiInputBase from './MuiInputBase';
import MuiLink from './MuiLink';
import MuiPaper from './MuiPaper';

export default {
  MuiButton,
  MuiInputBase,
  MuiLink,
  MuiPaper,
};
