import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ListingComp, ListingCompTable } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    marginTop: theme.spacing(1),
  },
}));

const headers = ['Verification ID', 'Case', 'Name', 'Email', 'Created date', 'Status'];

const filterOptions = {
  sort: true,
  search: true,
  dateRange: true,
  type: false,
  status: true,
  clear: true,
};

const extraOptions = {
  status: {
    all: { value: '', name: 'All' },
    completed: { value: 'completed', name: 'Completed' },
    submitted: { value: 'submitted', name: 'Submitted' },
    open: { value: 'open', name: 'Open' },
    disabled: { value: 'disabled', name: 'Disabled' },
  },
};

let mounted = false;
const VerificationListComp = (props) => {
  const {
    status,
    showPaginator = true,
    // eslint-disable-next-line no-unused-vars
    showCheckbox = true,
    showFilter = true,
    // eslint-disable-next-line no-unused-vars
    full = true,
    limit = 10,
    ExtraAction,
    children,
    className,
    routeType = 'account',
    ...rest
  } = props;
  const classes = useStyles();
  const [runLoadScreening] = useAsyncTask('loadScreening');
  const [screenings, setScreenings] = useState([]);
  const [filter, setFilter] = useState({ limit, offset: 0 });
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const loadScreeningList = (newFilter = filter) => {
    setLoading(true);
    if (!newFilter.status && status) newFilter['status'] = status;
    runLoadScreening(async () => {
      const loader = routeType === 'account' ? API.Users.list_verification_invites : API.Admin.list_verification_invites;
      const { models, meta } = await loader(newFilter);
      if (mounted) {
        sortListingData(models);
        setFilter({ ...newFilter, count: meta.count });
        setLoading(false);
        setLoaded(true);
      }
    });
  };

  const getCaseDetailPath = (id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/screenings/cases/${id}/detail`;
      default:
        return `/screenings/cases/${id}/detail`;
    }
  };

  const getVerificationDetailPath = (id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/verifications/${id}/detail`;
      default:
        return `/screenings/verifications/${id}/detail`;
    }
  };

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      allData.push([
        { label: model.reference, type: 'link', link: getVerificationDetailPath(model.id) },
        { label: model.case?.label, type: 'labelwithlink', link: getCaseDetailPath(model.case?.id), bottomLabel: model.case?.reference },
        { label: model.target_name },
        { label: model.target_email },
        { date: model.created_at, type: 'date' },
        { label: model.status, statusType: 'Verification', type: 'status' },
      ]);
    });

    setScreenings(allData);
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <ListingComp
        filter={filter}
        setFilter={setFilter}
        showFilter={showFilter}
        ExtraAction={ExtraAction}
        loaded={loaded}
        filterOptions={filterOptions}
        extraOptions={extraOptions}
        showPaginator={showPaginator}
        loading={loading || loadTask.loadScreening}
        reloadFunc={loadScreeningList}
        setLoading={setLoading}
        searchByText={'Verification ID or Name'}
        sortKey="created_at"
        dateKey="created_at">
        {screenings.length > 0 && <ListingCompTable headers={headers} data={screenings} />}
      </ListingComp>
    </Box>
  );
};

export default VerificationListComp;
