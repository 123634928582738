import { Box, Grid, LinearProgress, Link, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Card, CardHeader, Divider, CardContent, Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataRow, DateDisplay, EditDialog, MonoText, StatusLabel, Title, Crumbs, TraxxButton } from 'app/components';
import { StatusMaps } from 'app/constants';
import { API } from 'app/services';
import { snakeToTitle, useAsyncTask, useRouter, useToastCatcher } from 'app/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as BrowserLink } from 'react-router-dom';
// import EditIcon from "@material-ui/icons/Edit";
import { ImageHolder } from './components';

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  root: {
    padding: spacing(3, 3, 8),
    [breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
    [breakpoints.down('xs')]: {
      padding: spacing(1, 1, 3),
    },
  },
  card: {
    width: '100%',
  },
  table: {},
  head: {
    fontWeight: 'bold',
  },
  button: {
    marginTop: 10,
    width: '100%',
  },
  spaceTop: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  buttonMargin: {
    marginRight: spacing(2),
  },
  text: {
    whiteSpace: 'no-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

let mounted = false;
function VerificationDetail({ routeType = 'account' }) {
  const classes = useStyles();
  const [detail, setDetail] = useState(null);
  const loading = useSelector((state) => state.layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const [toaster, errorCatcher] = useToastCatcher({ errorParser: null, taskname: 'getInviteDetail' });
  const [runVerifyRequest] = useAsyncTask('verifyRequest');
  const router = useRouter();
  const { match } = router;
  const { params } = match;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editData, setEditData] = useState();

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) getInviteDetail();
    // eslint-disable-next-line
  }, [access]);

  const getInviteDetail = () => {
    if (!params.request_id) return toaster({ message: 'invalid id' });
    errorCatcher(async () => {
      const loader = routeType === 'account' ? API.Users.get_verification_invites : API.Admin.get_verification_request_detail;
      const { model } = await loader(params.request_id);

      if (mounted) {
        setDetail(model);
        configureEdit(model);
      }
    });
  };

  // const handleOpen = () => {
  //   setDialogOpen(true);
  // };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const setEditContent = (label, data_key, initial_value, extras = {}) => {
    return {
      label,
      data_key,
      extras,
    };
  };

  const onSubmit = (inputs) => {
    // console.log({ inputs });
  };

  const configureEdit = (model) => {
    let editArr = [
      [setEditContent('Email', 'target_email', model.target_email), setEditContent('Token', 'token', model.token)],
      [
        setEditContent('Document upload', 'doc_required', model.doc_required, {
          type: 'select',
          options: [
            { value: 'optional', label: 'Optional' },
            { value: 'required', label: 'Required' },
            { value: 'hidden', label: 'Hidden' },
          ],
        }),
      ],
    ];
    setEditData(editArr);
  };

  const verifyRequest = () => {
    runVerifyRequest(async () => {
      const { model } = await API.Users.verify_request(params.request_id);
      if (model) {
        router.history.push(`/documents/${model.id}/detail`);
      }
    });
  };

  return (
    <Box className={classes.root}>
      <Crumbs current="Detail" links={[{ text: 'Verifications', href: routeType === 'admin' ? '/admin/verifications' : '/screenings/verifications' }]} />
      <Title prefix={'Verification request detail'} />
      <Grid container spacing={2} item>
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper} className={classes.table}>
            {loading['getInviteDetail'] && <LinearProgress size={30} />}
            {!loading['getInviteDetail'] && detail && (
              <Table>
                <TableBody>
                  <DataRow name="Verification ID" value={detail.reference} />
                  <DataRow
                    name="Case ID"
                    value={
                      <Link underline="always" component={BrowserLink} to={`/screenings/cases/${detail.case?.id}/detail`}>
                        <MonoText>{detail.case?.reference}</MonoText>
                      </Link>
                    }
                  />
                  <DataRow name="Status" value={<StatusLabel variantMap={StatusMaps.Verification}>{detail.status}</StatusLabel>} />
                  <DataRow name="Created date" value={<DateDisplay date={detail.created_at} />} />

                  <DataRow name="Email" value={<DateDisplay date={detail.target_email} />} />
                  <DataRow name="Document upload" value={<DateDisplay date={detail.doc_required} />} />
                  <DataRow name="Live document upload" value={<DateDisplay date={detail.doc_force_live} />} />
                  <DataRow name="Selfie upload" value={<DateDisplay date={detail.selfie_required} />} />
                  <DataRow name="Token" value={<DateDisplay date={detail.token} />} />
                  {/* <DataRow name="" value={(
                    <Chip
                      icon={<EditIcon />}
                      onClick={() => handleOpen()}
                      label="Edit"
                    />
                  )} /> */}
                  {detail.status === 'submitted' && routeType === 'account' && (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <TraxxButton loading={loading.verifyRequest} onSubmit={() => verifyRequest()} fullWidth>
                          Verify request
                        </TraxxButton>
                      </TableCell>
                    </TableRow>
                  )}
                  {detail.status === 'completed' && detail.verification && (
                    <DataRow
                      name="Document ID"
                      value={
                        <Link underline="always" component={BrowserLink} to={`/documents/${detail.verification.id}/detail`}>
                          <MonoText>{detail.verification.reference}</MonoText>
                        </Link>
                      }
                    />
                  )}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Grid>
        {detail && detail.documents.length > 0 && (
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardHeader title="Submitted documents" />
              <Divider />
              <CardContent>
                <Grid container spacing={2} item xs={12} md={12}>
                  {detail.documents.map((doc) => (
                    <Grid item lg={4} md={4} xs={4}>
                      <Tooltip title={doc.name}>
                        <Typography className={classes.text}>{doc.name}</Typography>
                      </Tooltip>
                      <ImageHolder imgSrc={doc.uri} title={snakeToTitle(doc.name, true)} />
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
            {detail.status === 'completed' && detail.verification && (
              <TraxxButton className={classes.button} fullWidth onSubmit={() => router.history.push(`/documents/${detail.verification.id}/detail`)}>
                Proceed to result
              </TraxxButton>
            )}
          </Grid>
        )}
      </Grid>
      {detail && editData && <EditDialog loading={loading['getInviteDetail']} onSubmit={onSubmit} editData={editData} onClose={handleClose} open={dialogOpen} editHeader="Edit verification request" underConstruction={true} />}
    </Box>
  );
}

export default VerificationDetail;
