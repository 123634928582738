import { Grid, useMediaQuery } from '@material-ui/core';
import { GlobalRegex } from 'app/constants';
import { API } from 'app/services';
import { useToastCatcher } from 'app/utils';
import React, { useState } from 'react';
import { SignUpView, SuccessView } from './components';

const init_inputs = {
  countryCode: '65',
  company: '',
  email: '',
  name: '',
  password: '',
  phoneNumber: '',
  inviteToken: '',
};

const init_errors = {
  countryCode: '65',
  company: '',
  email: '',
  name: '',
  password: '',
  phoneNumber: '',
};

function SignUp() {
  const matches = useMediaQuery(({ breakpoints }) => breakpoints.up('md'));

  const [errors, setErrors] = useState(init_errors);
  const [inputs, setInputs] = useState(init_inputs);
  const [email, setEmail] = useState(null);
  const [success, setSuccess] = useState(false);
  const errorParser = (error) => {
    let message = 'Error occurred!';
    if (error.message === 'access_handle conflict') message = 'Email exist';
    return message;
  };
  const [toaster, errorCatcher] = useToastCatcher({ errorParser, taskname: 'signup' });

  const validateInput = () => {
    const { email, name, password, phoneNumber, company, inviteToken } = inputs;
    const error = {};
    if (!inviteToken && !company) error.company = 'please enter the company name';
    if (!GlobalRegex.EmailValidation.test(email)) error.email = 'please enter a valid email';
    if (!name) error.name = 'please enter the name';
    if (!password || password.length < 8) error.password = 'minimum 8 character';
    if (!phoneNumber || phoneNumber.length < 7) error.phoneNumber = 'please enter a valid phone number';
    return error;
  };

  const onSignup = (event) => {
    event.preventDefault();

    let error = validateInput();
    setErrors({ ...init_errors, ...error });
    if (Object.keys(error).length > 0) {
      toaster({ message: 'invalid input' }, { autoHideDuration: 2000 });
      return;
    }

    errorCatcher(async () => {
      const { email, name, password, phoneNumber, countryCode, company, inviteToken } = inputs;
      await API.Auth.register({
        user: {
          email,
          name,
          password,
          phone: countryCode + phoneNumber,
          username: email,
        },
        account: {
          ...(!!inviteToken && {
            token: inviteToken,
          }),
          ...(!inviteToken && {
            name: company,
          }),
        },
      });
      setEmail(email);
      setSuccess(true);
      toaster('Registration is successful!', { variant: 'success' });
    }, 'signup');
  };

  return (
    <Grid container item direction={matches ? 'row' : 'column'} alignItems="center" justify="center" xs>
      {!success && <SignUpView inputs={inputs} setInputs={setInputs} errors={errors} setErrors={setErrors} onSignup={onSignup} setSuccess={setSuccess} />}

      {success && <SuccessView email={email} />}
    </Grid>
  );
}

export default SignUp;
