import { Card, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import TitleField from '../TitleField';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: '100%',
  },
  imageContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    flexDirection: 'row',
  },
  image: {
    padding: theme.spacing(1),
    objectFit: 'contain',
    width: 200,
  },
}));

const ImageField = (props) => {
  const { images = [], className, ...rest } = props;
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.card, className)}>
      <TitleField>Uploaded Document</TitleField>
      <Box classes={classes.imageContainer}>
        {images.map((image) => (
          <img alt="uploaded" src={image.Uri} className={classes.image} />
        ))}
      </Box>
    </Card>
  );
};

export default ImageField;
