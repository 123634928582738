import { Box, Button, Chip, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert } from '@material-ui/lab';
import { DataRow, EditDialog, MonoText, StatusLabel, TabComp, TraxxButton, TraxxInput, Title, Crumbs } from 'app/components';
import { StatusMaps } from 'app/constants';
import { API } from 'app/services';
import { snakeToTitle, useAsyncTask, useRouter } from 'app/utils';
import EventLogListComp from 'app/views/EventLog/EventLogList/components/EventLogListComp';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AccountListComp from '../AccountListComp';
import { AddAccount } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  table: {
    borderRadius: 5,
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',
  },
  head: {
    fontWeight: 'bold',
  },
  summaryHeader: {
    width: '100%',
    fontWeight: 'bold',
  },
  createAcc: {
    width: '100%',
    flexDirection: 'column',
  },
  alignRight: {
    textAlign: 'right',
  },
  noShadow: {
    boxShadow: '',
  },
  chips: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

let mounted = false;
function PersonDetailComp() {
  const classes = useStyles();
  const [person, setPerson] = useState(null);
  const loading = useSelector((state) => state.layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const [runPersonDetail] = useAsyncTask('personDetail');
  const [runCreateAccount] = useAsyncTask('createAccount');
  const [runUpdateDetail] = useAsyncTask('updateDetail');
  const [runSuspensePerson] = useAsyncTask('suspensePerson');
  const router = useRouter();
  const { match } = router;
  const { params } = match;
  const [allAccounts, setAllAccounts] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({ name: '', owner_id: params.person_id });
  const [error] = useState('');
  const [preview, setPreview] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editData, setEditData] = useState();
  const [suspenseCheck, setSuspenseCheck] = useState(false);

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) getPerson();

    // eslint-disable-next-line
  }, [access]);

  const createAccount = () => {
    runCreateAccount(async () => {
      if (!inputs.name) {
        enqueueSnackbar('Please input a account name', { variant: 'error' });
        setPreview(false);
      }

      const { model } = await API.Admin.create_account(inputs);
      if (mounted) {
        enqueueSnackbar('Account created', { variant: 'success' });

        router.history.push(`/admin/accounts/${model.id}/detail`);
      }
    });
  };

  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const setEditContent = (label, data_key, initial_value, extras = {}) => {
    return {
      label,
      data_key,
      extras,
    };
  };

  const onSubmit = (inputs) => {
    runUpdateDetail(async () => {
      await API.Admin.update_person(params.person_id, inputs);
      if (mounted) {
        handleClose();
        getPerson();
      }
    });
  };

  const configureEdit = (model) => {
    let editArr = [
      [
        setEditContent('Name', 'firstname', model.firstname),
        setEditContent('Salutation', 'salutation', model.salutation, {
          type: 'select',
          options: [
            { value: 'Mr.', label: 'Mr.' },
            { value: 'Ms.', label: 'Ms.' },
            { value: 'Mrs.', label: 'Mrs.' },
            { value: 'Mdm.', label: 'Mdm.' },
            { value: 'Dr.', label: 'Dr.' },
          ],
        }),
      ],
      [
        setEditContent('Gender', 'gender', model.gender, {
          type: 'select',
          options: [
            { value: 'male', label: 'Male' },
            { value: 'female', label: 'Female' },
          ],
        }),
        setEditContent('Phone', 'phone', model.phone),
      ],
      [setEditContent('Email', 'email', model.email)],
    ];
    setEditData(editArr);
  };

  const getPerson = () => {
    if (!params.person_id) return enqueueSnackbar('invalid id', { variant: 'error' });
    runPersonDetail(async () => {
      const { model } = await API.Admin.get_person(params.person_id);
      if (mounted) {
        setPerson(model);
        configureEdit(model);
      }
    });
  };

  const loadAllAccount = (models) => {
    let allArr = [];
    models.forEach((acc) => {
      allArr.push(acc.account_id);
    });
    setAllAccounts(allArr);
  };

  const suspensePerson = () => {
    runSuspensePerson(async () => {
      await API.Admin.update_person_status(params.person_id, { status: person?.status === 'suspended' ? 'Active' : 'Suspended' });
      enqueueSnackbar('Person suspended', { variant: 'success' });
      setSuspenseCheck(false);
      getPerson();
    });
  };

  return (
    <>
      <Crumbs current="Detail" links={[{ text: 'Persons', href: '/admin/persons' }]} />
      <Title prefix={'Person detail'} />
      {loading.personDetail && <LinearProgress size={30} />}
      {!loading.personDetail && person && (
        <Grid className={classes.root} container spacing={2}>
          <Grid item md={4} xs={12}>
            <TableContainer className={classes.table} component={Paper}>
              <Table>
                <TableBody>
                  <DataRow name={'Name'} value={person.firstname} />
                  <DataRow name={'Type'} value={snakeToTitle(person.entity.owner_type)} />
                  <DataRow name={'Email'} value={person.primary_email} />
                  <DataRow name={'Phone'} value={person.primary_phone} />
                  <DataRow name={'Gender'} value={snakeToTitle(person.gender)} />
                  <DataRow name={'Person ID'} value={<MonoText>{person.reference}</MonoText>} />
                  <DataRow name={'Salutation'} value={person.salutation} />
                  <DataRow name={'Roles'} value={person.entity.roles.join(', ')} />
                  {person.status && <DataRow name="Status" value={<StatusLabel variantMap={StatusMaps.Account}>{person.status}</StatusLabel>} />}
                </TableBody>
                <TableFooter padding={0}>
                  {/* <DataRow name={""} value={} /> */}
                  {!suspenseCheck && (
                    <TableRow>
                      <TableCell className={classes.alignRight} colSpan={2}>
                        <Chip icon={<EditIcon fontSize="small" />} className={classes.chips} onClick={() => router.history.push(`/admin/persons/${person.id}/privilege/edit`)} label="Privilege" />
                        <Chip icon={<EditIcon fontSize="small" />} className={classes.chips} onClick={() => handleOpen()} label="Edit" />
                        <Chip icon={<EditIcon fontSize="small" />} className={classes.chips} onClick={() => setSuspenseCheck(true)} label={person?.status === 'suspended' ? 'Activate person account' : 'Suspend Person'} />
                      </TableCell>
                    </TableRow>
                  )}
                  {suspenseCheck && (
                    <TableRow>
                      <TableCell className={classes.alignRight} colSpan={2}>
                        <Alert severity="warning">This person will be {person?.status === 'suspended' ? 'activated' : 'suspended'}</Alert>
                        <Box mt={1} display="flex">
                          <Button disabled={loading.suspensePerson} onClick={() => setSuspenseCheck(false)}>
                            Cancel
                          </Button>
                          <Box flexGrow={1} />
                          <TraxxButton loading={loading.suspensePerson} onSubmit={() => suspensePerson()}>
                            Confirm
                          </TraxxButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan={2} padding={0}>
                      <ExpansionPanel elevation={0}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography className={classes.summaryHeader} color="primary" variant="body1">
                            Create new account
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.detail}>
                          {!preview && (
                            <div className={classes.createAcc}>
                              <TraxxInput
                                label="Account name"
                                required={true}
                                error={error}
                                InputProps={{}}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={({ target }) => {
                                  setInputs({ ...inputs, name: target.value });
                                }}
                                placeholder="Name for new account"
                                value={inputs.name}
                              />

                              <Box mt={1} display="flex">
                                <Box flexGrow={1} />
                                <TraxxButton disabled={!inputs.name} onSubmit={() => setPreview(true)}>
                                  confirm
                                </TraxxButton>
                              </Box>
                            </div>
                          )}
                          {preview && (
                            <div className={classes.createAcc}>
                              <Alert severity="info">
                                <Typography variant="body1">
                                  Account <u>{inputs.name}</u> will be created!
                                </Typography>
                                <Typography variant="body1">
                                  <u>{person.firstname}</u> will be the account owner.
                                </Typography>
                              </Alert>
                              <Box mt={1} display="flex">
                                <Button disabled={loading.createAccount} onClick={() => setPreview(false)}>
                                  Cancel
                                </Button>
                                <Box flexGrow={1} />
                                <TraxxButton loading={loading.createAccount} onSubmit={() => createAccount()}>
                                  Create
                                </TraxxButton>
                              </Box>
                            </div>
                          )}
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item lg={8} xl={8} md={8} xs={12}>
            <TabComp
              tabTitle="Person Tabs"
              tabHeaders={['Accounts', 'Event Logs', 'Add Account']}
              tabContent={[
                <AccountListComp onLoad={loadAllAccount} entityId={person.entity.id} />,
                <EventLogListComp routeType="admin" actor={person.entity.id} />,
                <AddAccount onCreate={getPerson} entityId={person.entity.id} userAcc={allAccounts} />,
              ]}
            />
          </Grid>
        </Grid>
      )}
      {person && editData && <EditDialog loading={loading['getInviteDetail']} onSubmit={onSubmit} editData={editData} onClose={handleClose} open={dialogOpen} editHeader="Edit person" />}
    </>
  );
}

export default PersonDetailComp;
