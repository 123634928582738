import { Box, Button, Chip, Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert } from '@material-ui/lab';
import { DataRow, MonoText, TraxxButton } from 'app/components';
import { API } from 'app/services';
import { snakeToTitle, useAsyncTask, useMoneyFormatter, useRouter } from 'app/utils';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: spacing(2),
  },
  table: {
    borderRadius: 5,
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',
  },
  head: {
    fontWeight: 'bold',
  },
  summaryHeader: {
    width: '100%',
  },
  createAcc: {
    width: '100%',
    flexDirection: 'column',
  },
  alignRight: {
    textAlign: 'right',
  },
  noShadow: {
    boxShadow: '',
  },
}));

let mounted = false;
function PersonDetailComp() {
  const classes = useStyles();
  const [product, setProduct] = useState(null);
  const loading = useSelector((state) => state.layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const [runProductDetail] = useAsyncTask('productDetail');

  const [runDeleteProduct] = useAsyncTask('deleteProduct');
  const router = useRouter();
  const { match } = router;
  const { params } = match;
  const { enqueueSnackbar } = useSnackbar();
  // const [inputs, setInputs] = useState({ name: "", owner_id: params.person_id });
  const [preview, setPreview] = useState(false);
  // const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) getDetail();
    // eslint-disable-next-line
  }, [access]);

  const getDetail = () => {
    if (!params.product_id) return enqueueSnackbar('invalid id', { variant: 'error' });
    runProductDetail(async () => {
      const { model } = await API.Admin.get_product_detail(params.product_id);
      if (mounted) setProduct(model);
    });
  };

  const deleteProduct = () => {
    runDeleteProduct(async () => {
      await API.Admin.delete_product(params.product_id);
      enqueueSnackbar('Deleted!', { variant: 'success' });
      router.history.push('/admin/products');
    });
  };

  const CurrencyWraper = ({ currency, amount, showCurrency = false }) => {
    const formatter = useMoneyFormatter({ currency, decPlaces: 0, showCurrency });

    return <MonoText>{formatter(amount)}</MonoText>;
  };

  return (
    <>
      {loading.productDetail && loading.deleteProduct && <LinearProgress size={30} />}
      {!loading.productDetail && !loading.deleteProduct && product && (
        <Grid className={classes.root} container spacing={2}>
          <Grid item md={4} xs={12}>
            <TableContainer className={classes.table} component={Paper}>
              <Table>
                <TableBody>
                  <DataRow name="Name" value={product.name} />
                  <DataRow name="Price" value={<CurrencyWraper currency={product.price_currency} showCurrency={true} amount={product.price} />} />
                  <DataRow name="Credit" value={<CurrencyWraper currency={'TXC'} amount={product.extras0} />} />
                  <DataRow name="SKU" value={product.sku} />
                  <DataRow name="Type" value={snakeToTitle(product.type)} />
                </TableBody>
                <TableFooter padding={0}>
                  <TableRow>
                    <TableCell className={classes.alignRight} colSpan={2}>
                      {!preview && (
                        <Box display="flex">
                          <Chip color="secondary" clickable icon={<DeleteIcon color="error" />} onClick={() => setPreview(true)} label="Remove" />
                          <Box flexGrow={1} />
                          {/* <Chip
                                                icon={<EditIcon />}
                                                onClick={() =>
                                                  router.history.push(`/admin/persons/${product.id}/privilege/edit`)
                                                }
                                                label="Edit"
                                              /> */}
                        </Box>
                      )}
                      {preview && (
                        <>
                          <Alert severity="warning">
                            <Typography variant="body1">Package will be deleted!</Typography>
                          </Alert>
                          <Box mt={1} display="flex">
                            <Button disabled={loading.deleteProduct} onClick={() => setPreview(false)}>
                              Cancel
                            </Button>
                            <Box flexGrow={1} />
                            <TraxxButton loading={loading.deleteProduct} onSubmit={deleteProduct}>
                              Delete
                            </TraxxButton>
                          </Box>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default PersonDetailComp;
