import { ListingComp, ListingCompTable } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const headers = ['Name', 'Price', 'Credit', 'SKU'];

const filterOptions = {
  sort: false,
  search: true,
  dateRange: false,
  type: false,
  status: false,
  clear: true,
};

const extraOptions = {};

let mounted = false;
function ProductListComp({ className }) {
  const [products, setProducts] = useState([]);
  const loadTask = useSelector((state) => state.layout.loadingTasks);
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const [loading, setLoading] = useState(false);
  const [errorCatcher] = useAsyncTask('productList');

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const listProduct = (newFilter = filter) => {
    setLoading(true);
    errorCatcher(async () => {
      const { models, meta } = await API.Admin.list_products(newFilter);
      if (mounted) {
        setFilter({ ...newFilter, count: meta.count });
        setLoading(false);
        sortListingData(models);
      }
    });
  };

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      allData.push([
        { label: model.name, type: 'labelwithlink', bottomLabel: model.reference, link: `/admin/products/${model.id}/detail` },
        { currency: model.price_currency, amount: model.price, type: 'currency' },
        { currency: 'TXC', amount: model.extras0, type: 'currency' },
        { label: model.sku },
      ]);
    });

    setProducts(allData);
  };

  return (
    <ListingComp filter={filter} filterOptions={filterOptions} extraOptions={extraOptions} reloadFunc={listProduct} setFilter={setFilter} searchByText={'Product Name'} loading={loadTask.productList || loading} setLoading={setLoading}>
      {products.length > 0 && <ListingCompTable headers={headers} data={products} />}
    </ListingComp>
  );
}

export default ProductListComp;
