import { Card, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton, TraxxInput } from 'app/components';
import VisibilityAdornment from 'app/components/TraxxInput/components';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 761,
    justifyContent: 'space-between',
    padding: 40,
    width: 410,
  },
  image: {
    height: 200,
    width: 200,
  },
  link: { alignSelf: 'flex-start' },
  name: { display: 'none' },
}));

function RequestView(props) {
  const classes = useStyles();

  const { user, loading, handleChange, resetPassword, inputs, errors, visibility, handleVisibility } = props;
  // Added name input for accessbility
  const inputProps = [
    {
      id: 'ResetPassword-name',
      autoComplete: 'name',
      className: classes.name,
      label: 'Name',
      onChange: () => {},
      placeholder: 'Full name',
      value: '',
    },
    {
      id: 'ResetPassword-newPassword',
      autoComplete: 'new-password',
      error: errors.newPassword,
      InputProps: {
        endAdornment: <VisibilityAdornment onClick={handleVisibility('newPassword')} />,
      },
      label: 'New Password',
      onChange: handleChange('newPassword'),
      placeholder: 'Minimum 8 characters',
      type: visibility.newPassword ? 'text' : 'password',
      value: inputs.newPassword,
    },
    {
      id: 'ResetPassword-confirmPassword',
      autoComplete: 'confirm-password',
      error: errors.confirmPassword,
      InputProps: {
        endAdornment: <VisibilityAdornment onClick={handleVisibility('confirmPassword')} />,
      },
      onKeyPress: (ev) => {
        if (ev.key === 'Enter') resetPassword();
      },
      label: 'Confirm Password',
      onChange: handleChange('confirmPassword'),
      placeholder: 'Re-enter new password',
      type: visibility.confirmPassword ? 'text' : 'password',
      value: inputs.confirmPassword,
    },
  ];

  return (
    <Card className={classes.card}>
      <form className={classes.form} autoComplete="false">
        <img className={classes.image} src="/images/reset.svg" alt="reset password" />

        <Typography variant="h5">Reset your password&#63;</Typography>

        {user && (
          <Typography align="center" variant="body2">
            Resetting your password for <strong>{user.email}</strong>&#46; Please enter your new password below&#46;
          </Typography>
        )}

        {!user && (
          <Typography align="center" color="error" variant="body2">
            {errors.message}
          </Typography>
        )}

        {inputProps.map((props) => (
          <TraxxInput disabled={!user || loading} key={props.id} {...props} />
        ))}

        <TraxxButton loading={loading} disabled={!user} fullWidth form onSubmit={resetPassword}>
          Reset my password
        </TraxxButton>

        {user && (
          <Link className={classes.link} color="secondary" component={RouterLink} to="">
            The email above is not mine&#46;
          </Link>
        )}
      </form>
    </Card>
  );
}

export default RequestView;
