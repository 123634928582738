import React, { useState } from 'react';
import { Box, Card, CardHeader, CardContent, CardActions, Link } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxInput, TraxxButton }from 'app/components'

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
  },
  card: {
    maxWidth: 700,
    margin: 'auto',
  },
  box: {
    margin: '0 10px 10px 10px',
    width: '100%',
    alignItems: 'center'
  },
  textBox: {
    margin: '20px',
    padding: '10px',
    backgroundColor: '#f5f9fa',
    borderRadius: '5px',
    color: 'black',
  }
}));

const account = {
  id: '9TRA016500',
  namespace: '18'
}

const ArticleRetrieval = () => {

  const classes = useStyles();
  const [number, setNumber] = useState('');
  const [link, setLink] = useState('');

  const handleChange = (value) => {
    value = value.replace(/\s/g, '')
    setNumber(value);
    const url = `https://global.factiva.com/du/article.aspx?accessionno=${value}&accountid=${account.id}&namespace=${account.namespace}`
    setLink(url);
  }

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <CardHeader title="Article Retrieval" />
        <CardContent>
          <Alert severity="info">Generate article links through accession number.</Alert>
        </CardContent>
        <CardActions>
          <Box className={classes.box} display="flex">
            <Box width="80%">
              <TraxxInput 
                onChange={({ target }) => handleChange(target.value)}
                placeholder="AFPR000020200629eg6t00dyt"
                value={number}
                label="Accession No"
              />
            </Box>
            <Box flexGrow={1} />
            { number && <Link target="_blank" href={link}><TraxxButton>Go to Link</TraxxButton></Link> }
          </Box>
        </CardActions>
      </Card>
    </Box>
  )
}

export default ArticleRetrieval;