import { Table, TableBody } from '@material-ui/core';
import { DataRow, MonoText, StatusLabel, DateDisplay } from 'app/components';
import { StatusMaps } from 'app/constants';
import { snakeToTitle } from 'app/utils';
import React, { useEffect } from 'react';

// eslint-disable-next-line
let mounted = false;
function TableDetail(props) {
  const { data } = props;
  const { entity } = data;
  const owner = entity[entity.owner_type];

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  return (
    <Table>
      <TableBody>
        <DataRow name="Firstname" value={owner.firstname || '-'} />
        <DataRow name="Middlename" value={owner.middlename || '-'} />
        <DataRow name="Lastname" value={owner.lastname || '-'} />
        <DataRow name="Gender" value={owner.gender || '-'} />
        <DataRow name="Email" value={owner.email || '-'} />
        <DataRow name="Owner reference" value={<MonoText>{owner.reference}</MonoText>} />
        <DataRow name="Status" value={<StatusLabel variantMap={StatusMaps.User}>{data.status}</StatusLabel>} />
        <DataRow name="Created date" value={<DateDisplay date={data.created_at} />} />
        <DataRow name="User projects" value={data.projects.join(', ') || '-'} />
        <DataRow name="User roles" value={snakeToTitle(data.roles.join(', '), true) || '-'} />
      </TableBody>
    </Table>
  );
}

export default TableDetail;
