import { Box, Card, LinearProgress, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Listing, ListingCompTable, Title } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UsersInvitesCreate } from './components';
import { useSnackbar } from 'notistack';
import DisableIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  card: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  input: {
    marginBottom: theme.spacing(3),
  },
}));

const headers = ['Name', 'Email', 'Invite ID', 'Invited by', 'Invite date', 'Status', 'View user'];

const UsersInvite = (props) => {
  const { children, className, staticContext, ...rest } = props;
  const classes = useStyles();
  const token = useSelector(({ profile }) => profile.access?.token);
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const [queryMeta, setQueryMeta] = useState({ count: 0 });
  const [runReloadTask, loading] = useAsyncTask('reloadUsersInvites');
  const [cancelLoading, setCancelLoading] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [runCancelTask] = useAsyncTask('reloadUsersInvites', (error) => error && enqueueSnackbar(`Error: ${error.message || 'unknown'}`, { variant: 'error' }));
  const [listing, setListing] = useState([]);

  const onCancel = (model) => {
    if (cancelLoading[model.id]) return;

    runCancelTask(async () => {
      setCancelLoading((state) => {
        return {
          ...state,
          [model.id]: true,
        };
      });

      await API.Users.cancel_invite(model.id);
      enqueueSnackbar('Invitation cancelled.');
      onReload();
    }).finally(() => {
      setCancelLoading((state) => {
        delete state[model.id];
        return { ...state };
      });
    });
  };

  useEffect(() => {
    if (token) onReload();

    // eslint-disable-next-line
  }, [token, filter]);

  const onReload = () => {
    runReloadTask(async () => {
      const { models, meta } = await API.Users.list_invite(filter);
      setQueryMeta({ count: meta.count });
      // setResultItems(models);
      sortListingData(models);
    });
  };

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      // const {  } = model;
      allData.push([
        { label: model.name },
        { label: model.email },
        { label: model.reference, monoText: true },
        { label: model.inviter?.firstname },
        { type: 'date', date: model.created_at },
        { label: model.status, type: 'status', statusType: 'Invite' },
        { label: model.invitee?.firstname, type: !!model.account_entity_id ? 'labelwithlink' : null, bottomLabel: model.invitee?.reference, link: `/app/users/${model.account_entity_id}/detail` },
        {
          type: 'custom',
          value: model.status === 'pending' && (
            <IconButton disabled={cancelLoading[model.id]} onClick={() => onCancel(model)}>
              <DisableIcon color="error" />
            </IconButton>
          ),
        },
      ]);
    });

    setListing(allData);
  };

  const onPageChange = (newFilter) => {
    if (newFilter.offset !== filter.offset) setFilter({ ...newFilter });
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Title prefix={'Users Invite'} />
      <UsersInvitesCreate onCreate={() => onReload()} />

      <Card className={classes.card}>
        <LinearProgress hidden={!loading} />
        <Listing filter={{ ...filter, ...queryMeta }} loading={loading} onPageChange={onPageChange}>
          {!!listing.length && <ListingCompTable headers={headers} data={listing} />}
        </Listing>
      </Card>
    </Box>
  );
};

export default UsersInvite;
