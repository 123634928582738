import { Card, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton } from 'app/components';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    // position: "absolute",
    // right: theme.spacing(2)

    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',
  },
  button: {
    marginTop: theme.spacing(2),
    '&:first-child': {
      marginTop: theme.spacing(0),
    },
  },
}));

const ActionsComp = (props) => {
  const { actions = [], children, className, ...rest } = props;
  const classes = useStyles();

  if (actions.length < 1) return <></>;
  return (
    <Card component={Paper} {...rest} className={clsx(classes.root, className)}>
      {actions.map((action, index) => (
        <TraxxButton className={classes.button} fullWidth onSubmit={action.onPress} key={index}>
          {action.buttonText}
        </TraxxButton>
      ))}
    </Card>
  );
};

export default ActionsComp;
