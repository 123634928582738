import moment from 'moment';
import { TYPES } from './action';

const initial_state = {
  loadingTasks: {},
  tasksRegistry: {},
  globalShow: {},
};

export default (state = initial_state, actions) => {
  let loadingTask = null,
    taskName;
  switch (actions.type) {
    case TYPES.ADD_BACKGROUND_LOADING:
      loadingTask = state.loadingTasks[actions.name] || {};
      loadingTask[actions.uuid] = moment();
      state.tasksRegistry[actions.uuid] = actions.name;
      return {
        ...state,
        loadingTasks: {
          ...state.loadingTasks,
          [actions.name]: loadingTask,
        },
        tasksRegistry: {
          ...state.tasksRegistry,
        },
      };
    case TYPES.REMOVE_BACKGROUND_LOADING:
      taskName = state.tasksRegistry[actions.uuid];
      if (!taskName) return state;
      loadingTask = state.loadingTasks[taskName];
      if (!loadingTask || !loadingTask[actions.uuid]) return state;

      delete loadingTask[actions.uuid];
      if (!Object.keys(loadingTask).length) delete state.loadingTasks[taskName];
      delete state.tasksRegistry[actions.uuid];
      return {
        ...state,
        loadingTasks: {
          ...state.loadingTasks,
        },
        tasksRegistry: {
          ...state.tasksRegistry,
        },
      };
    case TYPES.UPDATE_GLOBAL_SHOW:
      const key = actions.key;
      const show = actions.show === undefined ? !state.globalShow[key] : actions.show === true;
      return {
        ...state,
        globalShow: {
          ...state.globalShow,
          [key]: show,
        },
      };
    default:
      return state;
  }
};
