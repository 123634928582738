import { Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { EmptyState, ListingCompTable } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const DEFAULT_HEADERS = ['Case', 'Case ID', 'Status', 'Remove'];

let mounted = false;
const CaseListComp = (props) => {
  const { children, className, staticContext, accountId, onReload, group, cases = [], ...rest } = props;
  const classes = useStyles();
  const [runRemove] = useAsyncTask('removeCase');
  const [listing, setListing] = useState([]);

  // const router = useRouter();

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    sortCases();
    // eslint-disable-next-line
  }, [cases]);

  const onRemove = (id) => {
    runRemove(async () => {
      await API.Group.remove_case_group(group.id, { cases: [id] });
      if (mounted) if (typeof onReload === 'function') onReload();
    });
  };

  const sortCases = () => {
    let allData = [];
    cases.forEach((cas) => {
      allData.push([
        { label: cas.label },
        { label: cas.reference, type: 'link', link: `/screenings/cases/${cas.id}/detail` },
        { label: cas.status, type: 'status', statusType: 'Case' },
        {
          value: (
            <IconButton onClick={() => onRemove(cas.id)}>
              <DeleteIcon />
            </IconButton>
          ),
          type: 'custom',
        },
      ]);
    });
    setListing(allData);
  };

  return (
    <Box justifyItems="center" {...rest} className={clsx(classes.root, className)}>
      {/* <ListingComp
        filter={filter} setFilter={setFilter}
        showPaginator={showPaginator}
        loading={loadTask.loadOrders || loading}
        reloadFunc={() => { }}
        filterOptions={filterOptions}
        extraOptions={extraOptions}
        showFilter={true}
        setLoading={setLoading}
        dateKey="date"
      // sortKey="create_at" dateKey="create_date"
      > */}
      <EmptyState message="No cases in group" active={!listing.length} />
      {listing.length > 0 && <ListingCompTable headers={DEFAULT_HEADERS} data={listing} />}
      {/* </ListingComp> */}
    </Box>
  );
};

export default CaseListComp;
