import { store } from 'app/store';
import { get_api_path } from './api';
import { sessioned_client } from './auth';

export async function list_screenings(filter = {}) {
  const url = get_api_path('screen/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_screen(screen_id) {
  const url = get_api_path('screen/detail', null, { screen_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function update_ongoing_screening(screen_id) {
  const url = get_api_path('screen/ongoing_screening', null, { screen_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function create_simple_screen(data) {
  const url = get_api_path('screen/simple/create');
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function create_passport_screen(data) {
  const url = get_api_path('screen/passport/create');
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function create_organisation_screen(data) {
  const url = get_api_path('screen/organisation/create');
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function list_case(filter) {
  const url = get_api_path('case/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_case(case_id) {
  const url = get_api_path('case/detail', null, { case_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}
export async function get_countries() {
  const url = get_api_path('countries/list');
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}
export async function get_matching_list(screen_id, filter) {
  const url = get_api_path('screen/match/list', filter, { screen_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}
export async function get_match_detail(screen_id, match_id) {
  const url = get_api_path('screen/match/detail', null, { screen_id, match_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_match_profile(screen_id, match_id) {
  const url = get_api_path('screen/match/profile', null, { screen_id, match_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function retrieve_match(screen_id, match_id) {
  const url = get_api_path('screen/match/retrieve', null, { screen_id, match_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function list_documents(filter) {
  const url = get_api_path('document/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_document(verification_id) {
  const url = get_api_path('document/detail', null, { verification_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function flag_match(screen_id, match_id, data) {
  const url = get_api_path('screen/match/flag', null, { screen_id, match_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function list_cached_article_retrieval(filter = {}) {
  const url = get_api_path('account/cached_article_retrieval/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function detail_cached_article_retrieval(cached_article_retrieval_id) {
  const url = get_api_path('account/cached_article_retrieval/detail', null, { cached_article_retrieval_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function djrc_article_retrieval(screen_id, match_id, data) {
  const url = get_api_path('screen/match/djrc_article_retrieval', null, { screen_id, match_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function download_images(data) {
  const url = get_api_path('screen/download/images');
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function renew_ongoing_screening(data) {
  const url = get_api_path('screen/renew_ongoing_screening');
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function renew_screening(data) {
  const url = get_api_path('screen/renew');
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function get_renewal_screen() {
  const url = get_api_path('screen/renew_ongoing_screening/list');
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}
