import { Box, Button, FormControl, Typography, Switch, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SearchSelect, TraxxButton, TraxxInput } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask, useRouter } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {},
  typeOption: {
    alignItems: 'flex-start',
    display: 'flex',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  stepButton: {
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  input: {
    marginTop: theme.spacing(2),
  },
}));

const SelectCase = (props) => {
  const { children, className, caseName, onNameInput, onToggleCase, newCase, onSelect, onBack, caseId, onNext, ...rest } = props;
  const classes = useStyles();
  const access = useSelector((state) => state.profile.access);
  const [runLoadCase] = useAsyncTask('loadCase');
  const [inputs, setInputs] = useState();
  const [caseOptions, setCaseOptions] = useState([]);
  const [caseValue, setCaseValue] = useState('');
  const [timer, setTimer] = useState(null);
  const [loading, setLoading] = useState();
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const router = useRouter();
  const caseRef = new URLSearchParams(router.location.search).get('case');
  const { enqueueSnackbar } = useSnackbar();
  // const [isNew, setIsNew] = useState(newCase);

  useEffect(() => {
    if (access && access.token) {
      if (caseRef) {
        loadCases(caseRef);
      } else loadCases();
    }
    // eslint-disable-next-line
  }, []);

  const validateNext = () => {
    if (!newCase && !caseId) return enqueueSnackbar('Please select a case', { variant: 'error' });
    onNext();
  };

  const loadCases = (search) => {
    runLoadCase(async () => {
      let filter = { limit: 1000, offset: 0 };
      if (search) filter.search = search;
      const result = await API.Screening.list_case(filter);
      const caseOpts = result.models.map((mod) => ({
        name: `${mod.reference}${mod.label ? ` - ${mod.label}` : ''}${mod.status ? ` (${mod.status})` : ''}`,
        value: mod.id,
        ref: mod.reference,
      }));
      if (search) {
        caseOpts.forEach((cas) => {
          if (cas.ref === search) {
            // setInputs({ ...inputs, case_id: cas.value })
            onSelect(cas.value);
            setCaseValue(cas.name);
          }
        });
      }
      setCaseOptions(caseOpts);
    });
  };

  const onCaseInputs = (event, value, reason) => {
    setCaseValue(value);
    if (timer) clearTimeout(timer);
    setLoading(true);
    setTimer(
      setTimeout(() => {
        loadCases(value);
      }, 1000)
    );
  };

  const onCaseSelect = (event, value, reason) => {
    if (value && caseOptions) {
      let foundCase = caseOptions.find((c) => c.name === value);
      setInputs({ ...inputs, case_id: foundCase.value });
      onSelect(foundCase.value);
    } else {
      setInputs({ ...inputs, case_id: null });
      onSelect(null);
      setCaseValue('');
    }
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Box mt={2}>
        <Typography variant="body1">Which Case would you like to assign this to?</Typography>
      </Box>
      <Box mt={2}>
        <FormControlLabel control={<Switch checked={newCase} onChange={() => onToggleCase(!newCase)} />} label={newCase ? 'Create new case' : 'Use existing case'} />

        {!newCase && (
          <FormControl fullWidth>
            <SearchSelect label={'Select case'} inputValue={caseValue} options={caseOptions} loading={loading || loadTask['createScreening']} onSelect={onCaseSelect} onInput={onCaseInputs} helperText="Enter and select case ID" />
          </FormControl>
        )}

        {newCase && (
          <TraxxInput
            className={classes.input}
            label="Case label"
            id="case_name"
            InputProps={{
              classes: { underline: classes.underline },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target }) => onNameInput(target.value)}
            placeholder="Name of case (for your book-keeping use)"
            value={caseName}
          />
        )}
      </Box>
      <Box mt={6} display="flex">
        {onBack && <Button onClick={onBack}>Previous</Button>}
        <Box flexGrow={1} />
        <TraxxButton onSubmit={validateNext}>Next</TraxxButton>
      </Box>
    </Box>
  );
};

export default SelectCase;
