export const TYPES = {
  UPDATE_SESSION: 'UPDATE_SESSION',
  UPDATE_ACCESS: 'UPDATE_ACCESS',
  INIT: 'INIT',
  UPDATE_LAST_ROUTE: 'UPDATE_LAST_ROUTE',
};

export function updateSession({ self, wallet, access, accounts, workspace, expired = false, lastRoute = null }) {
  return {
    type: TYPES.UPDATE_SESSION,
    self,
    access,
    accounts,
    workspace,
    lastRoute,
    wallet,
    expired,
  };
}

export function init(init) {
  return {
    type: TYPES.INIT,
    init,
  };
}

export function updateAccess(access, workspace) {
  return {
    type: TYPES.UPDATE_ACCESS,
    access,
    workspace,
  };
}

export function updateLastRoute(lastRoute) {
  return {
    type: TYPES.UPDATE_LAST_ROUTE,
    lastRoute,
  };
}
