import { Box, Dialog, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import React from 'react';
import TraxxButton from '../TraxxButton';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 360,
  },
}));

/**
 * Grouping cases into a case group
 *
 * Props:
 ** className: name of css class element
 */
const CaseGrouper = (props) => {
  const { selectedGroup, caseDetail, open, handleClose, className, onSubmit, onRemoveCase, loading, ...rest } = props;
  const classes = useStyles();

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <Box {...rest} className={clsx(classes.root, className)}>
        <List subheader={<li />}>
          <ListSubheader>Update {selectedGroup?.label}</ListSubheader>
          {!caseDetail?.length && (
            <ListItem>
              <ListItemText primary={`No new cases selected`} />
            </ListItem>
          )}
          {caseDetail?.length > 0 &&
            caseDetail.map((cas, index) => (
              <ListItem key={`newCase-${index}`}>
                <ListItemText primary={`${cas.label}`} />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => onRemoveCase(cas)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          {/* <Divider />
          <ListSubheader>Current cases in group</ListSubheader>
          {!originalCases.length && (
            <ListItem>
              <ListItemText primary={`No cases`} />
            </ListItem>
          )} */}
          {/* {originalCases.length && originalCases.map(((original, index) => (
            <ListItem key={`newCase-${index}`}>

            </ListItem>
          )))} */}
        </List>
        <Box mt={2} padding={2}>
          <TraxxButton loading={loading} fullWidth onSubmit={onSubmit}>
            Update group
          </TraxxButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CaseGrouper;
