import { Box, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Crumbs, DowJonesBrand, RefinitivBrand, Title, TraxxButton } from 'app/components';
import { API } from 'app/services';
import theme from 'app/theme';
import { useAsyncTask, useRouter } from 'app/utils';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ActionsComp, DetailComp, InputParameter, MatchList, RenewScreeningComp, ReportPreview } from './components';

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  root: {
    padding: spacing(3, 3, 8),
    [breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
    '@media print': {
      boxShadow: 'none',
    },
  },
  card: {
    marginTop: 10,
    width: '100%',
  },
  table: {
    borderRadius: 5,
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',
  },
  head: {
    fontWeight: 'bold',
  },
  status: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  icon: {
    padding: 0,
  },
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
  footer: {
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',
  },
  renewBox: {
    marginTop: '15px',
  },
  floatButton: {
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  linkBox: {
    minHeight: 200,
    width: 700,
    padding: 10,
  },
  linksHeader: {
    textAlign: 'center',
    padding: 10,
  },
}));

let mounted = false;
function ScreeningDetail(props) {
  const { routeType = 'account', screenId, onNext, newPage } = props;
  const classes = useStyles();
  const [screening, setScreening] = useState(null);
  const loading = useSelector((state) => state.layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const wallet = useSelector((state) => state.profile.wallet);
  const [runLoadScreening] = useAsyncTask('getScreening');
  const [runAuditLog] = useAsyncTask('getAuditLog');
  const router = useRouter();
  const { match } = router;
  const { params } = match;
  const [showPreview, setShowPreview] = useState(false);
  const [confirmRenewScreen, setConfirmRenewScreen] = useState(false);
  const [renewScreeningLoading, setRenewScreeningLoading] = useState(false);

  const [matchList, setMatchList] = useState();
  const [auditLog, setAuditLog] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const screening_id = params.screening_id || screenId;

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) getScreening();
    // eslint-disable-next-line
  }, [access]);
  /**
   * Get the screening details
   * @returns the screening details
   */
  const getScreening = () => {
    if (!screening_id) return enqueueSnackbar('invalid id');
    runLoadScreening(async () => {
      const loader = routeType === 'account' ? API.Screening.get_screen : API.Admin.get_screen;
      const { model } = await loader(screening_id);
      getAuditLog();
      if (mounted) setScreening(model);
    });
  };

  const getAuditLog = () => {
    runAuditLog(async () => {
      const loader = routeType === 'account' ? API.Users.get_event_logs : API.Admin.get_event_logs;
      let filter = { owner_type: 'screen', owner: screening_id };
      const { models } = await loader(filter);
      if (mounted) setAuditLog(models);
    });
  };

  const actions = [{ buttonText: 'Generate report', onPress: () => setShowPreview(true) }];

  if (!screenId && routeType !== 'admin') {
    actions.push({ buttonText: 'Document verification', onPress: () => router.history.push(`/document-verify?case=${screening.case.reference}`) });
  }

  const renewScreen = [{ buttonText: 'Renew Screening', onPress: () => setConfirmRenewScreen(true) }];

  /**
   * Submits the renew screen request
   * @returns The new screening
   */
  const submitRenewScreen = async () => {
    if (400 > wallet.balance) {
      enqueueSnackbar('Insufficient wallet balance', { variant: 'error' });
    } else {
      setRenewScreeningLoading(true);
      const renewScreenResult = await API.Screening.renew_screening({ screening_id, case_id: JSON.parse(screening.case_id) });
      const { screens, screen_result_id } = renewScreenResult;
      if (screens[0]) {
        setRenewScreeningLoading(false);
        setConfirmRenewScreen(false);
        enqueueSnackbar('Screening submitted successfully!', { variant: 'success' });
        router.history.push(`/screenings/${screen_result_id}/detail`);
        getScreening();
        window.location.reload();
      }
    }
  };
  return (
    <Box className={classes.root}>
      {showPreview && <ReportPreview auditLog={auditLog} cancelPreview={() => setShowPreview(false)} routeType={routeType} matchList={matchList} screening={screening} />}
      {!showPreview && (
        <>
          {screenId && (
            <Box className={classes.noPrint} display="flex">
              <Box flexGrow={1} />
              <TraxxButton className={classes.floatButton} onSubmit={() => setShowPreview(true)}>
                Generate report
              </TraxxButton>
            </Box>
          )}
          {!screenId && <Crumbs current="Detail" links={[{ text: 'Screenings', href: routeType === 'admin' ? '/admin/screenings' : '/screenings' }]} />}
          <Title prefix={'Screening detail'}>{screening && (screening.screen_provider_id === 1 ? <RefinitivBrand /> : screening.screen_provider_id === 2 ? <DowJonesBrand /> : "Powered by ComplyAdvantage" )}</Title>
          <Grid container spacing={2}>
            <Grid item md={screenId ? 6 : 4} xs={12}>
              <DetailComp newPage={newPage} reload={getScreening} routeType={routeType} screening={screening} loading={loading.getScreening} />
              <ActionsComp actions={renewScreen} className={classes.renewBox} />
            </Grid>
            <Grid item md={screenId ? 6 : 4} xs={12}>
              <InputParameter reload={getScreening} routeType={routeType} screening={screening} loading={loading.getScreening} />
            </Grid>
            {!screenId && screening && (
              <Grid item xs={12} md={4}>
                <ActionsComp actions={actions} />
              </Grid>
            )}
            <Grid item xs={12}>
              {screening?.status === 'complete' && !loading.getScreening && (
                <MatchList newPage={newPage} routeType={routeType} onLoad={setMatchList} flagged={screening.no_of_flags} screening_id={screening.id} screen_provider_id={screening.screen_provider_id} />
              )}
            </Grid>
          </Grid>
          {!screenId && screening && (
            <Box p={1} mt={2} component={Paper} className={classes.footer} display="flex">
              <Box flexGrow={1} />
              <TraxxButton className={classes.floatButton} onSubmit={() => setShowPreview(true)}>
                Generate report
              </TraxxButton>
            </Box>
          )}
        </>
      )}
      {screenId && onNext && (
        <Box className={classes.noPrint} mt={6} display="flex">
          <Box flexGrow={1} />
          <TraxxButton onSubmit={onNext}>Next</TraxxButton>
        </Box>
      )}
      <RenewScreeningComp open={confirmRenewScreen} setOpen={setConfirmRenewScreen} loading={renewScreeningLoading} onSubmit={submitRenewScreen} />
    </Box>
  );
}

export default ScreeningDetail;
