import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Title } from 'app/components';
import { EventLogListComp } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
}));

function EventLog(props) {
  const { showTitle = true, routeType } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {showTitle && <Title prefix="Event Logs" />}
      <EventLogListComp routeType={routeType} />
    </Box>
  );
}

export default EventLog;
