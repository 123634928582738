import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment-timezone';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const DateDisplay = (props) => {
  const { dateFormat = 'DD-MMM-YYYY HH:mm', date, className, ...rest } = props;
  const classes = useStyles();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <Typography color="secondary" variant="body2" {...rest} className={clsx(classes.root, className)}>
      {moment(date, 'YYYY-MM-DD', true).isValid() ? moment(date).format(dateFormat || 'DD-MMM-YYY') : moment(date).isValid() ? moment(date).tz(timezone).format(dateFormat) : '-'}
    </Typography>
  );
};

export default DateDisplay;
