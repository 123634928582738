import useStatefulTask from './useStatefulTask';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useRouter from './useRouter';
import { actions } from 'app/store';
import { Paths } from 'app/constants';
import { useSnackbar } from 'notistack';
const parseError = (original) => {
  let error = original;
  if (original.isAxiosError) {
    if (original.response) {
      if (original.response.data && original.response.data.error) error = original.response.data.error;
      error.axios = {
        request: original.request,
        response: original.response,
        config: original.config,
      };
    }
  }
  return error;
};

const default_catcher = (enqueueSnackbar) => {
  return (error) => {
    if (!error) return;
    if (typeof error === 'string') enqueueSnackbar('error', { variant: 'error', autoHideDuration: '3000' });
    else if (error && error.message) enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: '3000' });
  };
};

export default (taskname, errorCatcher) => {
  const [error, setError] = useState(null);
  const loadingTasks = useSelector((store) => store.layout.loadingTasks);
  const dispatch = useDispatch();
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const cleanup = () => {
    setError(null);
    if (errorCatcher) errorCatcher(null);
  };
  const statefulTask = useStatefulTask();
  const _errorCatcher = errorCatcher || default_catcher(enqueueSnackbar);
  const asyncTaskRunner = async (task) => {
    if (typeof cleanup === 'function') cleanup();
    try {
      await statefulTask(task, taskname);
    } catch (rawError) {
      console.log('error within async task', taskname);
      console.error(rawError);
      const error = parseError(rawError);
      switch (error.message) {
        case 'token expired':
          dispatch(
            actions.Profile.updateSession({
              access: null,
              self: null,
              wallet: null,
              accounts: null,
              workspace: null,
              expired: true,
              lastRoute: router.location.pathname,
            })
          );
          router.history.push(Paths.Auth.Login);
          break;
        case 'invalid token':
        case 'unauthorised:invalid token':
          dispatch(
            actions.Profile.updateSession({
              access: null,
              self: null,
              wallet: null,
              accounts: null,
              workspace: null,
              expired: false,
              lastRoute: null,
            })
          );
          router.history.push(Paths.Auth.Login);
          break;
        default:
          break;
      }
      setError(error);
      _errorCatcher(error);
    }
  };
  const loadingState = !!loadingTasks[taskname];
  return [asyncTaskRunner, loadingState, error];
};
