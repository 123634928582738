import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Typography variant="h4">TRAXX Privacy Policy</Typography>
      <div className="flex justify-center">
        <div className="text-left bg-grey max-w-3xl sm:m-8 transition-all ease-in-out p-8 w-full">
          {/* Introduction */}
          <ol className="list-decimal list-inside ml-2">
            <li className="font-bold text-xl mb-4">Introduction</li>
            <ol className="list-decimal list-inside ml-4">
              <li className="mb-2">
                TRAXX Payments Pte Ltd (the “Company”, “we” or “us”) takes your privacy very seriously. We are committed to implementing policies, practices and processes to safeguard the collection, use and disclosure of the Personal Data you
                provide us, in compliance with the Singapore Personal Data Protection Act 2012 (“PDPA”) .
              </li>
              <li className="mb-2">
                We ask that you read this privacy policy (“Policy”) carefully as it contains important information about what to expect when the Company collects personal information about you and how the Company will use your Personal Data. The
                Company’s registered office is at, 9 Tagore Lane, #04-17 Singapore 787472.
              </li>
              <li className="mb-2">
                This Policy informs you of the Company’s policies regarding the collection, use and disclosure of any personal information which you may provide to the Company through its website, mobile application or Services. This Policy shall
                form an integral part of our Terms and Conditions (“Terms”); any capitalised terms used herein and not otherwise defined shall have the meaning assigned to them in our Terms.
              </li>
              <li className="mb-2">
                Please read our Terms carefully in conjunction with this Policy to understand our practices regarding the collection, use and disclosure of your Personal Data. Please refrain from using our Services if you disagree with any provision
                within this Policy. By using any of our Services, you are deemed to have read, understood and agreed with the Terms and this Policy.
              </li>
              <li className="mb-2">
                This Policy supplements but does not supersede nor replace any other consents you may have previously provided to us in respect of your Personal Data (as defined below), and your consents herein are additional to any rights which the
                Company may have at law to collect, use or disclose your Personal Data.
              </li>
              <li className="mb-2">
                By agreeing to this Policy, you represent the following:
                <ol className="list-decimal list-inside ml-4">
                  <li className="mb-2">That you have attained the age of 18 years (where applicable); and</li>
                  <li className="mb-2">That you have full capacity to enter into this Agreement.</li>
                </ol>
              </li>
              <li className="mb-2">
                If you have any questions about our collection, use, and/or disclosure of your Personal Data; feedback regarding this Policy, or any complaint you have relating to how we manage your Personal Data, you may contact our customer service
                department at email: contact@traxx.biz.
              </li>
              <li className="mb-4">
                For the purposes of this Policy,
                <ol className="list-decimal list-inside ml-4">
                  <li>
                    “Customer”, “you” or “your” means an individual who (i) has contacted us through any means to find out more about TRAXX Asia or its Services, or (ii) may, or has, entered into an Agreement with us for the use of the TRAXX Asia;
                    and
                  </li>
                  <li>“Personal Data” means data or information, whether true or not, about a Customer who can be identified: (i) from that data; or (ii) from that data and other information to which we have or are likely to have access.</li>
                </ol>
              </li>
            </ol>

            {/* How we collect personal data */}

            <li className="font-bold text-xl mb-4 list-decimal">How we Collect Personal Data</li>
            <ol className="list-decimal list-inside ml-4">
              <li className="mb-2">
                We collect and retain only Personal Data that is practical for the Company’s effective operation. We collect the Personal Data of our prospects and customers through the following methods/channels:
                <ol className="list-decimal list-inside ml-4">
                  <li>when you visit our website the mobile application or websites of third parties using our services;</li>
                  <li>when you make payment;</li>
                  <li>when you subscribe or log-in to our social media platforms;</li>
                  <li>when you participate in our external events / functions.</li>
                </ol>
              </li>
              <li className="mb-4">
                We generally do not collect your Personal Data unless,
                <ol className="list-decimal list-inside ml-4">
                  <li>
                    it is provided to us voluntarily by you directly or via a third party who has been duly authorised by you to disclose your Personal Data to us (your “authorised representative”) after (i) you (or your authorised representative)
                    have been notified of the purposes for which the data is collected, and (ii) you (or your authorised representative) consent to the collection and usage of your Personal Data for those purposes; or
                  </li>
                  <li>
                    collection and use of Personal Data without consent is permitted or required by the PDPA or other laws. We shall seek your consent before collecting any additional Personal Data and before using your Personal Data for a purpose
                    which has not been notified to you (except where permitted or authorised by law).
                  </li>
                </ol>
              </li>
            </ol>
            {/* How we collect personal data */}

            <li className="font-bold text-xl mb-4 list-decimal">Collection of Personal Data</li>
            <ol className="list-decimal list-inside ml-4">
              <li className="mb-2">
                Please note that when you provide information to us via a third-party, the information you provide may be separately collected by the third-party site or platform. The information we collect is covered by this Policy. We have no
                control or sight over the type of information collected by the third-party; all Personal Data collected by them is subject to the third-party’s respective privacy policy practices.
              </li>
              <li className="mb-2">
                Privacy choices you have made on the third-party site or platform will not apply to our use of the information we have collected directly through our applications. Kindly note that our website and applications may contain links to
                foreign third-party websites not owned or controlled by us and we are not responsible for the privacy practices of those sites.
              </li>
              <li className="mb-4">
                In order for us to effectively provide our Services to you, we may from time to time require you to provide the following information:
                <ol className="list-decimal list-inside ml-4">
                  <li>Demographic Information (including but not limited to, your date of birth, gender, country of citizenship, and documentation to verify your place of residence);</li>
                  <li>Transaction/ Payment Information (including but not limited to, your debit/ credit/ stored- value card number and cryptocurrency wallet);</li>
                  <li>Device and Log-in Information (including but not limited to, device, mobile network information, operating system, type of browser, IP address and web log data)</li>
                  <li>Contact Information (including but not limited to, mobile number, e-mail address and mailing address); and</li>
                  <li>User account data (including but not limited to, credentials to access the Services).</li>
                </ol>
              </li>
            </ol>
            {/* Purpose for collecting Personal Data */}

            <li className="font-bold text-xl mb-4 list-decimal">Purpose for collecting Personal Data</li>
            <ol className="list-decimal list-inside ml-4">
              <li className="mb-2">
                We use, store, and process information, including your personal information, about you in order to carry out, provide and ensure your access to the Services, improve and develop the Company, and maintain a trusted and safer
                environment and comply with our legal obligations.
              </li>
              <li className="mb-2">
                As an overview, we use your Personal Data mainly to:
                <ol className="list-decimal list-inside ml-4">
                  <li>Enable you to access and use the Services;</li>
                  <li>Detect and prevent fraud, abuse, security incidents, and other harmful activity;</li>
                  <li>Conduct security investigations and risk assessments;</li>
                  <li>Conduct checks against databases and other information sources;</li>
                  <li>Comply with legal obligations (such as AML and CFT regulations);</li>
                  <li>Enforce the Terms and other policies; </li>
                  <li>With your consent, send you promotional messages, marketing, advertising, and other information that may be of interest to you based on your preferences.</li>
                </ol>
              </li>
              <li className="mb-4">
                We utilise the information collected in Clause 3.3 for the following purposes:
                <ol className="list-decimal list-inside ml-4">
                  <li>
                    Demographic Information: to tailor your experience while using the website, mobile application or Services, including but not limited to showing you content and advertising that you might be interested in and displaying the
                    content in accordance with your country, postal code, gender or age.
                  </li>
                  <li>
                    Transaction/ Payment Information: for onward transmission to the relevant financial institutions for the processing of payments / withdrawals. For the avoidance of doubt, the Company will not be able to access your bank account or
                    credit card details at any time.
                  </li>
                  <li>
                    Device and Log-in Information: we collect information about the computer or mobile device you use to access the website and mobile application, including device identifiers, mobile network information, type of operating system,
                    and the type of browser used. We also log information about your use of the website, including access times, pages viewed, IP address, web log data, and the pages visited before and after navigating to our websites.
                  </li>
                  <li>
                    Contact information: to quickly and efficiently contact you in a way that you find most effective, especially with regard to sending notifications, news updates or warnings (where separate consent has been granted). Contact
                    Information is also used to prevent potentially prohibited or illegal activities carried out via the mobile application or the website, and to enforce the Terms.
                  </li>
                  <li>User Account Data: to provide you the Services, including managing your user account, providing customer support, sending updates, collecting fees, etc.</li>
                </ol>
              </li>
            </ol>

            {/* Sharing and Disclosing Information */}

            <li className="font-bold text-xl mb-4 list-decimal">Sharing and Disclosing Information</li>
            <ol className="list-decimal list-inside ml-4">
              <li className="mb-2">
                We may from time to time disclose selected Personal Data collected to the following non-exhaustive list of parties or organisations outside the Company:
                <ol className="list-decimal list-inside ml-4">
                  <li>banks and other financial institutions;</li>

                  <li>government agencies or organisations; </li>

                  <li>information technology service providers; </li>

                  <li>social media service providers; </li>

                  <li>the company’s subsidiaries; and </li>

                  <li>mailing houses/ post offices. </li>
                </ol>
              </li>
              <li className="mb-2">
                We may, subject to your level of consent and in compliance with applicable laws on data privacy, disclose your Personal Data to any personnel of the Company or third parties, in order to carry out the purposes set out above. When
                disclosing Personal Data to third parties, the Company takes all reasonable measures to ensure that any Personal Data disclosed is kept confidential and secure. For the avoidance of doubt, the Company will not, at any time, sell or
                trade your Personal Data to any third parties.
              </li>
              <li className="mb-2">
                We will only provide your Personal Data to third parties if you have consented to such transfer of your Personal Data, if you have consented to the transfer pursuant to a separate privacy policy which you have agreed to with a
                third-party, or if such transfer is required by law.
              </li>
              <li className="mb-4">
                Your Personal Data may be stored in servers hosted by third party cloud service providers such as Google, Amazon and Microsoft Azure. Please contact the Company to receive an updated list of such authorized processors. Any such
                authorized processor shall be obliged to adhere to the restrictions set forth in this Privacy Policy and the applicable data protection laws.
              </li>
            </ol>
            {/* Retention of Personal Data  */}

            <li className="font-bold text-xl mb-4 list-decimal">Retention of Personal Data</li>
            <ol className="list-decimal list-inside ml-4">
              <li className="mb-2">We may retain your Personal Data for as long as it is necessary to fulfil the purpose for which it was collected, or as required or permitted by applicable laws.</li>
              <li className="mb-4">
                This period may extend beyond the end of your relationship with us, but only for so long as is reasonably necessary for us to have sufficient information to respond to any legal issues that may arise after the end of your relationship
                with us or for as long as required by law. Certain retention periods are based on statutory or regulatory requirements. We will ensure that your Personal Data that no longer has any business or legal use will be destroyed or disposed
                of in a secured manner. This applies to both paper documents and electronic data stored in our databases.
              </li>
            </ol>
            {/* Amending/ Rectifying Personal Data collected  */}

            <li className="font-bold text-xl mb-4 list-decimal">Amending/ Rectifying Personal Data collected</li>
            <ol className="list-decimal list-inside ml-4">
              <li className="mb-2">You have a right to be informed of the Personal Data collected and controlled by the Company, a right to rectification/correction, erasure and restriction of processing.</li>
              <li className="mb-2">
                If you wish to make (a) an access request for access to a copy of the Personal Data which we hold about you or information about the ways in which we use or disclose your Personal Data, or (b) a correction request to correct or update
                any of your Personal Data which we hold about you, you may submit your request in writing or via email to the Company at the contact details provided below. Please note that the Company reserves the right to charge a reasonable fee
                for any access request. If so, we will inform you of the fee before processing your request.
              </li>
              <li className="mb-2">
                We will respond to your request as soon as reasonably possible. If we are unable to provide you with any Personal Data or to make a correction requested by you, we shall generally inform you of the reasons why we are unable to do so
                (except where we are not required to do so under the PDPA).
              </li>
              <li className="mb-4">You may exercise any of the rights described in this Clause by sending a request to, contact@traxx.biz. Please note that we may ask you to verify your identity before taking further action on your request(s).</li>
            </ol>
            {/* Withdrawing Consent   */}

            <li className="font-bold text-xl mb-4 list-decimal">Withdrawing Consent</li>
            <ol className="list-decimal list-inside ml-4">
              <li className="mb-2">
                The consent that you provide for the collection, use and disclosure of your Personal Data will remain valid until such time it is being withdrawn by you in writing. You may withdraw consent and request us to stop using and/or
                disclosing your Personal Data for any or all of the purposes listed above by submitting your request via email at the contact details provided at Clause 8 above. Upon receipt of your written request to withdraw your consent, we may
                require reasonable time (depending on the complexity of the request and its impact on our relationship with you) for your request to be processed and for us to notify you of the consequences of us acceding to the same, including any
                legal consequences which may affect your rights and liabilities to us.
              </li>
              <li className="mb-2">
                Whilst we respect your decision to withdraw your consent, you acknowledge and accept that any withdraw of consent may have a negative influence on the quality of the Services rendered to you, and may depending on the nature and scope
                of your request, render the Company is a position that prevents it from rendering the Services to you. The Company shall, in such circumstances, notify you before completing the processing of your request.
              </li>
              <li className="mb-4">
                Please note that withdrawing consent does not affect our right to continue to collect, use and disclose Personal Data where such collection, use and disclose without consent is permitted or required under applicable laws.
              </li>
            </ol>
            {/* Protection of Personal Data	 */}

            <li className="font-bold text-xl mb-4 list-decimal">Protection of Personal Data</li>
            <ol className="list-decimal list-inside ml-4">
              <li className="mb-2">
                We endeavour to maintain appropriate physical, procedural and technical safeguards with respect to our offices and information storage facilities in order to prevent any loss, misuse, or unauthorized access, disclosure, or
                modification of your Personal Data.
              </li>
              <li className="mb-2">
                We further protect Personal Data by restricting its access to employees, contractors, advisers, affiliates and service providers which the Company reasonable determines requires access to such information for the purposes set out in
                the Terms and this Policy.
              </li>
              <li className="mb-4">
                You should be aware, however, that no method of transmission over the Internet or method of electronic storage is completely secure. While security cannot be guaranteed, we strive to protect the security of your information and are
                constantly reviewing and enhancing our information security measures.
              </li>
            </ol>
            {/* Accuracy of Personal Data */}

            <li className="font-bold text-xl mb-4 list-decimal">Accuracy of Personal Data</li>
            <ol className="list-decimal list-inside ml-4">
              <li className="mb-4">
                We generally rely on Personal Data provided by you (or your authorised representative). In order to ensure that your Personal Data is current, complete and accurate, please update us if there are changes to your Personal Data by
                informing the Company at the contact details provided herein.
              </li>
            </ol>
            {/* Transfer of Personal Data */}

            <li className="font-bold text-xl mb-4 list-decimal">Transfer of Personal Data</li>
            <ol className="list-decimal list-inside ml-4">
              <li className="mb-4">
                If there is a need for us to transfer your Personal Data to another country, we will ensure that the standard of data protection in the recipient country is comparable to that of Singapore’s PDPA. If this is not so, we will enter into
                a contractual agreement with the receiving party to accord similar levels of data protection as that in Singapore.
              </li>
            </ol>
            {/* Compliance with Law  */}

            <li className="font-bold text-xl mb-4 list-decimal">Compliance with Law</li>
            <ol className="list-decimal list-inside ml-4">
              <li className="mb-2">
                We may disclose your information, including personal information, to courts, law enforcement or governmental authorities, or authorised third parties, if and to the extent we are required or permitted to do so by law or if such
                disclosure is reasonably necessary: (i) to comply with our legal obligations, (ii) to comply with legal process and to respond to claims asserted against the Company, (iii) to respond to verified requests relating to a criminal
                investigation or alleged or suspected illegal activity or any other activity that may expose us, you, or any other of our users to legal liability, (iv) to enforce and administer our Terms, or (v) to protect the rights, property or
                personal safety of the Company, its employees, the users of the TRAXX Asia, or members of the public.
              </li>
              <li className="mb-4">
                Where appropriate, we may notify you about legal requests unless:
                <ol className="list-decimal list-inside ml-4">
                  <li>providing notice is prohibited by the legal process itself, by court order we receive, or by applicable law; or</li>

                  <li>we believe that providing notice would create risk of any related illegal activity on the Company.</li>
                </ol>
              </li>
            </ol>
            {/* Cookies and other information gathering technologies */}

            <li className="font-bold text-xl mb-4 list-decimal">Cookies and other information gathering technologies</li>
            <ol className="list-decimal list-inside ml-4">
              <li className="mb-4">
                Our TRAXX Asia (including the Company’s website and mobile application) uses cookies. Cookies are text files placed on your computer to collect standard Internet log information and visitor behaviour information. These cookies allow
                us to distinguish you from other users of the website which helps us to provide you with a good experience when you browse our website and also allows us to improve our site.
              </li>
            </ol>
            {/*Changes to Privacy Policy */}

            <li className="font-bold text-xl mb-4 list-decimal">Changes to Privacy Policy </li>
            <ol className="list-decimal list-inside ml-4">
              <li className="mb-2">
                We may revise this Policy from time to time. The most current version of the Policy will govern our processing of your Personal Data and will always be available on our website, or by requesting for a copy of the same from the
                Company.
              </li>
              <li className="mb-4">
                If we make a change to this policy which we regard as material, we will notify you via e-mail at the e-mail address associated with your account. By continuing to access or use the Services after those changes become effective, you
                agree to be bound by the revised Policy.
              </li>
            </ol>
          </ol>
          <li className="mb-4 list-none">
            If you have any concerns or wish to make a report, please contact our Data Protection Officer (DPO) at{' '}
            <Link className="text-traxx-maroon hover:text-orange transition-colors duration-300" href="mailto:contact@traxx.biz">
              {' '}
              dpo@traxx.biz{' '}
            </Link>
          </li>
        </div>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
