import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Card, CardHeader, CardContent, CardActions, Typography, Table, TableBody, TableRow, TableCell, Checkbox } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

import { useAsyncTask } from "app/utils";
import { API } from "app/services";
import { DataRow } from 'app/components';
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
  },
  card: {
    maxWidth: 700,
    margin: 'auto',
  }
}));

let mounted = false;

const DownloadImage = () => {
  const classes = useStyles();
  const access = useSelector((state) => state.profile.access);

  const { enqueueSnackbar } = useSnackbar();
  const [runLoadAccounts] = useAsyncTask('loadAccounts');
  const [runUpdateAccount] = useAsyncTask('updateAccount');
  const [accounts, setAccounts] = useState([]);
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) getAccounts();
  }, [access])

  const loadSetChecked = (accounts) => {
    const checklist = [];
    for (const account of accounts) {
      checklist.push({
        name: account.name,
        isChecked: account.entity.privileges.find((privilege) => privilege.name === 'berbix.download_image'),
      });
    }
    setChecked(checklist);
  };

  const getAccounts = (callback) => {
    runLoadAccounts(async () => {
      const { models } = await API.Admin.list_accounts({ nolimit: true });
      if (mounted) {
        setAccounts(models);
        loadSetChecked(models);
        if (typeof callback === 'function') callback();
      }
    });
  };

  const handleChecked = (accountId) => {
    runUpdateAccount(async () => {
      await API.Admin.update_account_download_image_privilege(accountId);
      getAccounts(() => enqueueSnackbar('Download Image Privilege Updated', { variant: 'success' }));
    });
  }

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <CardHeader title="Download Image Privilege" />
        <CardContent>
          <Alert severity="info">Set download images privilege to the following accounts.</Alert>
        </CardContent>
        <CardActions>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Accounts</Typography>
                </TableCell>
              </TableRow>
              {accounts &&
                accounts.map((account, index) => (
                  <DataRow key={index} name={account.name} value={
                    <Checkbox 
                      checked={checked[index]?.isChecked}
                      name={account.name}
                      value={account.id}
                      onChange={() => handleChecked(account.id)}
                    />}
                  />
                ))}
            </TableBody>
          </Table>
        </CardActions>
      </Card>
    </Box>
  )
}

export default DownloadImage;