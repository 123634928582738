import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import moment from 'moment';
import { useEffect } from 'react';

const QueueButler = ({ dataSet, setData }) => {
  const [runLoadScreen, loading, error] = useAsyncTask('loadScreen'); // eslint-disable-line

  useEffect(() => {
    let queueTimer = null;
    const newSet = { ...dataSet };
    if (newSet) {
      let hasProcessing = false;
      const processSetIDs = Object.values(newSet)
        .map((set) => {
          if (set.status === 'processing') hasProcessing = true;
          if (set.status === 'processing' && set.data) return set.data.id;
          return null;
        })
        .filter((id) => id || id === 0);

      if (hasProcessing) {
        queueTimer = setInterval(async () => {
          const { models } = await API.Screening.list_screenings({
            open_date: moment().startOf('D').unix() + ',' + moment().endOf('D').unix(),
            limit: 10000000,
          });
          models.forEach((screen) => {
            if (processSetIDs.includes(screen.id) && screen.results_count) {
              Object.values(newSet).forEach((set) => {
                if (set.data && set.data.id === screen.id) {
                  set.data = screen;
                  set.status = 'processed';
                }
              });
            }
          });
          if (typeof setData === 'function') {
            setData(newSet);
          }
        }, 5000);
      } else {
        clearInterval(queueTimer);
      }
    }
    return () => queueTimer && clearInterval(queueTimer);
    // eslint-disable-next-line
  }, [{ ...dataSet }]);

  return null;
};

export default QueueButler;
