import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton } from 'app/components';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    marginBottom: spacing(1),
  },
  link: {
    color: palette.common.black,
    fontSize: 10,
    fontWeight: 'bold',
  },
  separator: {
    color: palette.grey.primary,
    fontSize: 16,
    marginTop: 4,
  },
  text: { paddingTop: 4 },
  floatButton: {
    float: 'right',
    height: 30,
  },
}));

function Title({ className, children, floatButton, crumbs = [], prefix }) {
  const classes = useStyles();

  // TODO remove breakcrumb till better implementation
  return (
    <Grid className={clsx(className, classes.root)} item xs={12}>
      <div>
        {/* <Breadcrumbs
          separator={<ChevronRightIcon className={classes.separator} />}
        >
          {crumbs.length > 0 && crumbs.map(({ label, path }, index) => {
            return <Link
              key={index}
              className={classes.link}
              component={BrowserLink}
              to={path}
            >
              {label}
            </Link>
          })}
          {crumbs.length > 0 && <Link key={crumbs.length} underline="none" className={classes.link}>
            {prefix}
          </Link>}
        </Breadcrumbs> */}

        {prefix && (
          <Typography variant="h5">
            {prefix}
            {floatButton && (
              <TraxxButton
                onSubmit={() => {
                  floatButton.onClick();
                }}
                className={clsx(classes.floatButton, floatButton.className)}>
                {floatButton.text}
              </TraxxButton>
            )}
          </Typography>
        )}
      </div>

      {children}
    </Grid>
  );
}

export default Title;

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Title.defaultProps = {
  className: '',
  children: null,
};
