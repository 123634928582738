import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Container } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TraxxButton } from 'app/components';

const useStyles = makeStyles((theme) => ({
  bottomText: { margin: '12px 0' },
  button: { marginTop: '15px' },
  text: {
    margin: '30px',
    textAlign: 'center',
  },
  dialogBox: {
    padding: '20px 30px',
    [theme.breakpoints.down('500')]: {
      padding: '5px',
    },
  },
}));
/**
 * The renew screening dialog component to confirm whether the user wants to rescreen
 * @params - The props to open, close, submit and display loading animation
 * @returns - The renew screening confirmation dialog
 */
const RenewScreeningComp = (props) => {
  const { open, setOpen, loading, onSubmit } = props;
  const classes = useStyles();

  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Container className={classes.dialogBox}>
        <DialogTitle>Renew Screening Confirmation</DialogTitle>
        <DialogContent>
          <div>
            <DialogContentText>
              You will be charged <strong>4 credits</strong> to renew this screening.
            </DialogContentText>
            <DialogContentText className={classes.bottomText}>An email will be sent to you if there are any updates to the screening.</DialogContentText>
          </div>
          <TraxxButton className={classes.button} loading={loading} fullWidth onSubmit={onSubmit}>
            Confirm
          </TraxxButton>
        </DialogContent>
      </Container>
    </Dialog>
  );
};

export default RenewScreeningComp;
