import { ListingComp, ListingCompTable } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const headers = ['Name', 'Account ID', 'Created date', 'Status'];

const filterOptions = {
  sort: false,
  search: true,
  dateRange: false,
  type: false,
  status: true,
  clear: true,
};

const extraOptions = {
  status: {
    all: { value: '', name: 'All' },
    active: { value: 'active', name: 'Active' },
    new: { value: 'new', name: 'New' },
    suspended: { value: 'suspended', name: 'Suspended' },
  },
};

let mounted = false;
function AccountList({ className }) {
  const [accounts, setAccounts] = useState([]);
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [runListAccount] = useAsyncTask('accountList');

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const listAccounts = (newFilter = filter) => {
    setLoading(true);
    runListAccount(async () => {
      const { models, meta } = await API.Admin.list_accounts(newFilter);
      if (mounted) {
        setFilter({ ...newFilter, count: meta.count });
        sortListingData(models);
        setLoading(false);
        setLoaded(true);
      }
    });
  };

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      allData.push([{ label: model.name }, { label: model.reference, type: 'link', link: `/admin/accounts/${model.id}/detail` }, { date: model.created_at, type: 'date' }, { label: model.status, statusType: 'Account', type: 'status' }]);
    });

    setAccounts(allData);
  };

  return (
    <ListingComp filterOptions={filterOptions} extraOptions={extraOptions} loading={loading || loadTask.accountList} setFilter={setFilter} searchByText="Account ID" reloadFunc={listAccounts} loaded={loaded} filter={filter} setLoading={setLoading}>
      <ListingCompTable headers={headers} data={accounts} />
    </ListingComp>
  );
}

export default AccountList;
