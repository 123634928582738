import snakeToTitle from './snakeToTitle';

const MSG = {
  'password reset request': 'Requested password reset',
  'update password': 'Updated password',
  'init privileges': 'Initialised privileges',
  'update admin privileges': 'Updated admin privileges',
  'update account privileges': 'Updated account privileges',
  'create wallet': 'Created new wallet',
  'charge account': 'Charged account',
  'create person': 'Created new person profile',
  'create organisation': 'Created new organisation',
  'create credential': 'Created credential',
  'create account': 'Created new account',
  'join account': 'Joined account',
  registration: 'Registered new account',
  'invite user': 'Invited user to group',
  'cancel invite': 'Cancelled invitation request',
  'email verified': 'Verified email address',
  'create case': 'Created case screening',
  'create screen': 'Created screening request',
  'create verification': 'Created verification request',
  'perform screen': 'Performed screening request',
  'retrieve profile': 'Retrieved screening result profile',
};

export default function parseMessage(key) {
  return MSG[key] || snakeToTitle(key);
}
