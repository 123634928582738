import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { EditPrivilegeComp } from './components';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: 700,
  },
}));

function EditPrivilege() {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container item alignItems="center" justify="center" xs>
      <EditPrivilegeComp />
    </Grid>
  );
}

export default EditPrivilege;
