import { Box, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { AESDecrypt, CreditExpiry, Mails, OngoingScreening, ArticleRetrieval, DownloadImage } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
    justifyContent: 'center',
  },
  card: {
    maxWidth: 700,
    margin: 'auto',
  },
}));

const tabs = [
  { value: 'misc', label: 'Misc' },
  { value: 'mails', label: 'Mails' },
  { value: 'ongoing_screening', label: 'Ongoing Screening' },
  { value: 'aes_decrypt', label: 'AES Decrypt' },
  { value: 'article_retrieval', label: 'Article Retrieval' },
  { value: 'download_image', label: 'Download Image' },
];

const Manage = (props) => {
  const { children, className, routeType, ...rest } = props;
  const classes = useStyles();

  const [currentTab, setCurrentTab] = useState('misc');

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Box pl={3}>
        <Tabs onChange={handleTabsChange} scrollButtons="auto" textColor="secondary" value={currentTab} variant="scrollable">
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </Box>
      <Box mt={3}>
        {currentTab === 'misc' && <CreditExpiry />}
        {currentTab === 'mails' && <Mails />}
        {currentTab === 'ongoing_screening' && <OngoingScreening />}
        {currentTab === 'aes_decrypt' && <AESDecrypt />}
        {currentTab === 'article_retrieval' && <ArticleRetrieval />}
        {currentTab === 'download_image' && <DownloadImage />}
      </Box>
    </Box>
  );
};

export default Manage;
