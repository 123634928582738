import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from 'app/components';
import React from 'react';
import { ScreeningListComp } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
}));

const ScreeningResults = ({ routeType = 'account' }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Title prefix="Screenings" />
      <ScreeningListComp routeType={routeType} />
    </Box>
  );
};

export default ScreeningResults;
