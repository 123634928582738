import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Paths } from 'app/constants';
import { actions } from 'app/store';
import { useRouter } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import LoginButler from '../LoginButler';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

let mounted = false;

/**
 * AuthGuard to ensure user has valid token to have authorisation for access
 *
 * Props:
 ** children: child components to be wrapped by AuthGuard
 ** className: name of css class element
 */
const AuthGuard = (props) => {
  const { children, className, ...rest } = props;
  const router = useRouter();
  const self = useSelector((state) => state.profile.self);
  const accounts = useSelector((state) => state.profile.accounts);
  const lastRoute = useSelector((state) => state.profile.lastRoute);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  });

  useEffect(() => {
    if (!mounted) return;

    if (self) {
      const inviteToken = queryParams.get('invite');
      const routeOverride = !!inviteToken ? `/app/account-invite?invite=${inviteToken}` : undefined;

      let route = routeOverride || lastRoute || Paths.Dashboard.Overview;
      if (accounts?.length < 1) route = Paths.Dashboard.nothing;
      router.history.push(route);
      dispatch(actions.Profile.updateLastRoute(null));
    }
    // eslint-disable-next-line
  }, [self, lastRoute]);

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <LoginButler />
      {children}
    </Box>
  );
};

export default AuthGuard;
