export const TYPES = {
  ADD_BACKGROUND_LOADING: 'ADD_BACKGROUND_LOADING',
  REMOVE_BACKGROUND_LOADING: 'REMOVE_BACKGROUND_LOADING',
  UPDATE_GLOBAL_SHOW: 'UPDATE_GLOBAL_SHOW',
};

export function addBackgroundLoading(name, uuid) {
  return {
    type: TYPES.ADD_BACKGROUND_LOADING,
    name,
    uuid,
  };
}

export function removeBackgroundLoading(uuid) {
  return {
    type: TYPES.REMOVE_BACKGROUND_LOADING,
    uuid,
  };
}

export function updateGlobalShow(key, show) {
  return {
    type: TYPES.UPDATE_GLOBAL_SHOW,
    key,
    show,
  };
}
