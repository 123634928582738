import { ListingComp, ListingCompTable } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const headers = ['Name', 'Person ID', 'Email'];

const filterOptions = {
  sort: false,
  search: true,
  dateRange: false,
  type: false,
  status: false,
  clear: true,
};

const extraOptions = {};

let mounted = false;
function PersonList({ className }) {
  const [persons, setPersons] = useState([]);
  const loadTask = useSelector((state) => state.layout.loadingTasks);
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errorCatcher] = useAsyncTask('personList');

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const listPersons = (newFilter = filter) => {
    setLoading(true);
    errorCatcher(async () => {
      const { models, meta } = await API.Admin.list_persons(newFilter);
      if (mounted) {
        setFilter({ ...newFilter, count: meta.count });
        setLoaded(true);
        setLoading(false);
        sortListingData(models);
      }
    });
  };

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      allData.push([
        { label: `${model.firstname}${model.middlename ? ' ' + model.middlename : ''}${model.lastname ? ' ' + model.lastname : ''}` },
        { label: model.reference, type: 'link', link: `/admin/persons/${model.id}/detail` },
        { label: model.primary_email },
      ]);
    });

    setPersons(allData);
  };

  return (
    <ListingComp filter={filter} filterOptions={filterOptions} extraOptions={extraOptions} reloadFunc={listPersons} setFilter={setFilter} loaded={loaded} searchByText={'Person ID'} loading={loadTask.personList || loading} setLoading={setLoading}>
      {!!persons.length && <ListingCompTable headers={headers} data={persons} />}
    </ListingComp>
  );
}

export default PersonList;
