import SumsubWebSdk from '@sumsub/websdk-react';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { API } from 'app/services';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useAsyncTask } from 'app/utils';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 255,
    },
  },
  app: {
    height: '100vh',
    margin: 'auto',
  },
  text: {
    marginTop: '1.5rem',
  },
}));

const Sumsub = () => {
  //fill this in
  const applicantEmail = '';
  const applicantPhone = '';

  const classes = useStyles();

  const [accessToken, setAccessToken] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [loaded, setLoaded] = useState(false);
  const [runLoadRequestDetail] = useAsyncTask('loadRequest');

  useEffect(() => {
    //sends a request to traxx ID with the request token
    requestAccessToken(queryParams.get('token'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Requests for the sumsub access token from traxx ID
   * @token - The request token taken from the location query
   * @returns - The sumsub access token from traxx ID
   */
  const requestAccessToken = async (token) => {
    // token from email
    if (token) {
      runLoadRequestDetail(async () => {
        // sends token to transaction
        const result = await API.Auth.sumsub_transaction({ token });

        setAccessToken(result.token);
        // if (result.status === 'disabled') {
        //   setInvalidToken(true);
        // } else if (result.status === 'submitted' || result.status === 'completed') {
        //   setComplete(true);
        // } else {
        //   setClientToken(result.clientToken);
        //   if (result.transactionId) {
        //     setInvalidToken(false);
        //   }
        // }
        // setInvalidToken(false);
        setLoaded(true);
      });
    }
  };

  return (
    <Box className={classes.app}>
      {loaded && (
        <SumsubWebSdk
          accessToken={accessToken}
          updateAccessToken={() => requestAccessToken(queryParams.get('token'))}
          expirationHandler={() => Promise.resolve(accessToken)}
          config={{
            lang: 'ru-RU',
            email: applicantEmail,
            phone: applicantPhone,
            i18n: {
              document: {
                subTitles: {
                  IDENTITY: 'Upload a document that proves your identity',
                },
              },
            },
            onMessage: (type, payload) => {
              console.log('WebSDK onMessage', type, payload);
            },
            uiConf: {
              customCssStr:
                ':root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 150px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}',
            },
            onError: (error) => {
              console.error('WebSDK onError', error);
            },
          }}
          options={{ addViewportTag: false, adaptIframeHeight: true }}
          onMessage={(type, payload) => {
            console.log('onMessage', type, payload);
          }}
          onError={(data) => console.log('onError', data)}
        />
      )}
      <Typography className={classes.text}>Note: Verification may take up to 2 minutes.</Typography>
    </Box>
  );
};

export default Sumsub;
