import { Box, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton, TraxxInput } from 'app/components';
import { API } from 'app/services';
import { snakeToTitle, useAsyncTask, useRouter, validate } from 'app/utils';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    padding: theme.spacing(2),
  },
}));

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  price: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  sku: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  extras0: {
    presence: { allowEmpty: false, message: '^Credit amount is required' },
  },
};

const INIT_INPUTS = {
  price: '',
  price_currency: 'USD',
  fulfilment_type: 'digital',
  sku: '',
  name: '',
  type: 'credit_purchase',
  extras0: '',
};

const INIT_ERRORS = {
  price: '',
  price_currency: '',
  fulfilment_type: '',
  sku: '',
  name: '',
  type: '',
  extras0: '',
};

const ProductCreateComp = (props) => {
  const { children, className, ...rest } = props;
  const classes = useStyles();
  const [inputs, setInputs] = useState(INIT_INPUTS);
  const [errors, setErrors] = useState(INIT_ERRORS);
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const [runCreateProduct] = useAsyncTask('createProduct');
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();

  const createProduct = () => {
    let error = validate(inputs, schema);
    setErrors(error || {});
    if (error && Object.keys(error).length > 0) return enqueueSnackbar('Please enter all required fields', { variant: 'error' });
    runCreateProduct(async () => {
      const newInput = { ...inputs };
      newInput.price = parseInt(inputs.price) * 100;
      newInput.extras0 = parseInt(inputs.extras0) * 100;
      const result = await API.Admin.create_product(newInput);
      enqueueSnackbar('Product created', { variant: 'success' });
      router.history.push(`/admin/products/${result.model.id}/detail`);
    });
  };

  const handleChange = (type) => {
    return ({ target }) => setInputs({ ...inputs, [type]: target.value });
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Grid className={classes.container} container item component={Paper} xs={12} sm={12} md={10} lg={6}>
        <Grid item xs={12} sm={6}>
          <TraxxInput
            label="Package name"
            required={true}
            value={inputs.name}
            className={classes.input}
            InputProps={{
              classes: { underline: classes.underline },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange('name')}
            error={errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TraxxInput
            label="Credit amount"
            value={inputs.extras0}
            required={true}
            className={classes.input}
            InputProps={{
              classes: { underline: classes.underline },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            onChange={handleChange('extras0')}
            error={errors.extras0}
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ paddingTop: 10 + 'px' }}>
          <TraxxInput
            label="Price"
            value={inputs.price}
            required={true}
            className={classes.input}
            InputProps={{
              classes: { underline: classes.underline },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            onChange={handleChange('price')}
            error={errors.price}
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ paddingTop: 10 + 'px' }}>
          <TraxxInput
            label="SKU"
            value={inputs.sku}
            required={true}
            className={classes.input}
            InputProps={{
              classes: { underline: classes.underline },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange('sku')}
            error={errors.sku}
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ paddingTop: 10 + 'px' }}>
          <TraxxInput
            label="Currency"
            required={true}
            disabled={true}
            value={inputs.price_currency}
            className={classes.input}
            InputProps={{
              classes: { underline: classes.underline },
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ paddingTop: 10 + 'px' }}>
          <TraxxInput
            label="Type"
            required={true}
            disabled={true}
            value={snakeToTitle(inputs.type)}
            className={classes.input}
            InputProps={{
              classes: { underline: classes.underline },
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {/* <Grid item xs={12} sm={4}>
            <TraxxInput
              label="Fulfillment type"
              required={true}
              disabled={true}
              value={snakeToTitle(inputs.fulfilment_type)}
              className={classes.input}
              InputProps={{
                classes: { underline: classes.underline },
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid> */}
        <Grid item xs={12}>
          <Box mt={2} display="flex">
            <Box flexGrow={1} />
            <TraxxButton loading={loadTask.createProduct} onSubmit={createProduct}>
              Create product
            </TraxxButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductCreateComp;
