import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ListingComp, ListingCompTable } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    display: 'inline-flex',
    alignItems: 'center',
  },
  card: {
    marginTop: 10,
  },
  filters: {
    marginTop: theme.spacing(1),
  },
  type: {
    fontFamily: ['Open Sans', 'monospace'],
    borderRadius: 4,
    fontSize: 12,
    fontWeight: 'bold',
    padding: theme.spacing(0.5, 2),
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
  green: {
    backgroundColor: '#C9EFE1',
    color: '#2CC990',
  },
  yellow: {
    backgroundColor: '#fff2cb',
    color: '#F2C94E',
  },
  blue: {
    backgroundColor: '#b3ffff',
    color: '#00e6e6',
  },
  teal: {
    backgroundColor: ' #b3d9ff',
    color: '#1a8cff',
  },
  text: {
    marginTop: theme.spacing(0.2),
  },
}));

const headers = ['Transaction ID', 'Transaction time', 'Type', 'Source', 'Amount', 'Running amount'];

const admin_header = ['Account', 'Wallet', 'Transaction ID', 'Transaction time', 'Type', 'Source', 'Amount', 'Running amount'];

const header_parser = {
  Account: 'account',
  Wallet: 'wallet',
  'Transaction ID': 'reference',
  'Transaction time': 'timestamp',
  Type: 'type',
  Amount: 'amount',
  'Running amount': 'post_balance',
};

const filterOptions = {
  sort: false,
  search: true,
  dateRange: true,
  type: false,
  status: false,
  clear: true,
  // accountSearch: true,
};

const extraOptions = {};

let mounted = false;
const WalletTxList = (props) => {
  const { userType = 'account', wallet, reloadflag, setReloadflag, routeType, setExportFilter, children, className, ...rest } = props;
  const classes = useStyles();
  const [runloadWalletTx] = useAsyncTask('loadWalletTx');
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const [listing, setListing] = useState([]);
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [sortDetail, setSortDetail] = useState({});

  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  // useEffect(() => {

  // }, [sortDetail])

  const loadBillings = (newFilter = filter) => {
    if (setLoading) setLoading(true);
    runloadWalletTx(async () => {
      let loader = routeType === 'admin' ? API.Admin.list_wallet_tx : API.Wallet.list_wallet_tx;
      if (userType === 'admin') {
        loader = API.Admin.list_wallet_tx;
        if (wallet) newFilter.wallet = wallet.id;
      }
      const { models, meta } = await loader(newFilter, wallet?.id);
      if (mounted) {
        sortListingData(models);
        setFilter({ ...newFilter, count: meta.count });
        if (typeof setExportFilter === 'function') setExportFilter({ ...newFilter });
        if (setLoading && setLoaded) {
          setLoading(false);
          setLoaded(true);
        }
      }
    });
  };

  const typeFilter = (type) => {
    switch (type) {
      case 'admin_modify':
        return {
          label: 'Admin Modify',
          style: 'green',
        };
      case 'pay_screen':
        return {
          label: 'Screen Payment',
          style: 'yellow',
        };
      case 'pay_profile':
        return {
          label: 'Profile Payment',
          style: 'yellow',
        };
      case 'credit_purchase':
        return {
          label: 'Credit Purchase',
          style: 'yellow',
        };
      case 'pay_verification':
        return {
          label: 'Pay Verification',
          style: 'yellow',
        };
      case 'pay_report':
        return {
          label: 'Pay Report',
          style: 'yellow',
        };
      case 'pay_cached_article_retrieval':
        return {
          label: 'Pay Cached Article Retrieval',
          style: 'yellow',
        };
      case 'refund_cached_article_retrieval':
        return {
          label: 'Refund Cached Article Retrieval',
          style: 'green',
        };
      case 'pay_ongoing_screening':
        return {
          label: 'Ongoing Screen Payment',
          style: 'yellow'
        }
      case 'pay_ongoing_screening_renewal':
        return {
          label: 'Renew Ongoing Screen Payment',
          style: 'yellow'
        }
      default:
        return { label: type };
    }
  };

  const TypeLabel = ({ children }) => {
    const content = typeFilter(children);
    if (!content) return '-';
    return <span className={clsx(classes.type, content.style && classes[content.style])}>{content.label}</span>;
  };

  const getTargetScreenLink = (id) => {
    if (!id) return '';
    if (routeType === 'admin') return `/admin/screenings/${id}/detail`;
    else return `/screenings/${id}/detail`;
  };
  const getTargetVerificationLink = (id) => {
    if (!id) return '';
    if (routeType === 'admin') return `/admin/documents/${id}/detail`;
    else return `/documents/${id}/detail`;
  };
  const getTargetScreenProfileLink = (screening_id, match_id) => {
    if (!screening_id) return '';
    if (routeType === 'admin') return `/admin/screenings/${screening_id}/match/${match_id}/detail`;
    else return `/screenings/${screening_id}/match/${match_id}/detail`;
  };

  const getTransactionTarget = (model) => {
    switch (model.type) {
      case 'pay_profile':
      case 'credit_purchase':
      case 'admin_modify':
        return { type: 'custom', value: <></> };
      case 'pay_screen':
      case 'pay_ongoing_screening':
      case 'pay_ongoing_screening_renewal':
      case 'pay_report':
        return {
          type: 'labelwithlink',
          label: model.owner_screen?.screen_request?.params?.name,
          link: getTargetScreenLink(model.owner_screen?.id),
          bottomLabel: model.owner_screen?.reference,
        };
      case 'pay_verification':
        return {
          type: 'link',
          label: model.owner_verification?.reference,
          link: getTargetVerificationLink(model.owner_verification?.id),
        };
      case 'pay_cached_article_retrieval':
      case 'refund_cached_article_retrieval':
        return {
          type: 'labelwithlink',
          label: model.owner_cached_article_retrieval?.screen_profile?.screen_result?.name,
          link: getTargetScreenProfileLink(model.owner_cached_article_retrieval?.screen_profile?.screen_result?.screen_id, model.owner_cached_article_retrieval?.screen_profile?.screen_result?.id),
          bottomLabel: model.owner_cached_article_retrieval?.screen_profile?.screen_result?.reference,
        };
      default:
        return { label: '' };
    }
  };

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      const { reference, timestamp, post_balance, amount, type, wallet, comments, id, wallet_id } = model;
      if (routeType === 'admin') {
        allData.push([
          { label: wallet?.entity?.account?.name, type: 'labelwithlink', link: `/admin/accounts/${wallet_id || wallet?.entity?.account?.id}/detail`, bottomLabel: wallet?.entity?.account?.reference },
          { label: wallet?.reference, type: 'link', link: `/admin/wallets/${wallet.id}/detail` },
          { label: reference, monoText: true, type: 'link', link: `/admin/transaction/${id}/detail` },
          { date: timestamp, type: 'date' },
          {
            type: 'custom',
            value: (
              <>
                <TypeLabel>{type}</TypeLabel>
                <Typography className={classes.text}>{comments}</Typography>
              </>
            ),
          },
          getTransactionTarget(model),
          { type: 'currency', amount, decimal: 0 },
          { type: 'currency', amount: post_balance, decimal: 0 },
        ]);
      } else {
        allData.push([
          { label: reference, monoText: true, type: 'link', link: `/wallet/${wallet_id || wallet?.id}/transaction/${id}/detail` },
          { date: timestamp, type: 'date' },
          {
            type: 'custom',
            value: (
              <>
                <TypeLabel>{type}</TypeLabel>
                <Typography className={classes.text}>{comments}</Typography>
              </>
            ),
          },
          getTransactionTarget(model),
          { type: 'currency', amount, decimal: 0 },
          { type: 'currency', amount: post_balance, decimal: 0 },
        ]);
      }
    });

    setListing(allData);
  };

  const handleSort = (detail) => {
    setSortDetail(detail);
    let newFilter = filter;

    Object.values(header_parser).forEach((head) => {
      if (newFilter[head]) delete newFilter[head];
    });

    if (detail.header && detail.order) {
      newFilter.sort = `${header_parser[detail.header]}:${detail.order}`;
      // newFilter.column = header_parser[detail.header];
      // newFilter.order = detail.order
    }
    loadBillings(newFilter);
  };

  return (
    <Box className={classes.root} {...rest}>
      {/* <Typography className={classes.title}>Transactions</Typography> */}
      <ListingComp
        searchByText={'Transaction ID/Account Name'}
        filterOptions={filterOptions}
        extraOptions={extraOptions}
        filter={filter}
        setFilter={setFilter}
        reloadFunc={loadBillings}
        loaded={loaded}
        loading={loading || loadTask['loadWalletTx']}
        dateKey="date"
        reloadflag={reloadflag}
        setReloadflag={setReloadflag}>
        {!!listing.length && <ListingCompTable sortDetail={sortDetail} handleSort={handleSort} headers={routeType === 'admin' ? admin_header : headers} data={listing} />}
      </ListingComp>
    </Box>
  );
};

export default WalletTxList;
