import { Box, Card, CardActions, CardContent, CardHeader, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { TraxxButton } from 'app/components';
import { Alert } from '@material-ui/lab';
import { API } from 'app/services';
import { useSelector } from 'react-redux';
import { useAsyncTask } from 'app/utils';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
  },
  card: {
    maxWidth: 700,
    margin: 'auto',
  },
}));

const CreditExpiry = (props) => {
  const { children, className, ...rest } = props;
  const classes = useStyles();
  const [showConfirm, setShowConfirm] = useState(false);
  const loading = useSelector(({ layout }) => layout.loadingTasks);
  const [runUpdateCreditExpiry] = useAsyncTask('balanceCredit');
  const { enqueueSnackbar } = useSnackbar();

  const balanceCredit = () => {
    runUpdateCreditExpiry(async () => {
      await API.Admin.floating_credit_set_expiry();
      enqueueSnackbar('Credit expiration has been updated.');
      setShowConfirm(false);
    });
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Card className={classes.card}>
        <CardHeader title="Credit Setting" />
        <CardContent>
          <Alert severity="info">Add expiration to credits without expiry date.</Alert>
        </CardContent>
        <CardActions disableSpacing>
          {!showConfirm && (
            <Box width="100%" display="flex">
              <Box flexGrow={1} />
              <TraxxButton onSubmit={() => setShowConfirm(true)}>Update</TraxxButton>
            </Box>
          )}
          {showConfirm && (
            <Box p={1} width="100%">
              <Alert severity="warning"> Confirm update. </Alert>
              <Box width="100%" display="flex" mt={1}>
                <Button onClick={() => setShowConfirm(false)}>Cancel</Button>
                <Box flexGrow={1} />
                <TraxxButton loading={loading.balanceCredit} onSubmit={() => balanceCredit()}>
                  Confirm
                </TraxxButton>
              </Box>
            </Box>
          )}
        </CardActions>
      </Card>
    </Box>
  );
};

export default CreditExpiry;
