import { Box, Card, CardContent, CardHeader, Dialog, Divider, Grid, LinearProgress, Link, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import { Crumbs, DataRow, DateDisplay, MonoText, StatusLabel, Title, TraxxButton, TraxxInput } from 'app/components';
import { StatusMaps } from 'app/constants';
import { API } from 'app/services';
import { useAsyncTask, useRouter, useToastCatcher } from 'app/utils';
import CryptoJS from 'crypto-js';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as BrowserLink } from 'react-router-dom';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    padding: spacing(3, 3, 8),
    [breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
    [breakpoints.down('xs')]: {
      padding: spacing(1, 1, 3),
    },
  },
  card: {
    width: '100%',
  },
  head: {
    fontWeight: 'bold',
  },
  button: {
    marginTop: 10,
    width: '100%',
  },
  spaceTop: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  buttonMargin: {
    marginRight: spacing(2),
  },
  text: {
    whiteSpace: 'no-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  centering: {
    textAlign: 'center',
    padding: 10,
  },
  uploadFileBox: {
    maxHeight: 500,
    width: 625,
    padding: 10,
  },
  updateRequestBox: {
    maxHeight: 500,
    width: 700,
    padding: 20,
  },
  valueMaxWidth: {
    maxWidth: 600,
  },
  confirmUploadButton: {
    float: 'right',
  },
  fileHolder: {
    width: 300,
    textAlign: 'center',
  },
  uploadButton: {
    float: 'right',
  },
  clickable: {
    cursor: 'pointer',
  },
  selectFile: {
    width: 300,
    textAlign: 'center',
  },
  finalCost: {
    width: 225,
  },
  confirmUpdateButton: {
    width: 250,
    textAlign: 'right',
  },
}));

let mounted = false;
function CachedArticleRetrievalDetail({ routeType = 'account' }) {
  const classes = useStyles();
  const self = useSelector((state) => state.profile.self);
  const [superuser, setSuperuser] = useState(false);
  const [weblink, setWeblink] = useState(null);
  const loading = useSelector((state) => state.layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const [toaster, errorCatcher] = useToastCatcher({ errorParser: null, taskname: 'getCachedArticleRetrievalDetail' });
  const router = useRouter();
  const { match } = router;
  const { params } = match;
  const [openUploadFileDialog, setOpenUploadFileDialog] = useState(false);
  const [openUpdateRetrievalDialog, setOpenUpdateRetrievalDialog] = useState(false);
  const [selectedLink, setSelectedLink] = useState();
  const [requestedLinks, setRequestedLinks] = useState([]);
  const [document, setDocument] = useState();
  const [runUploadDocument] = useAsyncTask('uploadDocument');
  const { enqueueSnackbar } = useSnackbar();
  const [totalCost, setTotalCost] = useState();
  const [refundCost, setRefundCost] = useState();
  const [runUpdateCachedArticle] = useAsyncTask('updateCachedArticle');

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) {
      if (self.roles.includes('traxx.superadmin')) {
        setSuperuser(true);
      }
      getCachedArticleRetrievalDetail();
    }
    // eslint-disable-next-line
  }, [access]);

  const getCachedArticleRetrievalDetail = () => {
    if (!params.cached_article_retrieval_id) return toaster({ message: 'invalid id' });
    errorCatcher(async () => {
      const loader = routeType === 'account' ? API.Screening.detail_cached_article_retrieval : API.Admin.detail_cached_article_retrieval;
      const { model } = await loader(params.cached_article_retrieval_id);
      if (mounted) {
        setWeblink(model);
        buildRequestedLinks(model);
      }
    });
  };

  const buildRequestedLinks = (model) => {
    const requestedLinks = [];
    const modelLinks = JSON.parse(model.links);
    for (let i = 0; i < modelLinks.length; i++) {
      let exists = false;
      for (const document of model.documents) {
        if (i === document.cached_article_retrieval_has_document.link_id) {
          exists = true;
          requestedLinks.push({
            id: i,
            link: modelLinks[i],
            filename: document.filename,
            uri: document.uri,
          });
          break;
        }
      }
      if (exists === false) {
        requestedLinks.push({
          id: i,
          link: modelLinks[i],
        });
      }
    }
    const cost = Math.abs(model.wallet_tx.amount) / 100;
    setTotalCost(cost);
    const currentCost = model.documents.length * 10;
    setRefundCost(cost - currentCost);
    setRequestedLinks(requestedLinks);
  };

  const getEncryptWorldId = (worldID) => {
    worldID = worldID.match(/\d+/g);
    return CryptoJS.AES.encrypt(worldID.toString(), 'TRAXX').toString(); // Decrypt at https://www.browserling.com/tools/aes-decrypt
  };

  const sendOpenUploadFileDialog = (link) => {
    setSelectedLink(link);
    setOpenUploadFileDialog(true);
  };

  const sendCloseUploadFileDialog = () => {
    setSelectedLink();
    setOpenUploadFileDialog(false);
  };

  const sendOpenUpdateRetrievalDialog = () => {
    setOpenUpdateRetrievalDialog(true);
  };

  const sendClosedUpdateRetrievalDialog = () => {
    setOpenUpdateRetrievalDialog(false);
  };

  const downloadFile = (url) => {
    window.location.href = `${url}`;
  };

  const onFileInput = () => {
    return (event) => {
      const files = event.target.files;
      setDocument(files[0]);
    };
  };

  const onSubmitFileUpload = () => {
    if (!document) {
      enqueueSnackbar('No file selected', { variant: 'error', autoHideDuration: 3000 });
    } else {
      runUploadDocument(async () => {
        const form = new FormData();
        form.append('retrieved_file', document);
        form.append('link', selectedLink.link);
        await API.Admin.upload_cached_article_retrieval(weblink.id, form);
        setOpenUploadFileDialog(false);
        setDocument(null);
        window.location.reload(false);
        enqueueSnackbar('Cached copy uploaded!', { variant: 'success' });
      });
    }
  };

  const onSubmitRetrievalUpdate = () => {
    runUpdateCachedArticle(async () => {
      enqueueSnackbar('Retrieval update sent to Requester', { variant: 'success' });
      await API.Admin.update_cached_article_retrieval(weblink.id);
      sendClosedUpdateRetrievalDialog();
      window.location.reload(false);
    });
  };

  return (
    <Box className={classes.root}>
      <Crumbs current="Detail" links={[{ text: 'Cached Article Retrieval', href: routeType === 'admin' ? '/admin/Cached Article Retrieval' : '/Cached Article Retrieval' }]} />
      <Title prefix={'Cached Article Retrieval Detail'} />
      <Grid container spacing={2} item>
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper}>
            {loading['getCachedArticleRetrievalDetail'] && <LinearProgress size={30} />}
            {!loading['getCachedArticleRetrievalDetail'] && weblink && (
              <Table>
                <TableBody>
                  <DataRow name="Cached Article Retrieval ID" value={weblink.reference} />
                  <DataRow
                    name="Screen Match ID"
                    value={
                      <Link underline="always" component={BrowserLink} to={`/screenings/${weblink.screen_profile.screen_result.screen.id}/match/${weblink.screen_profile.screen_result.id}/detail`}>
                        <MonoText>{weblink.screen_profile.screen_result.reference}</MonoText>
                      </Link>
                    }
                  />
                  {weblink.screen_profile.pvd_ref && (<DataRow name="World-Check ID" value={<MonoText>{getEncryptWorldId(weblink.screen_profile.pvd_ref)}</MonoText>} />)}
                  {!weblink.screen_profile.pvd_ref && (<DataRow name="DJRC Profile ID" value={<MonoText>{getEncryptWorldId(weblink.screen_profile.screen_result.pvd_ref)}</MonoText>} />)}
                  <DataRow name="Status" value={<StatusLabel variantMap={StatusMaps.CachedArticleRetrieval}>{weblink.status}</StatusLabel>} />
                  <DataRow name="Created date" value={<DateDisplay date={weblink.created_at} />} />
                  <DataRow name="Download File By" value={<DateDisplay date={weblink.download_file_by} />} />
                  {routeType === 'admin' && (superuser || weblink.status === 'pending') && <DataRow name="" value={<TraxxButton onSubmit={sendOpenUpdateRetrievalDialog}>Update Retrieval</TraxxButton>} />}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardHeader title="Requested links" />
            <Divider />
            <CardContent>
              <Grid container spacing={2} item xs={12} md={12}>
                {weblink &&
                  requestedLinks.map((requestedLink, index) => (
                    <Grid key={index} item lg={12} md={12} xs={12}>
                      <Table>
                        <TableBody>
                          <DataRow name={'Link #' + (index + 1)} value={requestedLink.link} />
                          <TableRow>
                            <TableCell className={classes.fileHolder}>
                              {(routeType === 'admin' || (routeType === 'account' && weblink.status === 'completed')) && requestedLink.filename && (
                                <>
                                  <DescriptionIcon fontSize="large" /> <br />
                                  <u className={classes.clickable} onClick={() => downloadFile(requestedLink.uri)}>
                                    {requestedLink.filename}
                                  </u>
                                </>
                              )}
                              {((routeType === 'account' && weblink.status === 'pending') || !requestedLink.filename) && 'No cache article available'}
                            </TableCell>
                            <TableCell className={classes.uploadButton}>
                              {routeType === 'admin' && (superuser || weblink.status === 'pending') && <TraxxButton onSubmit={() => sendOpenUploadFileDialog(requestedLink)}>Upload File</TraxxButton>}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {openUpdateRetrievalDialog && (
          <Dialog maxWidth={'md'} open={openUpdateRetrievalDialog} onClose={sendClosedUpdateRetrievalDialog}>
            <Box className={classes.updateRequestBox}>
              <Typography variant="h5">Update Cached Article Retrieval Request:</Typography>
              <hr />
              <Table>
                <TableBody>
                  {requestedLinks.map((requestedLink, index) => (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <b>Link #{index + 1}:</b> <u>{requestedLink.link}</u>
                      </TableCell>
                      <TableCell>
                        {requestedLink.filename && <StatusLabel variant="success">Uploaded</StatusLabel>}
                        {!requestedLink.filename && <StatusLabel variant="error">Unavailable</StatusLabel>}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell className={classes.finalCost}>Total Cost: {totalCost} Credits</TableCell>
                    <TableCell className={classes.finalCost}>Refund Credits: {refundCost} Credits</TableCell>
                    <TableCell className={classes.confirmUpdateButton} colSpan={2}>
                      <TraxxButton onSubmit={onSubmitRetrievalUpdate}>Confirm Update</TraxxButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Dialog>
        )}
        {openUploadFileDialog && (
          <Dialog maxWidth={'md'} open={openUploadFileDialog} onClose={sendCloseUploadFileDialog}>
            <Box className={classes.uploadFileBox}>
              <Typography variant="h5" className={classes.centering}>
                Upload Cached Copy of Link:
              </Typography>
              <hr />
              <Typography variant="body1" className={classes.centering}>
                Link: &emsp; <u> {selectedLink.link} </u>
              </Typography>
              <hr />
              <Typography variant="body1" className={classes.centering}>
                {selectedLink.filename && (
                  <TableCell className={classes.fileHolder}>
                    Existing File: <br />
                    <DescriptionIcon fontSize="large" /> <br />
                    {selectedLink.filename}
                  </TableCell>
                )}
                {!selectedLink.filename && <TableCell className={classes.fileHolder}>No cache article uploaded</TableCell>}
                <TableCell className={classes.selectFile}>
                  <TraxxInput
                    type="file"
                    id="cached_article"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onFileInput()}
                    label="Select document"
                  />
                </TableCell>
              </Typography>
              <hr />
              <TraxxButton className={classes.confirmUploadButton} onSubmit={onSubmitFileUpload}>
                Confirm Upload
              </TraxxButton>
            </Box>
          </Dialog>
        )}
      </Grid>
    </Box>
  );
}

export default CachedArticleRetrievalDetail;
