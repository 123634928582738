import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DocumentListingComp } from 'app/views/Screening/Document/DocumentListing/components';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

function DocumentList(props) {
  const classes = useStyles();
  const { case_id, className, ...rest } = props;

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <DocumentListingComp hideCase={true} case_id={case_id} routeType="account" />
    </Box>
  );
}

export default DocumentList;
