import { store } from "app/store";
import { get_api_path } from "./api";
import { sessioned_client } from "./auth";

export async function list_users(filter = {}) {
  const url = get_api_path("user/list", filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function detail_users(user_id) {
  const url = get_api_path("user/detail", {}, { user_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function list_invite(filter = {}) {
  const url = get_api_path("user/invite/list", filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function create_invite(data) {
  const url = get_api_path("user/invite/create");
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function cancel_invite(invite_id) {
  const url = get_api_path("user/invite/cancel", {}, { invite_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function list_privilege() {
  const url = get_api_path("user/privilege/list");
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function set_privilege(user_id, data) {
  const url = get_api_path("user/privilege/set", null, { user_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function verify_submit(data) {
  const url = get_api_path("verify/submit");
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function verify_check(data) {
  const url = get_api_path("verify/check");
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function verify_update(verification_id) {
  const url = get_api_path("verify/update", null, { verification_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function verification_status_update(verification_id, { reason, status }) {
  const url = get_api_path("verify/status", null, { verification_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, { reason, status });
  return response.data.result;
}

export async function get_event_logs(filter) {
  const url = get_api_path("event/log", filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function send_verification_invites(data) {
  const url = get_api_path("account/verify/create");
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function send_sumsub_verification_invites(data) {
  const url = get_api_path("account/verify/create_sumsub");
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function list_verification_invites(filter) {
  const url = get_api_path("account/verify/list", filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_verification_invites(request_id) {
  const url = get_api_path("account/verify/detail", null, { request_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}


export async function verify_request(request_id) {
  const url = get_api_path("account/request/verify", null, { request_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function list_products(filter) {
  const url = get_api_path("account/product/list", filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_product_detail(product_id) {
  const url = get_api_path("account/product/detail", null, { product_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function list_orders(filter) {
  const url = get_api_path("account/order/list", filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_order_detail(order_id) {
  const url = get_api_path("account/order/detail", null, { order_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function product_order(product_id) {
  const url = get_api_path("account/product/order", null, { product_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function product_request(product_id) {
  const url = get_api_path("account/product/request", null, { product_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function order_pay(order_id) {
  const url = get_api_path("account/order/pay", null, { order_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function order_cancel(order_id) {
  const url = get_api_path("account/order/cancel", null, { order_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function list_payments(filter) {
  const url = get_api_path("account/payment/list", filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_payment_detail(payment_id) {
  const url = get_api_path("account/payment/detail", null, { payment_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function generate_report(screen_id) {
  const url = get_api_path("account/generate/report", null, { screen_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function resolve_matches(screen_id, data) {
  const url = get_api_path("account/resolve/matches", null, { screen_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function unresolve_matches(screen_id, data) {
  const url = get_api_path("account/unresolve/matches", null, { screen_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function send_links(screen_id, match_id, data) {
  const url = get_api_path("screen/match/cached_article", null, { screen_id, match_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function generate_key(account_entity_id, username, password, label) {
  const url = get_api_path("user/key/generate", null, { account_entity_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, { grant_type: "client_credentials", client_id: username, client_secret: password, label });
  return response.data.result;
}

export async function retrieve_keys(account_entity_id) {
  const url = get_api_path("user/key/retrieve", null, { account_entity_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function update_api_key(account_entity_id, api_key_id, data) {
  const url = get_api_path("user/key/update", null, { account_entity_id, api_key_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function remove_api_key(account_entity_id, api_key_id) {
  const url = get_api_path("user/key/remove", null, { account_entity_id, api_key_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function batch_process(data) {
  const url = get_api_path("screen/batch/create");
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}
