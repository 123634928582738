import { useSnackbar } from 'notistack';

export default function (error_parser) {
  const { enqueueSnackbar } = useSnackbar();
  return (content, { autoHideDuration = 5000, variant = 'error' } = {}) => {
    if (!content) return;
    let message = '';
    if (typeof error_parser === 'function') message = error_parser(content);
    else message = typeof content === 'string' ? content : content.message;

    if (Array.isArray(message)) {
      message.forEach((msg) => {
        enqueueSnackbar(msg, { variant, autoHideDuration });
      });
    } else enqueueSnackbar(message, { variant, autoHideDuration });
  };
}
