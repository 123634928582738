import { Box, FormControl, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SearchSelect, TraxxButton, TraxxInput } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask, useRouter } from 'app/utils';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  input: {
    marginBottom: theme.spacing(2),
  },
}));

const INIT_INPUTS = {
  firstname: '',
  middlename: '',
  lastname: '',
  nickname: '',
  primary_email: '',
  primary_phone: '',
  gender: '',
  salutation: '',
  password: '',
  account_id: '',
};

const INIT_ERRORS = {
  firstname: '',
  middlename: '',
  lastname: '',
  nickname: '',
  primary_email: '',
  primary_phone: '',
  gender: '',
  salutation: '',
  password: '',
  account_id: '',
};

let mounted = false;
const AccountCreateComp = (props) => {
  const { children, className, ...rest } = props;
  const classes = useStyles();
  const access = useSelector((state) => state.profile.access);
  const router = useRouter();
  const accountRef = new URLSearchParams(router.location.search).get('account');
  const [runLoadAccounts] = useAsyncTask('loadAccounts');
  const [runSubmitPerson] = useAsyncTask('submitPerson');
  const [accounts, setAccounts] = useState([]);
  const [inputs, setInputs] = useState(INIT_INPUTS);
  const [errors] = useState(INIT_ERRORS);
  const [filter] = useState(accountRef ? { limit: 10000, search: accountRef } : { limit: 10000 });
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) getAccounts();
    // eslint-disable-next-line
  }, [access]);

  const getAccounts = (newFilter = filter) => {
    runLoadAccounts(async () => {
      let { models } = await API.Admin.list_persons(newFilter);
      if (mounted) {
        models.forEach((element) => {
          element.value = element.reference;
        });
        setAccounts(models);
      }
    });
  };

  const handleChange = (type) => {
    return ({ target }) => setInputs({ ...inputs, [type]: target.value });
  };

  const onAccount = (ev, value, meme) => {
    setInputs({ ...inputs, account_id: value });
  };

  const onSubmit = () => {
    runSubmitPerson(async () => {
      let submit_input = {};
      Object.keys(inputs).forEach((key) => {
        if (inputs[key] || inputs[key] === 0) {
          if (key === 'account_id') {
            let selected_id;
            accounts.forEach((acc) => {
              if (acc.name === inputs[key]) selected_id = acc.id;
            });
            submit_input[key] = selected_id;
          } else submit_input[key] = inputs[key];
        }
      });
      const result = await API.Admin.create_person(submit_input);
      if (mounted) {
        enqueueSnackbar('Successfully created!', { variant: 'success' });
        router.history.push(`/admin/persons/${result.model.id}/detail`);
      }
    });
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Grid>
        <Grid component={Paper} className={clsx(classes.root, className)} container item xs={12} sm={12} md={10} spacing={2}>
          <Grid item xs={12} sm={6}>
            <TraxxInput
              label="Account name"
              input={inputs.firstname}
              required={true}
              error={errors.firstname}
              onChange={handleChange('firstname')}
              className={classes.input}
              InputProps={{
                classes: { underline: classes.underline },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth className={classes.input}>
              <SearchSelect label="Account" options={accounts} ignoreCase={false} inputValue={inputs.account_id} onInput={() => {}} onSelect={onAccount} />
            </FormControl>
          </Grid>

          <Box mt={2} display="flex">
            <TraxxButton loading={loadTask.submitPerson} onSubmit={onSubmit}>
              Submit
            </TraxxButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountCreateComp;
