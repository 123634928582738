import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ListingComp, ListingCompTable } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    marginTop: theme.spacing(1),
  },
}));

const headers = ['Payment ID', 'Order ID', 'Payment date', 'Status'];

const DEFAULT_ADMIN_HEADERS = ['Account ID', 'Payment ID', 'Order ID', 'Payment date', 'Status'];

const filterOptions = {
  sort: false,
  search: true,
  dateRange: true,
  type: false,
  status: true,
  clear: true,
};

const extraOptions = {
  status: {
    all: { value: '', name: 'All' },
    gateway_processing: { value: 'gateway_processing', name: 'Processing' },
    pending_payment: { value: 'pending_payment', name: 'Pending Payment' },
    completed: { value: 'completed', name: 'Completed' },
    failed: { value: 'failed', name: 'Failed' },
  },
};

let mounted = false;
const PaymentListComp = (props) => {
  const {
    showPaginator = true,
    // eslint-disable-next-line no-unused-vars
    showCheckbox = true,
    showFilter = true,
    // eslint-disable-next-line no-unused-vars
    full = true,
    orderId,
    limit = 10,
    ExtraAction,
    children,
    className,
    routeType = 'account',
    ...rest
  } = props;
  const classes = useStyles();
  const [runListPayment] = useAsyncTask('listPayment');
  const [screenings, setScreenings] = useState([]);
  const [filter, setFilter] = useState({ limit, offset: 0 });
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const [loading, setLoading] = useState();

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const loadPaymentList = (newFilter = filter) => {
    runListPayment(async () => {
      const loader = routeType === 'account' ? API.Users.list_payments : API.Admin.list_payments;
      const { models, meta } = await loader(newFilter);
      if (mounted) {
        sortListingData(models);
        setFilter({ ...newFilter, count: meta.count });
        setLoading(false);
      }
    });
  };

  const getPaymentLink = (id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/payment/${id}/detail`;
      default:
        return `/app/payment/${id}/detail`;
    }
  };

  const getOrderLink = (id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/order/${id}/detail`;
      default:
        return `/order/${id}/detail`;
    }
  };

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      if (routeType === 'admin') {
        allData.push([
          { label: model.account?.name, type: 'labelwithlink', bottomLabel: model.account?.reference, link: `/admin/accounts/${model.account?.id}/detail` },
          { label: model.reference, type: 'link', link: getPaymentLink(model.id) },
          { label: model.order?.reference, type: 'link', link: getOrderLink(model.order?.id) },
          { date: model.updated_at, type: 'date' },
          { label: model.status, statusType: 'Payment', type: 'status' },
        ]);
      } else {
        allData.push([
          { label: model.reference, type: 'link', link: getPaymentLink(model.id) },
          { label: model.order?.reference, type: 'link', link: getOrderLink(model.order?.id) },
          { date: model.updated_at, type: 'date' },
          { label: model.status, statusType: 'Payment', type: 'status' },
        ]);
      }
    });

    setScreenings(allData);
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <ListingComp
        filter={filter}
        setFilter={setFilter}
        showFilter={showFilter}
        ExtraAction={ExtraAction}
        setLoading={setLoading}
        showPaginator={showPaginator}
        loading={loadTask.listPayment || loading}
        filterOptions={filterOptions}
        extraOptions={extraOptions}
        reloadFunc={loadPaymentList}
        searchByText={'Payment ID'}
        dateKey="date">
        {screenings.length > 0 && <ListingCompTable headers={routeType === 'admin' ? DEFAULT_ADMIN_HEADERS : headers} data={screenings} />}
      </ListingComp>
    </Box>
  );
};

export default PaymentListComp;
