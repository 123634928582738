import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  image: {
    width: 500,
  },
}));

const PlaceholderScreen = (props) => {
  const { children, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <img className={classes.image} src={'/images/wip.svg'} alt="coming soon" />
      <Typography align="center" variant="h4">
        Page coming soon…
      </Typography>
    </Box>
  );
};

export default PlaceholderScreen;
