import { Box, Button, Paper, Radio, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton } from 'app/components';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {},
  typeOption: {
    alignItems: 'flex-start',
    display: 'flex',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    cursor: 'pointer',
  },
  stepButton: {
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const typeOptions = [
  {
    value: 'person',
    title: 'An individual person',
    description: 'Screen a person with their name, gender, birthdate, place of birth, and nationality.',
  },
  {
    value: 'organisation',
    title: 'An organisation',
    description: 'Look up an organisation with its name and country of registration.',
  },
];

const SelectType = (props) => {
  const { children, className, screen_type, onSelect, onBack, onNext, ...rest } = props;
  const classes = useStyles();

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Box mt={2}>
        <Typography variant="body1">What are you screening?</Typography>
      </Box>
      <Box mt={2}>
        {typeOptions.map((typeOption) => (
          <Paper className={classes.typeOption} elevation={screen_type === typeOption.value ? 3 : 1} key={typeOption.value} onClick={() => onSelect(typeOption.value)}>
            <Radio checked={screen_type === typeOption.value} onClick={() => onSelect(typeOption.value)} />
            <Box ml={2}>
              <Typography gutterBottom variant="h5" color="textPrimary">
                {typeOption.title}
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {typeOption.description}
              </Typography>
            </Box>
          </Paper>
        ))}
      </Box>
      <Box mt={6} display="flex">
        {onBack && <Button onClick={onBack}>Previous</Button>}
        <Box flexGrow={1} />
        <TraxxButton onSubmit={onNext}>Next</TraxxButton>
      </Box>
    </Box>
  );
};

export default SelectType;
