import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
  },
  svg: {
    width: 800,
    [theme.breakpoints.down('md')]: {
      width: 500,
    },
    [theme.breakpoints.down('sm')]: {
      width: 400,
    },
    [theme.breakpoints.down('xs')]: {
      width: 'unset',
    },
  },
}));

/**
 * SVG content
 *
 * Props:
 ** children: data to be wrapped
 ** className: name of css class element
 */
const SVGContent = (props) => {
  const { children, className, svg: SVGComponent, ...rest } = props;
  const classes = useStyles();

  if (!SVGComponent) return null;

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      {children}
      <SVGComponent className={classes.svg} />
    </Box>
  );
};

export default SVGContent;
