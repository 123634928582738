import { Box, Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { TraxxButton, TraxxInput } from 'app/components';
import clsx from 'clsx';
import CryptoJS from 'crypto-js';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
  },
  card: {
    maxWidth: 700,
    margin: 'auto',
  },
  box: {
    margin: '0 10px 10px 10px',
    width: '100%'
  }
}));

const CreditExpiry = (props) => {
  const { children, className, ...rest } = props;
  const [encoded, setEncoded] = useState('');
  const classes = useStyles();

  const decrypt = () => {
    const decrptEncoded = CryptoJS.AES.decrypt(encoded, 'TRAXX');
    setEncoded(decrptEncoded.toString(CryptoJS.enc.Utf8));
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Card className={classes.card}>
        <CardHeader title="AES Decrypt" />
        <CardContent>
          <Alert severity="info">Decrypt world-check one IDs and references</Alert>
        </CardContent>
        <CardActions>
          <Box className={classes.box} display="flex">
            <Box width="80%" display="flex">
              <TraxxInput
                label="Encoded"
                placeholder="Encoded"
                value={encoded}
                onChange={({ target }) => {
                  setEncoded(target.value);
                }}
              />
            </Box>
            <Box flexGrow={1} />
            <TraxxButton onSubmit={decrypt}>Decrypt</TraxxButton>
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
};

export default CreditExpiry;
