import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NotificationBars } from 'app/components';
import { createBrowserHistory } from 'history';
import React from 'react';
import { renderRoutes } from 'react-router-config';
import { Router } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import routes from './routes';

const history = createBrowserHistory();

const useStyles = makeStyles(({ palette }) => ({
  background: { backgroundColor: palette.background.main },
  container: { minHeight: '100vh' },
}));

function App() {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <NotificationBars>
          <Grid className={classes.container} container direction="column">
            <Router history={history}>{renderRoutes(routes)}</Router>
          </Grid>
        </NotificationBars>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default App;
