import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  button: (form) => ({
    height: form && 46,
    textTransform: 'none',
  }),
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    borderRadius: 10,
  },
}));

/**
 * Unique button for TRAXX.ID
 *
 * Props:
 ** children: data to be wrapped
 ** className: name of css class element
 */
function TraxxButton({ children, className, color = 'primary', form, variant, onSubmit, loading, disabled, ...props }) {
  const classes = useStyles(form);
  return (
    <Button className={clsx(classes.button, className)} color={color} variant={variant} {...props} onClick={onSubmit} disabled={!!loading || disabled}>
      {children}

      {loading && <CircularProgress className={classes.buttonProgress} value={24} size={24} />}
    </Button>
  );
}

export default TraxxButton;

TraxxButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  form: PropTypes.bool,
  variant: PropTypes.string,
};

TraxxButton.defaultProps = {
  className: '',
  form: false,
  variant: 'contained',
};
