const DataSourceParser = (result) => {
  switch (result) {
    case 0:
      return 'None';
    case 1:
      return 'Barcode 1D';
    case 2:
      return 'Barcode 2D';
    case 3:
      return 'Contactless Chip';
    case 4:
      return 'Machine Readable Zone';
    case 5:
      return 'Magnetic Stripe';
    case 6:
      return 'Visual Inspection Zone';
    default:
      return 'Other';
  }
};

const ClassificationModeParser = (result) => {
  switch (result) {
    case 0:
      return 'Automatic';
    case 1:
      return 'Manual';
    default:
      return 'Invalid';
  }
};

const GenderParser = (result) => {
  switch (result) {
    case 0:
      return 'Female';
    case 1:
      return 'Male';
    case 2:
      return 'Unknown';
    default:
      return 'Unspecified';
  }
};

const AlertResultParser = (result) => {
  switch (result) {
    case 1:
      return 'Passed';
    case 2:
      return 'Failed';
    case 3:
      return 'Skipped';
    case 4:
      return 'Caution';
    case 5:
      return 'Attention';
    default:
      return 'Unknown';
  }
};

const BerbixResultParser = (result) => {
  switch (result) {
    case 'accept': return "Accept";
    case 'reject': return "Reject";
    case 'review': return "Review";
    default: return "Unknown";
  }
}

export default {
  DataSourceParser, ClassificationModeParser,
  GenderParser, AlertResultParser, BerbixResultParser,
}
