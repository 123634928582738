import { Box, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { TraxxButton } from 'app/components';
import { useRouter, useAsyncTask, snakeToTitle } from 'app/utils';
import { API } from 'app/services';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  image: {
    maxWidth: 500,
  },
}));

const PaymentSuccess = (props) => {
  const { className } = props;
  const classes = useStyles();
  const router = useRouter();
  const [runLoadOrder] = useAsyncTask('loadOrderDetail');
  // const received_result = new URLSearchParams(router.location.search).get("result");
  const order_id = new URLSearchParams(router.location.search).get('order');
  const access = useSelector((state) => state.profile.access);
  const loadTask = useSelector((state) => state.layout.loadingTasks);
  const [order, setOrder] = useState();

  useEffect(() => {
    if (access && access.token) {
      if (order_id) {
        getOrder();
      }
    }
    // eslint-disable-next-line
  }, [access]);

  const getOrder = () => {
    runLoadOrder(async () => {
      const { model } = await API.Users.get_order_detail(order_id);
      if (model) setOrder(model);
    });
  };

  const renderContent = (status) => {
    switch (status) {
      case 'pending_payment':
      case 'gateway_processing':
        return (
          <>
            <img className={classes.image} src={'/images/wip.svg'} alt="pending" />
            <Typography align="center" variant="h4">
              {snakeToTitle(status)}
            </Typography>
          </>
        );
      case 'completed':
        return (
          <>
            <img className={classes.image} src={'/images/success.svg'} alt="success" />
            <Typography align="center" variant="h4">
              Payment success!
            </Typography>
          </>
        );
      case 'failed':
        return (
          <>
            <img className={classes.image} src={'/images/unsuccess.svg'} alt="unsuccess" />
            <Typography align="center" variant="h4">
              Payment failed!
            </Typography>
          </>
        );
      default:
        return (
          <>
            <img className={classes.image} src={'/images/wip.svg'} alt="pending" />
            <Typography align="center" variant="h4">
              Pending
            </Typography>
          </>
        );
    }
  };

  return (
    <Box className={clsx(classes.root, className)}>
      {loadTask.loadOrderDetail && <LinearProgress />}
      {!loadTask.loadOrderDetail && order && (
        <>
          {renderContent(order.payment?.status)}

          <TraxxButton
            onSubmit={() => {
              router.history.push(`/order/${order.id}/detail`);
            }}>
            Go to order detail
          </TraxxButton>
        </>
      )}
      {!loadTask.loadOrderDetail && !order_id && (
        <>
          <img className={classes.image} src={'/images/unsuccessful.svg'} alt="unsuccess" />
          <Typography align="center" variant="h4">
            No order found
          </Typography>
        </>
      )}
    </Box>
  );
};

export default PaymentSuccess;
