import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
}));

const TitleField = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <Typography className={classes.title} variant="h6">
      {children}
    </Typography>
  );
};

export default TitleField;
