import { Card, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { MonoText } from 'app/components';
import InitialsAvatar from 'app/components/InitialsAvatar';
import { snakeToTitle, useRouter } from 'app/utils';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  avatar: { marginRight: 10 },
  caption: { fontSize: 10 },
  container: {
    // height: 116,
    padding: 20,
    width: 360,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.5,
      // backgroundColor: rgba("")
    },
  },
  icon: { fontSize: 18 },
  name: { fontWeight: 800 },
  results: { marginRight: 20 },
  root: {},
  text: {
    margin: theme.spacing(2),
  },
  yellow: { color: theme.palette.status.yellow },
  grey: { color: '#CCCCCC' },
  dot: { fontSize: 14 },
  green: { color: theme.palette.status.green },
}));

const GroupCard = ({ grp }) => {
  const classes = useStyles();

  const { label, description, reference, id, active, newCase, archived } = grp;
  const router = useRouter();

  return (
    <Card>
      <Grid onClick={() => router.history.push(`/group/${id}/detail`)} className={classes.container} alignItems="space-between" container>
        <Grid container alignItems="center" justify="space-between">
          <Grid container alignItems="center" item xs>
            <InitialsAvatar className={classes.avatar} index={0}>
              {snakeToTitle(label)}
            </InitialsAvatar>

            <div>
              <Typography className={classes.name} color="secondary">
                {label}
              </Typography>
              <Typography className={classes.caption} color="secondary">
                {/* <strong>Created on</strong>&nbsp;{date}&nbsp;&nbsp; */}
                <strong>
                  <MonoText>{reference}</MonoText>
                </strong>
                {/* <strong>Edited</strong>&nbsp;{timeDifference} */}
              </Typography>
            </div>
          </Grid>

          {/* <MoreVertIcon /> */}
        </Grid>

        <Typography variant="body1" className={classes.text}>
          {description}
        </Typography>
        <Grid container>
          {/* <Grid item sm={3} xs={3} md={3} alignContent="center">
            <FolderIcon className={classes.icon} />
          &nbsp;
          <Typography color="secondary" variant="caption">
              {cases.length} cases
          </Typography>
          </Grid> */}
          <Grid item sm={4} xs={4} md={4} alignContent="center">
            <FiberManualRecordIcon className={clsx(classes.dot, classes.green)} />
            &nbsp;
            <Typography color="secondary" variant="caption">
              {active} cases
            </Typography>
          </Grid>
          <Grid item sm={4} xs={4} md={4} alignContent="center">
            <FiberManualRecordIcon className={clsx(classes.dot, classes.yellow)} />
            &nbsp;
            <Typography color="secondary" variant="caption">
              {newCase} cases
            </Typography>
          </Grid>
          <Grid item sm={4} xs={4} md={4} alignContent="center">
            <FiberManualRecordIcon className={clsx(classes.dot, classes.grey)} />
            &nbsp;
            <Typography color="secondary" variant="caption">
              {archived} cases
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default GroupCard;
