import { Box, Card, CardActions, CardContent, CardHeader, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton } from 'app/components';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  startButton: {
    flex: 1,
  },
}));

const KYCOption = (props) => {
  const { cardStyle, buttonStyle, children, className, header, description, ArtComponent, startButtonText, subheader, href, disabled = false, ...rest } = props;
  const classes = useStyles();

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Card className={cardStyle}>
        <CardHeader title={header} subheader={subheader} />
        {!cardStyle && <Divider />}
        <CardContent>
          <Link to={href}>
            <ArtComponent />
          </Link>
          <Typography>{description}</Typography>
        </CardContent>
        <CardActions>
          <TraxxButton component={Link} className={clsx(classes.startButton, buttonStyle)} to={href} disabled={disabled}>
            {startButtonText || 'Start'}
          </TraxxButton>
        </CardActions>
      </Card>
    </Box>
  );
};

export default KYCOption;
