import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TraxxButton from 'app/components/TraxxButton';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  button: {
    maxWidth: 330,
  },
  image: {
    height: 250,
    width: 250,
  },
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #f5f5f5',
    borderRadius: 6,
    boxShadow: '0px 2px 4px rgba(108, 133, 154, 0.111781)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 442,
    padding: theme.spacing(5),

    [theme.breakpoints.down('xs')]: {
      padding: '40px 25px',
      width: '90%',
    },
  },
  spacing: {
    marginBottom: theme.spacing(4),
  },
}));

/**
 * Upload card
 *
 * Props:
 ** buttonText: button value
 ** children: data to be wrapped
 ** className: name of css class element
 ** content: description
 ** header: header
 */
const UploadModalCard = ({ alt, buttonText, children, className, content, header, onButtonClick, src }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      {children}

      {!children && (
        <>
          {src && <img className={clsx(classes.image, classes.spacing)} src={src} alt={alt} />}

          <Typography className={classes.spacing} align="center" variant="h5">
            {header}
          </Typography>

          <Typography className={buttonText && onButtonClick && classes.spacing} align="center" color="secondary" variant="body2">
            {content}
          </Typography>

          {buttonText && onButtonClick && (
            <TraxxButton className={classes.button} form fullWidth onSubmit={onButtonClick}>
              {buttonText}
            </TraxxButton>
          )}
        </>
      )}
    </div>
  );
};

export default UploadModalCard;
