import { Badge, Box, Button, Checkbox, FormControlLabel, Grid, LinearProgress, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FlagIcon from '@material-ui/icons/Flag';
import HelpIcon from '@material-ui/icons/Help';
import { Alert } from '@material-ui/lab';
import { Listing, ListingCompTable, TraxxButton, TraxxInput } from 'app/components';
import { API } from 'app/services';
import { snakeToTitle, useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {},
  head: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  paper: {
    overflowX: 'auto',
  },
  listing: {
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',
  },
  resolveButton: {
    float: 'right',
    marginTop: theme.spacing(2),
  },
  inputReason: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  flagged: {
    color: '#2CC990',
  },
  unflagged: {
    color: '#CCCCCC',
  },
  clickable: {
    cursor: 'pointer',
  },
}));

const DEFAULT_HEADER = ['', 'Match', 'Rating', 'Flag', 'Type', 'Profile', 'Resolved', 'Resolve reason'];
const DOWJONES_HEADER = ['', 'Match', 'Rating', 'Match Score', 'Flag', 'Type', 'Profile', 'Resolved', 'Resolve reason'];
const COMPLYADVANTAGE_HEADER = ['', 'Match', 'Match Type', 'Flag', 'Resolved', 'Resolve reason'];

let mounted = false;
function MatchList(props) {
  const { screening_id, screen_provider_id, newPage, routeType = 'account', onLoad, flagged = 0 } = props;
  const classes = useStyles();
  const [matchList, setMatchList] = useState([]);
  const [filter, setFilter] = useState({ limit: 100, offset: 0, type: [] });
  const loading = useSelector((state) => state.layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const [header, setHeader] = useState(screen_provider_id === 1 ? [...DEFAULT_HEADER] : screen_provider_id === 2 ? [...DOWJONES_HEADER] : [...COMPLYADVANTAGE_HEADER]);
  let [selected, setSelected] = useState([]);
  const [rawList, setRawList] = useState();
  const [attributes, setAttributes] = useState({});
  const [showResolved, setShowResolved] = useState(false);
  const [showUpdated, setShowUpdated] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [noMatchOnly, setNoMatchOnly] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [runLoadMatchList] = useAsyncTask('getMatchList');
  const [runResolveNoMatch] = useAsyncTask('resolveNoMatch');
  const [runFlagMatch] = useAsyncTask('flagMatch');
  const [showConfirmResolve, setShowConfirmResolve] = useState(false);
  const [resolveReason, setResolveReason] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [flaggedNumber, setFlaggedNumber] = useState(flagged);

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) getMatching(filter);
    // eslint-disable-next-line
  }, [access]);

  const onPageChange = (newFilter) => {
    getMatching(newFilter);
  };
  /**
   * Gets the list of matches from screen_result in the DB
   * @params filter
   */
  const getMatching = (newFilter = filter) => {
    setMatchList(null);
    runLoadMatchList(async () => {
      const loader = routeType === 'account' ? API.Screening.get_matching_list : API.Admin.list_match;
      const { models, meta } = await loader(screening_id, { ...newFilter, limit: 100, offset: 0 });
      if (mounted) {
        setRawList(models);
        sortListingData(models);
        if (typeof onLoad === 'function') onLoad(models);
        setFilter({ ...meta });
      }
    });
  };
  /**
   * Handler for the 'Show Updated' checkbox to query for types of rescreens.
   * @param {event} - The event parameter, if the checkbox is checked or not
   * @returns - The updated filter and match list
   */
  const getUpdated = (event) => {
    const checked = event.target.checked;
    setShowUpdated(checked);
    if (checked && !showNew) {
      setFilter({ ...filter, type: 'updated' });
      getMatching({ ...filter, type: 'updated' });
    }
    if (checked && showNew) {
      setFilter({ ...filter, type: ['new', 'updated'] });
      getMatching({ ...filter, type: ['new', 'updated'] });
    }
    if (!checked && showNew) {
      setFilter({ ...filter, type: 'new' });
      getMatching({ ...filter, type: 'new' });
    }
    if (!checked && !showNew) {
      getMatching({ ...filter });
      setFilter({ ...filter });
    }
  };

  /**
   * Handler for the 'Show New' checkbox to query for types of rescreens.
   * @param {event} - The event parameter, if the checkbox is checked or not
   * @returns - The updated filter and match list
   */
  const getNew = (event) => {
    const checked = event.target.checked;
    setShowNew(checked);
    if (checked && !showUpdated) {
      setFilter({ ...filter, type: 'new' });
      getMatching({ ...filter, type: 'new' });
    }
    if (checked && showUpdated) {
      setFilter({ ...filter, type: ['new', 'updated'] });
      getMatching({ ...filter, type: ['new', 'updated'] });
    }
    if (!checked && showUpdated) {
      setFilter({ ...filter, type: 'updated' });
      getMatching({ ...filter, type: 'updated' });
    }
    if (!checked && !showUpdated) {
      getMatching({ ...filter });
      setFilter({ ...filter });
    }
  };

  const getMatchDetailPath = (screening_id, id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/screenings/${screening_id}/match/${id}/detail`;
      default:
        return `/screenings/${screening_id}/match/${id}/detail`;
    }
  };

  const getVariant = (res) => {
    switch (res) {
      case 'NOT_MATCHED':
        return <CancelIcon color="error" />;
      case 'MATCHED':
        return <CheckCircleIcon style={{ color: '#2cc990' }} />;
      default:
        return <HelpIcon style={{ color: '#f2c94e' }} />;
    }
  };

  const toggleMatch = (id, selectedArr, rawListing, resolveOverride) => {
    return () => {
      if (selectedArr.includes(id)) selectedArr.splice(selectedArr.indexOf(id), 1);
      else selectedArr.push(id);
      sortListingData(rawListing, selectedArr, resolveOverride);
    };
  };

  const sortListingData = (models, selectedArr = selected, resolveOverride = showResolved) => {
    let allData = [];
    let newHeader = header;
    models.forEach((model) => {
      if (model.resolved_at && !resolveOverride);
      else {
        let arr = [];
        if (screen_provider_id === 1) {
          arr = [
            {
              value: !!model.resolved_at ? <div></div> : <Checkbox checked={selectedArr.includes(model.id)} onClick={toggleMatch(model.id, selectedArr, models, resolveOverride)} />,
              type: 'custom',
              selected: selectedArr.indexOf(model.id) > -1,
            },
            { label: model.name, type: 'labelwithlink', link: getMatchDetailPath(screening_id, model.id), bottomLabel: model.reference, matchedTerm: model.matched_term, newPage: newPage },
            { label: snakeToTitle(model.match_rating), monoText: true },
            {
              value:
                model.flag === 'flagged' ? (
                  <FlagIcon className={clsx(classes.flagged, classes.clickable)} onClick={() => toggleFlag(models, model)} />
                ) : (
                  <FlagIcon className={clsx(classes.clickable, classes.unflagged)} onClick={() => toggleFlag(models, model)} />
                ),
              type: 'custom',
            },
            { label: model.match_type },
            { date: model.profile_retrieved_at, type: 'date' },
            { date: model.resolved_at, type: 'date' },
            { label: model.resolved_reason || '', type: 'description' },
          ];
        } else if (screen_provider_id === 2) {
          arr = [
            {
              value: !!model.resolved_at ? <div></div> : <Checkbox checked={selectedArr.includes(model.id)} onClick={toggleMatch(model.id, selectedArr, models, resolveOverride)} />,
              type: 'custom',
              selected: selectedArr.indexOf(model.id) > -1,
            },
            { label: model.name, type: 'labelwithlink', link: getMatchDetailPath(screening_id, model.id), bottomLabel: model.reference, matchedTerm: model.matched_term, newPage: newPage },
            { label: snakeToTitle(model.match_rating), monoText: true },
            { label: model.score, monoText: true },
            {
              value:
                model.flag === 'flagged' ? (
                  <FlagIcon className={clsx(classes.flagged, classes.clickable)} onClick={() => toggleFlag(models, model)} />
                ) : (
                  <FlagIcon className={clsx(classes.clickable, classes.unflagged)} onClick={() => toggleFlag(models, model)} />
                ),
              type: 'custom',
            },
            { label: model.match_type },
            { date: model.profile_retrieved_at, type: 'date' },
            { date: model.resolved_at, type: 'date' },
            { label: model.resolved_reason || '', type: 'description' },
          ];
        } else {
          arr = [
            {
              value: !!model.resolved_at ? <div></div> : <Checkbox checked={selectedArr.includes(model.id)} onClick={toggleMatch(model.id, selectedArr, models, resolveOverride)} />,
              type: 'custom',
              selected: selectedArr.indexOf(model.id) > -1,
            },
            { label: model.name, type: 'labelwithlink', link: getMatchDetailPath(screening_id, model.id), bottomLabel: model.reference, matchedTerm: model.matched_term, newPage: newPage },
            { label: snakeToTitle(model.match_rating), monoText: true },
            // { label: model.score, monoText: true },
            {
              value:
                model.flag === 'flagged' ? (
                  <FlagIcon className={clsx(classes.flagged, classes.clickable)} onClick={() => toggleFlag(models, model)} />
                ) : (
                  <FlagIcon className={clsx(classes.clickable, classes.unflagged)} onClick={() => toggleFlag(models, model)} />
                ),
              type: 'custom',
            },
            // { label: model.match_type },
            // { date: model.profile_retrieved_at, type: 'date' },
            { date: model.resolved_at, type: 'date' },
            { label: model.resolved_reason || '', type: 'description' },
          ];
        }
        const matchAttributes = JSON.parse(model.match_attributes);
        if (matchAttributes && matchAttributes.length > 0) {
          matchAttributes.forEach((attribute) => {
            if (!newHeader.includes(snakeToTitle(attribute.field))) {
              newHeader.push(snakeToTitle(attribute.field));
              if (!attributes[attribute.field]) attributes[attribute.field] = false;
            }
            arr.push({
              type: 'custom',
              value: getVariant(attribute.result),
            });
          });
        }

        allData.push(arr);
      }
    });
    setHeader(newHeader);
    setMatchList(allData);
  };

  const sortSelected = ({ resolve = showResolved, nomatch = noMatchOnly, attrs = attributes, allSelected = selectAll }, keepSelected = false) => {
    return () => {
      setShowResolved(resolve);
      setNoMatchOnly(nomatch);
      setAttributes(attrs);
      setSelectAll(allSelected);

      let newSelected = [];
      if (keepSelected) newSelected = selected;
      rawList.forEach((raw) => {
        if (allSelected && !raw.resolved_at) {
          newSelected.push(raw.id);
        }
        if (!(!showResolved && raw.resolved_at)) {
          const matchAttributes = JSON.parse(raw.match_attributes);
          matchAttributes.forEach((attr) => {
            if (attrs[attr.field]) {
              if (nomatch && attr.result === 'MATCHED')
                if (!newSelected.includes(raw.id)) newSelected.push(raw.id);
                else;
            }
          });
        }
      });
      setSelected(newSelected);
      sortListingData(rawList, newSelected, resolve);
    };
  };

  const resolve = () => {
    if (selected.length < 1) return enqueueSnackbar('No selected profile', { variant: 'error' });
    if (!resolveReason) return enqueueSnackbar('Please state a reason', { variant: 'error' });
    runResolveNoMatch(async () => {
      await API.Users.resolve_matches(screening_id, { resolve_ids: selected, resolve_reason: resolveReason });
      setShowConfirmResolve(false);
      getMatching();
      sortSelected(selected.splice(0, selected.length));
    });
  };

  const toggleFlag = (models, model) => {
    const newFlag = model.flag === 'flagged' ? 'not_flagged' : 'flagged';
    let flagCount = 0;
    models.forEach((mod) => {
      if (mod.id === model.id) mod.flag = newFlag;
      if (mod.flag === 'flagged') flagCount++;
    });
    setFlaggedNumber(flagCount);
    sortListingData(models);
    runFlagMatch(async () => {
      await API.Screening.flag_match(screening_id, model.id, { flag: newFlag });
    });
  };

  return (
    <Paper>
      {loading.getMatchList && <LinearProgress size={30} />}
      {!loading.getMatchList && matchList && (
        <Listing title="Match Lists" filter={filter} className={classes.listing} loading={loading.getMatchList} customlimit={100} rowsPerPageArr={[100, 200, 500, 1000]} onPageChange={onPageChange}>
          <Box ml={1} display="flex" p={2}>
            <Box display="inline">
              <FormControlLabel control={<Checkbox onClick={sortSelected({ resolve: !showResolved }, true)} checked={showResolved} />} label="Show resolved" />
              <FormControlLabel control={<Checkbox onChange={getUpdated} checked={showUpdated} />} label="Show Updated" />
              <FormControlLabel control={<Checkbox onChange={getNew} checked={showNew} value="test" />} label="Show New" />
              {/* <FormControlLabel control={<Checkbox onClick={sortSelected({ nomatch: !noMatchOnly }, true)} checked={noMatchOnly} />} label="No match only" /> */}
              <FormControlLabel control={<Checkbox onClick={sortSelected({ allSelected: !selectAll }, false)} checked={selectAll} />} label="Select all" />
              <Badge badgeContent={flaggedNumber} color="primary">
                <FlagIcon className={classes.flagged} />
              </Badge>
            </Box>
            <Box flexGrow="1" />
            <Box display="inline">
              {Object.keys(attributes).map((attr, index) => (
                <FormControlLabel control={<Checkbox onClick={sortSelected({ attrs: { ...attributes, [attr]: !attributes[attr] } })} />} label={snakeToTitle(attr)} key={index} />
              ))}
            </Box>
          </Box>
          <Box display="row-reverse" p={2} mb={2} flexGrow={1}>
            {showConfirmResolve && selected.length > 0 && (
              <Box display="block">
                <Alert severity="warning">Resolve selected?</Alert>
                <Box display="flex" mt={1}>
                  <Button onClick={() => setShowConfirmResolve(false)}>Cancel</Button>
                  <Box flexGrow="1" />
                  <TraxxButton onSubmit={resolve}>Confirm</TraxxButton>
                </Box>
              </Box>
            )}
            {!showConfirmResolve && selected.length > 0 && (
              <Box display="inline" marginLeft={2}>
                <Grid className={classes.inputReason} container spacing={2}>
                  <Grid item md={10} xs={12} sm={10}>
                    <TraxxInput
                      label="Reason for resolution"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={({ target }) => setResolveReason(target.value)}
                    />
                  </Grid>
                  <Grid item md={2} xs={12} sm={2}>
                    <TraxxButton className={classes.resolveButton} onSubmit={() => setShowConfirmResolve(true)}>
                      Resolve
                    </TraxxButton>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          {matchList.length > 0 && <ListingCompTable headers={header} data={matchList} />}
        </Listing>
      )}
    </Paper>
  );
}

export default MatchList;
