import { Button, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { SnackbarProvider } from 'notistack';
import React, { useRef } from 'react';

const useStyles = makeStyles({
  icon: {
    color: '#fff',
  },
});

/**
 * Notification bars to be presented on bottom left
 *
 * Props:
 ** children: notifications to be shown in a stack
 */
const NotificationBars = (props) => {
  const { children } = props;
  const classes = useStyles();

  const ref = useRef();
  const onClickDismiss = (key) => {
    return () => {
      ref.current.closeSnackbar(key);
    };
  };

  return (
    <SnackbarProvider
      maxSnack={5}
      ref={ref}
      action={(key) => (
        <Button onClick={onClickDismiss(key)}>
          <CloseIcon className={classes.icon} />
        </Button>
      )}>
      {children}
    </SnackbarProvider>
  );
};

NotificationBars.propTypes = {};

export default NotificationBars;
