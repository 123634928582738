import { Box, Grid, LinearProgress, Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useRouter, useAsyncTask } from 'app/utils';
import { API } from 'app/services';
import { DataRow, DateDisplay, TabComp, Title, Crumbs } from 'app/components';
import { useSelector } from 'react-redux';
import { CaseListComp } from './components';

const useStyles = makeStyles((theme) => ({
  root: {},
  table: {
    borderRadius: 5,
  },
}));
let mounted = false;
const GroupDetailComp = () => {
  const classes = useStyles();
  const router = useRouter();
  const group_id = router.match.params.group_id;
  const [runLoadGroupDetail] = useAsyncTask('loadGroup');
  const [group, setGroup] = useState();
  const token = useSelector(({ profile }) => profile.access?.token);
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (token) loadGroupDetail();
    // eslint-disable-next-line
  }, [token]);

  const loadGroupDetail = () => {
    runLoadGroupDetail(async () => {
      const { model } = await API.Group.get_group(group_id);
      if (mounted) {
        setGroup(model);
      }
    });
  };

  return (
    <Box className={classes.root}>
      <Crumbs current="Detail" links={[{ text: 'Groups', href: '/groups' }]} />
      {!loadTask.loadGroup && group && <Title prefix="Group Detail" floatButton={{ text: 'Add cases', onClick: () => router.history.push(`/screenings/cases?caseRef=${group.reference}`) }} />}
      <Grid container spacing={2}>
        <Grid item md={4} xs={12} sm={12}>
          {loadTask.loadGroup && <LinearProgress size={30} />}
          {!loadTask.loadGroup && group && (
            <TableContainer component={Paper} className={classes.table}>
              <Table>
                <TableBody>
                  <DataRow name="Label" value={group.label} />
                  <DataRow name="Group ID" value={group.reference} />
                  <DataRow name="Description" value={group.description} />
                  <DataRow name="Created at" value={<DateDisplay date={group.created_at} />} />
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        {!loadTask.loadGroup && group && (
          <Grid item md={8} xs={12} sm={12}>
            <TabComp tabTitle="Group tabs" tabHeaders={['Cases']} tabContent={[<CaseListComp group={group} onReload={loadGroupDetail} cases={group?.cases} />]} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default GroupDetailComp;
