import { LinearProgress, makeStyles } from '@material-ui/core';
import { NavHeader } from 'app/components';
import React, { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    minHeight: 'calc(100vh - 69px)',
    paddingBottom: 69,
  },
}));

const PublicLayout = (props) => {
  const { route } = props;
  const classes = useStyles();

  return (
    <>
      <NavHeader hideNav={true} />
      <main className={classes.content}>
        <Suspense fallback={<LinearProgress />}>{renderRoutes(route.routes)}</Suspense>
      </main>
    </>
  );
};

export default PublicLayout;
