import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';

const useStyles = makeStyles(({ palette }) => ({
  button: {
    height: 50,
    width: 102,
  },
  buttonText: {
    color: palette.red.primary,
    fontSize: 10,
    fontWeight: 'bold',
  },
}));

const ViewAllButtonRow = ({ text = 'VIEW ALL', onClick }) => {
  const classes = useStyles();

  return (
    <Grid container item justify="flex-end" xs={12}>
      <Button onClick={onClick} className={classes.button} endIcon={<ChevronRightIcon />}>
        <Typography className={classes.buttonText}>{text}</Typography>
      </Button>
    </Grid>
  );
};

export default ViewAllButtonRow;
