import { Avatar, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(({ palette }) => ({
  avatar: {
    height: 16,
    width: 16,
  },
  button: {
    boxShadow: `inset 0px -1px 0px ${palette.grey.primary}`,
    borderRadius: 0,
    padding: '14px 5px 14px 0',
    textTransform: 'none',
  },
  buttonChild: { marginLeft: 20 },
  count: {
    color: palette.common.white,
    fontSize: 11,
    fontWeight: 'bold',
  },
  endIcon: { marginLeft: 5 },
  iconSizeMedium: {
    '& > :first-child': { fontSize: 11 },
  },
  selected: { boxShadow: `inset 0px -2px 0px ${palette.red.primary}` },
  tab: { minWidth: 0 },
}));

const TableTabs = ({ children, className, onSelect, selected }) => {
  const classes = useStyles();

  return (
    <Grid className={className} container item xs={12}>
      {children.map(({ count, to, value }, index) => (
        <Button
          key={value}
          classes={{
            endIcon: classes.endIcon,
            iconSizeMedium: classes.iconSizeMedium,
          }}
          className={clsx(classes.button, {
            [classes.buttonChild]: index !== 0,
            [classes.selected]: value === selected,
          })}
          component={Link}
          endIcon={
            count && (
              <Avatar className={classes.avatar}>
                <Typography className={classes.count}>{count}</Typography>
              </Avatar>
            )
          }
          onClick={() => onSelect(value)}
          to={to}>
          {startCase(value)}
        </Button>
      ))}
    </Grid>
  );
};

export default TableTabs;

TableTabs.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      to: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  onSelect: PropTypes.func,
  selected: PropTypes.string,
};

TableTabs.defaultProps = {
  className: '',
  children: [],
  onSelect: () => {},
  value: '',
};
