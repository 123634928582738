import AuthLayout from 'app/layout/Auth';
import DashboardLayout from 'app/layout/Dashboard';
import PublicLayout from 'app/layout/Public';
import AccountCreate from 'app/views/Account/AccountCreate';
import AccountDetail from 'app/views/Account/AccountDetail';
import AccountList from 'app/views/Account/AccountList';
import ForgetPasswordRequest from 'app/views/ForgetPasswordRequest';
import BatchKYC from 'app/views/KYC/BatchKYC';
import NewKYC from 'app/views/KYC/NewKYC';
import NewScreening from 'app/views/KYC/NewScreening';
import Login from 'app/views/Login';
import EditPrivilege from 'app/views/Person/EditPrivilege';
import PersonCreate from 'app/views/Person/PersonCreate';
import PersonDetail from 'app/views/Person/PersonDetail';
import PersonList from 'app/views/Person/PersonList';
import ResetPasswordRequest from 'app/views/ResetPasswordRequest';
import CaseDetail from 'app/views/Screening/Case/CaseDetail';
import CaseList from 'app/views/Screening/Case/CaseList';
import Screening from 'app/views/Screening/Dashboard';
import MatchDetail from 'app/views/Screening/MatchDetail';
import ScreeningDetail from 'app/views/Screening/ScreeningDetail';
import ScreeningList from 'app/views/Screening/ScreeningList'; // screening list
import SignUp from 'app/views/SignUp';
import Wallets from 'app/views/Wallet/AccountTransactions';
import React from 'react';
import { Redirect } from 'react-router-dom';
import CreateKey from './views/APIReference/CreateKey';
import References from './views/APIReference/References';
import DocumentUpload from './views/DocumentUpload';
import DocumentUploadV2 from './views/DocumentUploadV2';
// import DocumentScreening from "./views/DocumentScreening";
import EventLog from './views/EventLog/EventLogList';
import GroupDetail from './views/Groups/GroupDetail';
import GroupListing from './views/Groups/GroupListing';
import JoinAccount from './views/JoinAccount';
import FullScreening from './views/KYC/FullKYC';
import NewDocumentScreening from './views/KYC/NewDocumentScreening';
// import MaintenanceScreen from './views/MaintenanceScreen';
import Manage from './views/Manage';
// import PlaceholderScreen from "./views/PlaceholderScreen";
import ProductCreation from './views/Product/ProductCreation';
import ProductDetail from './views/Product/ProductDetail';
import ProductList from './views/Product/ProductList';
import SampleView from './views/SampleView';
import CachedArticleRetrievalDetail from './views/Screening/CachedArticleRetrieval/CachedArticleRetrievalDetail/CachedArticleRetrievalDetail';
import CachedArticleRetrievalList from './views/Screening/CachedArticleRetrieval/CachedArticleRetrievalList/CachedArticleRetrievalList';
import DocumentDetail from './views/Screening/Document/DocumentDetail';
import DocumentListing from './views/Screening/Document/DocumentListing';
import VerificationDetail from './views/Screening/Verification/VerificationDetail';
import VerificationListing from './views/Screening/Verification/VerificationListing';
import UserList from './views/Users/UserList';
import UsersDetail from './views/Users/UsersDetail';
import UsersInvites from './views/Users/UsersInvites';
import WalletTXList from './views/Wallet/AdminTxListing';
import CreditListing from './views/Wallet/Credit/CreditListing';
// import CreditPurchase from "./views/Wallet/CreditPurchase/CreditPurchase";
// import ManualPurchase from "./views/Wallet/CreditPurchase/ManualPurchase";
import ContactUs from './views/Wallet/CreditPurchase/ContactUs';
import OrderDetail from './views/Wallet/OrderDetail';
import OrderHistory from './views/Wallet/OrderHistory';
import PaymentDetail from './views/Wallet/Payment/PaymentDetail';
import PaymentListing from './views/Wallet/Payment/PaymentListing';
import PaymentResult from './views/Wallet/Payment/PaymentResult';
import WalletTXDetail from './views/Wallet/TransactionDetail';
import WalletDetail from './views/Wallet/WalletDetail';
import WalletList from './views/Wallet/WalletList';
import VerifyEmail from './views/SignUp/VerifyEmail';
import TraxxIDTerms from './views/SignUp/TraxxIDTerms';
import PrivacyPolicy from './views/SignUp/PrivacyPolicy';

import { Sumsub } from './views/DocumentUploadV3';

const route = (path, component, { exact, routes, access, roles } = {}) => {
  return { path, component, exact, routes, access, roles };
};
const exactRoute = (path, component, opts = {}) => {
  return route(path, component, {
    ...opts,
    exact: true,
  });
};
const redirectRoute = (path = undefined, to, opts = {}) => {
  return route(path, () => <Redirect to={to} />, opts);
};

const routes = [
  redirectRoute('/', '/dashboard', { exact: true }),
  route('/access', AuthLayout, {
    routes: [
      exactRoute('/access/forget-password/request', ForgetPasswordRequest),
      exactRoute('/access/reset-password/request', ResetPasswordRequest),
      exactRoute('/access/login', Login),
      exactRoute('/access/signup', SignUp),
      redirectRoute(undefined, '/access/login'),
    ],
  }),
  route('/verify', PublicLayout, {
    routes: [
      exactRoute('/verify/document-upload', DocumentUpload),
      exactRoute('/verify/document-upload-v2', DocumentUploadV2),
      exactRoute('/verify/email', VerifyEmail),
      exactRoute('/verify/sumsub', Sumsub),
      // redirectRoute('/verify/email/:token', '/access/login')
    ],
  }),
  route('/public', AuthLayout, {
    routes: [exactRoute('/public/terms-conditions', TraxxIDTerms), exactRoute('/public/privacy-policy', PrivacyPolicy), redirectRoute(undefined, '/access/login')],
  }),
  route('*', DashboardLayout, {
    routes: [
      exactRoute('/nothing', SampleView),
      exactRoute('/dashboard', Screening),
      exactRoute('/kyc/new', NewKYC),
      exactRoute('/app/account-invite', JoinAccount, {
        access: 'account',
      }),
      exactRoute('/admin/persons', PersonList, {
        access: 'traxx',
        roles: 'user_manager',
      }),
      exactRoute('/admin/persons/:person_id/detail', PersonDetail, {
        access: 'traxx',
        roles: 'user_manager',
      }),
      exactRoute('/admin/persons/:person_id/privilege/edit', EditPrivilege, {
        access: 'traxx',
        roles: 'user_manager',
      }),
      exactRoute('/admin/person/create', PersonCreate, {
        access: 'traxx',
        roles: 'user_manager',
      }),
      exactRoute('/admin/accounts', AccountList, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/accounts/:account_id/detail', AccountDetail, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/account/create', AccountCreate, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/product/create', ProductCreation, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/products', ProductList, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/products/:product_id/detail', ProductDetail, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/cached_article_retrieval', () => <CachedArticleRetrievalList routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/cached_article_retrieval/:cached_article_retrieval_id/detail', () => <CachedArticleRetrievalDetail routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/screenings', () => <ScreeningList routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/screenings/:screening_id/detail', () => <ScreeningDetail routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/screenings/cases', () => <CaseList routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/screenings/cases/:case_id/detail', () => <CaseDetail routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/screenings/:screening_id/match/:match_id/detail', () => <MatchDetail routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/verifications', () => <VerificationListing routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/verifications/:request_id/detail', () => <VerificationDetail routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/documents', () => <DocumentListing routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/documents/:document_id/detail', () => <DocumentDetail routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/wallets', WalletList, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/wallet-transactions', WalletTXList, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/transaction/:transaction_id/detail', () => <WalletTXDetail routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/wallets/:wallet_id/detail', WalletDetail, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/payments', () => <PaymentListing routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/payment/:payment_id/detail', () => <PaymentDetail routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/order-history', () => <OrderHistory routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/order/:order_id/detail', () => <OrderDetail routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/credit-usage', () => <Wallets routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager',
      }),
      exactRoute('/admin/logs', () => <EventLog routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager|user_manager',
      }),
      exactRoute('/admin/manage', () => <Manage routeType="admin" />, {
        access: 'traxx',
        roles: 'account_manager|user_manager',
      }),
      exactRoute('/cached_article_retrieval', CachedArticleRetrievalList, {
        access: 'account',
        roles: 'owner|compliance_manager|compliance_user',
      }),
      exactRoute('/cached_article_retrieval/:cached_article_retrieval_id/detail', CachedArticleRetrievalDetail, {
        access: 'account',
        roles: 'owner|compliance_manager|compliance_user',
      }),
      exactRoute('/screenings', ScreeningList, {
        access: 'account',
        roles: 'owner|compliance_manager|compliance_user',
      }),
      exactRoute('/screenings/:screening_id/detail', ScreeningDetail, {
        access: 'account',
        roles: 'owner|compliance_manager|compliance_user',
      }),
      exactRoute('/screenings/:screening_id/match/:match_id/detail', MatchDetail, {
        access: 'account',
        roles: 'owner|compliance_manager|compliance_user',
      }),
      exactRoute('/screenings/create', NewScreening, {
        access: 'account',
        roles: 'owner|compliance_manager|compliance_user',
      }),
      exactRoute('/screenings/cases', CaseList, {
        access: 'account',
        roles: 'owner|compliance_manager|compliance_user',
      }),
      exactRoute('/screenings/cases/:case_id/detail', CaseDetail, {
        access: 'account',
        roles: 'owner|compliance_manager|compliance_user',
      }),
      exactRoute('/groups', GroupListing, {
        access: 'account',
        roles: 'owner|compliance_manager|compliance_user',
      }),
      exactRoute('/group/:group_id/detail', GroupDetail, {
        access: 'account',
        roles: 'owner|compliance_manager|compliance_user',
      }),
      exactRoute('/screenings/verifications', VerificationListing, {
        access: 'account',
        roles: 'owner|compliance_manager|account_manager',
      }),
      exactRoute('/screenings/verifications/:request_id/detail', VerificationDetail, {
        access: 'account',
        roles: 'owner|compliance_manager|account_manager',
      }),
      exactRoute('/full-screening', FullScreening, {
        access: 'account',
        roles: 'owner|compliance_manager|account_manager',
      }),
      exactRoute('/batch-processing', BatchKYC, {
        access: 'account',
        roles: 'owner|compliance_manager|account_manager',
      }),
      exactRoute('/document-verify', NewDocumentScreening, {
        access: 'account',
        roles: 'owner|compliance_manager|account_manager',
      }),
      exactRoute('/documents', () => <DocumentListing routeType="account" />, {
        access: 'account',
        roles: 'owner|compliance_manager|compliance_user',
      }),
      exactRoute('/documents/:document_id/detail', () => <DocumentDetail routeType="account" />, {
        access: 'account',
        roles: 'owner|compliance_manager|compliance_user',
      }),
      exactRoute('/app/logs', EventLog, {
        access: 'account',
        roles: 'owner|account_manager|compliance_manager|user_manager',
      }),
      exactRoute('/credit-usage', Wallets, {
        access: 'account',
        roles: 'owner|billing_manager',
      }),
      exactRoute('/wallet/:wallet_id/transaction/:transaction_id/detail', () => <WalletTXDetail routeType="account" />, {
        access: 'account',
        roles: 'owner|billing_manager',
      }),
      exactRoute('/credit-purchase', ContactUs, {
        // ManualPurchase
        access: 'account',
        roles: 'owner|billing_manager',
      }),
      exactRoute('/credits', () => <CreditListing routeType="account" />, {
        access: 'account',
        roles: 'owner|billing_manager',
      }),
      exactRoute('/order-history', OrderHistory, {
        access: 'account',
        roles: 'owner|billing_manager',
      }),
      exactRoute('/order/:order_id/detail', OrderDetail, {
        access: 'account',
        roles: 'owner|billing_manager',
      }),
      exactRoute('/payment/result', PaymentResult, {
        access: 'account',
        // roles: "owner|billing_manager",
      }),
      // exactRoute("/app/payments", PaymentListing, {
      //   access: "account",
      //   roles: "owner|billing_manager",
      // }),
      exactRoute('/app/payment/:payment_id/detail', PaymentDetail, {
        access: 'account',
        roles: 'owner|billing_manager',
      }),
      exactRoute('/app/users', UserList, {
        access: 'account',
        roles: 'owner|user_manager',
      }),
      exactRoute('/app/users/:user_id/detail', UsersDetail, {
        access: 'account',
        roles: 'owner|user_manager',
      }),
      exactRoute('/app/users/invites', UsersInvites, {
        access: 'account',
        roles: 'owner|user_manager',
      }),
      exactRoute('/app/developer', References, {
        // PlaceholderScreen
        access: 'account',
        roles: 'owner|developer',
      }),
      exactRoute('/app/create-key', CreateKey, {
        // PlaceholderScreen
        access: 'account',
        roles: 'owner|developer',
      }),
      redirectRoute(undefined, '/dashboard'),
    ],
  }),
];

export default routes;
