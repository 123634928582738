import { Checkbox, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { API } from 'app/services';
import { useRouter, useToastCatcher } from 'app/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(({ palette }) => ({
  table: {
    margin: 24,
    borderRadius: 5,
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',
  },
  head: {
    fontWeight: 'bold',
  },
}));

let mounted = false;
function PersonDetail() {
  const classes = useStyles();
  const [privileges, setPrivileges] = useState(null);
  const [person, setPerson] = useState(null);
  const loading = useSelector((state) => state.layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const [toaster, errorCatcher] = useToastCatcher({ errorParser: null, taskname: 'editPrivilege' });
  const router = useRouter();
  const { match } = router;
  const { params } = match;

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (!mounted) return;

    if (access && access.token) {
      listPrivileges();
      getPerson();
    }
    // eslint-disable-next-line
  }, [access]);

  const getPerson = (callback) => {
    if (!params.person_id) return toaster({ message: 'invalid id' });
    errorCatcher(async () => {
      const { model } = await API.Admin.get_person(params.person_id);
      if (mounted) {
        setPerson(model);
        if (typeof callback === 'function') callback();
      }
    });
  };

  const listPrivileges = () => {
    errorCatcher(async () => {
      const { models } = await API.Admin.list_privilege();
      if (mounted) setPrivileges(models);
    });
  };

  const filterPrivileges = (privilege) => {
    return person?.entity.roles.indexOf(privilege.name) > -1 && privilege.manageable && privilege.domain === 'admin';
  };

  const handleChecked = (privilegeId) => {
    return () => {
      let hadPrivileges = privileges.filter(filterPrivileges).map((priv) => priv.id);

      if (hadPrivileges.indexOf(privilegeId) > -1) {
        hadPrivileges.splice(hadPrivileges.indexOf(privilegeId), 1);
      } else {
        hadPrivileges.push(privilegeId);
      }

      hadPrivileges = hadPrivileges.sort();

      errorCatcher(async () => {
        await API.Admin.set_privilege(person.id, { privileges: hadPrivileges });
        getPerson(() => toaster('Privilege Updated', { variant: 'success' }));
      });
    };
  };

  return (
    <TableContainer className={classes.table} component={Paper}>
      {loading['editPrivilege'] && <LinearProgress size={30} />}
      {privileges && person && (
        <Table>
          <TableBody>
            {privileges
              .filter((pri) => pri.manageable && pri.domain === 'admin')
              .map((priv, index) => (
                <TableRow key={index}>
                  <TableCell align="left">
                    <Checkbox disabled={!!loading['editPrivilege']} checked={person.entity.roles.indexOf(priv.name) > -1} onChange={handleChecked(priv.id)} name="rememberMe" />
                  </TableCell>
                  <TableCell align="left" fontWeight="bold">
                    {priv.label}
                  </TableCell>
                  <TableCell align="right">{priv.description}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

export default PersonDetail;
