import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { TraxxButton } from 'app/components';
import clsx from 'clsx';
import React, { useState } from 'react';
import { FilesDropzone, UploadDialog } from './components';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 255,
    },
  },
  card: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(4, 2.5),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 5),
    },
  },
  filesDropzone: {
    width: '100%',

    [theme.breakpoints.up('lg')]: { width: '48%' },
  },
  image: {
    height: 'auto',
    width: '85%',

    [theme.breakpoints.up('sm')]: {
      height: 400,
      marginRight: 60,
      width: 400,
    },
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 60,
  },
  listItem: {
    fontWeight: 800,
    marginBottom: 10,
  },
  root: {
    padding: theme.spacing(0, 2.5),
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 3, 8),
    },
  },
  text: {
    marginBottom: 30,
  },
  upload: {
    marginBottom: 20,
  },
}));

const DocumentUpload = () => {
  const classes = useStyles();

  const theme = useTheme();
  const matchSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const [business, setBusiness] = useState([]);
  const [identification, setIdentification] = useState([]);
  const [timeLeft, setTimeLeft] = useState(0);
  const [uploadError, setUploadError] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleSubmit = () => {
    setTimeLeft(10000);
    setUploading(true);
  };

  const handleRetry = () => {
    setUploading(false);
    setUploadError(false);
  };

  return (
    <Box className={classes.root}>
      {uploading && <UploadDialog error={uploadError} onRetry={handleRetry} timeLeft={timeLeft} />}

      {!uploading && (
        <Grid className={classes.card} container justify="space-between">
          <Grid className={classes.imageContainer} item xs={12} md="auto">
            <img className={classes.image} src="/images/verify.svg" alt="Verify" />
          </Grid>

          <Grid item xs>
            <Typography className={classes.text} variant="h5">
              Verify your identity
            </Typography>
            <Typography className={classes.text} variant="body2">
              To complete your identity verification&#44; please upload the following required documents&#58;
            </Typography>

            <Typography className={classes.listItem}>1&#46; Valid identification document</Typography>

            <Typography className={classes.text}>
              Such as your <strong>passport</strong>&#44; <strong>NRIC</strong> or <strong>driving license</strong>&#46;&nbsp;
              {matchSmUp && <br />}
              It must contain a photo of you&#44; your full name&#44; identification number&#44; date of birth and nationality&#46;
              <br />
              <strong>
                <em>Passports&#58;</em>
              </strong>{' '}
              Upload an image of your passport cover and identification page&#46;
              <br />
              <strong>
                <em>NRIC or driving license&#58;</em>
              </strong>{' '}
              Upload a front and back image of the card&#46;
            </Typography>

            <Typography className={classes.listItem}>2&#46; Proof of business ownership</Typography>

            <Typography className={classes.text}>
              Such as your <strong>rental agreement</strong>&#44; <strong>ACRA registration</strong> or <strong>a bill that is not older than 6 months</strong>&#46;
              <br />
              It must contain your business registration number&#44; full name&#44; identification number&#44; and date of birth&#46;
            </Typography>

            <Typography className={classes.upload} variant="h5">
              Upload documents
            </Typography>

            <Grid className={classes.text} container justify="space-between">
              <FilesDropzone
                buttonText="Choose files"
                className={clsx(classes.filesDropzone, classes.upload)}
                fileFormatText="JPG, PNG or PDF format, below 10MB in size"
                fileNumberLimit={2}
                fileRegex={/(jpeg|jpg|pdf|png)$/i}
                files={identification}
                fileSizeLimit={10000000}
                header1="Valid identification document"
                header2="Drag your files here or"
                instruction="You may select up to two files at a time"
                imgAlt="ID card"
                imgSrc="/images/id.svg"
                selectedInstruction="Drag more files here or click to browse"
                setFiles={setIdentification}
              />

              <FilesDropzone
                buttonText="Choose file"
                className={classes.filesDropzone}
                fileFormatText="JPG, PNG or PDF format, below 10MB in size"
                fileNumberLimit={1}
                fileRegex={/(jpeg|jpg|pdf|png)$/i}
                files={business}
                fileSizeLimit={10000000}
                header1="Proof of business ownership"
                header2="Drag a file here or"
                instruction="Please choose only one file"
                imgAlt="Business"
                imgSrc="/images/business.svg"
                selectedInstruction="Drag another file here or click to choose another"
                setFiles={setBusiness}
              />
            </Grid>

            <Grid container justify="center">
              <TraxxButton className={classes.button} form onSubmit={handleSubmit}>
                Submit for review
              </TraxxButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default DocumentUpload;
