import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxTable } from 'app/layout/Dashboard/components';
import PropTypes from 'prop-types';
import React from 'react';
import ViewAllButtonRow from './ViewAllButtonRow';

const useStyles = makeStyles(({ palette }) => ({
  card: { marginTop: 20 },
  table: {
    marginTop: 10,
    paddingRight: 120,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
}));

function CompletedScreens({ className, data }) {
  const classes = useStyles();

  return (
    <div className={className}>
      <Typography className={classes.title}>Latest completed screens</Typography>

      <Card className={classes.card}>
        <TraxxTable className={classes.table} data={data} />
        <ViewAllButtonRow />
      </Card>
    </div>
  );
}

export default CompletedScreens;

CompletedScreens.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      caseNumber: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

CompletedScreens.defaultProps = {
  className: '',
};
