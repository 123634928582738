const SELFIE_FLAGS = [
  'id_selfie_no_face_detected',
  'id_selfie_too_many_faces_detected',
  'id_selfie_direction_failed',
  'id_selfie_suspicious',
  'id_selfie_error_unknown',
];

const LIVENESS_FLAGS = [
  'id_liveness_no_face_detected',
  'id_liveness_too_many_faces_detected',
  'id_liveness_challenge_failed',
  'id_liveness_suspicious',
  'id_liveness_error_unknown',
];

const UPLOAD_FLAGS = {
  text_unreadable: 'The text on the ID could not be read. May indicate that the photo is blurry.',
  no_face_on_id_detected: 'Berbix expected to be able to find a photo of a face on the ID document in the uploaded image, but did not find one.',
  incomplete_barcode_detected: 'Either no barcode could be detected, or the barcode appears incomplete.',
  unsupported_id_type: 'An ID type we cannot accept was detected in the image. The `issue_details` may provide more detailed information if this issue is present.',
  bad_selfie: 'Berbix was unable to verify the uploaded selfie image, and another selfie should be uploaded.',
  bad_upload: 'A generic catch-all for errors that don`t fit into other issue types.',
};

const ALL_FLAGS = {
  id_expired: 'The expiry date was in the past',
  id_expiry_extended: 'The expiry date was in the past but is valid per issuer guidance on expiration extensions',
  id_expiry_unknown: 'The expiry date is unknown',
  id_under_18: 'The date of birth was less than 18 years ago',
  id_under_21: 'The date of birth was less than 21 years ago',
  id_age_unknown: 'The date of birth is unknown',
  id_user_confirmed: 'The user was presented with the information automatically extracted and confirmed accuracy',
  id_sample_detected: 'The provided ID was a sample ID that is inauthentic for that user',

  id_duplicate_possible: 'Berbix Verify found another user who previously provided an ID with similar information',
  id_duplicate_detected: 'Berbix Verify found another user who previously provided an ID with the same information',
  id_blocked_possible: 'Berbix Verify found a blocked user who previously provided an ID with similar information',
  id_blocked: 'Berbix Verify found a blocked user who previously provided an ID with the same information',

  id_selfie_face_mismatch: 'The similarity score between the selfie and the photo from the ID was too low',
  id_selfie_face_mismatch_possible: 'The similarity score between the selfie and the photo from the ID is inconclusive',
  id_selfie_face_comparison_unavailable: 'A similarity score between the front and selfie could not be determined',
  id_liveness_face_mismatch: 'The similarity score between the selfie and the liveness photo was too low',
  id_liveness_face_comparison_unavailable: 'A similarity score between selfie and liveness could not be determined',
  id_selfie_consent_declined: 'The user did not provide consent to digitally compare the faces across images where applicable',
  id_front_back_mismatch: 'The information scanned for the front is sufficiently different from the information drawn from the back',
  id_selfie_image_tampering_likely: 'The user provided a selfie that is likely to have been digitally altered',
  id_type_selection_mismatch: 'The type of ID uploaded by the user does not match the type that they selected',
  id_type_selection_mismatch_possible: 'The type of ID uploaded by the user may not match the type that they selected',
  id_issuer_selection_mismatch: 'The country that issued the ID uploaded by the user does not match the country that they selected',
  id_issuer_selection_mismatch_possible: 'The country that issued the ID uploaded by the user may not match the country that they selected',

  id_fake_possible: 'The ID is possibly a fake ID',
  id_fake_likely: 'The ID likely a fake ID',
  id_image_tampering_likely: 'The user provided a document that is likely to have been digitally altered',

  id_manual_entry: 'Some data about the photo ID returned was provided manually by the user and have not been verified by Berbix. A user can manually enter data when prompted to confirm their basic details if Berbix does not verify data through decoding a machine readable component',

  id_text_unreadable: 'Insufficient text was detected in the front image',
  id_no_face_detected: 'No face was detected in the front image of the ID',
  id_too_many_faces_detected: 'The image provided included more faces than expected for the ID type',
  id_mrz_parse_error: 'The machine readable zone was unreadable based on the scanned image',
  id_partial_mrz_read: 'Only part of the machine readable zone was successfully read from the scanned image',
  id_front_error_unknown: 'An unspecified error occurred when attempting to read the front image of the ID',
  id_front_suspicious: 'The user provided front image(s) were flagged as suspicious and warrant further review',

  id_back_text_unreadable: 'Insufficient text was detected in the back image',
  id_no_barcode_detected: 'No 2D barcode was detected in the back image of the ID',
  id_incomplete_barcode_detected: 'A partial 2D barcode was detected in the back image' ,
  id_barcode_decode_failed: 'A full 2D barcode was detected in the back image but could not be decoded',
  id_barcode_parse_error: 'A full 2D barcode was detected and decoded but the data was in an unsupported format',
  id_back_error_unknown: 'An unspecified error occurred when attempting to read the back image of the ID',
  id_back_suspicious: 'The user provided back image(s) were flagged as suspicious and warrant further review',

  id_selfie_no_face_detected: 'No face was detected in the selfie image',
  id_selfie_too_many_faces_detected: 'The selfie was rejected for having too many faces',
  id_selfie_direction_failed: 'The user was unable to take a valid selfie looking at the camera',
  id_selfie_suspicious: 'The user provided selfie images that were unlikely to be genuine',
  id_selfie_error_unknown: 'An unspecified error occurred when attempting to process the selfie',

  id_liveness_no_face_detected: 'No face was detected in the liveness image',
  id_liveness_too_many_faces_detected: 'The liveness image was rejected for having too many faces',
  id_liveness_challenge_failed: 'The user was unable to satisfy the presented liveness challenge',
  id_liveness_suspicious: 'The user provided liveness images that were unlikely to be genuine',
  id_liveness_error_unknown: 'An unspecified error occurred when attempting to process the liveness image',
}

export default {
  SELFIE_FLAGS,
  LIVENESS_FLAGS,
  ALL_FLAGS,
  UPLOAD_FLAGS,
};
