import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { API } from 'app/services';
import { useToastCatcher } from 'app/utils';
import { RequestView, SuccessView } from './components';
import { GlobalRegex } from 'app/constants';

function ForgetPasswordRequest() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [success, setSuccess] = useState(false);
  const [toaster, errorCatcher] = useToastCatcher({ errorParser: (error) => console.log(error), taskname: 'forgetPasswordRequest' });

  const onRequest = (event) => {
    event.preventDefault();

    if (!GlobalRegex.EmailValidation.test(email)) {
      setEmailError('invalid email');
      return;
    }

    errorCatcher(async () => {
      await API.Auth.password_request({ username: email });
      setSuccess(true);
      toaster('Request is successful!', { variant: 'success' });
    }, 'forgetpassword');
  };

  const onReset = () => {
    setEmail('');
    setSuccess(false);
    setEmailError('');
  };

  return (
    <Grid container item alignItems="center" justify="center" xs>
      {!success && <RequestView email={email} setEmail={setEmail} emailError={emailError} onRequest={onRequest} />}
      {success && <SuccessView email={email} onRequest={onRequest} onReset={onReset} />}
    </Grid>
  );
}

export default ForgetPasswordRequest;
