import { Checkbox, CircularProgress, Grid, IconButton, LinearProgress, Link, Paper, Table, TableBody, TableContainer, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { DataRow, DateDisplay, MonoText, StatusLabel } from 'app/components';
import { StatusMaps } from 'app/constants';
import { API } from 'app/services';
import { snakeToTitle, useAsyncTask, useRouter } from 'app/utils';
import clsx from 'clsx';
import CryptoJS from 'crypto-js';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as BrowserLink } from 'react-router-dom';
import { ConfirmOngoingScreeningDialog, UpdateDialog } from './components';
import { useSnackbar } from 'notistack';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',
  },
  checkbox: {
    color: 'green',
    '&$checked': {
      color: 'green',
    },
  },
  renewBtn: {
    fontSize: '16px',
  },
}));

const DetailComp = (props) => {
  const { reload, routeType, screening, loading, newPage, children, className, ...rest } = props;
  const classes = useStyles();
  const router = useRouter();
  const access = useSelector((state) => state.profile.access);
  const workspace = useSelector((state) => state.profile.workspace);
  const [runUpdateOngoingScreening] = useAsyncTask('updateOngoingScreening');
  const [runRenewOngoingScreening] = useAsyncTask('renewOngoingScreening');
  const [privilege, setPrivilege] = useState(false);

  const [ongoingScreeningLoading, setOngoingScreeningLoading] = useState(false);
  const [renewLoading, setRenewLoading] = useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [monthToExpire, setMonthToExpire] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (access && access.token) {
      if (workspace.account.entity.privileges.find((privilege) => privilege.name === 'dowjones.ongoing_screening') ? true : false) setPrivilege(true);
    }
    // eslint-disable-next-line
  }, [access]);

  useEffect(() => {
    if (screening) {
      const expiry = moment(screening?.ongoing_screening_expiry_date);
      const nextMonthEnd = moment().add(1, 'M').endOf('M');
      if (expiry.isSameOrBefore(nextMonthEnd)) setMonthToExpire(true);
    }
  }, [screening]);

  if (!screening) return <LinearProgress size={30} />;
  const { screen_provider_id, id, reference, type, pvd_ref, status, submit_at: submitted_at, ongoing_screening_expiry_date: expired_at, case: caseRef, ongoing_screening } = screening;

  const getCaseDetailPath = (id) => (routeType === 'admin' ? `/admin/screenings/cases/${id}/detail` : `/screenings/cases/${id}/detail`);

  const getRefreshPath = () => (routeType === 'admin' ? `/admin/screenings/${id}/detail` : `/screenings/${id}/detail`);

  const parseType = (type) => (type === 'passport' ? 'Passport/ID' : snakeToTitle(type));

  const getDate = (date) => `${date.format('Do')} ${date.format('MMM')} ${date.year()}`;

  const ongoingScreeningChange = () => setOpenConfirmDialog(true);

  const onSubmit = async () => {
    await runUpdateOngoingScreening(async () => {
      setOngoingScreeningLoading(true);
      const loader = routeType === 'admin' ? API.Admin.update_ongoing_screening : API.Screening.update_ongoing_screening;
      await loader(id);
      await reload();
      enqueueSnackbar(`Ongoing screening updated!`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    });
    setOngoingScreeningLoading(false);
    setOpenConfirmDialog(false);
    router.history.push(getRefreshPath());
  };

  const onUpdate = async () => {
    await runRenewOngoingScreening(async () => {
      setRenewLoading(true);
      const loader = routeType === 'admin' ? API.Admin.renew_ongoing_screening : API.Screening.renew_ongoing_screening;
      await loader([id]);
      await reload();
      enqueueSnackbar(`Ongoing screening renewed!`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setMonthToExpire(false);
    });
    setRenewLoading(false);
    setOpenUpdateDialog(false);
    router.history.push(getRefreshPath());
  };

  const getEncryptWorldRef = (worldRef) => {
    return worldRef ? CryptoJS.AES.encrypt(worldRef.toString(), 'TRAXX').toString() : '--'; // Decrypt at https://www.browserling.com/tools/aes-decrypt
  };

  const handleRenew = () => setOpenUpdateDialog(true);

  return (
    <TableContainer component={Paper} className={clsx(classes.root, className)} {...rest}>
      {loading && <LinearProgress size={30} />}
      {!loading && screening && (
        <Table>
          <TableBody>
            <DataRow name="Screening ID" value={<MonoText>{reference}</MonoText>} />
            <DataRow name="Type" value={parseType(type)} />
            <DataRow
              name="Status"
              value={
                <div className={classes.status}>
                  {status === 'screening' && (
                    <IconButton className={classes.icon} size="small" onClick={() => reload()}>
                      <RefreshIcon />
                    </IconButton>
                  )}
                  <StatusLabel variantMap={StatusMaps.Screen}>{status}</StatusLabel>
                </div>
              }
            />
            <DataRow name="Submitted date" value={<DateDisplay date={submitted_at} />} />
            {screen_provider_id === 1 && <DataRow name="World-Check Ref" value={<MonoText>{getEncryptWorldRef(pvd_ref)}</MonoText>} />}
            <DataRow
              name="Case ID"
              value={
                <Link target={newPage} underline="always" component={BrowserLink} to={getCaseDetailPath(caseRef?.id)}>
                  <MonoText>{caseRef?.reference}</MonoText>
                </Link>
              }
            />
            {(routeType === 'admin' || privilege) && status === 'complete' && !ongoingScreeningLoading && (
              <DataRow
                name={
                  <Grid>
                    Ongoing Screening
                    {monthToExpire && (
                      <Tooltip title="Renew ongoing screening">
                        <IconButton onClick={handleRenew}>
                          <AutorenewIcon className={classes.renewBtn} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                }
                value={<Checkbox color="default" className={classes.checkbox} checked={ongoing_screening} onChange={ongoingScreeningChange} />}
              />
            )}
            {(routeType === 'admin' || privilege) && ongoingScreeningLoading && <DataRow name="Ongoing Screening" value={<CircularProgress value={15} size={15} />} />}
          </TableBody>
        </Table>
      )}
      {openConfirmDialog && (
        <ConfirmOngoingScreeningDialog open={openConfirmDialog} setOpen={setOpenConfirmDialog} loading={ongoingScreeningLoading} onSubmit={onSubmit} checked={ongoing_screening} expired_at={expired_at} getDate={getDate} name={caseRef.label} />
      )}
      {openUpdateDialog && <UpdateDialog open={openUpdateDialog} setOpen={setOpenUpdateDialog} loading={renewLoading} onUpdate={onUpdate} expired_at={expired_at} getDate={getDate} name={caseRef.label} />}
    </TableContainer>
  );
};

export default DetailComp;
