import { Box, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    height: 69,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '14px 24px 12px',
  },
  link: {
    fontWeight: 'bold',
    marginLeft: 5,
  },
  logo: {
    height: 32,
    width: 110,

    [breakpoints.up('md')]: {
      height: 38,
      width: 128,
    },
  },
  text: { fontWeight: 'bold' },
}));

/**
 * Login page navigation bar
 */
function NavHeader({ hideNav }) {
  const classes = useStyles();
  const isSignUp = useRouteMatch('/access/signup');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const inviteToken = queryParams.get('invite');
  const inviteSuffix = typeof inviteToken === 'string' ? `?invite=${inviteToken}` : '';

  return (
    <Box className={classes.root}>
      <img className={classes.logo} src="/images/traxx_logo.svg" alt="TRAXX Logo" />

      {!hideNav && (
        <Box display="flex" alignItems="center" justify="flex-end">
          <Typography className={classes.text}>{isSignUp ? 'Already a member?' : 'New here?'}</Typography>

          <Link className={classes.link} color="secondary" component={RouterLink} to={isSignUp ? `/access/login${inviteSuffix}` : `/access/signup${inviteSuffix}`} variant="body1">
            {isSignUp ? 'Log in' : 'Sign up free'}
          </Link>
        </Box>
      )}
    </Box>
  );
}

export default NavHeader;
