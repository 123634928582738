import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ListingComp, ListingCompTable } from 'app/components';
import { API } from 'app/services';
import { snakeToTitle, useToastCatcher } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    marginTop: theme.spacing(1),
  },
}));

const DEFAULT_HEADERS = ['Document ID', 'Case', 'Email', 'Created Date', 'Type', 'Status'];

const filterOptions = {
  sort: false,
  search: true,
  dateRange: true,
  type: false,
  status: true,
  clear: true,
};

const extraOptions = {
  status: {
    all: { value: '', name: 'All' },
    open: { value: 'open', name: 'Open' },
    verifying: { value: 'verifying', name: 'Verifying' },
    complete: { value: 'complete', name: 'Complete' },
  },
};

let mounted = false;
const DocumentListingComp = (props) => {
  const {
    status,
    showPaginator = true,
    // eslint-disable-next-line no-unused-vars
    showCheckbox = true,
    // eslint-disable-next-line no-unused-vars
    showFilter = true,
    // eslint-disable-next-line no-unused-vars
    full = true,
    hideCase = false,
    limit = 10,
    ExtraAction,
    children,
    case_id,
    className,
    routeType = 'account',
    ...rest
  } = props;
  const classes = useStyles();
  // TODO
  // eslint-disable-next-line
  const [toaster, errorCatcher] = useToastCatcher({ taskname: 'listDocuments' });
  const [screenings, setScreenings] = useState([]);
  const [filter, setFilter] = useState({ limit, offset: 0 });
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [headers] = useState(routeType === 'account' ? DEFAULT_HEADERS : ['Account'].concat(DEFAULT_HEADERS));

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const loadDocumentList = (newFilter = filter) => {
    setLoading(true);
    if (!newFilter.status && status) newFilter['status'] = status;
    if (case_id) newFilter.case = case_id;
    errorCatcher(async () => {
      const loader = routeType === 'account' ? API.Screening.list_documents : API.Admin.list_verify_document;
      const { models, meta } = await loader(newFilter);
      if (mounted) {
        sortListingData(models);
        setFilter({ ...newFilter, count: meta.count });
        setLoading(false);
        setLoaded(true);
      }
    });
  };

  const getDocumentDetailPath = (id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/documents/${id}/detail`;
      default:
        return `/documents/${id}/detail`;
    }
  };

  const getCaseDetailPath = (id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/screenings/cases/${id}/detail`;
      default:
        return `/screenings/cases/${id}/detail`;
    }
  };

  const getVerificationRequestPath = (id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/verifications/${id}/detail`;
      default:
        return `/screenings/verifications/${id}/detail`;
    }
  };

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      let dataArr = [];
      if (hideCase) {
        dataArr = [
          { label: model.reference, type: 'link', link: getDocumentDetailPath(model.id) },
          { date: model.created_at, type: 'date' },
          {
            label: model.verification_request?.target_email,
            type: !!model.verification_request ? 'labelwithlink' : '',
            link: getVerificationRequestPath(model.verification_request?.id),
            bottomLabel: model.verification_request?.reference,
          },
          { label: snakeToTitle(model.type) },
          { label: model.status, statusType: 'Screen', type: 'status' },
        ];
      } else {
        dataArr = [
          { label: model.reference, type: 'link', link: getDocumentDetailPath(model.id) },
          { label: model.case?.label, type: 'labelwithlink', link: getCaseDetailPath(model.case?.id), bottomLabel: model.case?.reference },
          {
            label: model.verification_request?.target_email,
            type: !!model.verification_request ? 'labelwithlink' : '',
            link: getVerificationRequestPath(model.verification_request?.id),
            bottomLabel: model.verification_request?.reference,
          },
          { date: model.created_at, type: 'date' },
          { label: snakeToTitle(model.type) },
          { label: model.status, statusType: 'Screen', type: 'status' },
        ];
      }
      if (routeType === 'admin') {
        dataArr = [{ label: model.account?.name, type: 'labelwithlink', link: `/admin/accounts/${model.account_id}/detail`, bottomLabel: model.account.reference }].concat(dataArr);
      }

      allData.push(dataArr);
    });

    setScreenings(allData);
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <ListingComp
        ExtraAction={ExtraAction}
        loaded={loaded}
        filter={filter}
        setFilter={setFilter}
        showPaginator={showPaginator}
        loading={loading || loadTask['listDocuments']}
        reloadFunc={loadDocumentList}
        setLoading={setLoading}
        searchByText={'Document ID'}
        filterOptions={filterOptions}
        extraOptions={extraOptions}
        sortKey="create_at"
        dateKey="create_date">
        {screenings.length > 0 && <ListingCompTable headers={hideCase ? headers.filter((header) => header !== 'Case ID') : headers} data={screenings} />}
      </ListingComp>
    </Box>
  );
};

export default DocumentListingComp;
