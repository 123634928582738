import { Box, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import TitleField from '../TitleField';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    width: '100%',
  },
  container: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    flexDirection: 'row',
    display: 'flex',
  },
  imageContainer: {},
  image: {
    padding: theme.spacing(1),
    objectFit: 'contain',
    width: 200,
  },
}));

const DataFieldImages = (props) => {
  const { datafield, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.card, className)}>
      <TitleField>DataField Images</TitleField>
      <Box justifyContent="center" alignItems="center" classes={classes.container}>
        {datafield
          .filter((fie) => fie.Type === 'uri')
          .map((field) => (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" classes={classes.imageContainer}>
              <img alt={field.Key} src={field.Value} className={classes.image} />
              <Typography>{field.Name}</Typography>

              <Typography>{field.Description}</Typography>
            </Box>
          ))}
      </Box>
    </Card>
  );
};

export default DataFieldImages;
