import { Dialog, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { NavHeader } from '..';

const useStyles = makeStyles((theme) => ({
  body: {
    height: '100%',
  },
  card: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #f5f5f5',
    borderRadius: 6,
    boxShadow: '0px 2px 4px rgba(108, 133, 154, 0.111781)',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(5),

    [theme.breakpoints.down('xs')]: {
      padding: '40px 25px',
      width: '90%',
    },
  },
  paper: {
    backgroundColor: '#fafafa',
  },
}));

/**
 * Upload modal
 *
 * Props:
 ** children: data to be wrapped
 */
const UploadModal = ({ children, open }) => {
  const classes = useStyles();

  return (
    <Dialog classes={{ paper: classes.paper }} fullScreen open={open}>
      <NavHeader hideNav={true} />

      <Grid className={classes.body} container alignItems="center" justify="center">
        {children}
      </Grid>
    </Dialog>
  );
};

export default UploadModal;

UploadModal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
};

UploadModal.defaultProps = {
  open: true,
};
