import palette from './palette';

export default {
  fontFamily: 'Muli',
  body1: {
    '@media (max-width:960px)': {
      fontSize: '0.875rem',
    },
  },
  body2: {
    '@media (max-width:960px)': {
      fontSize: '0.675rem',
    },
  },
  button: { fontWeight: 800 },
  h1: {
    fontSize: 32,
    fontWeight: 800,
  },
  h4: {
    fontSize: 28,
    fontWeight: 800,
  },
  h5: { fontWeight: 800 },
  h6: {
    fontSize: 16,
    fontWeight: 800,
  },
  small: {
    fontSize: 8,
    fontWeight: 800,
  },
  mono: {
    color: palette.text.primary,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '21px',
    fontFamily: ['Roboto Mono', 'monospace'],
  },
};
