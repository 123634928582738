import { get_api_path } from './api';
import { sessioned_client } from './auth';
import { store } from 'app/store';

export async function list_group(filter) {
  const url = get_api_path('account/group/list', filter);

  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_group(group_id) {
  const url = get_api_path('account/group/detail', null, { group_id });

  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function create_group(data) {
  const url = get_api_path('account/group/create');

  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function update_group(data) {
  const url = get_api_path('account/group/update');

  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function remove_group(case_group_id) {
  const url = get_api_path('account/group/remove', null, { case_group_id });

  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function list_case_group(case_group_id) {
  const url = get_api_path('group/case/list', null, { case_group_id });

  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function create_case_group(case_group_id, data) {
  const url = get_api_path('group/case/create', null, { case_group_id });

  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function remove_case_group(case_group_id, data) {
  const url = get_api_path('group/case/remove', null, { case_group_id });

  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function all_case_groups(case_id) {
  const url = get_api_path('all/case/group', null, { case_id });

  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}
