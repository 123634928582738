import { Box, Button, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MonoText from 'app/components/MonoText';
import { LocalStorageKeys } from 'app/constants';
import { API } from 'app/services';
import { actions } from 'app/store';
import { useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  account: {
    flex: 1,
    padding: theme.spacing(1.5, 3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textTransform: 'none',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  sectionTitle: {
    padding: theme.spacing(2, 3, 1),
    fontFamily: 'Open Sans',
    fontSize: 12,
    lineHeight: 1.4,
  },
  image: {
    height: 28,
    width: 28,
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 10,
    lineHeight: 1.4,
  },
  selected: {
    backgroundColor: '#DDDDDD',
  },
}));

/**
 * Showing accounts associated to user
 *
 * Props:
 ** className: name of css class element
 */
const MenuAccounts = (props) => {
  const { children, className, onChangeAccount, ...rest } = props;
  const classes = useStyles();
  const accounts = useSelector(({ profile }) => profile.accounts);
  const workspace = useSelector(({ profile }) => profile.workspace);
  const loading = useSelector(({ layout }) => layout.loadingTasks);
  const [runLoadMenuAccount] = useAsyncTask('loadMenuAccount');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const onSelectAccount = (account) => {
    runLoadMenuAccount(async () => {
      const { expires_at, expires_in, token, workspace } = await API.Self.authorise(account.id);
      dispatch(actions.Profile.updateAccess({ expires_at, expires_in, token }, workspace));
      await localStorage.setItem(LocalStorageKeys.AccessToken, JSON.stringify({ expires_at, expires_in, token }));
      enqueueSnackbar(`Active account is now ${workspace.account.name}`);
      if (typeof onChangeAccount === 'function') onChangeAccount();
    });
  };

  if (!accounts?.length) return null;

  return (
    <>
      <Box {...rest} className={clsx(classes.root, className)}>
        <Typography color="secondary" className={classes.sectionTitle}>
          Associated accounts
        </Typography>
        {accounts?.map((account, index) => (
          <Button disabled={loading.loadMenuAccount || workspace?.account?.id === account.id} key={index} className={clsx(classes.account, workspace?.account?.id === account.id && classes.selected)} onClick={() => onSelectAccount(account)}>
            <img className={classes.image} src="/images/user.svg" alt="user" />
            <Box display="flex" justifyContent="center" alignItems="flex-start" flex="1" flexDirection="column">
              <Typography color={'primary'} className={classes.title}>
                {account.name || 'Account'}
              </Typography>
              <Typography color={'primary'} className={classes.subtitle}>
                <MonoText>{account.reference}</MonoText>
              </Typography>
            </Box>
          </Button>
        ))}
      </Box>
      <Divider />
    </>
  );
};

export default MenuAccounts;
