import { Card, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { TraxxButton, TraxxInput } from 'app/components';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  title: {
    marginTop: 20,
    marginLeft: theme.spacing(2),
  },
  card: {
    marginTop: 10,
    width: '100%',
    padding: '16px',
  },
  button: {
    marginTop: 10,
    float: 'right',
  },
  leftButton: {
    marginTop: 10,
    float: 'left',
  },
  warningMsg: {
    marginTop: 10,
  },
  inputs: {
    marginBottom: theme.spacing(1),
  },
}));

const AddCreditComp = (props) => {
  const { inputs, addCredit, inputError, handleChange, loading } = props;
  const classes = useStyles();
  const [showAddCredit, setShowAddCredit] = useState(false);

  const selectAddCredit = () => {
    setShowAddCredit(false);
    if (typeof addCredit === 'function') addCredit();
  };
  return (
    <>
      <Typography className={classes.title} variant="h5">
        Add Credit
      </Typography>
      <Card className={classes.card}>
        <TraxxInput InputLabelProps={{ shrink: true }} className={classes.inputs} label="Amount" error={inputError} value={inputs.amount || ''} required type="number" onChange={handleChange('amount')} placeholder="1000" />
        <TraxxInput InputLabelProps={{ shrink: true }} label="Remark" error={inputError} value={inputs.remark || ''} onChange={handleChange('remark')} placeholder="E.g. Bank ref" className={classes.inputs} />
        {!showAddCredit && inputs.amount && (
          <TraxxButton loading={loading} onSubmit={() => setShowAddCredit(true)} className={classes.button}>
            Add credit
          </TraxxButton>
        )}
        {showAddCredit && (
          <>
            <Alert severity="info" className={classes.warningMsg}>
              Amount will be added to the wallet.
            </Alert>
            <Button color="grey" onClick={() => setShowAddCredit(false)} className={classes.leftButton}>
              Cancel
            </Button>
            <TraxxButton onSubmit={selectAddCredit} className={classes.button}>
              Confirm
            </TraxxButton>
          </>
        )}
      </Card>
    </>
  );
};

export default AddCreditComp;
