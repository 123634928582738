import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ListingComp, ListingCompTable } from 'app/components';
import { API } from 'app/services';
import { snakeToTitle, useToastCatcher } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

const headers = ['Screening ID', 'Screening type', 'Open date', 'Status'];

let mounted = false;
function ScreeningList(props) {
  const classes = useStyles();
  const { case_id, routeType = 'account', className, ...rest } = props;
  // TODO
  // eslint-disable-next-line
  const [toaster, errorCatcher] = useToastCatcher({ taskname: 'loadScreening' });
  const [screenings, setScreenings] = useState([]);
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const loadScreeningList = (newFilter = filter) => {
    setLoading(true);
    newFilter.case = case_id;
    errorCatcher(async () => {
      const loader = routeType === 'account' ? API.Screening.list_screenings : API.Admin.list_screen;
      const { models, meta } = await loader(newFilter);
      if (mounted) {
        sortListingData(models);
        setFilter({ ...newFilter, count: meta.count });
        setLoading(false);
        setLoaded(true);
      }
    });
  };

  const getScreeningPath = (id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/screenings/${id}/detail`;
      default:
        return `/screenings/${id}/detail`;
    }
  };

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      allData.push([{ label: model.reference, type: 'link', link: getScreeningPath(model.id) }, { label: snakeToTitle(model.type) }, { date: model.open_at, type: 'date' }, { label: model.status, type: 'status', statusType: 'Screen' }]);
    });

    setScreenings(allData);
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <ListingComp
        filter={filter}
        setFilter={setFilter}
        loading={loading || loadTask['loadScreening']}
        loaded={loaded}
        reloadFunc={loadScreeningList}
        setLoading={setLoading}
        searchByText={'Screening ID'}
        enableRange={false}
        enableSort={false}
        enableType={false}>
        {!!screenings.length && <ListingCompTable headers={headers} data={screenings} />}
      </ListingComp>
    </Box>
  );
}

export default ScreeningList;
