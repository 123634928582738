import React, { useState, useEffect } from 'react';
import { Veriff } from '@veriff/js-sdk';
import { createVeriffFrame } from '@veriff/incontext-sdk';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { API } from 'app/services';

const useStyles = makeStyles((theme) => ({
  app: {
    height: '100vh',
    margin: 'auto',
    width: '50vw'
  },
  button: {
    margin: '10px'
  }
}));

const VeriffIntegration = () => {

  const classes = useStyles();

  useEffect(() => {
    initialiseVeriff();
  }, [])

  const initialiseVeriff = () => {
    
    const veriff = Veriff({
      host: 'https://stationapi.veriff.com',
      apiKey: '0b39bdd5-6888-431f-9ed7-5087466dc521',
      parentId: 'veriff-root',
      onSession: function (err, response) {
        createVeriffFrame({ url: response.verification.url });
      },
    });

    veriff.setParams({
      vendorData: ' '
    });

    veriff.mount();
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const inputData = new FormData(ev.target);
    const result = await API.Auth.veriff_transaction(inputData);
  }

  return <Box className={classes.app}>
    <div id="veriff-root"></div>

    <hr />

    <form onSubmit={handleSubmit}>

      <div>
        <label>First Name: </label>
        <input type="text" name="firstName"></input>
      </div>
      <div>
        <label>Last Name: </label>
        <input type="text" name="lastName"></input>
      </div>
      <div>
        <label>ID No: </label>
        <input type="text" name="idNumber"></input>
      </div>
      <div>
        <label>Gender (male or female): </label>
        <input type="text" name="gender"></input>
      </div>
      <div>
        <label>Date of birth (YYYY-MM-DD): </label>
        <input type="text" name="dateOfBirth"></input>
      </div>

      <div>
        <label>Document No: </label>
        <input type="text" name="number"></input>
      </div>
      <div>
        <label>Document type (PASSPORT, ID_CARD, RESIDENCE_PERMIT, DRIVERS_LICENSE, VISA): </label>
        <input type="text" name="type"></input>
      </div>
      <div>
        <label>Country (ISO-2 Issuing country of the document): </label>
        <input type="text" name="country"></input>
      </div>

      <div>
        <label>Address: </label>
        <input type="text" name="fullAddress"></input>
      </div>

      <label for="avatar">
        <ul>
          <li>Image files: approx 17MB in image files format</li>
        </ul>
      </label>
  

      <div>face: <input type="file" name="face" accept=".pdf, image/png, image/jpg" /></div>
      <div>front: <input type="file" name="document-front" accept=".pdf, image/png, image/jpg" /></div>
      <div>back: <input type="file" name="document-back" accept=".pdf, image/png, image/jpg" /></div>

      <div><button type="submit">Submit</button></div>
    </form>
    
  </Box>;
};

export default VeriffIntegration;
