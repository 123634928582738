import { Card, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  card: {
    alignContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    height: 102,
    justifyContent: 'space-evenly',
    paddingLeft: 20,
    // marginTop: 20
  },
  category: { marginLeft: 10 },
}));
const CountCard = ({ category, Icon, count, loading }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Grid container item alignItems="center" justify="flex-start">
        <Icon />
        <Typography className={classes.category} color="secondary" variant="body2">
          {category}
        </Typography>
      </Grid>
      {!loading && count !== null && <Typography variant="h5">{count}</Typography>}
      {(loading || count === null) && <CircularProgress />}
    </Card>
  );
};

export default CountCard;

CountCard.propTypes = {
  category: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
};
