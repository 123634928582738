import { InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(({ palette }) => ({
  adornedStart: { paddingLeft: 3 },
  input: {
    fontSize: 13,
    lineHeight: 24,
    padding: '12px 11px 12px 6px',
  },
  inputRoot: {
    backgroundColor: palette.common.white,
    color: palette.secondary.main,
  },
  field: { marginRight: 10 },
  searchIcon: { fontSize: 24 },
  textField: { width: 330 },
}));

const SearchField = ({ className, onChange, placeholder, value }) => {
  const classes = useStyles();

  return (
    <TextField
      className={clsx(classes.textField, className)}
      InputProps={{
        classes: {
          adornedStart: classes.adornedStart,
          input: classes.input,
          root: classes.inputRoot,
        },
        startAdornment: (
          <InputAdornment>
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        ),
      }}
      label=""
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      variant="outlined"
    />
  );
};

export default SearchField;

SearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

SearchField.defaultProps = {
  className: '',
  placeholder: '',
};
