import { Avatar } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    backgroundColor: ({ color }) => color,
    color: palette.common.white,
    height: 32,
    fontSize: typography.caption.fontSize,
    fontWeight: 800,
    width: 32,
  },
}));

const getInitials = (text) => {
  let textArray = text.split(' ');

  if (textArray.length > 2) {
    textArray = textArray.slice(0, 2);
  }

  return textArray.map((word) => word.charAt(0).toUpperCase()).join('');
};

/**
 * Filters used for data listing
 *
 * Props:
 ** className: name of css class element
 ** children: get initials of these children
 ** index: index number of avatar color
 */
const InitialsAvatar = ({ className, children, index }) => {
  const { palette } = useTheme();
  const avatarColors = [palette.red.primary, '#dad2c3', palette.secondary.main, '#b84a40', palette.primary.main];
  const classes = useStyles({ color: avatarColors[index] });

  return (
    <Avatar className={clsx(classes.root, className)} alt={children}>
      {getInitials(children)}
    </Avatar>
  );
};

export default InitialsAvatar;

InitialsAvatar.propTypes = {
  children: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
};

InitialsAvatar.defaultProps = {
  className: '',
};
