import { Box, Grid, LinearProgress, Link, Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataRow, DateDisplay, MonoText, StatusLabel, Title, Crumbs } from 'app/components';
import { StatusMaps } from 'app/constants';
import { API } from 'app/services';
import { useAsyncTask, useRouter } from 'app/utils';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as BrowserLink } from 'react-router-dom';

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  root: {
    padding: spacing(3, 3, 8),
    [breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  button: {
    marginTop: 10,
    float: 'right',
  },
  table: {
    borderRadius: 5,
  },
  green: {
    backgroundColor: '#c9efe1',
  },
  red: {
    backgroundColor: '#f8d0d0',
  },
  yellow: {
    backgroundColor: '#fff2cb',
  },
  status: {
    borderRadius: 4,
    fontSize: 12,
    fontWeight: 'bold',
    padding: '4px 6px',
  },
}));

let mounted = false;
function PaymentDetail({ routeType = 'account' }) {
  const classes = useStyles();
  const [payment, setPayment] = useState(null);
  const loading = useSelector((state) => state.layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const [runPaymentDetail] = useAsyncTask('loadPaymentDetail');
  const router = useRouter();
  const { match } = router;
  const { params } = match;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) getPaymentDetail();
    // eslint-disable-next-line
  }, [access]);

  const getPaymentDetail = () => {
    if (!params.payment_id) return enqueueSnackbar('invalid id', { variant: 'error' });
    runPaymentDetail(async () => {
      const loader = routeType === 'account' ? API.Users.get_payment_detail : API.Admin.get_payment_detail;
      const { model } = await loader(params.payment_id);
      if (mounted) setPayment(model);
    });
  };

  const getOrderLink = (id) => {
    switch (routeType) {
      case 'admin':
        return `/admin/order/${id}/detail`;
      default:
        return `/order/${id}/detail`;
    }
  };

  return (
    <Box className={classes.root}>
      <Crumbs current="Detail" links={[{ text: 'Payments', href: routeType === 'admin' ? '/admin/payments' : '/credit-usage' }]} />
      <Title prefix={'Payment detail'} />
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <TableContainer component={Paper} className={classes.table}>
            {loading.loadPaymentDetail && <LinearProgress size={30} />}
            {!loading.loadPaymentDetail && payment && (
              <Table>
                <TableBody>
                  <DataRow name="Payment ID" value={<MonoText>{payment.reference}</MonoText>} />
                  <DataRow name="Payment date" value={<DateDisplay date={payment.date} />} />
                  <DataRow name="Status" value={<StatusLabel variantMap={StatusMaps.Payment}>{payment.status}</StatusLabel>} />
                  <DataRow
                    name="Order ID"
                    value={
                      <Link underline="always" component={BrowserLink} to={getOrderLink(payment.id)}>
                        <MonoText>{payment.order?.reference}</MonoText>
                      </Link>
                    }
                  />
                  <DataRow name="Payment method" value={<MonoText>{payment.gateway}</MonoText>} />
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PaymentDetail;
