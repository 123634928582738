import { Box, Card, Chip, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { Title } from 'app/components';
import clsx from 'clsx';
import React, { useState } from 'react';
import XLSX from 'xlsx';
import { CountryCode } from '../../KYC/BatchKYC/components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  card: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  head: {
    fontSize: 20,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    marginBottom: 10,
  },
  content: {
    marginLeft: 10,
    marginBottom: 10,
  },
  belowAlert: {
    marginTop: 10,
  },
  success: {
    borderColor: 'green',
  },
  error: {
    borderColor: 'red',
  },
  clickable: {
    cursor: 'pointer',
  },
  code: {
    textAlign: 'center',
    maxWidth: 300,
    padding: 10,
    backgroundColor: '#4d4643',
    color: 'white',
    marginBottom: 10,
    marginLeft: 10,
  },
  info: {
    marginBottom: theme.spacing(1),
  },
  baseUrl: {
    fontSize: 18,
    alignSelf: 'center',
  },
  urlExtension: {
    marginTop: 10,
    marginBottom: 10,
  },
  url: {
    float: 'right',
  },
}));

const SampleView = (props) => {
  const { children, className, staticContext, ...rest } = props;
  const classes = useStyles();
  const [openCountryCode, setOpenCountryCode] = useState(false);

  const downloadSampleData = () => {
    const header_row = ['First Name', 'Last Name', 'Middle Name', 'Country of Birth', 'Date Of Birth', 'Gender', 'Nationality', 'Issuing State', 'ID Number', 'Date of Expiry', 'Case ID', 'Case Name'];
    const sample_one = ['John', 'Doe', 'M', 'US', '1975/02/07', 'male', 'US', 'US', 'S12312321Z', '2024/12/07', '123', 'Doe Case'];
    const sample_two = ['Jane', 'Doe', 'L', 'US', '1977/03/12', 'female', 'SG', 'SG', 'S12312321Z', '2024/11/21', '124', 'Doe Case'];

    const worksheet = XLSX.utils.aoa_to_sheet([header_row, sample_one, sample_two]);
    const workbook = {
      SheetNames: ['sheet1'],
      Sheets: {
        sheet1: worksheet,
      },
    };
    return XLSX.writeFile(workbook, `sample.csv`);
  };

  const handleCountryClose = () => {
    setOpenCountryCode(null);
  };

  const handleCountryOpen = () => {
    setOpenCountryCode(true);
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Title prefix="API References" />
      <Alert className={classes.info} severity="info">
        Postman Collection | Environment to run APIs -{' '}
        <a className={classes.clickable} href="/API Reference.postman_collection.json">
          Collection
        </a>{' '}
        |{' '}
        <a className={classes.clickable} href="/API Reference Environment.postman_environment.json">
          Environment
        </a>
      </Alert>
      <Grid container spacing={2}>
        <Grid item md="6" sm="12" xs="12">
          <Card className={classes.card}>
            <Typography className={classes.head}>Introduction</Typography>
            <Typography className={classes.content}>
              Extend your TRAXX.ID usage with our APIs, built on HTTP. <br />
              - Uses built-in HTTP capabilities for passing parameters and authentication. <br />
              - Responds with standard HTTP response codes to indicate errors. <br />
              - Returns JSON. <br />
            </Typography>
            <hr />
            <Grid container className={classes.urlExtension}>
              <Grid item md="6" sm="12" xs="12">
                <Typography className={classes.baseUrl}>Base Url</Typography>
              </Grid>
              <Grid item md="6" sm="12" xs="12">
                <Chip className={classes.url} color="secondary" label="https://app.traxx.id/api" />
              </Grid>
            </Grid>
            <hr />
            <Typography className={classes.head}>Authentication</Typography>
            <Typography className={classes.content}>TRAXX.ID API uses Bearer token authentication for account authorisation. Your Bearer token will be shown to you when you Login in the "access.token" field. </Typography>
          </Card>
        </Grid>
        <Grid item md="6" sm="12" xs="12">
          <Card className={classes.card}>
            <Typography className={classes.head}>Login API</Typography>
            <Grid container className={classes.urlExtension}>
              <Grid item md="6" sm="12" xs="12">
                <Typography className={classes.baseUrl}>Base Url Extension</Typography>
              </Grid>
              <Grid item md="6" sm="12" xs="12">
                <Chip className={classes.url} color="secondary" label="/auth/session/login" />
              </Grid>
            </Grid>
            <hr />
            <Typography className={classes.content}>
              Body Parameters - <br />• Send as <u>Raw</u> input <br />• <b>Username</b> and <b>Password</b>
            </Typography>
            <Typography className={classes.content}>Example - </Typography>
            <Paper elevation={3} variant="outlined" className={classes.code}>
              'username': 'example_username', <br />
              'password': 'example_password'
            </Paper>
            <Typography className={classes.content}>
              Response - <br />
              Set "access.token" field as"api-token" variable to access all APIs accessible for your account
            </Typography>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md="6" sm="12" xs="12">
          <Card className={classes.card}>
            <Typography className={classes.head}>Batch Processing API</Typography>
            <Alert severity="info">
              For effective multiple screening use the sample data format provided -{' '}
              <u className={classes.clickable} onClick={() => downloadSampleData()}>
                Sample data
              </u>{' '}
              |{' '}
              <u className={classes.clickable} onClick={handleCountryOpen}>
                ISO country code
              </u>
            </Alert>
            <Grid container className={classes.urlExtension}>
              <Grid item md="6" sm="12" xs="12">
                <Typography className={classes.baseUrl}>Base Url Extension</Typography>
              </Grid>
              <Grid item md="6" sm="12" xs="12">
                <Chip className={classes.url} color="secondary" label="/account/screen/create/api" />
              </Grid>
            </Grid>
            <hr />
            <CountryCode open={openCountryCode} onClose={handleCountryClose} />
            <Typography className={clsx(classes.content, classes.belowAlert)}>
              Body Parameters - <br />• Send as <u>Form data</u> <br />• Attach the excel sheet(Sample Data) with key <b>'batch_data'</b> <br />• To link with a pre-created case, fill in the existing case id in <b>'case_id'</b> inside the excel
              sheet. <br />• To define a case name for the batch processing, fill in the case name inside <b>'case_name'</b> in the excel sheet. <br />
            </Typography>
            <Typography className={classes.content}>
              Positive Response: <br />
              <Chip variant="outlined" className={classes.success} label="Batch processing has started successfully" /> <br />
              Negative Response: <br />
              <Chip variant="outlined" className={classes.error} label="Error ..." />
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SampleView;
