import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ListingComp, ListingCompTable } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

const headers = ['Label', 'Group ID', 'Description'];

let mounted = false;
function CaseGroupListing(props) {
  const classes = useStyles();
  const { case_id, className, ...rest } = props;
  // TODO
  // eslint-disable-next-line
  const [errorCatcher] = useAsyncTask('loadCaseGroups');
  const [caseGroups, setCaseGroups] = useState([]);
  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  const loadCaseGroups = (newFilter = filter) => {
    errorCatcher(async () => {
      const result = await API.Group.all_case_groups(case_id);
      if (mounted) {
        sortListingData(result);
        setFilter({ ...newFilter, count: result.length });
      }
    });
  };

  const sortListingData = (models) => {
    let allData = [];
    models.forEach((model) => {
      allData.push([{ label: model.label }, { label: model.reference, type: 'link', link: `/group/${model.id}/detail` }, { label: model.description }]);
    });

    setCaseGroups(allData);
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <ListingComp filter={filter} setFilter={setFilter} loading={loadTask.loadCaseGroups} reloadFunc={loadCaseGroups} showPaginator={false}>
        {caseGroups.length > 0 && <ListingCompTable headers={headers} data={caseGroups} />}
      </ListingComp>
    </Box>
  );
}

export default CaseGroupListing;
