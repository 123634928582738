import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { Title } from 'app/components';
import { WalletListComp } from './components';
import AllBalance from '../components/AllBalance';
import PastTransaction from '../components/PastTransactions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  title: {
    marginTop: theme.spacing(2),
    fontSize: 18,
    fontWeight: 'bold',
    display: 'inline-flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },
}));

const WalletList = (props) => {
  const { children, className, staticContext, ...rest } = props;
  const classes = useStyles();

  return (
    <Box justifyItems="center" {...rest} className={clsx(classes.root, className)}>
      <Title prefix={'Wallets'} />
      <AllBalance />
      <WalletListComp />
      <Typography className={classes.title}>Latest Transactions</Typography>
      <PastTransaction />
    </Box>
  );
};

export default WalletList;
