import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { TraxxButton } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
  },
  card: {
    maxWidth: 700,
    margin: 'auto',
  },
  group: {
    marginLeft: '25px',
  },
  label: {
    marginRight: '50px',
  },
}));

let mounted = false;
const Mails = (props) => {
  const { children, className, ...rest } = props;
  const classes = useStyles();
  const access = useSelector((state) => state.profile.access);
  const { enqueueSnackbar } = useSnackbar();
  const [showConfirm, setShowConfirm] = useState(false);
  const loading = useSelector(({ layout }) => layout.loadingTasks);
  const [runLoadEmailTemplates] = useAsyncTask('loadEmailTemplates');
  const [runSendPreviewEmails] = useAsyncTask('previewEmails');
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) {
      getMailTemplates();
    }
    // eslint-disable-next-line
  }, [access]);

  const getMailTemplates = () => {
    runLoadEmailTemplates(async () => {
      const models = await API.Admin.get_email_templates();
      if (mounted) {
        setEmailTemplates(models);
      }
    });
  };

  const previewEmails = () => {
    runSendPreviewEmails(async () => {
      await API.Admin.send_email_preview(checked);
      enqueueSnackbar('Email previews have been sent successfully!');
      setShowConfirm(false);
    });
  };

  const handleChange = (event) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Card className={classes.card}>
        <CardHeader title="Preview Email Templates" />
        <CardContent>
          <Alert severity="info">Selected email templates will be sent to your email for previewing.</Alert>
        </CardContent>
        <FormGroup row className={classes.group}>
          {emailTemplates && emailTemplates.map((template, index) => <FormControlLabel key={index} id={index} control={<Checkbox name={template} value={template} onChange={handleChange} />} label={template} className={classes.label} />)}
        </FormGroup>
        <CardActions disableSpacing>
          {!showConfirm && (
            <Box width="100%" display="flex">
              <Box flexGrow={1} />
              <TraxxButton onSubmit={() => setShowConfirm(true)}>Send</TraxxButton>
            </Box>
          )}
          {showConfirm && (
            <Box p={1} width="100%">
              <Alert severity="warning"> Confirmation to send emails. </Alert>
              <Box width="100%" display="flex" mt={1}>
                <Button onClick={() => setShowConfirm(false)}>Cancel</Button>
                <Box flexGrow={1} />
                <TraxxButton loading={loading.previewEmails} onSubmit={() => previewEmails()}>
                  Confirm
                </TraxxButton>
              </Box>
            </Box>
          )}
        </CardActions>
      </Card>
    </Box>
  );
};

export default Mails;
