import { Box, CardActions, makeStyles, TablePagination, Typography } from '@material-ui/core';
import { MonoText } from 'app/components';
import clsx from 'clsx';
import React from 'react';
import EmptyState from '../EmptyState';

const useStyles = makeStyles((theme) => ({
  root: {},
  inner: {},
  content: {
    padding: 0,
  },
  actions: {
    padding: theme.spacing(0),
    justifyContent: 'flex-end',
  },
  spinner: {
    marginTop: theme.spacing(3),
  },
  record: {
    padding: theme.spacing(1),
    borderBottom: '1px solid #EEEEEE',
    fontSize: theme.spacing(1.25),
  },
}));

/**
 * Data listing
 *
 * Props:
 ** rowsPerPageArr: number of rows per page
 ** className: name of css class element
 ** emptyMessage: message if no records found
 ** children: data to be shown
 */
const Listing = (props) => {
  const classes = useStyles();
  const { rowsPerPageArr, showPaginator = true, loading, filter, title, onPageChange, className, ExtraAction, emptyMessage, children, ...rest } = props;

  const { limit = 10, offset = 0, count = 0 } = filter;
  const page = Math.floor(offset / limit);
  const rowsPerPage = limit;

  const handleChangePage = (event, page) => {
    const new_offset = Math.floor(rowsPerPage * page);
    onPageChange({ ...filter, limit, offset: new_offset });
  };

  const handleChangeRowsPerPage = (event) => {
    const rows_per_page = event.target.value;
    onPageChange({ ...filter, limit: rows_per_page, offset: 0 });
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      {!!count && (
        <Typography className={classes.record} color="textSecondary" gutterBottom variant="body2">
          <MonoText>
            {count} Records found. Page {page + 1} of {Math.ceil(count / rowsPerPage)}
          </MonoText>
        </Typography>
      )}
      <div className={classes.content}>{children}</div>
      <EmptyState message={emptyMessage} active={!loading && !count} />
      <CardActions className={classes.actions}>
        {ExtraAction && <ExtraAction />}
        {showPaginator && <TablePagination component="div" count={count} onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRowsPerPage} page={page} rowsPerPage={rowsPerPage} rowsPerPageOptions={rowsPerPageArr || [5, 10, 25, 50]} />}
      </CardActions>
    </Box>
  );
};

Listing.defaultProps = {
  loading: false,
  onPageChange: () => {},
  filter: {},
  title: null,
};

export default Listing;
