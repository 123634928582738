const PRIVILEGE_MAP = {
  traxx: 'traxx',
  account: 'account',
  external: 'external',
};

export const validatePrivilege = (roles) => {
  let sorted_roles = {};
  roles.forEach((role) => {
    const [main, sub] = role.split('.');
    if (!sorted_roles[PRIVILEGE_MAP[main]]) sorted_roles[PRIVILEGE_MAP[main]] = [sub];
    else sorted_roles[PRIVILEGE_MAP[main]].push(sub);
  });

  return sorted_roles;
};

export const usePrivilegeChecker = (roles, { excludeSuperAdmin = false } = {}) => {
  return (mainRole, subRole) => {
    if (roles.traxx && roles.traxx.indexOf('superadmin') > -1 && !excludeSuperAdmin) return true;
    if (!roles[mainRole]) return false;
    if (subRole) {
      const subRoles = subRole.split('|');
      let check = false;
      subRoles.forEach((sRole) => {
        if (roles[mainRole].indexOf(sRole) > -1) check = true;
      });
      if (subRole === 'all') check = true;
      return check;
    }
    return true;
  };
};
