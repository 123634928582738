import { Accordion, AccordionDetails, AccordionSummary, Card, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DataRow, StatusLabel } from 'app/components';
import clsx from 'clsx';
import React from 'react';
import TitleField from '../TitleField';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    width: '100%',
  },
  title: {
    paddingLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
  subTitle: {
    fontWeight: 'bold',
  },
  table: {
    padding: 0,
  },
  small: {
    fontSize: 12,
    color: theme.palette.grey.primary,
  },
  result: {
    float: 'right',
  },
  detailBox: {
    marginBottom: theme.spacing(2),
  },
}));

const BerbixFieldTable = (props) => {
  const { datafield, className } = props;
  const classes = useStyles();

  const keyFormatter = (key) => {
    if (key === 'ocr') return key.toUpperCase();

    const words = key.replaceAll('_', ' ').split(' ');
    return words
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(' ');
  };

  const getLabelStyle = (result) => {
    switch (result) {
      case 'high':
        return 'success';
      case 'medium':
        return 'warning';
      case 'low':
        return 'error';
      default:
        return 'warning';
    }
  };

  return (
    <Card className={clsx(classes.card, className)}>
      <TitleField>Data Fields</TitleField>
      {Object.keys(datafield).map((key) => (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Table>
              <TableBody>
                <DataRow name={keyFormatter(key)} value={'Confidence'} />
                <TableRow>
                  <TableCell>
                    <Typography className={classes.summaryHeader} color="primary" variant="body1">
                      {datafield[key].value}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <StatusLabel variant={getLabelStyle(datafield[key].confidence)} className={classes.result}>
                      {keyFormatter(datafield[key].confidence)}
                    </StatusLabel>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionSummary>
          <AccordionDetails className={classes.detail}>
            <Table>
              <TableBody>
                <DataRow name={'Capture'} />
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {Object.keys(datafield[key].sources).map((source) => (
                  <TableRow>
                    <TableCell>
                      <Typography className={classes.summaryHeader} color="primary" variant="body1">
                        {keyFormatter(datafield[key].sources[source].type)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.summaryHeader} color="primary" variant="body1">
                        {datafield[key].sources[source].value}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <StatusLabel variant={getLabelStyle(datafield[key].sources[source].confidence)} className={classes.result}>
                        {keyFormatter(datafield[key].sources[source].confidence)}
                      </StatusLabel>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      ))}
    </Card>
  );
};

export default BerbixFieldTable;
