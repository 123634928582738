import axios from 'axios';
import moment from 'moment';
import { get_api_path } from './api';

export function sessioned_client({ token, access }) {
  let accessToken = token;
  if (!token) {
    accessToken = access.token;
  }

  if (!accessToken) throw new Error('no access token');
  if (access && moment().isAfter(moment(access.expires_at * 1000))) throw new Error('token expired');

  const defaultOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return {
    get: (url, options = {}) => axios.get(url, { ...defaultOptions, ...options }),
    post: (url, data, options = {}) => axios.post(url, data, { ...defaultOptions, ...options }),
    put: (url, data, options = {}) => axios.put(url, data, { ...defaultOptions, ...options }),
    delete: (url, options = {}) => axios.delete(url, { ...defaultOptions, ...options }),
  };
}

export async function load_invite(token) {
  const url = get_api_path('public/invite', { token });
  const response = await axios.get(url);
  return response.data.result;
}

export async function login({ username, password, send_code }) {
  const url = get_api_path('auth/login');
  const response = await axios.post(url, { username, password, send_code });
  return response.data.result;
}

export async function retrieve_profile({ token, access }) {
  const url = get_api_path('self/profile');
  const response = await sessioned_client({ token, access }).get(url);
  return response.data.result;
}

export async function password_request({ username }) {
  const url = get_api_path('password/request');
  const response = await axios.post(url, { username });
  return response.data.result;
}

export async function password_query({ token }) {
  const url = get_api_path('password/query', { token });
  const response = await axios.get(url);
  return response.data.result;
}

export async function password_reset({ token, password }) {
  const url = get_api_path('password/reset');
  const response = await axios.post(url, { token, password });
  return response.data.result;
}

export async function register(data) {
  const url = get_api_path('public/register');
  const response = await axios.post(url, data);
  return response.data.result;
}

export async function verify_document(data) {
  const url = get_api_path('public/verify/document');
  const response = await axios.post(url, data);
  return response.data.result;
}

export async function document_submit(data) {
  const url = get_api_path('public/document/submit');
  const response = await axios.post(url, data);
  return response.data.result;
}

export async function get_acuant_bearer_token(data) {
  const url = get_api_path('public/acuant/auth');
  const response = await axios.get(url, data);
  return response.data.result;
}

export async function berbix_transaction(data) {
  const url = get_api_path("public/verify/berbix/transaction");
  const response = await axios.post(url, data);
  return response.data.result;
}

export async function berbix_callback(data) {
  const url = get_api_path("public/verify/berbix/callback");
  const response = await axios.post(url, data);
  return response.data.result;
}

export async function verify_email({token}) {
  const url = get_api_path("public/verify/email");
  const response = await axios.post(url, {token});
  return response.data.result;
}

export async function sumsub_transaction(data) {
  const url = get_api_path("public/verify/sumsub/transaction");
  const response = await axios.post(url, data);
  return response.data.result;
}

export async function veriff_transaction(data) {
  const url = get_api_path("public/verify/veriff/transaction");
  const response = await axios.post(url, data);
  return response.data.result;
}