import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import responsiveFontSizes from '@material-ui/core/styles/responsiveFontSizes';
import overrides from './overrides/index';
import palette from './palette';
import typography from './typography';

let theme = createMuiTheme({
  overrides,
  palette,
  typography,
});

export default responsiveFontSizes(theme);
