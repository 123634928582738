import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, Container } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { TraxxButton } from "app/components";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  bottomText: { margin: '12px 0' },
  button: { marginTop: '15px' },
  text: { 
    margin: '30px',
    textAlign: 'center' 
  },
  dialogBox: {
    padding: '20px 30px',
    [theme.breakpoints.down('500')]: {
      padding: '5px'
    }
  }
}))

const ConfirmOngoingScreeningDialog = (props) => {
  const { open, setOpen, loading, onSubmit, checked, expired_at, getDate, name } = props;
  const classes = useStyles();

  useEffect(() => {
    const now = moment();
    const toExpire = moment().add(1, 'y').subtract(1, 'd');
    setToday(getDate(now));
    if (expired_at) {
      const expire = moment(expired_at, moment.ISO_8601);
      setExpiryDate(getDate(expire));
      setDays(expire.toNow(true));
    } else {
      setExpiryDate(getDate(toExpire));
    }
  }, [])
  
  const [today, setToday] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [days, setDays] = useState(0);

  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Container className={classes.dialogBox}>
        <DialogTitle>{ checked ? "Cancel Ongoing Screening" : "Ongoing Screening Confirmation"}</DialogTitle>
        <DialogContent>    
        { checked ?
          <DialogContentText>
            You are about to cancel ongoing screening for { name ? name : "this person" }. <br />
            { expired_at && `The date of expiry is set at ${expiryDate}.` }{ moment(expired_at, moment.ISO_8601) > moment() && ` You still have ${days} left.` }{ expired_at && <br />}
            Are you sure you want to continue?
          </DialogContentText>
          :
          <div>
            <DialogContentText>
              You will be charged <strong>4 credits</strong> for ongoing screening with a validity of <strong>1 year</strong> from date of purchase.
            </DialogContentText>
            <DialogContentText className={classes.text}>
              Date of purchase: {today} <br />
              Date of expiry: {expiryDate}
            </DialogContentText>
            <DialogContentText className={classes.bottomText}>
              An email will be sent a month before month of expiry as a reminder to renew your purchase.
            </DialogContentText>
          </div>
        }
          <TraxxButton className={classes.button} loading={loading} fullWidth onSubmit={onSubmit}>Confirm</TraxxButton>
        </DialogContent>
      </Container>
    </Dialog>
  )
}

export default ConfirmOngoingScreeningDialog;