import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import TraxxButton from 'app/components/TraxxButton';
import VerificationInput from 'react-verification-input';
import './index.css'
import { useSnackbar } from 'notistack';
import { API } from 'app/services';
import { useDispatch } from 'react-redux';
import { actions } from 'app/store';
import { LocalStorageKeys } from 'app/constants';
import { useToastCatcher } from 'app/utils';

const useStyles = makeStyles(theme => ({
  button: { marginTop: '10px' },
  dialog: { backgroundColor: 'rgba(0,0,0,0.6)' },
  gridLeft: { textAlign: 'right' },
  checkbox: { 
    color: theme.palette.secondary.main,
    marginTop: '5px'
  },
  dialogBox: {
    padding: '20px 30px',
    [theme.breakpoints.down('500')]: {
      padding: '5px'
    }
  },
  closeIcon: {
    position: 'fixed',
    right: '20px',
    top: '20px',
    color: 'white',
    fontSize: '35px',
    cursor: 'pointer'
  },
}))

// Initialise countdown timer to 3 minutes
const init_counter = {
  sec: 0,
  min: 3
}

function LoginDialog({ open, setOpen, data, setData, lastRoute }) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [toaster, errorCatcher] = useToastCatcher({ errorParser: null, taskname: 'login' });

  const [counter, setCounter] = useState(init_counter);
  const [attempt, setAttempt] = useState(0);
  const [input, setInput] = useState('');
  const [error, setError] = useState();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const timer = (counter.sec > 0 || counter.min > 0) && setInterval(countdown, 1000)
    return () => clearInterval(timer);
  }, [counter]);

  const handleChange = (value) => setInput(value);

  const handleClose = () => setOpen(false);

  const handleChecked = () => setChecked((value) => !value);

  const handleResend = async () => {
    setCounter(init_counter);
    setInput('');
    setError();
    setAttempt(0);
    errorCatcher(async () => {
      const { email, password } = data;
      const newData = await API.Auth.login({
        username: email,
        password,
        send_code: true
      });
      setData({...data, ...newData});
      enqueueSnackbar('New verification code sent', {
        variant: 'success',
        autoHideDuration: 3000
      });
    }, 'login');
  };

  const onSubmit = () => {
    const { wallet, self, access, accounts, workspace, pin, email } = data;
    if (input === pin) {
      setError();
      if (checked) {
        localStorage.setItem(`traxxid:${email}`, true)
      }
      dispatch(actions.Profile.updateSession({ wallet, self, access, accounts, lastRoute, workspace }));
      localStorage.setItem(LocalStorageKeys.AccessToken, JSON.stringify(access));
      enqueueSnackbar(`Welcome, ${self.person.firstname}.`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } else {
      setAttempt(attempt + 1)
      const errorMsg = (input === '' ? 'Empty verification code' : 'Invalid verification code')
      setError(errorMsg)
      enqueueSnackbar(errorMsg, {
        variant: 'error',
        autoHideDuration: 3000
      })
    }
  }

  const countdown = () => { 
    let { min, sec } = counter;
    if (sec === 0) {
      min--;
      sec = 60;
    }
    sec--;
    setCounter({ sec, min });
  }

  const counting = counter.sec > 0 || counter.min > 0;

  return (
    <div>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        
        <CloseIcon className={classes.closeIcon} onClick={handleClose}/>
        <Container className={classes.dialogBox}>
          <DialogTitle>Login Verification</DialogTitle>
          <DialogContent>
            <DialogContentText>
              A verification code has been sent to {data.email ? data.email : 'your email address'}. <br />
              Enter the code below to verify your login.
            </DialogContentText>

            <Grid container direction="row" justifycontent="center" alignItems="stretch" spacing={2}>
              <Grid item xs={6} className={classes.gridLeft}>
                <Typography variant="h4">
                  {String(counter.min).padStart(2, "0")}
                </Typography>
                <Typography variant="body2">minutes</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h4">
                  {String(counter.sec).padStart(2, "0")}
                </Typography>
                <Typography variant="body2">seconds</Typography>
              </Grid>
            </Grid>

            <div className="div">
              <VerificationInput 
                autoFocus={true}
                length={6}
                classNames={{
                  container: "container",
                  character: `character ${error && 'character--error'}`,
                  characterSelected: "character--selected"
                }}
                value={input}
                onChange={handleChange}
                validChars='0-9'
                placeholder=''
              />
            </div>
          
            { 
              (attempt >= 2 || !counting) && 
              <TraxxButton className={classes.button} form fullWidth onSubmit={handleResend}>
                Resend Code
              </TraxxButton>
            }
            { 
              !!counting && 
              <TraxxButton className={classes.button} form fullWidth onSubmit={onSubmit}>
                Login
              </TraxxButton>
            }

            <div className="div">
              <FormControlLabel 
                className={classes.checkbox} 
                control={<Checkbox checked={checked} onChange={handleChecked} name="rememberMe" />} 
                label="Remember me on this device" 
              />
            </div>
          
          </DialogContent>
        </Container>
      </Dialog>
    </div>
  );
}

export default LoginDialog;