import { Box, Grid, LinearProgress, Link, Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataRow, MonoText, Title, StatusLabel, TabComp, Crumbs } from 'app/components';
import { API } from 'app/services';
import { snakeToTitle, useMoneyFormatter, useRouter, useToastCatcher } from 'app/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as BrowserLink } from 'react-router-dom';
import { WalletTxList } from '../components';
import { AddCreditComp } from './components';
import { currencies } from 'app/utils';
import { StatusMaps } from 'app/constants';
import { CreditListingComp } from '../Credit/CreditListing/components';

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  root: {
    padding: spacing(3, 3, 8),
    [breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  button: {
    marginTop: 10,
    float: 'right',
  },
  table: {
    borderRadius: 5,
  },
  green: {
    backgroundColor: '#c9efe1',
  },
  red: {
    backgroundColor: '#f8d0d0',
  },
  yellow: {
    backgroundColor: '#fff2cb',
  },
  status: {
    borderRadius: 4,
    fontSize: 12,
    fontWeight: 'bold',
    padding: '4px 6px',
  },
}));

let mounted = false;
function WalletDetail() {
  const classes = useStyles();
  const [wallet, setWallet] = useState(null);
  const [inputs, setInputs] = useState({});
  // eslint-disable-next-line
  const [inputError, setInputError] = useState();
  const loading = useSelector((state) => state.layout.loadingTasks);
  const access = useSelector((state) => state.profile.access);
  const [toaster, errorCatcher] = useToastCatcher({ errorParser: null, taskname: 'getWallet' });
  const router = useRouter();
  const { match } = router;
  const { params } = match;
  const formatter = useMoneyFormatter({ currency: wallet?.currency, decPlaces: 0 });

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) getWallet();
    // eslint-disable-next-line
  }, [access]);

  const getWallet = () => {
    if (!params.wallet_id) return toaster({ message: 'invalid id' });
    errorCatcher(async () => {
      const { model } = await API.Admin.get_wallet(params.wallet_id);
      if (mounted) setWallet(model);
    });
  };

  const handleChange = (type) => {
    return (e) => {
      setInputs({
        ...inputs,
        [type]: e.target.value,
      });
    };
  };

  const addCredit = () => {
    if (!inputs.amount) {
      setInputError('Please enter an valid amount');
      return;
    }
    setInputError('');
    errorCatcher(async () => {
      await API.Admin.add_credit(params.wallet_id, { amount: currencies.decompress(parseInt(inputs.amount), 'TXC'), comments: inputs.remark });
      if (mounted) {
        toaster('Credit added!', { variant: 'success' });
        setInputs({
          amount: undefined,
          remark: undefined,
        });
        getWallet();
      }
    });
  };

  return (
    <Box className={classes.root}>
      <Crumbs current="Detail" links={[{ text: 'Wallets', href: '/admin/wallets' }]} />
      <Title prefix={'Wallet detail'} />
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <TableContainer component={Paper} className={classes.table}>
            {loading['getWallet'] && <LinearProgress size={30} />}
            {!loading['getWallet'] && wallet && (
              <Table>
                <TableBody>
                  <DataRow name="Owner" value={<MonoText>{wallet.entity[wallet.entity.owner_type].name || wallet.entity[wallet.entity.owner_type].name}</MonoText>} />
                  <DataRow name="Owner Type" value={snakeToTitle(wallet.entity.owner_type)} />
                  <DataRow
                    name="Owner account ID"
                    wrapperProps={{ underline: 'always', to: `/admin/${wallet.entity.owner_type}s/${wallet.entity.owner_id}/detail`, component: BrowserLink }}
                    wrapper={Link}
                    value={<MonoText>{wallet.entity[wallet.entity.owner_type].reference}</MonoText>}
                  />
                  <DataRow name="Status" value={<StatusLabel variantMap={StatusMaps.Wallet}>{wallet.status}</StatusLabel>} />
                  <DataRow name="Wallet balance" value={<MonoText>{formatter(wallet.balance)}</MonoText>} />
                  <DataRow name="Transaction time" value={moment(wallet.created_at).format('YYYY-MM-DD HH:mm')} />
                  <DataRow name="Wallet ID" value={<MonoText>{wallet.reference}</MonoText>} />
                </TableBody>
              </Table>
            )}
          </TableContainer>
          <AddCreditComp inputs={inputs} inputError={inputError} addCredit={addCredit} handleChange={handleChange} />
        </Grid>
        <Grid item lg={8} xl={8} md={8} xs={12}>
          {!loading['getWallet'] && wallet && (
            <TabComp tabTitle="Wallet tabs" tabHeaders={['Wallet tx', 'Credits']} tabContent={[<WalletTxList userType="admin" wallet={wallet} />, <CreditListingComp routeType="admin" walletId={wallet.id} />]}></TabComp>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default WalletDetail;
