import { Box, Card, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton } from 'app/components';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 605,
    justifyContent: 'space-between',
    padding: 40,
    width: 410,
  },
  image: {
    height: 200,
    width: 200,
  },
  link: { alignSelf: 'flex-start' },
}));

function SuccessView(props) {
  const classes = useStyles();
  const { email, onReset, onRequest } = props;
  return (
    <Card className={classes.card}>
      <form className={classes.form} autoComplete="false">
        <Box alignSelf="flex-start">
          <TraxxButton component={RouterLink} startIcon={<img src="/images/arrow_back.svg" alt="back to log in" />} to="/access/login" variant="text">
            Back to log in
          </TraxxButton>
        </Box>

        <img className={classes.image} src="/images/sent_mail.svg" alt="sent mail" />

        <Typography variant="h5">Password reset link sent</Typography>

        <Typography align="center" variant="body2">
          We have sent a password reset link to your email at {email}&#46;
        </Typography>

        <TraxxButton fullWidth onSubmit={onRequest} form>
          Resend password reset link
        </TraxxButton>

        <Link className={classes.link} component={RouterLink} onClick={onReset}>
          Send password reset link to another email&#46;
        </Link>
      </form>
    </Card>
  );
}

export default SuccessView;
