import querystring from 'querystring';

const API_HOST = process.env.REACT_APP_API_HOST;
const PATHS = {};

PATHS['public/register'] = '/public/register'; // post
PATHS['public/invite'] = '/public/invite'; // post
PATHS['public/verify/document'] = '/public/verify/document'; // post
PATHS['public/document/submit'] = '/public/verify/document/submit'; // post
PATHS['public/acuant/auth'] = '/public/acuant/auth'; // get
PATHS['public/verify/berbix/transaction'] = '/public/verify/berbix/transaction'; // get
PATHS['public/verify/berbix/callback'] = '/public/verify/berbix/callback'; // get
PATHS['public/verify/email'] = '/public/verify/email'; // post
PATHS['public/verify/sumsub/transaction'] = '/public/verify/sumsub/transaction'; // post
PATHS['public/verify/sumsub/document_upload'] = '/public/verify/sumsub/document_upload'; // post
PATHS['public/verify/veriff/transaction'] = '/public/verify/veriff/transaction'; // post

PATHS['auth/login'] = '/auth/session/login'; // post

PATHS['password/request'] = '/auth/password/request'; // post
PATHS['password/query'] = '/auth/password/query'; // get
PATHS['password/reset'] = '/auth/password/reset'; // post

PATHS['self/profile'] = '/self/profile'; // get
PATHS['self/account/list'] = '/self/account/list'; // get
PATHS['self/account/join'] = '/self/account/join'; // post
PATHS['self/authorise'] = '/self/account/:account_id/authorise'; // post

PATHS['person/list'] = '/admin/person/list'; // get
PATHS['person/create'] = '/admin/person/create'; // post
PATHS['person/detail'] = '/admin/person/:person_id/detail'; // get
PATHS['person/update'] = '/admin/person/:person_id/update'; // post
PATHS['person/privilege'] = '/admin/person/:person_id/privilege'; // get

PATHS['account/list'] = '/admin/account/list'; // get
PATHS['account/create'] = '/admin/account/create'; // post
PATHS['account/update'] = '/admin/account/:account_id/update'; // post
PATHS['account/detail'] = '/admin/account/:account_id/detail'; // get
PATHS['account/assign'] = '/admin/account/:account_id/assign'; // post
PATHS['account/activate'] = '/admin/account/:account_id/activate'; // post
PATHS['account/verify/create'] = '/account/verify/request/create'; // post
PATHS['account/verify/create_sumsub'] = '/account/verify/request/create_sumsub'; // post
PATHS['account/verify/detail'] = '/account/verify/request/:request_id/detail'; // get
PATHS['account/verify/list'] = '/account/verify/request/list'; // get
PATHS['account/verify/disable'] = '/account/verify/request/:request_id/disable'; // post
PATHS['account/request/verify'] = '/account/verify/request/:request_id/verify'; // post
PATHS['account/product/list'] = '/account/product/list'; // get
PATHS['account/product/detail'] = '/account/product/:product_id/detail'; // get
PATHS['account/order/list'] = '/account/order/list';
PATHS['account/order/detail'] = '/account/order/:order_id/detail'; // get
PATHS['account/product/order'] = '/account/product/:product_id/order'; // post
PATHS['account/product/request'] = '/account/product/:product_id/request'; // post
PATHS['account/order/pay'] = '/account/order/:order_id/pay'; // post
PATHS['account/order/cancel'] = '/account/order/:order_id/cancel'; // post
PATHS['account/payment/list'] = '/account/payment/list'; // get
PATHS['account/payment/detail'] = '/account/payment/:payment_id/detail'; // get
PATHS['account/resolve/matches'] = '/account/screen/:screen_id/match/resolve'; // post
PATHS['account/unresolve/matches'] = '/account/screen/:screen_id/match/unresolve'; // post
PATHS['account/group/list'] = '/account/group/list';
PATHS['account/group/detail'] = '/account/group/:group_id/detail';
PATHS['account/group/create'] = '/account/group/create';
PATHS['account/group/update'] = '/account/group/:group_id/update';
PATHS['account/group/remove'] = '/account/group/:group_id/remove';
PATHS['account/generate/report'] = '/account/screen/:screen_id/report'; // post
PATHS['account/cached_article_retrieval/list'] = '/account/cached_article_retrieval/list'; // get
PATHS['account/cached_article_retrieval/detail'] = '/account/cached_article_retrieval/:cached_article_retrieval_id/detail'; // get

PATHS['privilege/list'] = '/admin/privilege/list'; // get

PATHS['case/list'] = '/account/case/list'; // get
PATHS['case/detail'] = '/account/case/:case_id/detail'; // get

PATHS['screen/list'] = '/account/screen/list'; // get
PATHS['screen/simple/create'] = '/account/screen/create/simple'; // post
PATHS['screen/passport/create'] = '/account/screen/create/passport'; // post
PATHS['screen/organisation/create'] = '/account/screen/create/organisation'; // post
PATHS['screen/batch/create'] = '/account/screen/create/batch'; // post
PATHS['screen/detail'] = '/account/screen/:screen_id/detail'; // get
PATHS['screen/ongoing_screening'] = '/account/screen/:screen_id/ongoing_screening'; // post
PATHS['screen/renew_ongoing_screening'] = '/account/screen/renew_ongoing_screening'; // post
PATHS['screen/renew'] = '/account/screen/create/renew'; // post
PATHS['screen/renew_ongoing_screening/list'] = '/account/screen/list_renew_ongoing_screening'; // get

PATHS['screen/match/list'] = '/account/screen/:screen_id/match/list'; // get
PATHS['screen/match/detail'] = '/account/screen/:screen_id/match/:match_id/detail'; // get
PATHS['screen/match/profile'] = '/account/screen/:screen_id/match/:match_id/profile'; // get
PATHS['screen/match/retrieve'] = '/account/screen/:screen_id/match/:match_id/retrieve'; // post
PATHS['screen/match/flag'] = '/account/screen/:screen_id/match/:match_id/flag'; // post
PATHS['screen/match/cached_article'] = '/account/screen/:screen_id/match/:match_id/cached_article'; // post
PATHS['screen/match/djrc_article_retrieval'] = '/account/screen/:screen_id/match/:match_id/djrc_article_retrieval'; // post
PATHS['screen/download/images'] = '/account/screen/download_images'; //get

PATHS['countries/list'] = '/account/screen/info/countries'; // get

PATHS['stats/summary'] = '/account/stats/summary'; // get
PATHS['event/log'] = '/account/log/list'; // get

PATHS['document/list'] = '/account/verify/list'; // get
PATHS['document/detail'] = '/account/verify/:verification_id/detail'; // get

PATHS['user/list'] = '/account/user/list'; // get
PATHS['user/detail'] = '/account/user/:user_id/detail'; // get
PATHS['user/invite/list'] = '/account/user/invite/list'; // get
PATHS['user/invite/create'] = '/account/user/invite/create'; // post
PATHS['user/invite/cancel'] = '/account/user/invite/:invite_id/cancel'; // post
PATHS['user/privilege/list'] = '/account/privilege/list'; // get
PATHS['user/privilege/set'] = '/account/user/:user_id/privilege'; // post
PATHS['user/key/generate'] = '/account/user/:account_entity_id/api_key/generate'; // post
PATHS['user/key/retrieve'] = '/account/user/:account_entity_id/api_key/list'; // get
PATHS['user/key/update'] = '/account/user/:account_entity_id/api_key/:api_key_id/update'; // post
PATHS['user/key/remove'] = '/account/user/:account_entity_id/api_key/:api_key_id/remove'; // post

PATHS['verify/submit'] = '/account/verify/submit'; // post
PATHS['verify/check'] = '/account/verify/check'; // post
PATHS['verify/update'] = '/account/verify/:verification_id/update'; // post
PATHS['verify/status'] = '/account/verify/:verification_id/status'; // post

PATHS['wallet/list'] = '/account/wallet/list'; // get
PATHS['wallet/detail'] = '/account/wallet/:wallet_id/detail'; // get
PATHS['wallet/tx/list'] = '/account/wallet/:wallet_id/wallet_tx/list'; // get
PATHS['wallet/tx/detail'] = '/account/wallet/:wallet_id/wallet_tx/:wallet_tx_id/detail'; // get
PATHS['wallet/main'] = '/account/wallet/main'; // get
PATHS['wallet/statistic'] = '/account/wallet/:wallet_id/statistics'; //get
PATHS['wallet/credit/list'] = '/account/wallet/:wallet_id/credit/list'; //get
PATHS['wallet/credit/detail'] = '/account/wallet/:wallet_id/credit/:credit_id/detail'; //get

PATHS['admin/wallet/list'] = '/admin/wallet/list'; // get
PATHS['admin/wallet/detail'] = '/admin/wallet/:wallet_id/detail'; // get
PATHS['admin/wallet/transact'] = '/admin/wallet/:wallet_id/transact'; // post
PATHS['admin/wallet/tx/list'] = '/admin/wallet_tx/list'; // get
PATHS['admin/wallet/tx/detail'] = '/admin/wallet_tx/:wallet_tx_id/detail'; // get
PATHS['admin/wallet/info'] = '/admin/wallet/info'; // get
PATHS['admin/screen/list'] = '/admin/screen/list'; // get
PATHS['admin/screen/detail'] = '/admin/screen/:screen_id/detail'; // get
PATHS['admin/screen/ongoing_screening'] = '/admin/screen/:screen_id/ongoing_screening'; // post
PATHS['admin/screen/renew_ongoing_screening'] = '/admin/screen/:screen_id/renew_ongoing_screening'; // post
PATHS['admin/case/list'] = '/admin/case/list'; // get
PATHS['admin/case/detail'] = '/admin/case/:case_id/detail'; // get
PATHS['admin/match/list'] = '/admin/match/list'; // get
PATHS['admin/match/detail'] = '/admin/match/:match_id/detail'; // get
PATHS['admin/account/entity/list'] = '/admin/account/entity/list'; // get
PATHS['admin/account/entity/detail'] = '/admin/account/entity/:account_entity_id/detail'; // get
PATHS['admin/person/entity/list'] = '/admin/account/:account_id/person/list'; // get
PATHS['admin/person/account/list'] = '/admin/entity/:entity_id/account/list'; // get
PATHS['admin/account/wallet'] = '/admin/account/:account_id/wallet/detail'; // get
PATHS['admin/event/log'] = '/admin/log/list'; // get
PATHS['admin/person/account/add'] = '/admin/account/entity/create'; // post
PATHS['admin/person/account/remove'] = '/admin/account/entity/:account_entity_id/delete'; // delete
PATHS['admin/person/update/status'] = '/admin/person/:person_id/status/update'; // post
PATHS['admin/verify/list'] = '/admin/verify/list'; // get
PATHS['admin/verify/detail'] = '/admin/verify/:verification_id/detail'; // get
PATHS['admin/verify/request/list'] = '/admin/verify/request/list'; // get
PATHS['admin/verify/request/detail'] = '/admin/verify/request/:request_id/detail'; // get
PATHS['admin/product/list'] = '/admin/product/list'; // get
PATHS['admin/product/detail'] = '/admin/product/:product_id/detail'; // get
PATHS['admin/product/create'] = '/admin/product/create'; //post
PATHS['admin/product/update'] = '/admin/product/:product_id/update'; // post
PATHS['admin/product/delete'] = '/admin/product/:product_id/delete'; // delete
PATHS['admin/order/list'] = '/admin/order/list';
PATHS['admin/order/detail'] = '/admin/order/:order_id/detail'; // get
PATHS['admin/order/cancel'] = '/admin/order/:order_id/cancel'; // post
PATHS['admin/payment/list'] = '/admin/payment/list'; // get
PATHS['admin/payment/detail'] = '/admin/payment/:payment_id/detail'; // get
PATHS['admin/wallet/statistic'] = '/admin/wallet/statistics'; // get
PATHS['admin/account/status'] = '/admin/account/:account_id/status/update'; // post
PATHS['admin/credit/list'] = '/admin/wallet/:wallet_id/credit/list'; // get
PATHS['admin/credit/detail'] = '/admin/wallet/:wallet_id/credit/:credit_id/detail'; // get
PATHS['admin/credit/update'] = '/admin/wallet/:wallet_id/credit/:credit_id/update'; // post
PATHS['admin/credit/invalidate'] = '/admin/wallet/:wallet_id/credit/:credit_id/invalidate'; // post
PATHS['admin/credit/set/expiry'] = '/admin/wallet/balance_wallets'; // post
PATHS['admin/mail/list'] = '/admin/mail/list'; // get
PATHS['admin/mail/preview'] = '/admin/mail/preview'; // post
PATHS['admin/cached_article_retrieval/list'] = '/admin/cached_article_retrieval/list'; // get
PATHS['admin/cached_article_retrieval/detail'] = '/admin/cached_article_retrieval/:cached_article_retrieval_id/detail'; // get
PATHS['admin/cached_article_retrieval/upload'] = '/admin/cached_article_retrieval/:cached_article_retrieval_id/upload'; // post
PATHS['admin/cached_article_retrieval/update'] = '/admin/cached_article_retrieval/:cached_article_retrieval_id/update'; // post
PATHS['admin/privilege/ongoing_screening'] = '/admin/privilege/ongoing_screening'; // post
PATHS['admin/screen/match/djrc_article_retrieval'] = '/admin/screen/:screen_id/match/:match_id/djrc_article_retrieval'; // post
PATHS['admin/privilege/download_image'] = '/admin/privilege/download_image'; // post

PATHS['group/case/list'] = '/account/group/:case_group_id/case/list';
PATHS['group/case/create'] = '/account/group/:case_group_id/case/create';
PATHS['group/case/remove'] = '/account/group/:case_group_id/case/remove';
PATHS['all/case/group'] = '/account/case/:case_id/group/list';

export function get_api_path(key, query_params, route_params = {}) {
  const prefix = API_HOST;

  let url = prefix + PATHS[key];
  // eslint-disable-next-line no-unused-vars
  for (const paramKey in route_params) url = url.replace(`:${paramKey}`, route_params[paramKey]);

  if (query_params) url += '?' + querystring.stringify(query_params);

  return url;
}
