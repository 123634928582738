import { store } from 'app/store';
import { get_api_path } from './api';
import { sessioned_client } from './auth';

export async function create_person(data) {
  const url = get_api_path('person/create');
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  const { model } = response.data.result;
  return { model };
}

export async function list_persons(filter = {}) {
  const url = get_api_path('person/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  const { models, meta } = response.data.result;
  return { models, meta };
}
export async function get_person(person_id) {
  const url = get_api_path('person/detail', null, { person_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  const { model } = response.data.result;
  return { model };
}
export async function update_person(person_id, data) {
  const url = get_api_path('person/update', null, { person_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  const { model } = response.data.result;
  return { model };
}

export async function update_person_status(person_id, data) {
  const url = get_api_path('admin/person/update/status', null, { person_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  const { model } = response.data.result;
  return { model };
}

export async function list_accounts(filter = {}) {
  const url = get_api_path('account/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  const { models, meta } = response.data.result;
  return { models, meta };
}
export async function get_account(account_id) {
  const url = get_api_path('account/detail', null, { account_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  const { model } = response.data.result;
  return { model };
}
export async function create_account(data) {
  const url = get_api_path('account/create');
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  const { model } = response.data.result;
  return { model };
}
export async function update_account(account_id, data) {
  const url = get_api_path('account/update', null, { account_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  const { model } = response.data.result;
  return { model };
}

export async function list_privilege(filter = {}) {
  const url = get_api_path('privilege/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  const { models, meta } = response.data.result;
  return { models, meta };
}
export async function set_privilege(person_id, data) {
  const url = get_api_path('person/privilege', null, { person_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  const { model } = response.data.result;
  return { model };
}
export async function set_active_account(account_id) {
  const url = get_api_path('account/activate', null, { account_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  const { model } = response.data.result;
  return { model };
}
export async function list_wallets(filter = {}) {
  const url = get_api_path('admin/wallet/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function wallet_statistic(filter = {}) {
  const url = get_api_path('admin/wallet/statistic', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_wallet(wallet_id) {
  const url = get_api_path('admin/wallet/detail', null, { wallet_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function list_wallet_tx(filter = {}) {
  const url = get_api_path('admin/wallet/tx/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_wallettx_detail(wallet_tx_id) {
  const url = get_api_path('admin/wallet/tx/detail', null, { wallet_tx_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_wallet_info() {
  const url = get_api_path('admin/wallet/info');
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function add_credit(wallet_id, data) {
  const url = get_api_path('admin/wallet/transact', null, { wallet_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function list_screen(filter = {}) {
  const url = get_api_path('admin/screen/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_screen(screen_id) {
  const url = get_api_path('admin/screen/detail', null, { screen_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function update_ongoing_screening(screen_id) {
  const url = get_api_path('admin/screen/ongoing_screening', null, { screen_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function renew_ongoing_screening(screen_id) {
  const url = get_api_path('admin/screen/renew_ongoing_screening', null, { screen_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function list_case(filter = {}) {
  const url = get_api_path('admin/case/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_case(case_id) {
  const url = get_api_path('admin/case/detail', null, { case_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function list_match(screen, filter = {}) {
  filter.screen = screen;
  const url = get_api_path('admin/match/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_match(screen, match_id, filter = {}) {
  filter.screen = screen;
  const url = get_api_path('admin/match/detail', filter, { match_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function list_account_entities(filter = {}) {
  const url = get_api_path('admin/account/entity/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_account_entity_detail(entity_id) {
  const url = get_api_path('admin/account/entity/detail', null, { entity_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function list_account_users(account_id, filter = {}) {
  const url = get_api_path('admin/person/entity/list', filter, { account_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function list_user_accounts(entity_id, filter = {}) {
  const url = get_api_path('admin/person/account/list', filter, { entity_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_account_wallet(account_id) {
  const url = get_api_path('admin/account/wallet', null, { account_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_event_logs(filter) {
  const url = get_api_path('admin/event/log', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_email_templates() {
  const url = get_api_path('admin/mail/list');
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function send_email_preview(state) {
  const url = get_api_path('admin/mail/preview', state);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function add_person_to_account(data) {
  const url = get_api_path('admin/person/account/add');
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function remove_person_from_account(account_entity_id) {
  const url = get_api_path('admin/person/account/remove', null, { account_entity_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).delete(url);
  return response.data.result;
}

export async function list_verify_document(filter) {
  const url = get_api_path('admin/verify/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_verify_detail(verification_id) {
  const url = get_api_path('admin/verify/detail', null, { verification_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function list_verification_invites(filter) {
  const url = get_api_path('admin/verify/request/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_verification_request_detail(request_id) {
  const url = get_api_path('admin/verify/request/detail', null, { request_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function list_orders(filter) {
  const url = get_api_path('admin/order/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_order_detail(order_id) {
  const url = get_api_path('admin/order/detail', null, { order_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function cancel_order(order_id) {
  const url = get_api_path('admin/order/cancel', null, { order_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function list_payments(filter) {
  const url = get_api_path('admin/payment/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_payment_detail(payment_id) {
  const url = get_api_path('admin/payment/detail', null, { payment_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function create_product(data) {
  const url = get_api_path('admin/product/create');
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function update_product(product_id, data) {
  const url = get_api_path('admin/product/update', null, { product_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function list_products(filter) {
  const url = get_api_path('admin/product/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function get_product_detail(product_id) {
  const url = get_api_path('admin/product/detail', null, { product_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function delete_product(product_id) {
  const url = get_api_path('admin/product/delete', null, { product_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function update_account_status(account_id, data) {
  const url = get_api_path('admin/account/status', null, { account_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function list_credit(wallet_id) {
  const url = get_api_path('admin/credit/list', null, { wallet_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function credit_detail(wallet_id, credit_id) {
  const url = get_api_path('admin/credit/detail', null, { wallet_id, credit_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function update_credit(wallet_id, credit_id, data) {
  const url = get_api_path('admin/credit/update', null, { wallet_id, credit_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function invalidate_credit(wallet_id, credit_id) {
  const url = get_api_path('admin/credit/invalidate', null, { wallet_id, credit_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function floating_credit_set_expiry() {
  const url = get_api_path('admin/credit/set/expiry');
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function list_cached_article_retrieval(filter = {}) {
  const url = get_api_path('admin/cached_article_retrieval/list', filter);
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function detail_cached_article_retrieval(cached_article_retrieval_id) {
  const url = get_api_path('admin/cached_article_retrieval/detail', null, { cached_article_retrieval_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).get(url);
  return response.data.result;
}

export async function upload_cached_article_retrieval(cached_article_retrieval_id, data) {
  const url = get_api_path('admin/cached_article_retrieval/upload', null, { cached_article_retrieval_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function update_cached_article_retrieval(cached_article_retrieval_id) {
  const url = get_api_path('admin/cached_article_retrieval/update', null, { cached_article_retrieval_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function update_account_ongoing_screening_privilege(account_id) {
  const url = get_api_path('admin/privilege/ongoing_screening', { account_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}

export async function djrc_article_retrieval(screen_id, match_id, data) {
  const url = get_api_path('admin/screen/match/djrc_article_retrieval', null, { screen_id, match_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url, data);
  return response.data.result;
}

export async function update_account_download_image_privilege(account_id) {
  const url = get_api_path('admin/privilege/download_image', { account_id });
  const { access } = store.getState().profile;
  const response = await sessioned_client({ token: access.token, access }).post(url);
  return response.data.result;
}