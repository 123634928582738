import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton, TraxxInput } from 'app/components';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {},
  typeOption: {
    alignItems: 'flex-start',
    display: 'flex',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  stepButton: {
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  input: {
    marginTop: theme.spacing(2),
  },
  tinyFont: {
    fontSize: 12,
  },
}));

const CaseAssignment = (props) => {
  const { children, className, caseName, onNameInput, onNext, ...rest } = props;
  const classes = useStyles();

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Box mt={2}>
        <Typography variant="body1">Will you like to assign a case name?</Typography>
        <Typography color="secondary" className={classes.tinyFont}>
          (Click next if you do not want to assign a case name)
        </Typography>
      </Box>
      <Box mt={2}>
        <TraxxInput
          className={classes.input}
          label="Case label"
          id="case_name"
          InputProps={{
            classes: { underline: classes.underline },
          }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={({ target }) => onNameInput(target.value)}
          placeholder="Name of case (for your book-keeping use)"
          value={caseName}
        />
      </Box>
      <Box mt={6} display="flex">
        <Box flexGrow={1} />
        <TraxxButton onSubmit={onNext}>Next</TraxxButton>
      </Box>
    </Box>
  );
};

export default CaseAssignment;
