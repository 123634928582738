import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateDisplay, MonoText, StatusLabel, Title } from 'app/components';
import { StatusMaps } from 'app/constants';
import { API } from 'app/services';
import { getTimezone, snakeToTitle, useAsyncTask } from 'app/utils';
import 'app/utils/arialNormal';
import clsx from 'clsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {},
  noPrint: {},
  sameRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    float: 'right',
    height: 30,
    marginLeft: theme.spacing(2),
  },
  frame: {
    width: '100%',
    height: ({ screenHeight }) => screenHeight,
  },
}));

const matchHeader = (header) => {
  switch (header) {
    case 'Date Of Birth':
      return 'D.O.B';
    case 'Country Location':
      return 'Country';
    case 'Place Of Birth':
      return 'P.O.B';
    default:
      return header;
  }
};

const getVariant = (res) => {
  switch (res) {
    case 'NOT_MATCHED':
      return `NOT_MATCHED`;
    case 'MATCHED':
      return `MATCHED`;
    default:
      return `UNKNOWN`;
  }
};

const getTableData = (key, value, extra) => {
  return { key, value, extra };
};

const parseType = (type) => {
  switch (type) {
    case 'passport':
      return 'Passport/ID';
    default:
      return snakeToTitle(type);
  }
};

const getScreen = (screen) => {
  return [
    getTableData('Date', getTimezone(screen.submit_at, 'DD-MMM-YYYY'), { wrapper: () => <DateDisplay date={screen.submit_at} /> }),
    getTableData('Screen ID', screen.reference, { wrapper: MonoText }),
    getTableData('Type', parseType(screen.type)),
    getTableData('Status', snakeToTitle(screen.status, true), { wrapper: ({ children }) => <StatusLabel variantMap={StatusMaps.Screen}>{children}</StatusLabel> }),
    getTableData('Match Count', screen.results_count),
    getTableData('Ongoing Screening', screen.ongoing_screening ? 'Enabled' : 'Disabled'),
    getTableData('Ongoing Screening From', screen.ongoing_screening ? getTimezone(screen.ongoing_screening_date, 'DD-MMM-YYYY') : '-'),
  ];
};

const getCase = ({ case: caseRef }) => {
  if (!caseRef) return [];
  return [
    getTableData('Name', caseRef.label),
    getTableData('Case ID', caseRef.reference, { wrapper: MonoText }),
    getTableData('Status', snakeToTitle(caseRef.status, true), { wrapper: ({ children }) => <StatusLabel variantMap={StatusMaps.Case}>{children}</StatusLabel> }),
  ];
};

const sortAudit = (logs = []) => {
  let sorted = [];
  logs.forEach((log) => {
    sorted.push([log.actor?.owner_type === 'person' ? log.actor.person.firstname : '', snakeToTitle(log.description, true), getTimezone(log.timestamp)]);
  });
  return sorted;
};

const sortScreenParams = (params, type) => {
  const data = [];
  switch (type) {
    case 'organisation':
    case 'vessel':
      Object.keys(params).forEach((key) => {
        data.push(getTableData(snakeToTitle(key, true), params[key]));
      });
      break;
    default:
      Object.keys(params).forEach((key) => {
        if (key === 'date_of_birth' || key === 'date_of_expiry') data.push(getTableData(snakeToTitle(key, true), getTimezone(params[key], 'DD-MMM-YYYY'), { wrapper: () => <DateDisplay date={params[key]} dateFormat={'DD-MMM-YYYY HH:mm'} /> }));
        else data.push(getTableData(snakeToTitle(key, true), params[key]));
      });
  }
  return data;
};

const text_parser = (text) => {
  switch (text) {
    case 'Id number':
      return 'ID number';
    default:
      return text;
  }
};

let mounted = false;
const ReportPreview = (props) => {
  const { auditLog, screening, routeType, cancelPreview, className } = props;
  const { params } = screening.screen_request;
  const [screenData] = useState(getScreen(screening));
  const classes = useStyles({ screenHeight: window.screen.height });
  const [runMatchList] = useAsyncTask('loadMatches');
  const [runGenerateReport] = useAsyncTask('generateReport');
  const [matchList, setMatchList] = useState([]);
  const [auditData] = useState(sortAudit(auditLog));
  const [screenParams] = useState(sortScreenParams(params, screening.type));
  const [frameSrc, setFrameSrc] = useState();
  const [displayFrame, setDisplayFrame] = useState(false);
  const [caseData] = useState(getCase(screening));
  const [downloadReady, setDownloadReady] = useState();
  // const [matchData] = useState(getMatch(detail))
  // const [matchDetailData] = useState(getMatchDetail(profile))

  useEffect(() => {
    getMatching({ limit: 10000 });

    mounted = true;
    return () => (mounted = false);
    // eslint-disable-next-line
  }, []);

  const generatePDF = (list, download) => {
    const pdfDoc = new jsPDF();
    const matchHeaders = ['Name', 'Match Rating', 'Resolved'];
    const matchData = [];

    const [statsHeader, statsData] = getMatchStatistic(list);
    list.forEach((record) => {
      let arr = [
        (record.matched_term ? record.matched_term + '\n' : '') + record.name,
        record.match_rating + `${record.match_type ? '\n' + record.match_type : ''}`,
        (record.resolved_at ? moment(record.resolved_at).format('YYYY-MM-DD HH:MM') : 'Not resolved') + (record.resolved_at ? `${record.resolved_reason ? '\n' + record.resolved_reason : ''}` : ''),
      ];
      const matchAttributes = JSON.parse(record.match_attributes);
      if (matchAttributes) {
        matchAttributes.forEach((attribute) => {
          if (!matchHeaders.includes(matchHeader(snakeToTitle(attribute.field)))) matchHeaders.push(matchHeader(snakeToTitle(attribute.field)));
          arr.push(getVariant(attribute.result));
        });
      }

      matchData.push(arr);
    });

    let positionY = 0;
    pdfDoc.addImage('/images/traxx_logo.png', 'png', 9, 15);
    pdfDoc.setFont('Arial');
    pdfDoc.setFontSize(10);
    pdfDoc.text(`Powered by ${screening.screen_provider_id === 1 ? 'Refinitiv World-Check One' : screening.screen_provider_id === 2 ? "Dow Jones Risk and Compliance" : 'ComplyAdvantage'}`, 10, 34);
    pdfDoc.setFontSize(12);
    pdfDoc.setFont('Times', 'bold');
    pdfDoc.text('Screening Report', 10, 40);
    pdfDoc.setFont('Arial', 'normal');
    pdfDoc.setFontSize(10);
    pdfDoc.text(`Report generated on ${moment().format('DD MMM YYYY, HH:mm')}`, 10, 45);

    pdfDoc.autoTable({
      head: [[{ content: 'Screen Input', colSpan: 2 }]],
      body: screenParams.map((da) => [[text_parser(da.key)], da.key === 'Gender' ? snakeToTitle(da.value, true) : da.value]),
      startY: 50,
      margin: 10,
      tableWidth: 90,
      styles: { cellWidth: 'break-word', font: 'Arial' },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 60, halign: 'right' },
      },
      didDrawPage: (cell) => {
        if (positionY < cell.cursor.y) {
          positionY = cell.cursor.y;
        }
      },
      headStyles: {
        fillColor: [82, 0, 0],
      },
    });
    let tempY = 0;
    pdfDoc.autoTable({
      head: [[{ content: 'Screen Detail', colSpan: 2 }]],
      body: screenData.map((da) => [[da.key], da.value]),
      startY: 50,
      margin: { left: 110 },
      tableWidth: 90,
      styles: { cellWidth: 'break-word', font: 'Arial' },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 60, halign: 'right' },
      },
      headStyles: {
        fillColor: [82, 0, 0],
      },
      didDrawPage: (cell, param2) => {
        tempY = cell.cursor.y;
      },
    });

    pdfDoc.autoTable({
      head: [statsHeader],
      body: [statsData],
      startY: tempY + 5,
      margin: { left: 110 },
      tableWidth: 90,
      styles: { cellWidth: 'break-word', font: 'Arial' },
      headStyles: {
        fillColor: [82, 0, 0],
      },
      columnStyles: {
        0: { cellWidth: 'auto', halign: 'center' },
        1: { cellWidth: 'auto', halign: 'center' },
        2: { cellWidth: 'auto', halign: 'center' },
        3: { cellWidth: 'auto', halign: 'center' },
      },
      didDrawPage: (cell, param2) => {
        if (positionY < cell.cursor.y) {
          tempY = cell.cursor.y;
        }
      },
    });

    pdfDoc.autoTable({
      head: [[{ content: 'Case Detail', colSpan: 2 }]],
      body: caseData.map((da) => [[da.key], da.value]),
      startY: positionY + 5,
      margin: 10,
      tableWidth: 90,
      styles: { cellWidth: 'break-word', font: 'Arial' },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 60, halign: 'right' },
      },
      headStyles: {
        fillColor: [82, 0, 0],
      },
      didDrawPage: (cell) => {
        if (positionY < cell.cursor.y) {
          positionY = cell.cursor.y;
          if (tempY > positionY) positionY = tempY;
        }
      },
    });

    // add match table

    pdfDoc.addPage('a4', 'landscape');
    positionY = 20;

    const matchHead = [[{ content: 'All Matches', colSpan: matchHeaders.length, styles: { fillColor: [82, 0, 0], halign: 'left', textColor: 'white' } }]];
    if (matchData.length > 0) matchHead.push(matchHeaders);
    pdfDoc.autoTable({
      head: matchHead,
      body: matchData.length > 0 ? matchData : [[{ content: 'NO MATCH', styles: { halign: 'center' } }]],
      startY: positionY + 5,
      margin: 10,
      tableWidth: 276,
      styles: { cellWidth: 'break-word', font: 'Arial' },
      columnStyles: {
        0: { cellWidth: matchHeaders.length > 5 ? 40 : 50 },
        1: { halign: 'center', cellWidth: 40, valign: 'middle' },
        2: { halign: 'center', valign: 'middle' },
        3: { halign: 'center', valign: 'middle' },
        4: { halign: 'center', valign: 'middle' },
        5: { halign: 'center', valign: 'middle' },
        6: { halign: 'center', valign: 'middle' },
        7: { halign: 'center', valign: 'middle' },
        8: { halign: 'center', valign: 'middle' },
        9: { halign: 'center', valign: 'middle' },
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontWeight: 400,
        halign: 'center',
      },
      didDrawPage: (cell) => {
        if (positionY < cell.cursor.y) {
          positionY = cell.cursor.y;
        }
      },
      didParseCell: function (data) {
        if (data.section === 'head' && data.cell.raw === 'Name') {
          data.cell.styles.halign = 'left';
        }
        if (data.section === 'body' && matchData.length < 1) {
          data.cell.styles.halign = 'middle';
        }
        if (data.section === 'body' && data.cell.raw === 'NOT_MATCHED') {
          data.cell.styles.textColor = [255, 51, 0];
          data.cell.text = 'X';
        }
        if (data.section === 'body' && data.cell.raw === 'MATCHED') {
          data.cell.styles.textColor = [0, 153, 51];
          data.cell.text = 'MATCH';
        }
        if (data.section === 'body' && data.cell.raw === 'UNKNOWN') {
          data.cell.styles.textColor = [204, 122, 0];
          data.cell.text = '?';
        }
      },
    });

    const auditHead = [[{ content: 'Audit', colSpan: 3, styles: { fillColor: [82, 0, 0], halign: 'left', textColor: 'white' } }]];
    if (auditData.length > 0) auditHead.push(['Actioned by', 'Action', 'Timestamp']);

    pdfDoc.autoTable({
      head: auditHead,
      body: auditData.length > 0 ? auditData : [[{ content: 'NO AUDIT DATA', styles: { halign: 'center' } }]],
      startY: positionY + 5,
      margin: 10,
      tableWidth: 276,
      styles: { cellWidth: 'break-word', font: 'Arial' },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontWeight: 400,
        halign: 'left',
      },
      didDrawPage: (cell) => {
        if (positionY < cell.cursor.y) {
          positionY = cell.cursor.y;
        }
      },
    });

    if (download) {
      pdfDoc.save(`${screening.case?.label || screening.screen_request?.params?.name || screening.reference}.pdf`);
    } else {
      var totalPages = pdfDoc.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdfDoc.setPage(i);
        // pdfDoc.addImage("/images/draft.png", 'PNG', 40, 40, 75, 75);
        pdfDoc.setTextColor(150);
        pdfDoc.setFontSize(100);
        pdfDoc.text(pdfDoc.internal.pageSize.width / 2 - 25, pdfDoc.internal.pageSize.height / 2 + 25, 'DRAFT', 45);
      }
      const dataurl = pdfDoc.output('bloburi');
      setFrameSrc(dataurl);
      setDisplayFrame(true);
      setDownloadReady(true);
    }
  };

  const getMatching = (newFilter) => {
    setMatchList(null);
    runMatchList(async () => {
      const loader = routeType === 'account' ? API.Screening.get_matching_list : API.Admin.list_match;
      const { models } = await loader(screening.id, newFilter);
      if (mounted) {
        setMatchList(models);
        generatePDF(models);
      }
    });
  };

  const getMatchStatistic = (matches) => {
    let stats = {};
    let statHeaders = [];
    matches.forEach((match) => {
      if (stats[match.match_rating]) stats[match.match_rating] += 1;
      else stats[match.match_rating] = 1;
    });
    let stats_arr = [];
    Object.keys(stats).forEach((key) => {
      if (!statHeaders.includes(key)) statHeaders.push(key);
    });
    statHeaders.forEach((header) => {
      stats_arr.push(stats[header] || 0);
    });

    return [statHeaders, stats_arr];
  };

  const getButtons = () => {
    let arr = [
      { space: 3, onClick: () => cancelPreview(), text: 'Cancel' },
      { space: 4, onClick: onGenerate, text: downloadReady ? 'Download PDF' : ' PDF Download Preparing...' },
      { space: 5, holder: true },
    ];
    return arr;
  };

  const onGenerate = () => {
    runGenerateReport(async () => {
      if (routeType === 'account') await API.Users.generate_report(screening.id);
      generatePDF(matchList, true);
    });
  };

  return (
    <Box className={clsx(classes.root, className)}>
      <Title prefix="Print preview" className={classes.noPrint} floatButtons={getButtons()} />

      {displayFrame && <iframe title="Report Preview" className={classes.frame} src={frameSrc} />}
    </Box>
  );
};

export default ReportPreview;
