import { Box, Card, CardContent, CardHeader, Checkbox, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { DataRow } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
  },
  card: {
    maxWidth: 700,
    margin: 'auto',
  },
  group: {
    marginLeft: '25px',
  },
  label: {
    marginRight: '50px',
  },
}));

let mounted = false;
const OngoingScreening = (props) => {
  const { children, className, ...rest } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const access = useSelector((state) => state.profile.access);
  const [runLoadAccounts] = useAsyncTask('loadAccounts');
  const [runUpdateAccount] = useAsyncTask('updateAccount');
  const [accounts, setAccounts] = useState([]);
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (access && access.token) {
      getAccounts();
    }
    // eslint-disable-next-line
  }, [access]);

  const loadSetChecked = (accounts) => {
    const checklist = [];
    for (const account of accounts) {
      checklist.push({
        name: account.name,
        isChecked: account.entity.privileges.find((privilege) => privilege.name === 'dowjones.ongoing_screening') ? true : false,
      });
    }
    setChecked(checklist);
  };

  const getAccounts = (callback) => {
    runLoadAccounts(async () => {
      const { models } = await API.Admin.list_accounts({ nolimit: true });
      if (mounted) {
        setAccounts(models);
        loadSetChecked(models);
        if (typeof callback === 'function') callback();
      }
    });
  };

  const handleChecked = (accountId) => {
    return () => {
      runUpdateAccount(async () => {
        await API.Admin.update_account_ongoing_screening_privilege(accountId);
        getAccounts(() => enqueueSnackbar('Ongoing Screening Updated', { variant: 'success' }));
      });
    };
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Card className={classes.card}>
        <CardHeader title="Ongoing Screening Setting" />
        <CardContent>
          <Alert severity="info">Set ongoing screening privilege to the following accounts.</Alert>
        </CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="h6">Accounts</Typography>
              </TableCell>
            </TableRow>
            {accounts &&
              accounts.map((account, index) => (
                <DataRow key={index} id={index} name={account.name} value={<Checkbox checked={checked[index] && checked[index].isChecked ? true : false} name={account.name} value={account.id} onChange={handleChecked(account.id)} />} />
              ))}
          </TableBody>
        </Table>
      </Card>
    </Box>
  );
};

export default OngoingScreening;
