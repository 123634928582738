import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LogoutIcon from '@material-ui/icons/ExitToApp';
// import PersonIcon from '@material-ui/icons/Person';
// import SettingsIcon from '@material-ui/icons/Settings';
import { LocalStorageKeys, Paths } from 'app/constants';
import { actions } from 'app/store';
import { useRouter } from 'app/utils';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  action: {
    flex: 1,
    padding: theme.spacing(1.5, 3),
    justifyContent: 'flex-start',
  },
  actionLabel: {
    fontFamily: 'Muli',
    fontSize: 14,
    lineHeight: '20px',
    marginLeft: theme.spacing(1),
    textTransform: 'none',
    fontWeight: 'bold',
  },
}));

/**
 * Actions for user extra menu
 *
 * Props:
 ** className: name of css class element
 */
const MenuActions = (props) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const router = useRouter();

  const onLogout = () => {
    dispatch(
      actions.Profile.updateSession({
        wallet: null,
        access: null,
        self: null,
        accounts: null,
        workspace: null,
        lastRoute: null,
      })
    );
    localStorage.removeItem(LocalStorageKeys.AccessToken);
    enqueueSnackbar('Log out success', {
      variant: 'success',
      autoHideDuration: 3000,
    });
    router.history.push(Paths.Auth.Login);
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      {/* <Button className={classes.action}>
        <PersonIcon />
        <Typography className={classes.actionLabel}>Edit Profile</Typography>
      </Button>
      <Button className={classes.action}>
        <SettingsIcon />
        <Typography className={classes.actionLabel}>Settings</Typography>
      </Button> */}
      <Button className={classes.action} onClick={onLogout}>
        <LogoutIcon />
        <Typography className={classes.actionLabel}>Log out</Typography>
      </Button>
    </Box>
  );
};

export default MenuActions;
