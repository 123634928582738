import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TraxxButton } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask, useRouter, validate } from 'app/utils';
import clsx from 'clsx';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NormalScreening, OrganisationScreening } from './components';

const useStyles = makeStyles((theme) => ({
  root: {},
  typeOption: {
    alignItems: 'flex-start',
    display: 'flex',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  stepButton: {
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const organisation_schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  registered_country: {},
};

const schema = {
  first_name: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  gender: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  last_name: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  nationality: {},
  country_of_birth: {},
  date_of_birth: {},
};

const schemaPassport = {
  type: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  first_name: {
    presence: { allowEmpty: true },
  },
  issuing_state: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  id_number: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  given_names: {
    presence: { allowEmpty: true, message: 'is required' },
  },
  date_of_expiry: {
    presence: { allowEmpty: false, message: 'is required' },
  },
};

const init_inputs = {
  first_name: '',
  case_name: '',
  gender: '',
  nationality: '',
  country_of_birth: '',
  date_of_birth: null,
  identification_type: '',
  type: '',
  issuing_state: '',
  id_number: '',
  given_names: '',
  last_name: '',
  date_of_expiry: null,
  organisation: '',
  registered_country: '',
};
const init_errors = {
  first_name: '',
  case_name: '',
  identification_type: '',
  gender: '',
  nationality: '',
  country_of_birth: '',
  date_of_birth: '',
  issuing_state: '',
  id_number: '',
  given_names: '',
  last_name: '',
  date_of_expiry: '',
  registered_country: '',
  imo_number: '',
};

let mounted = false;

const sortInitialType = (fullKyc, type) => {
  if (fullKyc || type === 'person') return 'passport';
  if (!type) return 'passport';
  return type;
};

const ScreenParams = (props) => {
  const { documentResult, children, newCase, documentType = 'passport', caseId, caseName, className, type, full, onResult, onBack, onNext, ...rest } = props;
  const classes = useStyles();
  const [inputs, setInputs] = useState({
    ...init_inputs,
    screen_type: sortInitialType(full, type),
  });
  const loadTask = useSelector(({ layout }) => layout.loadingTasks);
  const [errors, setErrors] = useState(init_errors);
  const [countries, setCountries] = useState([]);
  const [runLoadCountries] = useAsyncTask('loadCountries');
  const { enqueueSnackbar } = useSnackbar();
  const [runSubmitScreening] = useAsyncTask('submitScreening');
  const [runGetDocumentDetail] = useAsyncTask('getDocumentDetail');
  const router = useRouter();
  const document_id = new URLSearchParams(router.location.search).get('document') || documentResult?.model?.id;

  useEffect(() => {
    mounted = true;
    getCountries();
    if (document_id) {
      getDocumentDetail();
    }
    return () => (mounted = false);
    // eslint-disable-next-line
  }, []);

  const sortDocumentDetail = (model) => {
    if (model.verification_results) {
      model.verification_results.forEach((request) => {
        const { type: docType } = request;
        const data = JSON.parse(request.verification_result_raw?.content);
        if (docType === 'berbix') {
          let additionalInput = {};
          console.log('data.fields: ', data.fields);
          const { given_name, middle_name, family_name, date_of_birth, id_issuer, id_expiry_date, id_number } = data.fields;
          additionalInput = {
            ...additionalInput,
            name: `${given_name?.value} ${middle_name?.value} ${family_name?.value}`,
            given_names: given_name?.value + middle_name?.value,
            last_name: family_name?.value,
            date_of_birth: moment(date_of_birth?.value),
            nationality: id_issuer?.value,
            issuing_state: id_issuer?.value,
            date_of_expiry: id_expiry_date?.value,
            id_number: id_number?.value,
          };
          setInputs({
            ...inputs,
            ...additionalInput,
          });
        }
        if (docType === 'id-card' || docType === 'passport') {
          let { Biographic, Classification } = data;
          let additionalInput = {};
          if (Biographic) {
            additionalInput = {
              ...additionalInput,
              name: Biographic.FullName,
              gender: Biographic.Gender ? 'male' : 'female',
              date_of_birth: moment(Biographic.BirthDate),
            };
          }
          if (Classification?.ClassificationDetails && Classification?.ClassificationDetails.Front) {
            let { IssuerCode, IssueType } = Classification.ClassificationDetails.Front;
            if (IssueType === 'Identification Card') {
              additionalInput = {
                ...additionalInput,
                nationality: IssuerCode,
              };
            }
          }
          setInputs({
            ...inputs,
            ...additionalInput,
          });
        }
      });
    }
  };

  const getDocumentDetail = () => {
    runGetDocumentDetail(async () => {
      const loader = API.Screening.get_document;
      const { model } = await loader(document_id);
      if ((mounted && model.case_id === caseId) || documentResult) {
        sortDocumentDetail(model);
      }
    });
  };

  const getCountries = () => {
    runLoadCountries(async () => {
      const { countries } = await API.Screening.get_countries();
      const map = Object.keys(countries)
        .map((country) => ({ code: country, name: countries[country], value: country }))
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      console.log(countries);
      setCountries(map);
    });
  };
  const validateInputs = () => {
    let check_schema = schema;
    switch (inputs.screen_type) {
      case 'organisation':
        check_schema = organisation_schema;
        break;
      default:
        break;
    }
    let error = validate(inputs, check_schema) || {};

    if (inputs.screen_type === 'passport') error = { ...error, ...validate(inputs, schemaPassport) };

    setErrors(error);
    if (Object.keys(error).length > 0) return false;
    return true;
  };

  const onSubmit = () => {
    runSubmitScreening(async () => {
      if (!validateInputs()) {
        enqueueSnackbar('Please ensure the inputs are correct!');
        return;
      }

      let newInput = { ...inputs };
      if (newInput.date_of_birth) newInput.date_of_birth = moment(newInput.date_of_birth).format('YYYY-MM-DD');
      if (newInput.date_of_expiry) newInput.date_of_expiry = moment(newInput.date_of_expiry).format('YYYY-MM-DD');

      const params = {};
      Object.keys(newInput).forEach((key) => {
        if (newInput[key] !== null && newInput[key] !== '') params[key] = newInput[key];
      });

      console.log(params);

      delete params.screen_type;

      let generator = null;
      switch (inputs.screen_type) {
        case 'simple':
          generator = API.Screening.create_simple_screen;
          break;
        case 'passport':
          generator = API.Screening.create_passport_screen;
          break;
        case 'organisation':
          generator = API.Screening.create_organisation_screen;
          break;
        default:
          return;
      }

      const submitParam = { params, case_name: inputs.case_name };
      if (newCase) {
        submitParam.case_name = caseName;
      } else {
        submitParam.case_id = caseId;
      }

      if (!submitParam.case_name && !submitParam.case_id) submitParam.case_name = inputs.first_name;

      const screenResult = await generator(submitParam);
      const { screens, screen_result_id } = screenResult;

      if (screens[0]) {
        enqueueSnackbar('Screening submitted successfully!', { variant: 'success' });
        if (documentResult) {
          if (typeof onResult === 'function') onResult(screenResult);
          if (typeof onNext === 'function') onNext();
        } else {
          router.history.push(`/screenings/${screen_result_id ? screen_result_id : screens[0].id}/detail`);
        }
      }
    });
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Box mt={2}>
        <Typography variant="body1">Fill in the details.</Typography>
      </Box>
      <Box mt={2}>
        {type === 'person' && <NormalScreening full={full} documentType={documentType} inputs={inputs} setInputs={setInputs} countries={countries} errors={errors} />}
        {type === 'organisation' && <OrganisationScreening inputs={inputs} setInputs={setInputs} countries={countries} errors={errors} />}
      </Box>
      <Box mt={6} display="flex">
        {onBack && <Button onClick={onBack}>Previous</Button>}
        <Box flexGrow={1} />
        <TraxxButton loading={loadTask.submitScreening} onSubmit={onSubmit}>
          Submit
        </TraxxButton>
      </Box>
    </Box>
  );
};

export default ScreenParams;
