export default (str, sentenceCase) => {
  if (!str) str = '';
  str = str.toLowerCase();
  str = str.split('_');

  for (var i = 0; i < str.length; i++) {
    if (!sentenceCase) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    } else {
      if (i === 0) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      } else {
      }
    }
  }

  return str.join(' ');
};
