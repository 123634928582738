import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from 'app/components';
import { API } from 'app/services';
import { useAsyncTask } from 'app/utils';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TableDetail, EditPrivilege } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6),
    },
  },
  cards: {
    marginTop: theme.spacing(1),
  },
}));

let mounted = false;
const UsersDetail = (props) => {
  const { children, className, staticContext, ...rest } = props;
  const classes = useStyles();
  const token = useSelector(({ profile }) => profile.access?.token);
  const { user_id: userId } = useParams();
  const [model, setModel] = useState();
  const [runReload] = useAsyncTask('reloadUsersDetail');

  useEffect(() => {
    mounted = true;
    return () => (mounted = false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (token) onReload();
    // eslint-disable-next-line
  }, [token]);

  const onReload = () => {
    runReload(async () => {
      const result = await API.Users.detail_users(userId);
      if (mounted) setModel(result.model);
    });
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Title prefix="User detail" />
      <Grid container className={clsx(classes.cards, className)} spacing={2}>
        <Grid item md={6} xs={12}>
          <Card>
            <CardContent>{model && <TableDetail data={model} />}</CardContent>
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Privileges</Typography>
              {model && <EditPrivilege onUpdate={onReload} user={model} />}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UsersDetail;
